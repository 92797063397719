import { format } from "date-fns";
import { es } from "date-fns/locale";
import InvoiceCell from "./InvoiceCell";
import StatusCell, {
  sortComparator as statusCellSortComparator,
  valueFormatter as statusCellValueFormatter,
} from "./StatusCell";
import { formatDateRange, formatMexicanPeso } from "./utils";

const columsAgentComisions = [
  {
    field: "invoicePDF",
    width: 120,
    headerName: "Facturas",
    renderCell: InvoiceCell,
  },
  {
    field: "status",
    width: 200,
    headerName: "Estatus",
    sortComparator: statusCellSortComparator,
    valueFormatter: statusCellValueFormatter,
  },
  {
    field: "salesAmout",
    width: 200,
    headerName: "Monto de la venta",
    valueFormatter: ({ value }) => {
      if (typeof value === "number") return formatMexicanPeso(value);
    },
  },
  {
    field: "salesCommissionAmountWithTaxes",
    width: 200,
    headerName: "Monto de la comisión",
    valueFormatter: ({ value }) => {
      if (typeof value === "number") return formatMexicanPeso(value);
    },
  },

  {
    field: "timestamp",
    width: 200,
    headerName: "Fecha de solicitud",
    valueFormatter: ({ value }) => {
      if (typeof value === "number") {
        return format(value, "dd'/'MM'/'yyyy", {
          locale: es,
        });
      }
    },
  },
  {
    field: "timestampStart",
    width: 200,
    headerName: "Periodo",
    renderCell: ({ row }) =>
      formatDateRange(row.timestampStart, row.timestampEnd),
  },
  {
    field: "timestampPaid",
    width: 200,
    headerName: "Fecha de pago",
    valueFormatter: ({ value }) => {
      if (typeof value === "number") {
        return format(value, "dd'/'MM'/'yyyy", {
          locale: es,
        });
      }
    },
  },
  {
    field: "timestampRejected",
    width: 200,
    headerName: "Fecha de rechazo",
    valueFormatter: ({ value }) => {
      if (typeof value === "number") {
        return format(value, "dd'/'MM'/'yyyy", {
          locale: es,
        });
      }
    },
  },
];

export default columsAgentComisions;
