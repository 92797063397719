import logoclupp from "../../Assets/SVG/logo-cluppBCO2.svg";

export default function CatalogHead() {
  return (
    <div
    style={{
      position: "absolute",
      zIndex: 1201,
      width: "100%",
      backgroundColor: "#00bbee",
      top: "0px",
    }}
  >
    <a href="https://clupp.com.mx/" target="_blank" rel="noreferrer">
      <img src={logoclupp} alt="clupp-logo" style={{ height: "80px" }} />
    </a>
  </div>
  )
}
