import Dialog from "./Dialog";
import { useAgentAuth } from "../AuthAgentContext";
import {
  Box,
  TextField,
  Autocomplete,
  Button,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { db } from "../Firebase/firebase";

export default function SelectOtherAgentModal({ isOpen, onClose }) {
  const [allAgents, setAllAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState();
  const { otherAgent, setOtherAgent } = useAgentAuth();

  useEffect(() => {
    const unsubscribe = db
      .collection("agentProspects")
      .where("isApproved", "==", true)
      .onSnapshot((snap) => {
        const data = snap.docs.map((item) => {
          const docData = item.data();

          return {
            email: docData.email,
            id: item.id,
            firstName: docData.firstName,
            lastName: docData.lastName,
            lastName2: docData.lastName2,
            timestamp: docData.timestamp,
            phone: docData.phone,
            stats: docData.stats || {},
            uid: item.id,
          };
        });

        setAllAgents(data);
      });

    return unsubscribe;
  }, []);

  function handleOnChange(val) {
    setSelectedAgent(val);
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Selecciona un agente para ver sus comisiones"
      content={
        <Box sx={{ display: "grid", gap: "14px" }}>
          <Typography>
            Desde esta sección podrás seleccionar algún agente registrado para
            ver sus comisiones y pólizas vendidas
          </Typography>
          <Box>
            <Typography variant="body1">
              {otherAgent
                ? `Agente ${otherAgent?.firstName} seleccionado`
                : " "}
            </Typography>
          </Box>
          <Autocomplete
            id="Agentes"
            freeSolo
            value={otherAgent}
            onChange={(e, value) => {
              handleOnChange(value);
            }}
            options={allAgents}
            getOptionLabel={(option) =>
              `${option?.firstName} ${option?.lastName} - ${option?.email}`
            }
            renderInput={(params) => (
              <TextField fullWidth {...params} label="Selecciona un agente" />
            )}
          />
        </Box>
      }
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOtherAgent(selectedAgent);
              localStorage.setItem(
                "selectedAgent",
                JSON.stringify(selectedAgent)
              );
              onClose();
            }}
          >
            Confirmar
          </Button>
        </>
      }
    />
  );
}
