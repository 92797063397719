import React from "react";
import Modal from "@mui/material/Modal";
import devicesNot from "../../Assets/SVG/devicesNot.svg";
import "./ModalCobertura.css";

export default function ModalDispositivosCobertura({ selectedMobility }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const terms = "https://clupp.com.mx/blog/cg-clupp/";

  const body = (
    <div className="paper">
      <img src={devicesNot} alt="car-icon" />
      <p className="modal-cobertura-title">¿Qué no cubrimos?</p>
      <p className="modal-cobertura-subtitle">
        {selectedMobility === 3
          ? `Actualmente los casos y riesgos que no cubrimos son:`
          : `Además de que no quedan cubiertas las coberturas marcadas con ❌. Actualmente los casos y riesgos que no cubrimos son:`}
      </p>
      <ul style={{ flexDirection: "column" }}>
        <li>Daños por deterioro del dispositivo.</li>
        <li>Fraude o abuso de confianza.</li>
        <li>
          Daños resultantes de cualquier proceso de reparación, limpieza, ajuste
          o servicios de mantenimiento.
        </li>
      </ul>
      <a href={terms} target="_blank" rel="noreferrer">
        <button className="terms">Ver todas las condiciones generales</button>
      </a>
      <button className="entendido-btn" onClick={handleClose}>
        Entendido
      </button>
    </div>
  );

  return (
    <div>
      <p onClick={handleOpen} className="link-modal">
        ¿Qué no cubrimos?
      </p>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
}
