import { Link } from "react-router-dom";
import { useEffect, useState, Fragment } from "react";
import { slide as MenuB } from "react-burger-menu";
import "./Menu.css";
import clupplogo from "../../Assets/SVG/CluppNG.svg";
import logoclupp from "../../Assets/SVG/logo-cluppBCO2.svg";
import plus from "../../Assets/SVG/plus.svg";
import email from "../../Assets/SVG/email.svg";
//import like from '../../Assets/SVG/like.svg';
import whatsapp from "../../Assets/SVG/whatsapp.svg";
import faqs from "../../Assets/SVG/informacion.svg";
//import { db } from '../../Firebase/firebase';
//import { useHistory } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { useAgentAuth } from "../../AuthAgentContext";
import ModalAgregar from "../ModalAgregar/ModalAgregar";
import CustomLink from "../CustomLink";
import getWhatsAppUrl from "../../utils/getWhatsAppUrl";
import FormDialog from "../NuevaCotizacionDialog/FormDialog";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import { Typography } from "@mui/material";
import styles from "./DashboardHeader.module.css";
import BuenFinBanner from "../BuenFinBanner/BuenFinBanner";
import useQuery from "../../hooks/useQuery";
import AGENTES from "../../utils/agentes";

const Menu = ({ viewOption }) => {
  //const history = useHistory();
  const [docRef, setDocRef] = useState();
  const { currentUser, logout } = useAuth();
  const { isAgentLogged, setCurrentAgentUser } = useAgentAuth();
  //const [currentVehicle, setCurrentVehicle] = useState();
  const [goMenu, setGoMenu] = useState({ menuOpen: false });
  const [openModal, setOpenModal] = useState(false);
  const { currentAgentUser } = useAgentAuth();

  const query = useQuery();
  const isAgentQuote = query.get("utm_source") === "agentes";

  const whatsappUrl = getWhatsAppUrl(
    `Hola acabo de visitar su página. Mi número de cotización% es ${currentUser?.uid}. Mi nombre es`
  );
  //console.log(currentUser.uid);
  useEffect(() => {
    const getRef = localStorage.getItem("docRef");
    setDocRef(getRef);
    //const getCurrentVehicle = localStorage.getItem('currentVehicle')
    //setCurrentVehicle(getCurrentVehicle)
  }, []);

  const whatsappUd = getWhatsAppUrl(
    `Hola acabo de visitar su página. Mi número de cotización% es ${docRef}. Mi nombre es`
  );

  async function handleLogout() {
    logout();
    localStorage.clear();
    setCurrentAgentUser(null);
  }

  const handlerOpenModal = () => setOpenModal((prev) => !prev);

  const handleStateChange = (goMenu) => setGoMenu({ menuOpen: goMenu.isOpen });

  const closeMenu = () => setGoMenu({ menuOpen: false });

  //   const toggleMenu= ()=> {
  //     setGoMenu(goMenu=> ({menuOpen: !goMenu.menuOpen}))
  //   }

  /*const enviarDatos = ()=>{
        const correo = document.getElementById("correo2").value;
        const what= document.getElementById("whatsapp2").value;
        const mobility= document.getElementById("mobi").value;
        console.log(what); 
        console.log(correo); 
        db.collection("prospectsNew").doc(docRef).update({
            "email": correo,
            "whatsapp": what,
            "qStatus": Number(2),
            timestamp: Date.now(),
        })
        .then(() => {
            db.collection('prospectsNew').doc(docRef).collection('vehicles').doc(currentVehicle)
            .update({
                mobility: mobility
            })
        })
        .then(() => {
            history.push('/contrata')
        })
    }*/

  const shouldDisplayBuenFinBanner = false; //!isAgentQuote
  return (
    <Fragment>
      {shouldDisplayBuenFinBanner && <BuenFinBanner />}
      <div className="menuB">
        <FormDialog open={openModal} onClose={handlerOpenModal} />
        <MenuB
          right
          isOpen={goMenu.menuOpen}
          onStateChange={(goMenu) => handleStateChange(goMenu)}
        >
          <div className="menu-header">
            <div>
              <img src={clupplogo} alt="clupp-logo" />
            </div>
          </div>
          <div className="menu-title">
            <p>Clupp {isAgentLogged && "Agentes"}</p>
            <p>Seguro de movilidad</p>
          </div>
          {currentAgentUser && (
            <CustomLink to="/agentes/dashboard">
              <HomeOutlinedIcon sx={{ color: "#bababa" }} />
              Ir al menú principal
            </CustomLink>
          )}
          {viewOption !== "cotizar" && viewOption !== "agentDashboard" && (
            <div className="menu-options">
              {!currentAgentUser ? (
                <CustomLink id="home" className="menu-item" to="/">
                  <img src={plus} alt="nueva-cotizacion" />
                  Nueva cotización
                </CustomLink>
              ) : (
                <a className="menu-item" onClick={handlerOpenModal}>
                  <img src={plus} alt="nueva-cotizacion" />
                  Nueva cotización
                </a>
              )}
              {!currentAgentUser && (
                <a id="about" className="menu-item" onClick={closeMenu}>
                  <div className="modal">
                    <ModalAgregar />
                  </div>
                </a>
              )}
              {/*<a id="contact" className="menu-item"  href={descuento} target='_blank' rel="noreferrer">
                                <img src={etiqueta} alt='conseguir-descuento' />
                                Conseguir un descuento
                            </a>*/}
              {!currentAgentUser && (
                <a
                  id="contact"
                  className="menu-item"
                  href="#enviar"
                  onClick={closeMenu}
                >
                  <img src={email} alt="enviar-cotizacion" />
                  Enviarme la cotización
                </a>
              )}
              {/*<a id="contact" className="menu-item" onClick={enviarDatos}>
                                <img src={like} alt='lo-quiero' />
                                ¡Lo quiero!
                            </a>*/}
            </div>
          )}
          <hr></hr>
          {viewOption === "cotizar" ? (
            <a
              id="contact"
              className="menu-item"
              href={whatsappUrl}
              target="_blank"
              rel="noreferrer"
            >
              <img src={whatsapp} alt="whatsapp" />
              Contactar por whatsapp
            </a>
          ) : (
            !viewOption === "agentesDashboard" && (
              <a
                id="contact"
                className="menu-item"
                href={whatsappUd}
                target="_blank"
                rel="noreferrer"
              >
                <img src={whatsapp} alt="whatsapp" />
                Contactar por whatsapp
              </a>
            )
          )}
          {/* only displaying this options in dashboard section */}
          {viewOption === "agentDashboard" ? (
            <div className="menu-options">
              <CustomLink to="/agentes/dashboard">
                <AccountCircleIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                Perfil
              </CustomLink>
              <CustomLink to="/agentes/cotizaciones">
                <SummarizeOutlinedIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                Mis cotizaciones
              </CustomLink>
              <CustomLink onClick={handleLogout} to="/agentes/dashboard">
                <LogoutIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                Cerrar sesión
              </CustomLink>
            </div>
          ) : (
            <CustomLink to="/preguntas-frecuentes">
              <img src={faqs} alt="peguntas-frecuentes" />
              Preguntas frecuentes
            </CustomLink>
          )}
        </MenuB>
      </div>

      {viewOption !== "agentDashboard" && (
        <div className="menuWeb">
          <div className="container-menu">
            <div className="container-seleccion">
              <div className="seleccion-logo">
                {isAgentLogged ? (
                  <a href="/agentes/dashboard">
                    <div
                      className={styles.bandage}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img src={logoclupp} alt="clupp-logo" />
                      <Typography variant="body1">Agentes</Typography>
                    </div>
                  </a>
                ) : (
                  <a
                    href="https://clupp.com.mx/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={logoclupp} alt="clupp-logo" />
                  </a>
                )}
              </div>
              <div className="menu-d">
                {viewOption === "cotizar" ? (
                  <div className="container-seleccion">
                    {viewOption === "cotizar" ? (
                      <div className="seleccion">
                        <div className="seleccion-a">
                          <a
                            className="a"
                            href={whatsappUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Contactar por whatsapp
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div className="seleccion">
                        <div className="seleccion-a">
                          <a
                            className="a"
                            href={whatsappUd}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Contactar por whatsapp
                          </a>
                        </div>
                      </div>
                    )}
                    <div className="seleccion">
                      <div className="seleccion-a">
                        <CustomLink to="/preguntas-frecuentes" className="a">
                          Preguntas frecuentes
                        </CustomLink>
                      </div>
                    </div>
                    {currentAgentUser && (
                      <div className="seleccion">
                        <div className="seleccion-a">
                          <Link className="a" to="/agentes/dashboard">
                            Ir al menú principal
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="aling-m">
                    <div className="container-seleccion">
                      <div className="seleccion-o">
                        <div className="seleccion-a">
                          {!currentAgentUser ? (
                            <CustomLink id="home" className="a" to="/">
                              Nueva <br /> cotización
                            </CustomLink>
                          ) : (
                            <a className="a" onClick={handlerOpenModal}>
                              Nueva <br /> cotización
                            </a>
                          )}
                        </div>
                      </div>
                      {!currentAgentUser && (
                        <div className="seleccion-o">
                          <div className="seleccion-a">
                            <a id="about">
                              <ModalAgregar />
                            </a>
                          </div>
                        </div>
                      )}
                      {/*<div className="seleccion-o">
                                        <div className="seleccion-a">
                                            <a id="contact" className="a"  href={descuento} target='_blank' rel="noreferrer">
                                                Conseguir un descuento
                                            </a>
                                        </div>
                                        </div>*/}
                      {!currentAgentUser && (
                        <div className="seleccion-o">
                          <div className="seleccion-a">
                            <a
                              id="contact"
                              className="a"
                              href="#enviar"
                              onClick={closeMenu}
                            >
                              Enviarme la cotización
                            </a>
                          </div>
                        </div>
                      )}
                      {/*<div className="seleccion-o">
                                        <div className="seleccion-a">
                                            <a id="contact" className="a" onClick={enviarDatos}>
                                                ¡Lo quiero!
                                            </a>
                                        </div>
                                    </div>*/}
                      {viewOption === "cotizar" ? (
                        <div className="seleccion-o">
                          <div className="seleccion-a">
                            <a
                              className="a"
                              href={whatsappUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Contactar por whatsapp
                            </a>
                          </div>
                        </div>
                      ) : (
                        <div className="seleccion-o">
                          <div className="seleccion-a">
                            <a
                              className="a"
                              href={whatsappUd}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Contactar por whatsapp
                            </a>
                          </div>
                        </div>
                      )}

                      <div className="seleccion-o">
                        <div className="seleccion-a">
                          <CustomLink to="/preguntas-frecuentes" className="a">
                            Preguntas frecuentes
                          </CustomLink>
                        </div>
                      </div>
                      {currentAgentUser && (
                        <div className="seleccion-o">
                          <div className="seleccion-a">
                            <Link className="a" to="/agentes/dashboard">
                              Ir al menú principal
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Menu;
