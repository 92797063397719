import { Link } from "react-router-dom";
import "./ElegirMovilidad.css";
import FormatAmount from "../../FormatAmout/FormatAmout";
import ModalPasa from "../ModalPasa/ModalPasa";
import { Fragment } from "react";
import getTotalPremium from "../../utils/getPremium";
import { getType } from "../../utils/isADevice";

const ElegirMovilidad = ({
  amounts,
  selectedMobility,
  setSelectedMobility,
  mobilityDescription,
  vehicleData,
  vehicleType,
  paymentPeriod,
  onSelectMobility,
}) => {
  const handleMobility = (mobility, premium) => {
    onSelectMobility(mobility, premium);
    setSelectedMobility({ mobility, premium });
  };

  function getPeriodicityLabel(periodicity) {
    switch (periodicity) {
      case "month":
        return "al mes";
      case "3month":
        return "cada 3 meses";
      case "6month":
        return "cada 6 meses";
      case "year":
        return "al año";
      default:
        return "";
    }
  }
  const periodicityLabel = getPeriodicityLabel("month");
  return (
    <div className="elegir-movilidad">
      <p>
        Seguro de cobertura <b>AMPLIA</b> para tu {vehicleData?.brand}{" "}
        {vehicleData?.model} {vehicleData?.year ? vehicleData.year : ""}
      </p>
      {vehicleData.type === "bike" ||
        vehicleData.type === "eBike" ||
        (vehicleData.type === "scooter" && (
          <>
            <p>
              Para tu{" "}
              <b>
                {getType(vehicleType)}, {vehicleData.brand}, {vehicleData.model}
              </b>
              , te ofrecemos el siguiente plan de movilidad:
            </p>
            <p>Nuestro seguro se adapta a la frecuencia con la que conduces</p>
          </>
        ))}
      {(vehicleData.type === "auto" ||
        vehicleData.type === "moto" ||
        vehicleData.type === "otro") && (
        <>
          <h3
            style={{ marginBottom: "12px" }}
          >{`Elige tu plan de movilidad:`}</h3>{" "}
          <p style={{ margin: "0", marginBottom: "2rem" }}>
            (todos son de cobertura <b>AMPLIA</b>)
          </p>
        </>
      )}
      <div className="elegir-movilidad-content">
        {vehicleData.type === "auto" ||
        vehicleData.type === "moto" ||
        vehicleData.type === "otro" ? (
          <div className="cards-container">
            <div
              className={
                selectedMobility.mobility === 1 ? "selected-plan" : "plan"
              }
              onClick={() => handleMobility(1, amounts.premium1)}
            >
              <h4>Reducida</h4>
              {selectedMobility.mobility !== 1 && (
                <p className="kilometers">{mobilityDescription(1)}</p>
              )}
              <p className="premium">
                $
                {amounts &&
                  FormatAmount(getTotalPremium(amounts.premium1, "month")[2])}
              </p>
              <p className="normal-plan month">{periodicityLabel}</p>
              {selectedMobility.mobility === 1 && (
                <>
                  <p className="selected-kilometers">
                    {mobilityDescription(1)}
                  </p>
                  <p className="normal-plan">
                    41% de nuestros clientes eligen este plan
                  </p>
                </>
              )}
            </div>

            <div
              className={
                selectedMobility.mobility === 2 ? "selected-plan" : "plan"
              }
              onClick={() => handleMobility(2, amounts.premium2)}
            >
              <h4>Normal</h4>
              {selectedMobility.mobility !== 2 && (
                <p className="kilometers">{mobilityDescription(2)}</p>
              )}
              <p className="premium">
                $
                {amounts &&
                  FormatAmount(getTotalPremium(amounts.premium2, "month")[2])}
              </p>
              <p className="normal-plan month">{periodicityLabel}</p>
              {selectedMobility.mobility === 2 && (
                <>
                  <p className="selected-kilometers">
                    {mobilityDescription(2)}
                  </p>
                  <p className="normal-plan">
                    51% de nuestros clientes eligen este plan
                  </p>
                </>
              )}
            </div>

            {(selectedMobility.mobility === 1 ||
              selectedMobility.mobility === 2 ||
              selectedMobility.mobility === 3) && (
              <div
                className={
                  selectedMobility.mobility === 3 ? "selected-plan" : "plan"
                }
                onClick={() => handleMobility(3, amounts.premium3)}
              >
                <h4>
                  {vehicleData &&
                  vehicleData.features.includes("Soy conductor de plataforma")
                    ? "Frecuente"
                    : "Intensiva"}
                </h4>
                {selectedMobility.mobility !== 3 && (
                  <p className="kilometers">{mobilityDescription(3)}</p>
                )}
                <p className="premium">
                  $
                  {amounts &&
                    FormatAmount(getTotalPremium(amounts.premium3, "month")[2])}
                </p>
                <p className="normal-plan month">{periodicityLabel}</p>
                {selectedMobility.mobility === 3 && (
                  <>
                    <p className="selected-kilometers">
                      {mobilityDescription(3)}
                    </p>
                    <p className="normal-plan">
                      8% de nuestros clientes eligen este plan
                    </p>
                  </>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="cards-container">
            <div
              className={
                selectedMobility.mobility === 2 ? "selected-plan" : "plan"
              }
              onClick={() => {
                handleMobility(2, amounts.premium2);
                // saveMobility(2, amounts.premium2);
              }}
            >
              <h4>Normal</h4>
              <p className="premium-normal">
                $
                {amounts &&
                  FormatAmount(getTotalPremium(amounts.premium2, "month")[2])}
              </p>
              <p className="normal-plan month-normal">{periodicityLabel}</p>
            </div>
          </div>
        )}
        <div className="links">
          {(vehicleData.type === "auto" ||
            vehicleData.type === "moto" ||
            vehicleData.type === "otro") && (
            <Fragment>
              {!vehicleData.features.includes(
                "Soy conductor de plataforma"
              ) && (
                <p>
                  <Link to="/conoce-planes">
                    No sé cuantos kilómetros recorro al mes
                  </Link>
                </p>
              )}
              <ModalPasa />
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default ElegirMovilidad;
