import { useEffect, useState } from "react";
import { db } from "../Firebase/firebase";
import { useAgentAuth } from "../AuthAgentContext";
import { subBusinessDays } from "date-fns";

async function getProspectVehicle(snapshotDocs = []) {
  const prospectsVehcileResponse = await snapshotDocs.map(async (doc) => {
    const docVehicle = await db
      .collection(`prospectsNew/${doc.id}/vehicles`)
      .orderBy("timestamp", "desc")
      .limit(1)
      .get();
    const dataUser = {
      ...doc.data(),
      id: doc.id,
      userName: doc.data().firstName
        ? `${doc.data().firstName} ${doc.data().lastName} ${
            doc.data().lastName2
          }`
        : "",
      lastVehicleId: docVehicle.docs[0]?.id,
      lastVehicle: docVehicle.docs[0]?.data()
        ? `${docVehicle.docs[0]?.data().brand} ${
            docVehicle.docs[0]?.data().model
          } ${docVehicle.docs[0]?.data().year}`
        : "",
    };

    return dataUser;
  });
  return await Promise.all(prospectsVehcileResponse);
}

export default function useQuoteHistory(dayLimit = 0) {
  const daySince = subBusinessDays(new Date(), dayLimit).getTime();
  const [loading, setLoading] = useState(true);
  const [quotations, setQuotations] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState(quotations);
  const { currentAgentUser, otherAgent } = useAgentAuth();

  const agentUid = otherAgent ? otherAgent?.id : currentAgentUser?.uid;

  useEffect(() => {
    let unsubscribe;
    setLoading(true)
    if (agentUid && dayLimit) {
      unsubscribe = db
        .collection("prospectsNew")
        .where("agentUID", "==", agentUid)
        .where("timestamp", ">", daySince)
        .onSnapshot(async (snapshot) => {
          const vehiclesArr = await getProspectVehicle(snapshot.docs);

          setQuotations(vehiclesArr);
          setFilteredProjects(vehiclesArr);

          setLoading(false);
        });
    } else if (agentUid) {
      unsubscribe = db
        .collection("prospectsNew")
        .where("agentUID", "==", agentUid)
        .onSnapshot(async (snapshot) => {
          const vehiclesArr = await getProspectVehicle(snapshot.docs);

          setQuotations(vehiclesArr);
          setFilteredProjects(vehiclesArr);
          setLoading(false);
        });
    } else {
      setLoading(false)
    }
    return unsubscribe;
  }, [currentAgentUser, agentUid]);

  return {
    loading,
    quotations,
    setQuotations,
    filteredProjects,
    setFilteredProjects,
  };
}
