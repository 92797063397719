import {
  Menu,
  MenuItem
} from "@mui/material";

import { Link } from "react-router-dom";

export default function DottedMenuOptions({
  open,
  handleClose,
  anchorEl,
  typeMenu,
}) {
  return (
    <>
      {typeMenu === "dotted" && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{ zIndex: "1060" }}
        >
          <Link style={{textDecoration: "none", color: "inherit"}} to="/agentes/cotizaciones">
            <MenuItem
              component="div"
            >
                Ver todas las cotizaciones
            </MenuItem>
          </Link>
        </Menu>
      )}
    </>
  );
}