import { useAgentAuth } from "../../AuthAgentContext";
import { Typography, Box, Paper } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Redirect } from "react-router-dom";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";
import { db } from "../../Firebase/firebase";
import { useEffect, useState } from "react";

export default function VideoTutoriales() {
  const [loomsVideoTutorials, setLoomsVideoTutorials] = useState([]);

  const { currentAgentUser } = useAgentAuth();

  useEffect(() => {
    const unsubscribe = db
      .collection("IT/agents/tutorials")
      .orderBy("timestamp", "asc")
      .where("deleted", "==", false)
      .onSnapshot((doc) => {
        const docs = doc.docs.map((info) => info.data());
        setLoomsVideoTutorials(docs);
      });
    return unsubscribe;
  }, []);

  const sortVideos = [...loomsVideoTutorials].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  console.log(loomsVideoTutorials);

  if (!currentAgentUser) return <Redirect to="/agentes/inicio-de-sesion" />;

  return (
    <Paper sx={{ minHeight: "100vh" }}>
      <DashboardHeader />
      <Box
        sx={{
          margin: "20px 20px 0 20px",
          paddingBottom: "30px",
          marginTop: "80px",
        }}
      >
        <Box sx={{ maxWidth: "900px", margin: "0 auto" }}>
          <Typography
            sx={{ marginTop: "30px", marginBottom: "20px", fontWeight: "600" }}
            variant="h4"
          >
            Videos tutoriales
          </Typography>
          <Typography variant="body1">
            Te damos la bienvenida a los tutoriales de nuestro portal de
            agentes. Aquí podrás consultar algunos videos para resolver algunas
            dudas o saber cómo sacar más provecho a tus herramientas.
          </Typography>
        </Box>
        {sortVideos.map((item) => (
          <Box key={item.timestamp}>
            <Box
              sx={{
                maxWidth: "900px",
                margin: "0 auto",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "60px",
                padding: "10px",
                flexDirection: ["column", "row"],
                border: "1px solid #00bbee",
                borderRadius: "3px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  marginBottom: "5px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ fontWeight: 600 }}>{item.name}</Typography>
                <Typography>{item.description}</Typography>
              </Box>
              <Box>
                <a
                  style={{
                    textDecoration: "none",
                  }}
                  href={`${item?.url}?hide_owner=true`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    sx={{ color: "white", width: "150px", marginLeft: "10px" }}
                  >
                    Ver video tutorial
                  </LoadingButton>
                </a>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Paper>
  );
}
