import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function NotAgentDialog({
  open,
  handleClose,
  logout,
  toRegister,
}) {
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {" "}
          {logout ? (
            <IconButton
              aria-label="close"
              onClick={logout}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              size="large">
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Antes de comenzar a ofrecer el seguro de Clupp necesitamos algunos
            datos extra. Si ya registraste tus datos espera nuestra
            confirmación.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={logout} variant="outlained">
            Cerrar
          </Button>
          <Button onClick={toRegister} variant="contained">
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
