import { MIN_PRODUCT_VALUES } from "../data/quotes";

export default function isValidProductValue({ type, value = 0 }) {
  if (!(type in MIN_PRODUCT_VALUES)) {
    type = "other";
  }
  if (!Boolean(value)) return false;
  if (typeof value !== "number") return false;

  const minValue = MIN_PRODUCT_VALUES[type] || MIN_PRODUCT_VALUES["other"];
  return value >= minValue;
}
