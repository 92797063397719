import React from "react";
import Modal from "@mui/material/Modal";
import carIcon from "../../Assets/SVG/Group 9231.svg";
import "./ModalCobertura.css";

export default function ModalCoberturaPlats(vehicleData) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const terms = "https://clupp.com.mx/blog/cg-clupp/";

  const body = (
    <div className="paper">
      <img src={carIcon} alt="car-icon" />
      <p className="modal-cobertura-title">¿Qué no cubrimos?</p>
      <p className="modal-cobertura-subtitle">
        Actualmente algunos bienes y riesgos que no cubrimos son:
      </p>
      <ul>
        <div>
          <li>Vehículos de carga</li>
          <li>Vehículos de carreras</li>
        </div>
      </ul>
      <a href={terms} target="_blank" rel="noreferrer">
        <button className="terms">Ver todas las condiciones generales</button>
      </a>
      <button className="entendido-btn" onClick={handleClose}>
        Entendido
      </button>
    </div>
  );

  return (
    <div>
      <p onClick={handleOpen} className="link-modal">
        ¿Qué no cubrimos?
      </p>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
}
