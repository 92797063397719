import {
  Alert,
  Avatar,
  Box,
  Button,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import position1 from "../../Assets/PNG/Position1.png";
import position2 from "../../Assets/PNG/Position2.png";
import position3 from "../../Assets/PNG/Position3.png";
import copperMedal from "../../Assets/PNG/copperMedal.png";
import silverMedal from "../../Assets/PNG/silverMedal.png";
import goldMedal from "../../Assets/PNG/goldMedal.png";
import RedeemIcon from "@mui/icons-material/Redeem";
import TitlesHeadersRange from "./TitleHeaderRange";
import sadCluppcito from "../../Assets/PNG/Clupp_ThumbsUp.png";

const Podium = ({
  winners,
  rankingRange,
  currentAgentUser,
  isOnMobile,
  isAdmin,
}) => {
  const headerTitles = {
    weekly: "Ganadores de la semana pasada",
    monthly: "Ganadores del mes pasado",
    quarterly: "Ganadores del trimestre pasado",
    yearly: "Ganadores del año pasado",
  };

  const headerTitlesMessage = {
    weekly: "semanal",
    monthly: "mensual",
    quarterly: "trimestral",
    yearly: "anual",
  };

  const currentAgent = winners.find((item) => item.id === currentAgentUser.uid);

  const currentAgentIsAWinner = currentAgent;
  const alreadyColleted = currentAgent?.rewardClaimedTimestamp;
  const activeButton = currentAgent && !alreadyColleted;

  const rangeTitles = {
    weekly: "semanal",
    monthly: "mensual",
    quarterly: "trimestral",
    yearly: "anual",
  };

  return winners && Boolean(winners.length > 0) ? (
    <>
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: 600, textAlign: "center", marginLeft: "30px" }}
      >
        <TitlesHeadersRange
          title={headerTitles[rankingRange]}
          rankingRange={rankingRange}
          last={true}
        />
      </Typography>
      <Stack
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="flex-end"
          spacing={[2, -15]}
        >
          {/* Position 2 */}
          <Grid
            item
            xs={4}
            container
            direction="column"
            alignItems="center"
            justifySelf={"flex-end"}
            sx={{
              marginBottom: ["-35px", "-45px"],
            }}
          >
            <img
              src={silverMedal}
              style={{
                width: "40px",
                height: "40px",
                zIndex: "20",
                marginBottom: "-40px",
                marginLeft: "50px",
              }}
              alt="silver-medal"
            />
            {isAdmin ? (
              <Tooltip title={<Typography variant="body2">{`Correo: ${winners[1].email}`}</Typography>}>
                <Avatar
                  sx={{ width: "85px", height: "85px" }}
                  src={winners[1]?.profilePictureURL}
                />
              </Tooltip>
            ) : (
              <Avatar
                sx={{ width: "85px", height: "85px" }}
                src={winners[1]?.profilePictureURL}
              />
            )}

            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 600,
                marginTop: "5px",
                textOverflow: "ellipsis",
                maxWidth: "100%",
                color:
                  currentAgentUser.uid === winners[1].id ? "#00CA7D" : "#000",
              }}
            >
              {winners[1]?.username?.length > 18
                ? `${winners[1].username.substring(0, 18)}...`
                : winners[1]?.username || "#2 Agente"}
            </Typography>
            <p style={{ marginTop: "-8px" }}>
              {winners[1]?.score?.toLocaleString("es-MX") || "0"} EXP
            </p>
          </Grid>
          {/* Position 1 */}
          <Grid item xs={4} container direction="column" alignItems="center">
            <img
              src={goldMedal}
              style={{
                width: "45px",
                height: "45px",
                zIndex: "20",
                marginBottom: "-40px",
                marginLeft: "50px",
              }}
              alt="gold-medal"
            />
            {isAdmin ? (
              <Tooltip title={<Typography variant="body2">{`Correo: ${winners[0].email}`}</Typography>}>
                <Avatar
                  sx={{ width: "85px", height: "85px" }}
                  src={winners[0]?.profilePictureURL}
                />
              </Tooltip>
            ) : (
              <Avatar
                sx={{ width: "85px", height: "85px" }}
                src={winners[0]?.profilePictureURL}
              />
            )}

            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 600,
                marginTop: ["7px", "5px"],
                textOverflow: "ellipsis",
                maxWidth: "100%",
                color:
                  currentAgentUser.uid === winners[0].id ? "#00CA7D" : "#000",
              }}
            >
              {winners[0]?.username?.length > 18
                ? `${winners[0].username.substring(0, 18)}...`
                : winners[0]?.username || "#1 Agente"}
            </Typography>

            <p style={{ marginTop: "-8px" }}>
              {winners[0]?.score?.toLocaleString("es-MX") || "0"} EXP
            </p>
          </Grid>

          {/* Position 3 */}
          <Grid
            item
            xs={4}
            container
            direction="column"
            alignItems="center"
            sx={{
              marginBottom: ["-60px", "-70px"],
            }}
          >
            <img
              src={copperMedal}
              style={{
                width: "35px",
                height: "35px",
                zIndex: "20",
                marginBottom: "-35px",
                marginLeft: "45px",
              }}
              alt="copper-medal"
            />
            {isAdmin ? (
              <Tooltip title={<Typography variant="body2">{`Correo: ${winners[2].email}`}</Typography>}>
                <Avatar
                  sx={{ width: "85px", height: "85px" }}
                  src={winners[2]?.profilePictureURL}
                />
              </Tooltip>
            ) : (
              <Avatar
                sx={{ width: "85px", height: "85px" }}
                src={winners[2]?.profilePictureURL}
              />
            )}
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 600,
                marginTop: ["7px", "5px"],
                textOverflow: "ellipsis",
                maxWidth: "100%",
                color:
                  currentAgentUser.uid === winners[2].id ? "#00CA7D" : "#000",
              }}
            >
              {winners[2]?.username?.length > 18
                ? `${winners[2].username.substring(0, 18)}...`
                : winners[2]?.username || "#3 Agente"}
            </Typography>
            <p style={{ marginTop: "-8px" }}>
              {winners[2]?.score?.toLocaleString("es-MX") || "0"} EXP
            </p>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" spacing={[2, -15]}>
          <Grid
            item
            xs={4}
            container
            justifyContent="center"
            alignItems={"flex-end"}
          >
            <img
              src={position2}
              alt="position2"
              style={{
                width: isOnMobile ? "160px" : "250px",
                height: isOnMobile ? "85px" : "100px",
                borderTopRightRadius: "6px",
                borderTopLeftRadius: "6px",
              }}
            />
          </Grid>
          <Grid
            item
            xs={4}
            container
            justifyContent="center"
            alignItems={"flex-end"}
          >
            <img
              src={position1}
              alt="position1"
              style={{
                borderTopRightRadius: "6px",
                borderTopLeftRadius: "6px",
                maxWidth: isOnMobile ? "170px" : "",
              }}
            />
          </Grid>
          <Grid
            item
            xs={4}
            container
            justifyContent="center"
            alignItems={"flex-end"}
          >
            <img
              src={position3}
              alt="position3"
              style={{
                width: isOnMobile ? "190px" : "220px",
                height: isOnMobile ? "60px" : "75px",
                borderTopRightRadius: "6px",
                borderTopLeftRadius: "6px",
              }}
            />
          </Grid>
        </Grid>
      </Stack>

      <Stack
        sx={{
          marginTop: "10px",
        }}
      >
        <Button
          href={`https://wa.me/5215577460177?text=Hola%2C%20soy%20el%20agente%20${currentAgentUser.displayName}.%20Mi%20ID%20es:%20${currentAgentUser.uid}.%20Quiero%20reclamar%20mi%20premio%20${rangeTitles[rankingRange]}%20por%20haber%20alcanzado%20el%20podio.%20%C2%A1Gracias!`}
          target="_blank"
          variant={activeButton ? "contained" : "outlined"}
          startIcon={<RedeemIcon />}
          disabled={!activeButton}
          disableElevation
          sx={{
            maxWidth: "250px",
            margin: "10px auto 15px auto",
            backgroundColor: activeButton ? "#00BBEE" : "",
            animation: activeButton ? `tiltNMoveShaking 1s infinite` : "",
            "@keyframes tiltNMoveShaking": {
              "0%": { transform: "translate(0, 0) rotate(0deg)" },
              "25%": { transform: "translate(5px, 5px) rotate(5deg)" },
              "50%": { transform: "translate(0, 0) rotate(0deg)" },
              "75%": { transform: "translate(-5px, 5px) rotate(-5deg)" },
              "100%": { transform: "translate(0, 0) rotate(0deg)" },
            },
            "&:hover": {
              backgroundColor: "#008FB7",
            },
          }}
        >
          Reclamar premio
        </Button>
        <Typography
          variant="subtitle2"
          sx={{
            width: "60%",
            margin: "0px auto",
          }}
        >
          {currentAgentIsAWinner
            ? alreadyColleted
              ? "¡Felicidades por haber ganado! Sigue esforzándote para ganar más recompensas."
              : "¡Felicidades por haber obtenido un lugar en el podio! Haz clic en el botón y contáctate con nuestros agentes para reclamar tu premio."
            : "Las personas ganadoras del podio reciben un incentivo económico."}
        </Typography>
        <Alert
          sx={{
            width: ["90%", "60%"],
            margin: "15px auto",
            borderRadius: "6px",
            fontSize: "12px",
          }}
          severity="warning"
        >
          Visita periódicamente esta sección para enterarte si formas parte del
          podio y reclamar tu premio, ya que por un lado no recibirás
          notificaciones, y por otro, pasado el periodo ya no podrás reclamar tu
          premio.
        </Alert>
      </Stack>
    </>
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      paddingTop={"50px"}
    >
      <img
        src={sadCluppcito}
        alt="Cluppcito-triste"
        style={{ width: "160px", height: "200px" }}
      />
      <p>
        {`Asciende a la cima del ranking ${headerTitlesMessage[rankingRange]} de agentes Clupp.`}
      </p>
    </Box>
  );
};

export default Podium;
