import { useEffect, useState } from "react";
import { db } from "../Firebase/firebase";
import { useAgentAuth } from "../AuthAgentContext";

export default function usePreventClosingWindow(
  message = "¿Estás seguro de querer abandonar tu cotización?"
) {
  const [vehicle, setVehicle] = useState({});
  const user = localStorage.getItem("docRef");

  const { isAgentLogged } = useAgentAuth();

  useEffect(() => {
    const query = db
      .collection(`prospectsNew/${user}/vehicles`)
      .orderBy("timestamp", "desc")
      .limit(1);
    query.get().then((snapshot) => {
      if (snapshot.docs.length >= 1) {
        const vehicle = snapshot.docs[0].data();
        setVehicle(vehicle);
      }
    });
  }, []);

  useEffect(() => {
    const handleUnload = async (event) => {
      event.preventDefault();

      const phone = localStorage.getItem("phone");
      const user = localStorage.getItem("docRef");
      if (phone && user && !isAgentLogged && vehicle.utm_source !== "agentes") {
        const W_URL_VENTAS =
          "https://hooks.slack.com/services/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";

        const callLink = `https://clupp-admin.web.app/clientes/llamadas?phone=${phone}`;

        if (vehicle.brand && vehicle.model) {
          fetch(W_URL_VENTAS, {
            method: "POST",
            body: JSON.stringify({
              text: `El cliente ${user} con teléfono <${callLink}|${phone}> abandonó la cotización de su ${
                vehicle.brand
              } ${vehicle.model} ${vehicle.year || ""}`,
            }),
          });
        }
      }

      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [vehicle, isAgentLogged]);
}
