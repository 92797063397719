import { useState, Fragment } from "react";
import { slide as MenuB } from "react-burger-menu";
import clupplogo from "../../Assets/SVG/CluppNG.svg";
import { useAuth } from "../../AuthContext";
import { useAgentAuth } from "../../AuthAgentContext";
import CustomLink from "../CustomLink";
import FormDialog from "../NuevaCotizacionDialog/FormDialog";
import LinkIcon from "@mui/icons-material/Link";
import Swal from "sweetalert2";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import RecuperarContraseñaModal from "../RecuperarContraseña/RecuperarContraseña";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import CircularProgress from "@mui/material/CircularProgress";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import SellIcon from "@mui/icons-material/Sell";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import SchoolIcon from "@mui/icons-material/School";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import SelectOtherAgentModal from "../SelectOtherAgentModal";
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import { auth } from "../../Firebase/firebase";

const Menu = () => {
  const { logout } = useAuth();
  const {
    isAgentLogged,
    currentAgentUser,
    setCurrentAgentUser,
    agentDoc,
    agentClaims,
  } = useAgentAuth();
  const [goMenu, setGoMenu] = useState({ menuOpen: false });
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPasswordModal, setShowPasswordModa] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  function handlerSendEmailPassword() {
    setLoading(true);
    auth
      .sendPasswordResetEmail(currentAgentUser.email)
      .then(() => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: `Se ha enviado un correo a ${currentAgentUser.email} con instrucciones para cambiar tu contraseña`,
        });
      })
      .catch((e) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: "El correo electrónico no se encuentra registrado",
        });
      });
  }

  async function handleLogout() {
    logout();
    localStorage.clear();
    setCurrentAgentUser(null);
  }

  function handleOnClosePasswordModa() {
    setShowPasswordModa(false);
  }

  const handlerOpenModal = () => setOpenModal((prev) => !prev);

  const handleStateChange = (goMenu) => setGoMenu({ menuOpen: goMenu.isOpen });
  function handleCloseModal() {
    setIsOpenModal(false);
  }

  return (
    <Fragment>
      {agentClaims.isAdmin && isOpenModal && (
        <SelectOtherAgentModal isOpen onClose={handleCloseModal} />
      )}
      <RecuperarContraseñaModal
        option="Cambiar"
        title="Cambiar contraseña"
        open={showPasswordModal}
        onClose={handleOnClosePasswordModa}
      />
      <div className="menuB">
        <FormDialog open={openModal} onClose={handlerOpenModal} />
        <MenuB
          right
          isOpen={goMenu.menuOpen}
          onStateChange={(goMenu) => handleStateChange(goMenu)}
        >
          <div className="menu-header">
            <div>
              <img src={clupplogo} alt="clupp-logo" />
            </div>
          </div>
          <div className="menu-title">
            <p>Clupp {isAgentLogged && "Agentes"}</p>
            <p>Seguro de movilidad</p>
          </div>
          <hr></hr>
          <section className="menu-options" style={{ paddingBottom: "10px" }}>
            <CustomLink to="/agentes/perfil">
              <AccountCircleIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
              Perfil
            </CustomLink>
            <CustomLink to="/agentes/cotizaciones">
              <SummarizeOutlinedIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
              Mis cotizaciones
            </CustomLink>
            <CustomLink to="/agentes/videos-tutoriales">
              <OndemandVideoIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
              Videos tutoriales
            </CustomLink>
            <CustomLink to="/agentes/metas-y-bonos">
              <EmojiEventsIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
              Metas y bonos
            </CustomLink>
           <CustomLink to="/agentes/ranking-agentes">
              <FlagCircleIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
              Ranking agentes
            </CustomLink> 
            <CustomLink to="/agentes/comisiones">
              <RequestQuoteIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
              Comisiones
            </CustomLink>
            <CustomLink to="/agentes/polizas-vendidas">
              <SellIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
              Pólizas Vendidas
            </CustomLink>
            {agentDoc?.promotoria && (
              <CustomLink to="/agentes/ventas-agentes">
                <SupervisorAccountIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                Ventas de agentes
              </CustomLink>
            )}
            <a href="https://www.guros.com/?promocode=CLUPP" target="__blank">
              <LinkIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
              Cotizar con otras aseguradoras
            </a>
            <a
              href="https://drive.google.com/drive/folders/1LpjkQvCZ3lPW0IbeRqPPn0wKw_rWl7t2?usp=sharing"
              target="__blank"
            >
              <PermMediaIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
              Recursos audiovisuales
            </a>
            <a href="https://calendly.com/abraham-clupp/30min" target="__blank">
              <SchoolIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
              Capacitación
            </a>
            <a
              href="https://auto-clupp.web.app/privacy/aviso_privacidad_personal_vigente.pdf"
              target="__blank"
            >
              <PrivacyTipIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
              Aviso de privacidad
            </a>
            <a onClick={() => handlerSendEmailPassword()}>
              <VpnKeyIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
              Cambiar contraseña
              {loading && (
                <CircularProgress sx={{ marginLeft: "10px" }} size="small" />
              )}
            </a>
            {agentClaims.isAdmin && (
              <a
                onClick={() => {
                  setIsOpenModal((prev) => !prev);
                }}
              >
                <SupervisedUserCircleIcon
                  sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                />
                Cambiar de agente
              </a>
            )}
            <CustomLink onClick={handleLogout} to="/agentes/dashboard">
              <LogoutIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
              Cerrar sesión
            </CustomLink>
          </section>
        </MenuB>
      </div>
    </Fragment>
  );
};

export default Menu;
