import { Paper } from "@mui/material";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";
import Progress from "../../Components/AgentsGamification/Progress";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { useAgentAuth } from "../../AuthAgentContext";

export default function MetasYBonos() {
  const { isAgentLogged } = useAgentAuth();
  if (!isAgentLogged) return <Redirect to="/agentes/inicio-de-sesion" />;
return (
    <Paper sx={{ minHeight: "100vh" }}>
      <DashboardHeader />
      <Progress />
    </Paper>
  );
}
