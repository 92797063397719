import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Paper, Typography, Button, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SideBarMenu from "../../Components/AgentSidebar/AgentSidebar";
import FormDialog from "../../Components/NuevaCotizacionDialog/FormDialog";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { useAgentAuth } from "../../AuthAgentContext";
import useQuoteHistory from "../../hooks/useQuoteHistory";
import DottedMenuOptions from "../AgenteDashboard/DottedMenuOptios";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";
import styles from "./styles.module.css";
import { DataGrid } from "@mui/x-data-grid";
import columsAgentComisions from "./COLUMNS";
import { db } from "../../Firebase/firebase";

export default function AgentDashboard() {
  const { currentAgentUser, setCurrentAgentUser, otherAgent } = useAgentAuth();
  const { logout } = useAuth();
  const [openNewQuote, setOpenNewQuote] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isDesktopView = useMediaQuery("(min-width:900px)");
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const unsubscribeVehicles = db
      .collection(
        `/agentProspects/${
          otherAgent ? otherAgent.uid : currentAgentUser.uid
        }/commissionPayouts`
      )
      .orderBy("timestamp", "desc")
      .onSnapshot(
        (snapshot) => {
          const comisionData = snapshot.docs.map((doc) => ({
            agentId: doc.ref.path.split("/")[1],
            ...doc.data(),
            id: doc.id,
          }));

          const docs = comisionData.filter(
            ({ deleted = false }) => deleted === false
          );

          setRows(docs);
          setIsLoading(false);
        },
        (error) => {
          console.error(`Error getting comision payment for agentes: ${error}`);
          setRows([]);
          setIsLoading(undefined);
        }
      );

    return () => unsubscribeVehicles();
  }, [currentAgentUser, otherAgent]);

  const handleOpenDottedMenuOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnCloseDottedMenu = () => {
    setAnchorEl(null);
  };

  function handleCloseQuoteModal() {
    setOpenNewQuote(false);
  }

  async function handleLogout() {
    logout();
    localStorage.clear();
    setCurrentAgentUser(null);
  }

  return (
    <Paper sx={{ width: "100%", minHeight: "100vh" }}>
      {!currentAgentUser && <Redirect to="/agentes/inicio-de-sesion" />}
      <FormDialog open={openNewQuote} onClose={handleCloseQuoteModal} />
      <DottedMenuOptions
        open={open}
        setOpenModal={() => setOpenNewQuote(true)}
        handleClose={handleOnCloseDottedMenu}
        anchorEl={anchorEl}
        logout={handleLogout}
        typeMenu={isDesktopView ? "" : "dotted"}
      />
      <SideBarMenu />
      <DashboardHeader />
      <main
        style={{
          width: "90%",
          maxWidth: "1140px",
          margin: "0 auto",
          paddingTop: "60px",
        }}
      >
        <Box
          sx={{
            display: ["grid", "grid", "flex"],
            gridTemplateColumns: "3fr, 1fr",
            alignItems: "center",
            padding: ["16px 0", "24px 0"],
            justifyContent: ["auto", "auto", "space-between"],
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                {otherAgent
                  ? `Historial de pagos de ${otherAgent?.firstName}`
                  : "Historial de pagos"}
              </Typography>
            </Box>
            {!isDesktopView && (
              <MoreVertIcon onClick={handleOpenDottedMenuOptions} />
            )}
          </Stack>
        </Box>
        <DataGrid
          getRowClassName={(params) => {
            switch (params.row.status) {
              case "pending":
                return styles.pending;
              case "paid":
                return styles.paid;
              case "approved":
                return styles.approved;
              case "rejected":
                return styles.rejected;
              default:
                return "";
            }
          }}
          sx={{
            minHeight: "76vh",
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "inherit !important",
            },
            "& .MuiDataGrid-row.Mui-hovered": {
              backgroundColor: "inherit !important",
            },
          }}
          loading={isLoading}
          rows={rows}
          columns={columsAgentComisions}
        />
      </main>
    </Paper>
  );
}
