const getRankingText = (currentAgentRanking, ranking) => {
    const position = currentAgentRanking.position;
    if (position === 1) {
      return `¡Woow! Estás en el primer puesto. ¡Mantente fuerte! 💪`;
    }
    const nextOpponent = ranking.find((item) => item.position === position - 1);
    let scoreDifference = nextOpponent.score - currentAgentRanking.score;
    const nextOpponentName = nextOpponent.username || `#${nextOpponent.position} Agente`

    if (position >= 2 && position <= 4) {
      return `¡Impresionante! ${
        scoreDifference === 0
          ? `${nextOpponent.username} y tú se disputan el puesto ${position}`
          : `Estás en la posición ${position}. Te faltan solo ${scoreDifference} puntos para alcanzar a ${nextOpponentName}.`
      } ¡Sigue así!`;
    }

    if (position >= 5 && position <= 7) {
      return `¡Genial! ${
        scoreDifference === 0
          ? `${nextOpponentName} y tú se disputan el puesto ${position}`
          : `Estás en la posición ${position}. Te faltan solo ${scoreDifference} puntos para alcanzar a ${nextOpponentName}.`
      } ¡No te detengas!`;
    }

    if (position >= 8 && position <= 10) {
      return `¡Muy bien! ${
        scoreDifference === 0
          ? `${nextOpponentName} y tú se disputan el puesto ${position}`
          : `Estás en la posición ${position}. Te faltan solo ${scoreDifference} puntos para alcanzar a ${nextOpponentName}.`
      } ¡Continúa con el buen trabajo!`;
    }
    return `¡Estás cerca! ${
      scoreDifference === 0
        ? `${nextOpponentName} y tú se disputan el puesto ${position}`
        : `Estás en la posición ${position}. Te faltan solo ${scoreDifference} puntos para alcanzar a ${nextOpponentName}.`
    } ¡Vamos por más!`;
  };


  export default getRankingText;