import {
  Box,
  Dialog as MUIDialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { Close } from "@mui/icons-material";
const PREFIX = 'Dialog';

const classes = {
  transparent: `${PREFIX}-transparent`,
  hidden: `${PREFIX}-hidden`
};

const StyledMUIDialog = styled(MUIDialog)({
  [`&.${classes.transparent}`]: {
    "& .MuiDialog-paper": {
      background: "transparent",
      boxShadow: "none",
    },
  },
  [`&.${classes.hidden}`]: {
    "& .MuiDialogContent-root": {
      overflow: "hidden",
    },
  },
});

const Dialog = ({
  title,
  isOpen,
  onClose,
  content,
  actions,
  transparent = false,
  contentOverflowHidden = false,
  sx
}) => {

  return (
    <StyledMUIDialog
      sx={sx}
      open={isOpen}
      onClose={onClose}
      className={`
        ${contentOverflowHidden ? classes.hidden : ""} ${
        transparent ? classes.transparent : ""
      }`}
    >
      <Box style={{ display: "flex", alignItems: "self-start" }}>
        <DialogTitle style={{ flex: "1" }}>{title}</DialogTitle>
        {onClose && (
          <IconButton onClick={onClose} size="large">
            <Close />
          </IconButton>
        )}
      </Box>

      <DialogContent>{content}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </StyledMUIDialog>
  );
};

export default Dialog;
