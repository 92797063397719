import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../Firebase/firebase";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Stack, Typography } from "@mui/material";
import { formatCurrency } from "../../utils/formatCurrency";
import useQuery from "../../hooks/useQuery";
import Dialog from "../../Components/Dialog";
import LoadingOverlay from "../../Components/LoadingOverlay";
import CatalogHead from "../../Components/CatalogHead/CatalogHead";

async function getQuotes(cluppIdArr = []) {
  const quotes = cluppIdArr.map(async (cluppId) => {
    const response = await fetch(
      `https://clupp-api.web.app/v1/quotes/${cluppId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN_QUOTE}`,
        },
        redirect: "follow",
      }
    );
    const result = await response.json();
    return result;
  });

  const quoteResults = await Promise.all(quotes);
  return quoteResults;
}

export default function Catalogo() {
  const query = useQuery();
  const token = query.get("token");
  const searchParam = useParams();
  const [vehicleQuotes, setVehiclesQuotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTokenAutenticated, setIsTokenAutenticated] = useState(false);
  const [cluppIds, setCluppIds] = useState();

  useEffect(() => {
    setIsLoading(true);
    const unsubscribe = db
      .collection(`IT/quoteSections/${searchParam.agent}`)
      .doc(searchParam.brand)
      .onSnapshot((doc) => {
        const docs = doc.data();
        if (docs.token !== token) {
          setIsTokenAutenticated(false);
          setIsLoading(false);
          return;
        } else {
          setIsTokenAutenticated(true);
        }
        setCluppIds(docs.cluppIds);
        getQuotes(docs.cluppIds)
          .then((results) => {
            const orderedResults = results.sort((a, b) => {
              if (a.vehicle.model < b.vehicle.model) {
                return -1;
              }
              if (a.vehicle.model > b.vehicle.model) {
                return 1;
              }
              return 0;
            });
            setVehiclesQuotes(orderedResults);
            setIsLoading(false);
          })
          .catch((err) => {
            console.error(err);
            setIsLoading(false);
          });
      });

    return unsubscribe;
  }, [searchParam, token]);

  useEffect(() => {
    const dayTime = 1000 * 60 * 60 * 24;
    setInterval(() => {
      setIsLoading(true);
      getQuotes(cluppIds)
        .then((results) => {
          const orderedResults = results.sort((a, b) => {
            if (a.vehicle.model < b.vehicle.model) {
              return -1;
            }
            if (a.vehicle.model > b.vehicle.model) {
              return 1;
            }
            return 0;
          });
          setVehiclesQuotes(orderedResults);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    }, dayTime);
  }, []);

  if (isLoading) return <LoadingOverlay isOpen />;

  if (!isTokenAutenticated)
    return (
      <Dialog
        isOpen={!isTokenAutenticated}
        content="No tienes los permisos necesarios para ingresar a esta página"
      />
    );
  return (
    <Paper
      style={{ minHeight: "100vh", paddingTop: "80px", paddingBottom: "20px" }}
    >
      <CatalogHead />
      <Stack sx={{ margin: "20px" }}>
        <Typography variant="h4">
          {searchParam?.brand.charAt(0).toUpperCase() +
            searchParam?.brand.slice(1)}
        </Typography>
      </Stack>
      <TableContainer>
        <Table
          sx={{
            border: "1px solid #a0a0a09d",
            borderRadius: "6px",
            minWidth: 650,
          }}
          aria-label="quote tables"
        >
          <TableHead sx={{ color: "#FFF" }}>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  borderRadius: "0",
                  padding: "0.4rem 14px !important",
                },
                "& .MuiTableCell-head:first-child": {
                  borderRadius: "0",
                },
                "& .MuiTableCell-head:last-child": {
                  borderRadius: "0",
                },
              }}
            >
              <TableCell>
                <Typography
                  sx={{ fontWeight: 600, fontSize: "18px" }}
                  variant="h6"
                >
                  Modelo
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  sx={{ fontWeight: 600, fontSize: "18px" }}
                  variant="h6"
                >
                  Año
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  sx={{ fontWeight: 600, fontSize: "18px" }}
                  variant="h6"
                >
                  Valor
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  sx={{ fontWeight: 600, fontSize: "18px" }}
                  variant="h6"
                >
                  Prima mensual
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  sx={{ fontWeight: 600, fontSize: "18px" }}
                  variant="h6"
                >
                  Prima anual
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  sx={{ fontWeight: 600, fontSize: "18px" }}
                  variant="h6"
                >
                  Prima anual MSI
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vehicleQuotes.map((vehicle, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {vehicle?.vehicle?.model}
                </TableCell>
                <TableCell align="right">{vehicle?.vehicle?.year}</TableCell>
                <TableCell align="right">
                  {formatCurrency(vehicle?.vehicle?.value, {
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
                <TableCell align="right">
                  {vehicle?.mobilities &&
                    formatCurrency(
                      vehicle?.mobilities[2].paymentPeriodicities[0].total,
                      { maximumFractionDigits: 0 }
                    )}
                </TableCell>
                <TableCell align="right">
                  {vehicle?.mobilities &&
                    formatCurrency(
                      vehicle?.mobilities[2].paymentPeriodicities[3].total,
                      { maximumFractionDigits: 0 }
                    )}
                </TableCell>
                <TableCell align="right">
                  {vehicle?.mobilities &&
                    formatCurrency(
                      vehicle?.mobilities[2].paymentPeriodicities[3].subtotal,
                      { maximumFractionDigits: 0 }
                    )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
