import {
  FormControl,
  TextField,
  FormGroup,
  FormControlLabel,
  FormHelperText,
} from "@mui/material/";
import { Fragment, forwardRef } from "react";
import Swal from "sweetalert2";
import { mobileVendor, mobileModel, isMobile } from "react-device-detect";
import "./RoboCelular.css";
import NumberFormat from "react-number-format";
import GreenCheckbox from "../GreenCheckbox";
import { useAgentAuth } from "../../AuthAgentContext";

function getMobileData() {
  const vendor = mobileVendor === "none" || !mobileVendor ? "" : mobileVendor;
  const model = mobileModel === "none" || !mobileModel ? "" : mobileModel;
  if (isMobile) return `${vendor} ${model}`;
  return "celular";
}

const MAX_VAL = 50000;
const isAllowed = (inputObj) => {
  const { value } = inputObj;
  if (value <= MAX_VAL) return true;
  return false;
};
export const NumberFormatInput = forwardRef(function NumberFormatInput(props, ref) {
  const { onChange, ...other } = props;
  
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
      isAllowed={isAllowed}
    />
  );
})

const saberMas = () =>
  Swal.fire({
    icon: "info",
    text: "Nuestro seguro es el único en el mercado SIN DEDUCIBLE siempre y cuando tengas activado un trayecto en nuestra app al momento del robo. De otro modo, aplica un deducible del 40%.",
    confirmButtonText: `Ok`,
  });

const RoboCelular = ({
  onActiveChange,
  onValueChange,
  isActive,
  value,
  onBlur,
}) => {
  const { isAgentLogged } = useAgentAuth();

  function handleOnActiveChange(event) {
    onActiveChange(event, event.target.checked);
    if (!isAgentLogged) {
      // Register event in Google Tag Manager
      const gtmEvent = {
        event: event.target.checked ? "agregarCelular" : "eliminarCelular",
        active: event.target.checked,
        value: value
      };
      window.dataLayer.push(gtmEvent);
    }
  }
  function handleOnBlur(event) {
    onBlur(event, value);
    if (!isAgentLogged) {
      // Register event in Google Tag Manager
      const gtmEvent = {
        event: "agregarCelular",
        active: true,
        value: value
      };
      window.dataLayer.push(gtmEvent);
    }
  }
  function handleOnValueChange(event) {
    const { value } = event.target;
    onValueChange(event, parseInt(value));
  }

  function handleOnKeyUp(event) {
    if (event.key === "Enter") {
      event.target.blur();
    }
  }

  const mobileData = getMobileData();

  const isPhoneValueValid =
    !!value && parseInt(value) > 1000 && parseInt(value) < 50000;
  return (
    <div className="robo-celular-container">
      <FormControl component="fieldset">
        <FormGroup>
          <div className="robo-celular-field-container">
            <p className="robo-celular-field-container-title">
              Robo de celular
            </p>

            {isActive ? (
              <Fragment>
                <FormControlLabel
                  checked={isActive}
                  control={<GreenCheckbox onChange={handleOnActiveChange} />}
                  label={`Tu ${mobileData} está cubierto ante robo con violencia por:`}
                />
                <TextField
                  onBlur={handleOnBlur}
                  error={!isPhoneValueValid}
                  variant="outlined"
                  className="phone-value"
                  label="Valor comercial del teléfono"
                  value={value}
                  onChange={handleOnValueChange}
                  onKeyUp={handleOnKeyUp}
                  name="numberformat"
                  InputProps={{
                    inputComponent: NumberFormatInput,
                  }}
                />
                <FormHelperText>Puedes cambiar este valor</FormHelperText>
                {!isPhoneValueValid && (
                  <FormHelperText style={{ color: "red" }}>
                    El valor debe ser mayor a $1,000 y menor a $50,000
                  </FormHelperText>
                )}
              </Fragment>
            ) : (
              <FormControlLabel
                checked={isActive}
                control={<GreenCheckbox onChange={handleOnActiveChange} />}
                label={`Tu ${mobileData} no está asegurado.`}
              />
            )}
            <p onClick={saberMas} className="robo-celular-info">
              Saber más
            </p>
          </div>
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default RoboCelular;
