function calculateCLABE(number) {
  let num = number;
  if(number.length === 18) {
    num = number.substring(0, 17);
  }
  const ponderacion = [3,7,1,3,7,1,3,7,1,3,7,1,3,7,1,3,7];

  const sum = num
    .split('')
    .map((digit, index) => {
      const multiply = digit * ponderacion[index];
      const moduleMultiply = multiply % 10;

      return moduleMultiply;
    }).reduce((a, b) => a + b);

  const result = 10 - (sum % 10);

  const digitControl = result % 10;

  return digitControl;
}

export default calculateCLABE;