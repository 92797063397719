const URI =
  "https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/mailChimp/addOrUpdateMember";

async function addOrUpdateMember({ email, uid, firstName, lastName, phone }) {
  const body = {
    email,
    uid,
  };

  if (firstName) {
    body["firstName"] = firstName;
  }

  if (phone) {
    body["phone"] = phone;
  }

  if (lastName) {
    body["lastName"] = lastName;
  }
  try {
    const response = await fetch(URI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const json = await response.json();
    console.log("Add or update member response");
    console.log({ json });
  } catch (error) {
    console.log(error);
  }
}
export default addOrUpdateMember;
