import { DataGrid, esES } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import COLUMNS from "./columns.js";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { lighten } from "@mui/material/styles";

const quoteRoutes = {
  0: "/",
  1: "/elegir-plan",
  2: "/elegir-extras",
  3: "/contrata",
};

const getBackgroundColor = (color) => lighten(color, 0.6);

export default function AgenteCotizacionesGrid({
  noRowOverlayLabel = () => {
    return "No rows";
  },
  rowData,
  loading,
  type = "",
  agentUid,
  idRow = "id",
  onRowClickProp,
  CustomToolBar
}) {
  const utmRedirectionToQuote = `/?utm_source=agentes&utm_medium=${agentUid}&utm_campaign=${agentUid}`;
  const { setCurrentUser } = useAuth();
  const history = useHistory();

  function openQuote(e) {
    console.log(e.row);
    if (e.row.isInsured === true) {
      console.log(e.row.isInsured);
    } else if (e.row.stripeSuccess === true) {
      console.log(e.row.stripeSuccess);
    } else if (!e.row.lastVehicleId) {
      localStorage.setItem("currentUserId", e.row.id);
      localStorage.setItem("docRef", e.row.id);
      setCurrentUser({ uid: e.row.id });
      history.push(utmRedirectionToQuote);
    } else if (e.row.lastVehicleId) {
      localStorage.setItem(
        "currentVehicle",
        e.row.lastVehicleId ? e.row.lastVehicleId : ""
      );
      localStorage.setItem(
        "email",
        e.row.email ? e.row.email : ""
      );
      localStorage.setItem(
        "firstName",
        e.row.firstName ? e.row.firstName : ""
      );
      localStorage.setItem(
        "lastName",
        e.row.lastName ? e.row.lastName : ""
      );
      localStorage.setItem(
        "lastName2",
        e.row.lastName2 ? e.row.lastName2 : ""
      );
      localStorage.setItem(
        "phone",
        e.row.phone ? e.row.phone : ""
      );
      localStorage.setItem("docRef", e.row.id ? e.row.id : "");
      localStorage.setItem("rfc", e.row.rfc ? e.row.rfc : "");
      setCurrentUser({ uid: e.row.id });

      history.push(
        quoteRoutes[e.row.qStatus] +
          `?utm_source=agentes&utm_medium=${agentUid}&utm_campaign=${agentUid}`
      );
    }
  }
  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .stripe-success": {
          backgroundColor: (theme) =>
            getBackgroundColor(theme.palette.success.main),
            cursor: "not-allowed",
        },
        "& .no-active": {
          backgroundColor: "#ff7961cf",
        },
        "& .MuiDataGrid-row:hover": {
          cursor: "pointer",
        }
      }}
    >
      <DataGrid
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        getRowId={(row) => row[idRow]}
        initialState={{
          sorting: {
            sortModel: (type ==="agentsPolizas" || type ==="polizas") ? [{ field: "timestampPayed", sort: "asc" }] : type === "polizasDeUsuarios" ? [{ field: "startVigency", sort: "desc" }] : [{ field: "timestamp", sort: "desc" }]
          },
        }}
        components={{
          NoRowsOverlay: () => noRowOverlayLabel(),
          Footer: CustomToolBar
        }}
        onRowClick={onRowClickProp ? onRowClickProp : openQuote}
        rows={rowData}
        columns={COLUMNS(type)}
        onSelect
        loading={loading}
        getCellClassName={(params) => {
          let className = `${params.row.stripeSuccess === true ? "stripe-success" : ""} ${(params?.row?.vehicle?.isActive === false || params?.row?.isActive  === false) ? "no-active" : ""}`;
          return className
        }}
      />
    </Box>
  );
}
