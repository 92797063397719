/**
 *  Returns a human readable label for a policy type based on the type string.
 *
 *  @param {string} type - "auto", "moto", "other", "otro", "scooter", "bike", "eBike", "laptop", "smartphone", "tablet"
 * @param {object} config - Configuration object
 * @param {boolean} config.capitalize - Capitalize the first letter of the label
 *
 * @returns {string} - A human readable label for the policy type
 */
export default function policyTypeToLabel(type = "", config) {
  const { capitalize } = config || {
    capitalize: false,
  };
  const TYPE_TO_FORMATTED_TYPE = {
    auto: "auto",
    moto: "motocicleta",
    other: "vehículo",
    otro: "vehículo",
    scooter: "monopatín",
    bike: "bicicleta",
    eBike: "bicicleta eléctrica",
    laptop: "laptop",
    smartphone: "celular",
    tablet: "tablet",
  };
  const label = TYPE_TO_FORMATTED_TYPE[type] || "";
  return capitalize ? capitalizeFirstLetter(label) : label;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
