import { Typography } from "@mui/material"
import { Link } from "react-router-dom"
import CluppTriste from "../../Assets/PNG/Clupp_Triste.png"

export default function NotFound() {
  return (
    <section style={{width: "100%", height: "100vh", display: "grid", placeItems: "center", background: "#fff"}}>
      <div>
        <img
          src={CluppTriste}
          alt="Clupp triste"
          style={{width: "100%", maxWidth: "180px", margin: "10px"}}
        />
        <Typography variant="h5">Lo sentimos.</Typography>
        <Typography variant="h6">La página que estás buscando no existe.</Typography>
        <div>
          <Link to="/agentes/inicio-de-sesion">Iniciar sesión como agente Clupp</Link>{" | "}
          <Link to="/">Cotizar seguro</Link>
        </div>
      </div>
    </section>
  )
}
