import { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import { startOfDay, endOfDay } from "date-fns";
import { db } from "../../Firebase/firebase";
import { useAgentAuth } from "../../AuthAgentContext";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { formatCurrency } from "../../utils/formatCurrency";
import ModalComissionCheckout from "../Comisiones/ModalComissionCheckout";
import Swal from "sweetalert2";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";
import AgenteCotizacionesGrid from "../../Components/AgenteCotizacionesGrid/AgenteCotizacionesGrid";
import BasicDatePicker from "../../Components/DatePickerRange/DatePickerRange";
import ModalVenta from "../Comisiones/ModalVenta";

const COMMISSION_STATUS = {
  pending: "Por pagar",
  paid: "Pagado",
  unpaid: "Sin pagar",
};

const SALES_INITIAL_STATE = {
  vehicle: [],
  smartphone: [],
  extras: [],
};

const PERIODICITY = {
  month: "Mensual",
  "3month": "Trimestral",
  "6month": "Semestral",
  year: "Anual",
};

export default function AgentsSales() {
  const date = new Date();
  const firstDay = startOfDay(new Date(date.getFullYear(), date.getMonth(), 1));
  const lastDay = endOfDay(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );
  const agentComissionPercent = 0.1;
  const promotorComissionPercent = 0.05;

  const [searchValue, setSearchValue] = useState("");
  const [selectedRowData, setSelectedRowData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [userDateRange, setUserDateRange] = useState([firstDay, lastDay]);
  const [isLoading, setIsLoading] = useState(false);
  const [salesData, setSalesData] = useState(SALES_INITIAL_STATE);
  const [mergeSalesData, setMergeSalesData] = useState([]);
  const [filteredMergeSalesData, setFilteredMergeSalesData] = useState([]);
  const [openModalComission, setOpenModalComission] = useState(false);
  const { currentAgentUser, agentDoc } = useAgentAuth();
  const totalAmounts = getAllPayments();
  const [salesIdDocs, setSalesIdDocs] = useState([]);

  function filterByDate() {
    const [startDate, endDate] = userDateRange;

    const initDate = new Date(startDate);
    const finalDate = new Date(endDate);
    finalDate.setDate(finalDate.getDate() + 1);

    let filteredProjectByDate = mergeSalesData.filter((obj) => {
      const timestampDataCorrected =
        obj.timestampPayed.toString().length === 13
          ? Number(obj.timestampPayed)
          : Number(`${obj.timestampPayed}000`);
      let date = new Date(timestampDataCorrected).getTime();
      return date >= initDate && date <= finalDate;
    });
    return filteredProjectByDate;
  }

  async function fetchSubAgentsSales(agentId) {
    const response = await db
      .collection("agentProspects")
      .doc(agentId)
      .collection("sales")
      .get();

    return await response.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  }

  async function fetchSubAgents(idPromotor) {
    const response = await db
      .collection("agentProspects")
      .where("agentGroup", "==", idPromotor)
      .get();

    return await response.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  }

  function onOpenModal(selected) {
    setOpenModal(true);
    setSelectedRowData(selected.row);
  }

  function onCloseModal() {
    setOpenModal(false);
  }

  function getAllPayments() {
    let commition = filteredMergeSalesData.reduce((acc, item) => {
      if (item.promotorComissionStatus === "Sin pagar") {
        return acc + Number(item.promotorComission);
      }
      return acc;
    }, 0);
    let total = filteredMergeSalesData.reduce((acc, item) => {
      return acc + Number(item.amount);
    }, 0);

    return {
      commition,
      total,
    };
  }

  useEffect(() => {
    setIsLoading(true);
    const unsubscribe = db
      .collection("agentProspects")
      .doc(currentAgentUser?.uid)
      .onSnapshot((querySnapshot) => {
        if (querySnapshot.exists) {
          const data = querySnapshot.data();
          if (data?.promotoria) {
            fetchSubAgents(data.promotoria).then((subAgentsData) => {
              setSalesData(SALES_INITIAL_STATE);

              if (subAgentsData.length === 0) {
                setIsLoading(false);
              }
              subAgentsData.forEach((subAgent) => {
                fetchSubAgentsSales(subAgent.id).then((sales) => {
                  const salesId = sales.map((doc) => ({
                    id: doc.id,
                    ...doc,
                  }));
                  if (salesId.length === 0) {
                    setIsLoading(false);
                  }
                  setSalesIdDocs(salesId);

                  const startAt = new Date(
                    new Date(userDateRange[0]).setHours(0, 0, 0, 0)
                  ).getTime();
                  const endAt = new Date(
                    new Date(userDateRange[1]).setHours(23, 59, 59, 999)
                  ).getTime();

                  sales.forEach((itemSale) => {
                    db.collectionGroup("subPayments")
                      .where("vid", "==", itemSale.itemId)
                      .where("status", "==", "succeeded")
                      .where("timestampPayed", ">=", startAt)
                      .where("timestampPayed", "<=", endAt)
                      .get()
                      .then((querySnapshot) => {
                        const payment = querySnapshot.docs.map((item) => {
                          const paths = item.ref.path.split("/");
                          const isExtra = paths.includes("extras");
                          return {
                            agent: subAgent,
                            path: item.ref.path,
                            type: isExtra ? "extra" : "vehicle",
                            ...item.data(),
                          };
                        });

                        if (payment.length > 0) {
                          payment.forEach((item) => {
                            if (item.type === "vehicle") {
                              setSalesData((prev) => ({
                                ...prev,
                                vehicle: prev.vehicle.concat(item),
                              }));
                            } else if (item.type === "extra") {
                              setSalesData((prev) => ({
                                ...prev,
                                extras: prev.extras.concat(item),
                              }));
                            }
                          });
                        }
                      });

                    db.collectionGroup("subPayments")
                      .where("sid", "==", itemSale.itemId)
                      .where("status", "==", "succeeded")
                      .where("timestampPayed", ">=", startAt)
                      .where("timestampPayed", "<=", endAt)
                      .get()
                      .then((querySnapshot) => {
                        const payment = querySnapshot.docs.map((item) => {
                          return {
                            agent: subAgent,
                            path: item.ref.path,
                            idPayment: item.id,
                            ...item.data(),
                          };
                        });
                        if (payment.length > 0) {
                          setSalesData((prev) => ({
                            ...prev,
                            smartphone: prev.smartphone.concat(payment),
                          }));
                        }
                      });
                  });
                });
              });
            });
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      });

    return unsubscribe;
  }, []);

  function onChange(e) {
    setSearchValue(e.target.value);
  }

  function handleSearch() {
    const filteredDate = filterByDate();
    const normilizeSearch = (data) => {
      return data
        .normalize("NFD")
        .replace(/[\u0300-\u036f]|\s/g, "")
        .toLowerCase()
        .includes(
          searchValue
            .normalize("NFD")
            .replace(/[\u0300-\u036f]|\s/g, "")
            .toLowerCase()
        );
    };
    let projectFilter = filteredDate.filter((item) => {
      const searchByStatus = normilizeSearch(item?.promotorComissionStatus);
      const searchByAmount = item.amount?.toString().includes(searchValue);
      const searchByComission = item?.agentComission
        ?.toString()
        .includes(searchValue);

      return searchByStatus || searchByComission || searchByAmount;
    });
    setFilteredMergeSalesData(projectFilter);
  }

  function mergeData() {
    const mergedData = salesData.vehicle.map((item, index) => {
      let filteredSmatphone = salesData.smartphone.filter((filter) => {
        return item.chargeId === filter.chargeId;
      })[0];

      let filteredExtras = salesData.extras.filter((filter) => {
        return item.chargeId === filter.chargeId;
      });

      const totalExtras = filteredExtras.reduce((acc, item) => {
        return acc + Number(item.amount);
      }, 0);

      return {
        vehicle: item,
        agentName: `${item?.agent?.firstName ? item?.agent?.firstName : ""} ${
          item?.agent?.lastName ? item?.agent?.lastName : ""
        } ${item?.agent?.lastName2 ? item?.agent?.lastName2 : ""}`,
        idPayment: item?.idPayment ? item?.idPayment : index,
        timestampPayed: item?.timestampPayed,
        smarthphone: filteredSmatphone,
        extras: filteredExtras,
        periodicity: PERIODICITY[item?.period],
        amount:
          item?.amount +
          (filteredSmatphone?.amount ? filteredSmatphone?.amount : 0) +
          totalExtras,
        agentComission: (
          (item?.amount +
            (filteredSmatphone?.amount ? filteredSmatphone?.amount : 0) +
            totalExtras) *
          agentComissionPercent
        ).toFixed(2),
        promotorComission: (
          (item?.amount +
            (filteredSmatphone?.amount ? filteredSmatphone?.amount : 0) +
            totalExtras) *
          promotorComissionPercent
        ).toFixed(2),
        status: item?.status,
        promotorComissionStatus: item?.promotorComissionStatus
          ? COMMISSION_STATUS[item?.commissionStatus]
          : "Sin pagar",
        sid: filteredSmatphone?.sid ? filteredSmatphone?.sid : "",
        vid: item?.vid ? item?.vid : "",
      };
    });
    setMergeSalesData(mergedData);
  }
  console.log("merged data ->", mergeData);

  useEffect(() => {
    if (!!userDateRange.length && userDateRange) {
      setFilteredMergeSalesData(filterByDate());
    }
  }, [userDateRange, mergeSalesData]);

  useEffect(() => {
    mergeData();
  }, [salesData]);

  useEffect(() => {
    handleSearch();
  }, [searchValue]);

  function onCloseModalComission() {
    setOpenModalComission(false);
    localStorage.removeItem("paymentRequestId");
  }

  if (!currentAgentUser) return <Redirect to="/agentes/inicio-de-sesion" />;

  if (!agentDoc?.promotoria) return <Redirect to="/agentes/dashboard" />;

  return (
    <Paper elevation={0} sx={{ width: "100%", minHeight: "100vh" }}>
      <DashboardHeader />
      <ModalVenta
        isOpen={openModal}
        sales={selectedRowData}
        onClose={onCloseModal}
      />
      <ModalComissionCheckout
        open={openModalComission}
        onClose={onCloseModalComission}
        timestamps={userDateRange}
        comission={totalAmounts}
        selectedSales={salesIdDocs}
        salesIdDocs={filteredMergeSalesData}
      />
      <main
        style={{
          width: "90%",
          maxWidth: "1140px",
          margin: "0 auto",
          paddingTop: "80px",
        }}
      >
        <Typography variant="h5">Ventas de agentes</Typography>
        <BasicDatePicker
          label="polizas"
          initialEndDate={userDateRange[1]}
          initialStartDate={userDateRange[0]}
          onSelectDate={setUserDateRange}
          isDatePicked={true}
        />
        <Box>
          <Typography>Producción (prima pagada) del periodo:</Typography>
          <Box sx={{ marginBottom: "10px" }}>
            {formatCurrency(totalAmounts?.total, { maximumFractionDigits: 0 })}
          </Box>
          <Typography>Comisión del periodo:</Typography>
          <Box>
            {formatCurrency(totalAmounts?.commition, {
              maximumFractionDigits: 2,
            })}
          </Box>
        </Box>
        <TextField
          sx={{ backgroundColor: "white", margin: "20px 0" }}
          onChange={onChange}
          onKeyUp={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter") handleSearch();
          }}
          size="small"
          fullWidth
          placeholder="Escribe el estatus, producción total, o comisión del promotor"
          variant="outlined"
          value={searchValue}
        />
        <AgenteCotizacionesGrid
          onRowClickProp={onOpenModal}
          rowData={filteredMergeSalesData}
          type="agentsPolizas"
          noRowOverlayLabel={
            mergeSalesData.length === 0
              ? NoInfoDataRow
              : () => {
                  return null;
                }
          }
          // agentUid={currentAgentUser?.uid}
          idRow="idPayment"
          loading={isLoading}
        />
        {/* <Box sx={{ margin: "20px" }}>
          <Typography>
            <Button
              onClick={() => {
                if (totalAmounts.commition <= 0) {
                  Swal.fire({
                    icon: "warning",
                    text: "No tienes comisiones pendientes por cobrar, por favor verifica la fecha de inicio y fin de periodo",
                  });

                  return;
                }
                setOpenModalComission((prev) => !prev);
              }}
              variant="outlined"
            >
              Cobrar comisiones del periodo{" "}
            </Button>
          </Typography>
        </Box> */}
      </main>
    </Paper>
  );
}

function NoInfoDataRow() {
  return (
    <Stack
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box>
        <Typography>Sin ventas de agentes</Typography>
      </Box>
    </Stack>
  );
}
