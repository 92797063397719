import { useState } from "react";
import { Box } from "@mui/system";
import { Paper, Typography, Button, Stack, Grid, Alert } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SideBarMenu from "../../Components/AgentSidebar/AgentSidebar";
import FormDialog from "../../Components/NuevaCotizacionDialog/FormDialog";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { useAgentAuth } from "../../AuthAgentContext";
import useQuoteHistory from "../../hooks/useQuoteHistory";
import AgenteCotizacionesGrid from "../../Components/AgenteCotizacionesGrid/AgenteCotizacionesGrid";
import DottedMenuOptions from "./DottedMenuOptios";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";
import AchievementTimeline from "../../Components/AgentsGamification/AchievementTimeline";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import moment from "moment-timezone";
import { formatCurrency } from "../../utils/formatCurrency";
import getCurrentQuarter from "../../utils/getCurrentQuarter";
import useRedirect from "../../hooks/useRedirect";

export default function AgentDashboard() {
  const {
    currentAgentUser,
    setCurrentAgentUser,
    otherAgent,
    agentClaims,
    handleRemoveSelectedAgent,
    agentData,
  } = useAgentAuth();
  const { logout } = useAuth();
  const { loading, quotations } = useQuoteHistory(7);
  const [openNewQuote, setOpenNewQuote] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const redirect = useRedirect();

  const isDesktopView = useMediaQuery("(min-width:900px)");

  const handleOpenDottedMenuOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnCloseDottedMenu = () => {
    setAnchorEl(null);
  };

  function handleCloseQuoteModal() {
    setOpenNewQuote(false);
  }

  async function handleLogout() {
    logout();
    localStorage.clear();
    setCurrentAgentUser(null);
  }

  const getTimeAgo = (timestamp) => {
    const now = moment();
    const updatedTime = moment(timestamp);
    const diffMinutes = now.diff(updatedTime, "minutes");
    const diffHours = now.diff(updatedTime, "hours");
    const diffDays = now.diff(updatedTime, "days");

    if (diffMinutes < 60) {
      return `hace ${diffMinutes} min`;
    } else if (diffHours < 24) {
      return `hace ${diffHours} h`;
    } else {
      return `hace ${diffDays} día${diffDays > 1 ? "s" : ""}`;
    }
  };

  function NoInfoDataRow() {
    return (
      <Stack
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {quotations.length === 0 && (
          <Box>
            <Typography>Aún no has realizado tu primera cotización</Typography>

            <Button
              sx={{
                zIndex: 10,
                backgroundColor: "#00CA7D",
                "&:hover": {
                  backgroundColor: "#009b5f",
                },
              }}
              variant="contained"
            >
              <a
                style={{
                  color: "white",
                  textDecoration: "none",
                  pointerEvents: "auto",
                  cursor: "pointer",
                }}
                href="/agentes/videos-tutoriales"
                target="_blank"
                rel="noreferrer"
              >
                Ver video tutorial
              </a>
            </Button>
          </Box>
        )}
      </Stack>
    );
  }

  const bonusProgress = otherAgent
    ? otherAgent.stats?.totalPremium ?? 0
    : agentData.stats?.totalPremium ?? 0;
  const bonusProgressUpdated = otherAgent
    ? otherAgent.stats?.timestampUpdated ?? 0
    : agentData.stats?.timestampUpdated ?? 0;

  const quarterName = {
    1: "primer",
    2: "segundo",
    3: "tercer",
    4: "cuarto",
  };

  const currentQuarter = getCurrentQuarter();

  return (
    <Paper sx={{ width: "100%", minHeight: "100vh" }}>
      {!currentAgentUser && <Redirect to="/agentes/inicio-de-sesion" />}
      <FormDialog open={openNewQuote} onClose={handleCloseQuoteModal} />
      <DottedMenuOptions
        open={open}
        setOpenModal={() => setOpenNewQuote(true)}
        handleClose={handleOnCloseDottedMenu}
        anchorEl={anchorEl}
        logout={handleLogout}
        typeMenu={isDesktopView ? "" : "dotted"}
      />
      <SideBarMenu />
      <DashboardHeader haveABanner={false} />
      <main
        style={{
          width: "90%",
          maxWidth: "1140px",
          margin: "20px auto",
          paddingTop: "60px",
        }}
      >
        <Box sx={{ marginTop: "15px", marginBottom: "45px" }}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <Box>
              <Typography
                variant="h6"
                sx={{ fontWeight: "600", textAlign: "left" }}
              >
                {`Bono del ${quarterName[currentQuarter]} trimestre ${
                  otherAgent ? `(${otherAgent?.firstName})` : ""
                }`}
              </Typography>
              {Boolean(bonusProgressUpdated) && (
                <>
                  <Typography variant="subtitle2" textAlign={"left"}>
                    ¡Excelente! Sigue acumulando recompensas
                  </Typography>
                  <Typography
                    variant="body2"
                    textAlign={"left"}
                    fontSize={"12px"}
                  >
                    {`Actualizado última vez: ${getTimeAgo(
                      bonusProgressUpdated
                    )}`}
                  </Typography>
                </>
              )}
            </Box>

            <Box>
              <Button
                startIcon={<FlagCircleIcon color="#fff" />}
                variant="contained"
                disableElevation
                sx={{
                  marginRight: "10px",
                }}
                href="/agentes/ranking-agentes?utm_medium=dashboardSection"
              >
                Ranking de Agentes
              </Button>
              <Button
                startIcon={<EmojiEventsIcon color="#fff" />}
                variant="contained"
                disableElevation
                href="/agentes/metas-y-bonos"
              >
                Metas y Bonos
              </Button>
            </Box>
          </Stack>

          <Grid container spacing={2} marginTop={"20px"}>
            <Grid item xs={12} md={6}>
              <AchievementTimeline
                amount={bonusProgress}
                tooltipText={`Llevas ${formatCurrency(
                  bonusProgress
                )} actualemente.`}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Alert
                sx={{
                  width: "80%",
                  marginLeft: "auto",
                  textAlign: "left",
                  borderRadius: "6px",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
                severity="warning"
                onClick={() =>
                  redirect(
                    "/agentes/ranking-agentes?utm_medium=dashboardSection"
                  )
                }
              >
                Visita periódicamente la sección de{" "}
                <strong>"Ranking de Agentes"</strong> para enterarte si formas
                parte del podio y reclamar tu premio.
              </Alert>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: ["grid", "grid", "flex"],
            gridTemplateColumns: "3fr, 1fr",
            alignItems: "center",
            padding: ["16px 0", "24px 0"],
            justifyContent: ["auto", "auto", "space-between"],
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <Box>
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                {otherAgent
                  ? `Cotizaciones recientes de ${otherAgent?.firstName}`
                  : "Cotizaciones recientes"}
              </Typography>
              <Typography variant="subtitle2" textAlign={"left"}>
                últimos 7 días
              </Typography>
            </Box>
            {!isDesktopView && (
              <MoreVertIcon onClick={handleOpenDottedMenuOptions} />
            )}
          </Stack>
          {isDesktopView ? (
            <Stack spacing={2} direction="row">
              {agentClaims.isAdmin === true && otherAgent && (
                <Button
                  disabled={!otherAgent}
                  variant="outlined"
                  onClick={() => handleRemoveSelectedAgent()}
                >
                  Mostrar mis cotizaciones
                </Button>
              )}
              <Button
                variant="outlined"
                onClick={() => history.push("/agentes/cotizaciones")}
              >
                <Link
                  to="/agentes/cotizaciones"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Ver todas las cotizaciones
                </Link>
              </Button>
              <Button variant="contained" onClick={() => setOpenNewQuote(true)}>
                Nueva cotización
              </Button>
            </Stack>
          ) : (
            <Box sx={{ display: "grid", gap: "10px" }}>
              {agentClaims.isAdmin === true && otherAgent && (
                <Button
                  disabled={!otherAgent}
                  variant="outlined"
                  onClick={() => handleRemoveSelectedAgent()}
                >
                  Mostrar mis cotizaciones
                </Button>
              )}
              <Button variant="contained" onClick={() => setOpenNewQuote(true)}>
                Nueva cotización
              </Button>
            </Box>
          )}
        </Box>
        <AgenteCotizacionesGrid
          noRowOverlayLabel={NoInfoDataRow}
          loading={loading}
          rowData={quotations}
          agentUid={currentAgentUser?.uid}
        />
      </main>
    </Paper>
  );
}
