export default function isNumberWithinRange(
  numberToVerify,
  givenNumber,
  rangeStart,
  rangeEnd
) {
  const lowerBound = givenNumber + rangeStart;
  const upperBound = givenNumber + rangeEnd;

  return numberToVerify >= lowerBound && numberToVerify <= upperBound;
}
