import {
  Paper,
  Typography,
  Stack,
  TextField,
  Box,
  Skeleton,
  IconButton,
  Tooltip,
  Button,
  InputAdornment,
  FormControl,
  OutlinedInput,
  Avatar,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";
import { useAgentAuth } from "../../AuthAgentContext";
import { db, storage } from "../../Firebase/firebase";
import { useEffect, useState } from "react";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Visibility } from "@material-ui/icons";
import { VisibilityOff } from "@mui/icons-material";
import calculateCLABE from "../../utils/calculateCLABE";
import ToClipboardText from "../../Components/ToClipboardText";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import { Redirect } from "react-router-dom";
import LoadingOverlay from "../../Components/LoadingOverlay";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import notUserPhoto from "../../Assets/PNG/noUserPhoto.png";
import { UploadButton } from "@bytescale/upload-widget-react";
import { useSnackbar } from "notistack";

const INITIAL_STATE_DATA = {
  firstName: "",
  lastName: "",
  lastName2: "",
  email: "",
  phone: "",
  rfc: "",
  gender: "-",
  displayName: "",
};

const INITIAL_STATE_BANKDATA = {
  bankName: "",
  CLABE: "",
  holderName: "",
};

export default function Profile() {
  const {
    currentAgentUser,
    configuracionPromotoria,
    agentData: agentDataLocal,
  } = useAgentAuth();
  const [agentData, setAgentData] = useState(INITIAL_STATE_DATA);
  const [agentBankData, setAgentBankData] = useState(INITIAL_STATE_BANKDATA);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchinData, setIsFetchingData] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);
  const [showToken, setShowToken] = useState(false);
  const [webServiceToken, setWebServiceToken] = useState([]);
  const [loadingToken, setLoadingToken] = useState(false);
  const [CLABEerror, setCLABEError] = useState(false);
  const magicLink = {
    title: "Seguro de movilidad Clupp",
    text: "Te invito a contratar tu seguro con Clupp",
    url: `https://cotizador-clupp.web.app/?utm_source=agentes&utm_medium=${
      agentData?.agentGroup ? agentData?.agentGroup : agentData?.uid
    }&utm_campaign=${agentData?.uid}&utm_content=link`,
  };
  const canShare = navigator.canShare && navigator.canShare(magicLink);

  useEffect(() => {
    setIsFetchingData(true);
    const unsubscribe = db
      .collection("agentProspects")
      .doc(currentAgentUser?.uid)
      .onSnapshot((doc) => {
        setAgentData({
          ...doc.data(),
          displayName: currentAgentUser.displayName,
          uid: doc.id,
        });
      });

    const unsubscribe2 = db
      .collection(`agentProspects/${currentAgentUser?.uid}/bankDetails`)
      .orderBy("timestamp", "desc")
      .limit(1)
      .onSnapshot((snapshot) => {
        const bankDetails = snapshot.docs.map((doc) => doc.data());
        if (!snapshot.empty) {
          setAgentBankData(bankDetails[0]);
        }
        setIsFetchingData(false);
      });

    const unsubscribe3 = db
      .collection(`agentProspects/${currentAgentUser?.uid}/webServiceTokens`)
      .orderBy("timestamp", "desc")
      .limit(1)
      .onSnapshot((snapshot) => {
        const webServiceTokens = snapshot.docs.map((doc) => doc.data());
        if (!snapshot.empty) {
          setWebServiceToken(webServiceTokens);
        }
      });

    return () => {
      unsubscribe();
      unsubscribe2();
      unsubscribe3();
    };
  }, [currentAgentUser]);

  function deleteExtrasSpacesInObject(obj) {
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        obj[key] = obj[key].trim();
      }
    }
    return obj;
  }
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (agentBankData.CLABE.length !== 18) {
      Swal.fire({
        icon: "warning",
        text: "La CLABE debe contar con 18 dígitos",
      });
      return;
    }
    setIsLoading(true);
    const updatedData = deleteExtrasSpacesInObject(agentBankData);
    setAgentBankData((prev) => ({ ...prev, ...updatedData }));
    if (hasChanged) {
      db.doc(`agentProspects/${currentAgentUser?.uid}`).update({
        gender: agentData.gender,
      });
    }
    if(agentData.displayName !== currentAgentUser.displayName){
      await currentAgentUser
      .updateProfile({
        displayName: agentData.displayName,
      })
      .then(() => {
        console.log("Auth display Name Updated Successfully");
      })
      .catch((error) => {
        console.log("Error updating Auth profile:", error);
      });
    }

    db.collection(`agentProspects/${currentAgentUser?.uid}/bankDetails`)
      .add({
        ...updatedData,
        timestamp: Date.now(),
      })
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Datos guardados",
        });
        setIsLoading(false);
        setHasChanged(false);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Se ha producido un error al intentar guardar tus datos",
        });
        console.log(error);
        setIsLoading(false);
      });
  };

  function titleCase(str) {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function showTokenHandler() {
    setShowToken((prev) => !prev);
  }
  async function requestToken() {
    setLoadingToken(true);
    const response = await fetch(
      `https://clupp-api.web.app/v1/clupp/agents/${currentAgentUser.uid}/request-ws-integration`
    );
    const responseJson = await response.json();
    if (responseJson.success === true) {
      Swal.fire({
        icon: "success",
        title: "Se ha solicitado tu token, espera unos minutos",
      });
    } else if (responseJson.success === false) {
      Swal.fire({
        icon: "error",
        title: "Se ha producido un error, intenta más tarde",
        text: responseJson.message,
      });
    }
    setLoadingToken(false);
  }

  function handleShare() {
    if (canShare) {
      // open the native share menu
      return navigator.share(magicLink);
    }
    // redirect to WhatsApp
    window.open(
      `https://wa.me/?text=${magicLink.text} \n\n${magicLink.url}`,
      "_blank"
    );
  }

  const onChange = (event) => {
    setHasChanged(true);
    setAgentData({
      ...agentData,
      [event.target.name]: event.target.value,
    });
  };

  const onChangBank = (event) => {
    if (event.target.name === "CLABE" && event.target.value.length === 18) {
      const stringClabe = event.target.value.slice(-1);
      const resultLastDigit = calculateCLABE(event.target.value);

      if (Number(stringClabe) !== Number(resultLastDigit)) {
        setCLABEError(true);
      } else {
        setCLABEError(false);
      }
    }
    if (event.target.name === "CLABE") {
      setHasChanged(true);
      setAgentBankData({
        ...agentBankData,
        [event.target.name]: event.target.value.slice(0, 18),
      });
      return;
    }
    setHasChanged(true);
    setAgentBankData({
      ...agentBankData,
      [event.target.name]: event.target.value,
    });
  };

  if (configuracionPromotoria.isLoading) return <LoadingOverlay isOpen />;
  if (!currentAgentUser) return <Redirect to="/agentes/inicio-de-sesion" />;

  return (
    <Paper sx={{ minHeight: "100vh", width: "100%", paddingBottom: "40px" }}>
      <DashboardHeader />
      <main style={{ padding: "0 20px", paddingTop: "80px" }}>
        <Typography variant="h5" sx={{ marginBottom: "10px" }}>
          Perfil
        </Typography>
        <Stack>
          {!isFetchinData ? (
            <form
              action=""
              onSubmit={handleOnSubmit}
              style={{
                width: "100%",
                maxWidth: "480px",
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                gap: "40px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  width: "100%",
                }}
              >
                <ProfilePhotoContainer
                  user={agentDataLocal}
                  currentAgentUser={currentAgentUser}
                />
                <Typography>Información general</Typography>
                <TextField
                  value={agentData?.firstName}
                  disabled
                  size="small"
                  label="Nombre"
                  onChange={onChange}
                />
                <TextField
                  value={agentData?.lastName}
                  disabled
                  size="small"
                  label="Primer apellido"
                  onChange={onChange}
                />
                <TextField
                  value={agentData?.lastName2}
                  disabled
                  size="small"
                  label="Segundo apellido"
                  onChange={onChange}
                />
                <TextField
                  value={agentData?.email}
                  disabled
                  size="small"
                  label="Correo electrónico"
                  onChange={onChange}
                />
                <TextField
                  value={agentData?.phone}
                  disabled
                  size="small"
                  label="Teléfono"
                  type="number"
                  onChange={onChange}
                />
                <TextField
                  value={agentData?.rfc}
                  disabled
                  size="small"
                  label="RFC"
                  onChange={onChange}
                />
                <FormControl
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  <TextField
                    value={agentData?.displayName}
                    size="small"
                    label="Nombre público"
                    name="displayName"
                    onChange={onChange}
                    sx={{ width: "100%", minWidth: ["360px"]}}
                  />
                  <Tooltip
                    enterTouchDelay={0}
                    title={
                      <Typography>
                        El nombre público aparecerá en cotizaciones a clientes,
                        rankings de agentes, entre otros.
                      </Typography>
                    }
                  >
                    <IconButton>
                      <QuestionMarkIcon />
                    </IconButton>
                  </Tooltip>
                </FormControl>
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel>Genero</InputLabel>
                  <Select
                    value={agentData?.gender}
                    name="gender"
                    label="Genero"
                    onChange={onChange}
                    sx={{
                      textAlign: "left",
                      maxHeight: "40px",
                    }}
                  >
                    <MenuItem value={"Masculino"}>Masculino</MenuItem>
                    <MenuItem value={"Femenino"}>Femenino</MenuItem>
                    <MenuItem value={"Undifined"}>Prefiero no decir</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItem: "center",
                }}
              >
                <Typography sx={{ display: "flex", alignItems: "center" }}>
                  Link mágico
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <ToClipboardText
                    color="primary"
                    variant="body1"
                    textToClip={magicLink.url}
                    text={
                      <IconButton>
                        <ContentCopyIcon />
                      </IconButton>
                    }
                    success="Se ha copiado tu link mágico al portapapeles"
                  />
                  <Tooltip title={<Typography>Compartir</Typography>}>
                    <IconButton
                      size="small"
                      variant="outlined"
                      onClick={handleShare}
                    >
                      <ShareIcon size="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    enterTouchDelay={0}
                    title={
                      <Typography>
                        Usa este enlace para enviar en correos, poner en tu
                        página web, difundir en redes sociales, etc. Toda la
                        actividad (cotizaciones y ventas) que se genere de este
                        enlace se atribuirá a tu perfil de agente.
                      </Typography>
                    }
                  >
                    <IconButton>
                      <QuestionMarkIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItem: "center",
                }}
              >
                {webServiceToken.length > 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography sx={{ marginRight: "10px" }}>
                      Token para web services:
                    </Typography>
                    <ToClipboardText
                      color="primary"
                      variant="body1"
                      textToClip={webServiceToken[0].token}
                      text={
                        <IconButton>
                          <ContentCopyIcon />
                        </IconButton>
                      }
                      success="Se ha copiado tu token al portapapeles"
                    />
                    <FormControl
                      sx={{ m: 1, width: "100%" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showToken ? "text" : "password"}
                        value={webServiceToken[0].token}
                        size="small"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={showTokenHandler}
                              onMouseDown={showTokenHandler}
                              edge="end"
                            >
                              {showToken ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItem: "center",
                      width: "100%",
                    }}
                  >
                    <LoadingButton
                      variant="outlined"
                      loading={loadingToken}
                      onClick={requestToken}
                      sx={{ width: "140px" }}
                    >
                      Solicitar token
                    </LoadingButton>
                    <Box>
                      <Tooltip
                        enterTouchDelay={0}
                        title={
                          <Typography>
                            Consulta la documentación para integrar a Clupp en
                            tus aplicativos que requieran web services.
                          </Typography>
                        }
                      >
                        <IconButton>
                          <QuestionMarkIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                )}
              </Box>

              {configuracionPromotoria.hideAgentBankDetails ? null : (
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
                >
                  <Typography>Datos bancarios</Typography>
                  <TextField
                    value={capitalizeFirstLetter(agentBankData?.bankName)}
                    label="Banco"
                    size="small"
                    name="bankName"
                    onChange={onChangBank}
                  />
                  <TextField
                    value={agentBankData?.CLABE}
                    label="CLABE Interbancaria"
                    type="number"
                    size="small"
                    name="CLABE"
                    helperText={CLABEerror ? "Número de CLABE inválida" : " "}
                    error={CLABEerror}
                    onChange={onChangBank}
                  />
                  <TextField
                    value={titleCase(agentBankData?.holderName)}
                    label="Nombre completo del titular"
                    size="small"
                    name="holderName"
                    onChange={onChangBank}
                  />
                </Box>
              )}
              <LoadingButton
                disabled={!hasChanged}
                loading={isLoading}
                type="submit"
                variant="contained"
                sx={{ width: "120px", justifySelf: "right" }}
              >
                Guardar
              </LoadingButton>
            </form>
          ) : (
            <SkeletonPerfil />
          )}
        </Stack>
      </main>
    </Paper>
  );
}

const ProfilePhotoContainer = ({ user, currentAgentUser }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isUploading, setIsUploading] = useState(false);
  const options = {
    apiKey: "free",
    maxFileCount: 1,
    locale: {
      cancelBtn: "cancelar",
      cancelBtnClicked: "cancelled",
      cancelPreviewBtn: "Cancelar",
      continueBtn: "Continuar",
      doneBtn: "Hecho",
      finishBtn: "Finalizado",
      finishBtnIcon: true,
      orDragDropFile: "...o arrastrar y soltar un archivo.",
      removeBtn: "eliminar",
      removeBtnClicked: "eliminado",
      submitBtnError: "Error!",
      submitBtnLoading: "Porfavor espera...",
      unsupportedFileType: "Tipo de archivo no compatible.",
      uploadFileBtn: "Carga una imagen",
      uploadImageBtn: "Subir una imagen",
    },
  };

  const handleImageUpload = async (files) => {
    setIsUploading(true);
    if (files && files[0] && files[0].fileUrl) {
      const imgURL = files[0].fileUrl;
      await downloadImageAndStore(imgURL);
    } else {
      enqueueSnackbar("Por favor, selecciona un archivo de imagen.", {
        variant: "warning",
      });
    }
    setTimeout(() => setIsUploading(false), 700);
  };

  const downloadImageAndStore = async (imageUrl) => {
    try {
      // Obtiene el uid del usuario
      const { uid } = user;

      // Descarga la imagen
      const response = await fetch(imageUrl);
      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.statusText}`);
      }
      const blob = await response.blob();

      // Define la referencia de almacenamiento en Firebase
      const ref = storage.ref(
        `agents/${uid}/profile/userProfilePhoto_${Date.now()}.jpeg`
      );

      // Sube la imagen
      const uploadTask = ref.put(blob);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Puedes manejar el progreso de la carga aquí si es necesario
        },
        (error) => {
          console.error(error);
        },
        async () => {
          // Obtiene la URL de descarga y guarda la referencia en la base de datos
          const imgURL = await ref.getDownloadURL();
          currentAgentUser
            .updateProfile({
              photoURL: imgURL,
            })
            .then(() => {
              console.log("Auth profile Updated Successfully");
            })
            .catch((error) => {
              console.log("Error updating Auth profile:", error);
            });

          await db.doc(`agentProspects/${uid}`).set(
            {
              profileImg: imgURL,
            },
            { merge: true }
          );
          console.log("Image stored successfully:", imgURL);
          enqueueSnackbar("Imagen subida correctamente.", {
            variant: "success",
          });
        }
      );
    } catch (error) {
      console.error("Error storing image:", error);
      enqueueSnackbar("Ha ocurrido un error, por favor intenta nuevamente.", {
        variant: "error",
      });
    }
  };
  return (
    <Box
      sx={{
        position: "relative",
        marginTop: "20px",
        marginBottom: "20px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "220px",
        height: "220px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          overflow: "hidden",
        }}
      >
        <Avatar
          src={user?.profileImg ? user.profileImg : notUserPhoto}
          alt="Profile Photo"
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Box>

      <IconButton
        onClick={() => {}}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          bottom: "0",
          right: "15px",
          border: "solid 4px white",
          width: "50px",
          height: "50px",
          backgroundColor: "#00BBEE",
          borderRadius: "50%",
          "&:hover": {
            backgroundColor: "#009ACD", // Color on hover
          },
        }}
      >
        {isUploading ? (
          <Stack>
            <CircularProgress sx={{ color: "#fff" }} size={30} />
          </Stack>
        ) : (
          <UploadButton options={options} onComplete={handleImageUpload}>
            {({ onClick }) => (
              <CloudUploadIcon
                sx={{ color: "white", fontSize: "24px" }}
                onClick={onClick}
              />
            )}
          </UploadButton>
        )}
      </IconButton>
    </Box>
  );
};

function SkeletonPerfil() {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "480px",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
      <br />
      <br />
      <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
    </Box>
  );
}
