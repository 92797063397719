import { Fragment, useEffect, useRef, useState } from "react";
import { Button } from "@mui/material/";
import "./SeleccionVehiculo.css";
import Car from "../../Assets/SVG/AutoGris.svg";
import CarActive from "../../Assets/SVG/AutoVerde.svg";
import Bici from "../../Assets/SVG/BicicletaGris.svg";
import BiciActive from "../../Assets/SVG/BicicletaVerde.svg";
import Moto from "../../Assets/SVG/MotoGris.svg";
import MotoActive from "../../Assets/SVG/MotoVerde.svg";
import Monopatin from "../../Assets/SVG/MonopatinGris.svg";
import MonopatinActive from "../../Assets/SVG/MonopatinVerde.svg";
import BiciElectrica from "../../Assets/SVG/BiciElectricaGris.svg";
import BiciElectricaActive from "../../Assets/SVG/BiciElectricaVerde.svg";
import Otro from "../../Assets/SVG/OtroGris.svg";
import OtroActive from "../../Assets/SVG/OtroVerde.svg";
import Devices from "../../Assets/SVG/divices.svg";
import DevicesActive from "../../Assets/SVG/divicesActive.svg";

const SeleccionVehiculo = ({
  quoteData,
  setQuoteData,
  vehicleData,
  setVehicleData,
  setTipoSelected,
  prevVehicleData,
  isWowUser,
}) => {
  const [type, setType] = useState({
    auto: vehicleData.type === "auto",
    bike: vehicleData.type === "bike",
    moto: vehicleData.type === "moto",
    scooter: vehicleData.type === "scooter",
    eBike: vehicleData.type === "eBike",
    smartphone: vehicleData.type === "devices",
    laptop: vehicleData.type === "devices",
    tablet: vehicleData.type === "devices",
    devices: vehicleData.type === "devices",
    otro: vehicleData.type === "otro",
  });
  const scrollContainer = useRef();
  useEffect(() => {
    if (prevVehicleData) {
      setVehicleData({ ...prevVehicleData });
      const selectedType = prevVehicleData.type;
      setQuoteData({ ...quoteData, mobilityMeans: [selectedType] });
      const doc = document.getElementById("tipoVehiculo");
      if (doc) doc.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [prevVehicleData]);

  const switchTypes = (selectedType) =>
    setType((prevState) => {
      const updatedState = {};
      Object.keys(prevState).forEach((type) => {
        updatedState[type] = type === selectedType;
      });
      return updatedState;
    });

  const handleChange = (selectedType) => {
    switchTypes(selectedType);
    setQuoteData({ ...quoteData, mobilityMeans: [selectedType] });
    setVehicleData({ ...vehicleData, type: selectedType });
    setTipoSelected(selectedType);
    const doc = document.getElementById("tipoVehiculo");
    if (doc) doc.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  /**
   *  Devices quotes are not enabled for agents
   */
  const isDevicesQuoteEnabled = !isWowUser;
  /**
   *  Other quotes are not enabled for Woow quotes
   */
  const isOtherEnabled = !isWowUser;
  return (
    <Fragment>
      <div className="container-title">
        <div className="title-select">
          <p>¿Qué deseas asegurar?</p>
        </div>
      </div>
      <div className="container">
        <div className="container-seleccion" ref={scrollContainer}>
          <div className="icono">
            <Button onClick={() => handleChange("auto")} id="auto">
              {type.auto ? (
                <div>
                  <img src={CarActive} alt="Clupp" />
                </div>
              ) : (
                <div>
                  {" "}
                  <img src={Car} alt="Clupp" />
                </div>
              )}
            </Button>
            <p>Automóvil</p>
          </div>
          <div className="icono">
            <Button onClick={() => handleChange("moto")} id="moto">
              {type.moto ? (
                <img src={MotoActive} alt="Clupp" />
              ) : (
                <img src={Moto} alt="Clupp" />
              )}
            </Button>
            <p>Motocicleta</p>
          </div>
          <div className="icono">
            <Button onClick={() => handleChange("bike")} id="bike">
              {type.bike ? (
                <img src={BiciActive} alt="Clupp" />
              ) : (
                <img src={Bici} alt="Clupp" />
              )}
            </Button>
            <p>Bicicleta</p>
          </div>

          <div className="icono">
            <Button onClick={() => handleChange("scooter")} id="scooter">
              {type.scooter ? (
                <img src={MonopatinActive} alt="Clupp" />
              ) : (
                <img src={Monopatin} alt="Clupp" />
              )}
            </Button>
            <p>Monopatín</p>
          </div>
          <div className="icono">
            <Button onClick={() => handleChange("eBike")} id="eBike">
              {type.eBike ? (
                <img src={BiciElectricaActive} alt="Clupp" />
              ) : (
                <img src={BiciElectrica} alt="Clupp" />
              )}
            </Button>
            <p>
              Bicicleta <br />
              eléctrica
            </p>
          </div>

          {isDevicesQuoteEnabled && (
            <div className="icono">
              <Button onClick={() => handleChange("devices")} id="devices">
                {type.devices ? (
                  <img src={DevicesActive} alt="Clupp" />
                ) : (
                  <img src={Devices} alt="Clupp" />
                )}
              </Button>
              <p>Dispositivos</p>
            </div>
          )}

          {isOtherEnabled && (
            <div className="icono">
              <Button onClick={() => handleChange("otro")} id="otro">
                {type.otro ? (
                  <img src={OtroActive} alt="Clupp" />
                ) : (
                  <img src={Otro} alt="Clupp" />
                )}
              </Button>
              <p>Otro</p>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default SeleccionVehiculo;
