import { Fragment } from "react";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import Stepper from "../../Components/Stepper/Stepper";
import Msi from "../../Components/Msi/Msi";
import { useState, useEffect } from "react";
import BotonPrincipal from "../../Components/BotonPrincipal/BotonPrincipal";
import { loadStripe } from "@stripe/stripe-js";

import { Elements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe(
  "pk_live_51H5q3WAQwCaNdJ3ZN0bCb7PsC2l3w4yOkkvCD0bC6J6blQjFzNG33MAriRSGEZXfbpmPCjtR2VythfeUV7Ebpx0N006NiETUco"
);
const MSI = () => {
  console.log("MSI");
  //const history = useHistory();
  const [primaf, setPrimaf] = useState();
  const [msi, setMsi] = useState("0");
  const [msi12, setMsi12] = useState(false);
  const [msi9, setMsi9] = useState(false);
  const [msi6, setMsi6] = useState(false);
  const [msi3, setMsi3] = useState(false);
  const [uidMSI, setUidMSI] = useState();
  const [emailMSI, setEmailMSI] = useState();
  const [phoneMSI, setPhoneMSI] = useState();
  const [nameMSI, setNameMSI] = useState();
  const [customerMSI, setCustomerMSI] = useState();
  const [spPremiumMSI, setSpPremiumMSI] = useState();
  const [rfcMSI, setRfcMSI] = useState();

  useEffect(() => {
    console.log("1er useEffect de MSI");
    const pF = localStorage.getItem("primaf");
    setPrimaf(pF);
    console.log("primaf en MSI: " + primaf);
  }, [primaf]);

  useEffect(() => {
    const email = localStorage.getItem("emailMsi");
    setEmailMSI(email);
    console.log("email en MSI: " + emailMSI);
  }, [emailMSI]);

  useEffect(() => {
    const rfc = localStorage.getItem("rfc");
    setRfcMSI(rfc);
    console.log("rfc en MSI: " + rfcMSI);
  }, [rfcMSI]);

  useEffect(() => {
    const uid = localStorage.getItem("uidMsi");
    setUidMSI(uid);
    console.log("uid en MSI: " + uidMSI);
  }, [uidMSI]);

  useEffect(() => {
    const phone = localStorage.getItem("phoneMsi");
    setPhoneMSI(phone);
    console.log("phone en MSI: " + phoneMSI);
  }, [phoneMSI]);

  useEffect(() => {
    const name = localStorage.getItem("nameMsi");
    setNameMSI(name);
    console.log("name en MSI: " + nameMSI);
  }, [nameMSI]);

  useEffect(() => {
    const cus = localStorage.getItem("cusMsi");
    setCustomerMSI(cus);
    console.log("customer en MSI 2: " + customerMSI);
  }, [customerMSI]);

  useEffect(() => {
    const spP = localStorage.getItem("spPremiumMsi");
    setSpPremiumMSI(spP);
    console.log("spPremium en MSI: " + spPremiumMSI);
  }, [spPremiumMSI]);

  return (
    <Fragment>
      <Elements
        stripe={stripePromise}
        options={{
          locale: "es-ES",
        }}
      >
        <Menu />
        <Header />
        <div className="cotizar adjusted-height">
          <Stepper step={4} />
          <Msi
            primaf={primaf}
            msi={msi}
            setMsi={setMsi}
            msi12={msi12}
            msi9={msi9}
            msi6={msi6}
            msi3={msi3}
            setMsi12={setMsi12}
            setMsi9={setMsi9}
            setMsi6={setMsi6}
            setMsi3={setMsi3}
            customerMSI={customerMSI}
            uidMSI={uidMSI}
            emailMSI={emailMSI}
            spPremiumMSI={spPremiumMSI}
            rfcMSI={rfcMSI}
          />
        </div>
        <BotonPrincipal tipo={"msi"} back="/contrata" />
      </Elements>
    </Fragment>
  );
};

export default MSI;
