import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  TextField,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../Firebase/firebase";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { useAgentAuth } from "../../AuthAgentContext";
import logError from "../../services/logError";

export default function FormDialog({ open, onClose }) {
  const [newUserData, setNewUserData] = useState({
    firstName: "",
    lastName: "",
    lastName2: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { setCurrentUser } = useAuth();
  const { currentAgentUser } = useAgentAuth();
  const history = useHistory();
  const utmRedirectionToQuote = `/?utm_source=agentes&utm_medium=${currentAgentUser?.uid}&utm_campaign=${currentAgentUser?.uid}`;

  function titleCase(str) {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  function handlerOnChange(e) {
    setNewUserData((prev) => ({
      ...prev,
      [e.target.name]: titleCase(e.target.value),
    }));
  }

  function generateNewUuid() {
    const newUid = uuidv4().replace(/-/g, "");
    localStorage.setItem("currentUserId", newUid);
    localStorage.setItem("docRef", newUid);
    setCurrentUser({ uid: newUid });

    return newUid;
  }

  async function newQuote() {
    setIsLoading(true);
    const userUidFromStorage = generateNewUuid();
    const itemToRemove = [
      "rfc",
      "firstName",
      "lastName",
      "lastName2",
      "email",
      "phone",
    ];
    for (let key of itemToRemove) {
      localStorage.removeItem(key);
    }
    try {
      await db.collection(`prospectsNew`).doc(userUidFromStorage).set({
        agentUID: currentAgentUser.uid,
        utm_source: "agentes",
        utm_medium: currentAgentUser?.uid,
        utm_campaign: currentAgentUser?.uid,
      });
    } catch (e) {
      logError({
        error: e.toString(),
        metadata: {
          descripcion: "Error al generar una nueva cotización sin nombre",
          agentUserId: currentAgentUser?.uid
            ? currentAgentUser.uid
            : "undefined",
          userId: userUidFromStorage ? userUidFromStorage : "undefined",
        },
      });
      console.log(e);
    } finally {
      setIsLoading(false);
    }
    history.push(utmRedirectionToQuote);
  }

  async function newQuoteWithData() {
    setIsLoading(true);
    const userUidFromStorage = generateNewUuid();
    try {
      await db
        .collection(`prospectsNew`)
        .doc(userUidFromStorage)
        .set({
          ...newUserData,
          agentUID: currentAgentUser.uid,
          utm_source: "agentes",
          utm_medium: currentAgentUser?.uid,
          utm_campaign: currentAgentUser?.uid,
        });
    } catch (e) {
      logError({
        error: e.toString(),
        metadata: {
          descripcion: "Error al generar una nueva cotización con nombres",
          agentUserId: currentAgentUser?.uid
            ? currentAgentUser.uid
            : "undefined",
          userId: userUidFromStorage ? userUidFromStorage : "undefined",
        },
      });
    } finally {
      setIsLoading(false);
    }
    localStorage.setItem("firstName", newUserData.firstName);
    localStorage.setItem("lastName", newUserData.lastName);
    localStorage.setItem("lastName2", newUserData.lastName2);
    history.push(utmRedirectionToQuote);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      style={{ zIndex: 1100 }}
      sx={{
        minWith: "80%",
      }}
    >
      <DialogTitle id="form-dialog-title">
        Nueva Cotización
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Información del cliente</DialogContentText>
        <TextField
          variant="outlined"
          margin="dense"
          id="name"
          label="Nombre"
          type="text"
          fullWidth
          name="firstName"
          value={newUserData.firstName}
          onChange={handlerOnChange}
          inputProps={{ autoCapitalize: "words" }}
        />
        <TextField
          variant="outlined"
          margin="dense"
          id="lastName"
          label="Primer Apellido"
          type="text"
          name="lastName"
          value={newUserData.lastName}
          fullWidth
          onChange={handlerOnChange}
          inputProps={{ autoCapitalize: "words" }}
        />
        <TextField
          variant="outlined"
          margin="dense"
          id="lastName2"
          name="lastName2"
          label="Segundo Apellido"
          type="text"
          value={newUserData.lastName2}
          fullWidth
          onChange={handlerOnChange}
          inputProps={{ autoCapitalize: "words" }}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={isLoading}
          variant="text"
          onClick={newQuote}
          color="primary"
        >
          Omitir
        </LoadingButton>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={newQuoteWithData}
          color="primary"
        >
          Continuar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
