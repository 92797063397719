import { useState, useEffect } from "react";
const useLocalStorage = (storageKeys) => {
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    if (!storageKeys) throw new Error("storageKeys is undefined");

    storageKeys.forEach((key) => {
      const item = localStorage.getItem(key);
      if (item) {
        setKeys((prev) => [...prev, item]);
      }
    });
  }, []);

  return keys;
};

export default useLocalStorage;
