import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Stepper, Step, StepLabel, IconButton, Paper } from "@mui/material/";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { AddCircle } from "@mui/icons-material";
import cotizaActive from "../../Assets/SVG/Group 9216.svg";
import eligeActive from "../../Assets/SVG/Group 9219.svg";
import contrataActive from "../../Assets/SVG/Group 9220.svg";
import elige from "../../Assets/SVG/Group 9217.svg";
import contrata from "../../Assets/SVG/Group 9218.svg";
import "./Stepper.css";
import { Link, useHistory } from "react-router-dom";
import useRedirect from "../../hooks/useRedirect";

import CustomLink from "../CustomLink";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#00CA7D",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#00CA7D",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}));

const Root = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "#eaeaea",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    background: "#00CA7D",
  }),
  ...(ownerState.completed && {
    background: "#00CA7D",
  }),
}));

function ColorlibStepIcon(props) {
  const redirect = useRedirect();
  const { active, completed, className } = props;
  const icons = {
    1: (
      <CustomLink to="/">
        <img src={cotizaActive} alt="cotiza" />
      </CustomLink>
    ),
    2: active ? (
      <img src={eligeActive} alt="elige" />
    ) : completed ? (
      <CustomLink to="/elegir-plan">
        <img src={eligeActive} alt="elige" />
      </CustomLink>
    ) : (
      <img src={elige} alt="contrata" />
    ),
    3: active ? (
      <IconButton color="inherit">
        <AddCircle />
      </IconButton>
    ) : completed ? (
      <IconButton color="inherit" onClick={() => redirect("/elegir-extras")}>
        <AddCircle />
      </IconButton>
    ) : (
      <IconButton color="inherit">
        <AddCircle />
      </IconButton>
    ),
    4: active ? (
      <img src={contrataActive} alt="contrata" />
    ) : completed ? (
      <img src={contrataActive} alt="contrata" />
    ) : (
      <img src={contrata} alt="contrata" />
    ),
  };

  return (
    <Root ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </Root>
  );
}

ColorlibStepIcon.propTypes = {
  // Whether this step is active.
  active: PropTypes.bool,
  className: PropTypes.string,
  // Mark the step as completed. Is passed to child components.
  completed: PropTypes.bool,
  // The label displayed in the step icon.
  icon: PropTypes.node,
};

function getSteps(type) {
  return type === "devices"
    ? ["1. Cotiza", "2. Elige tu plan", "3. Contrata"]
    : ["1. Cotiza", "2. Elige tu plan", "3.Extras", "4. Contrata"];
}

export default function CustomizedSteppers({ step, type }) {
  const steps = getSteps(type);

  return (
    <Paper elevation={0}>
      <Stepper
        style={{ padding: 24 }}
        alternativeLabel
        activeStep={step}
        connector={<ColorlibConnector />}
        className="stepper"
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Paper>
  );
}
