import React from 'react';
import Modal from '@mui/material/Modal';
import carIcon from '../../Assets/SVG/Group 9231.svg';
import plus from '../../Assets/SVG/plus.svg';
import './ModalAgregar.css';

export default function ModalAgregar() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //const terms = 'https://auto-clupp.web.app/privacy/condiciones_generales_del_seguro_vigentes.pdf';

  const body = (
    <div className='paper'>
      <img src={carIcon} alt='car-icon' />
      <p className='modal-agregar-title'>Agregar vehículo</p>
      <p className='modal-agregar-subtitle'>
        Por el momento solo puedes cotizar para un sólo vehículo. No te preocupes, después de contratar podrás  agregar un vehículo a tu seguro desde la app fácilmente.</p>
      <button className='entendido-btn' onClick={handleClose}>Entendido</button>
    </div>
  );

  return (
    <div>
    <div className="movil">
      <p onClick={handleOpen} className='link-modal-agregar'>
        <img src={plus} alt='agregar-vehiculo' />
        <span style={{color: "black"}} className="al">Agregar vehículo</span></p>
      <Modal
        open={open}
        onClose={handleClose}
      >
        {body}
      </Modal>
    </div>
    <div className="web">
      <div className="web2">
          <p onClick={handleOpen} className='link-modal-agregar'>
        Agregar <br/> vehículo</p>
        </div>

      <Modal
        open={open}
        onClose={handleClose}
      >
        {body}
      </Modal>
    </div>
    </div>
  );
}