import { Fragment } from "react";
import Dialog from "./Dialog";
import { Typography } from "@mui/material";

const LoadingOverlay = ({ isOpen }) => (
  <Dialog
    transparent
    contentOverflowHidden
    isOpen={isOpen}
    content={
      <Fragment>
        <img
          style={{
            width: "150px",
            height: "150px",
            animation: "spin 2s linear infinite",
          }}
          src="/Clupp_Logo.png"
          alt="logo de cargando contenido"
        />
        <Typography
          style={{
            color: "#fff",
            fontWeight: "700",
            textAlign: "center",
            letterSpacing: "3px",
          }}
        >
          Cargando
        </Typography>
      </Fragment>
    }
  />
);

export default LoadingOverlay;
