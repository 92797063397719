import { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Checkbox, Chip } from "@mui/material";

const COMMISSION_STATUS = {
  "Por pagar": "Por pagar",
  Pagado: "Pagado",
  "Sin pagar": "Sin pagar",
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const INITIAL_STATE = {
  Pagado: true,
  "Sin pagar": true,
  "Por pagar": true,
};

const cleanState = {
  Pagado: false,
  "Sin pagar": false,
  "Por pagar": false,
};

export default function ComissionFilterOption({
  filterByComissionStatus,
  setFilterByComissionStatus,
  showRowsByPaidStatus,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [optionSelected, setOptionSelected] = useState(INITIAL_STATE);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOptionSelected(filterByComissionStatus);
  };

  function handleSelectOption(e) {
    setOptionSelected((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  }

  function handleConfirmOption() {
    setAnchorEl(null);
    setFilterByComissionStatus(optionSelected);
    showRowsByPaidStatus(optionSelected);
  }

  function handleDelete() {
    setFilterByComissionStatus(cleanState);
    showRowsByPaidStatus(cleanState);
    setOptionSelected(cleanState);
  }

  function getKeysWithValueTrue(obj) {
    return Object.keys(obj).filter((key) => obj[key] === true);
  }

  return (
    <Box sx={{ width: "100%", display: "flex" }}>
      <Chip
        variant="contained"
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        avatar={<KeyboardArrowDownIcon />}
        onDelete={handleDelete}
        label={`Estatus de comisión ${
          Object.values(filterByComissionStatus).indexOf(true) > -1 ? " | " : ""
        }
        ${getKeysWithValueTrue(filterByComissionStatus).join(", ")}`}
      ></Chip>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <FormGroup onChange={handleSelectOption} sx={{ margin: "8px" }}>
          <FormControlLabel
            control={
              <Checkbox
                name="Sin pagar"
                checked={optionSelected["Sin pagar"]}
              />
            }
            label="Sin pagar"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={optionSelected["Por pagar"]}
                name="Por pagar"
              />
            }
            label="Por pagar"
          />
          <FormControlLabel
            control={
              <Checkbox checked={optionSelected["Pagado"]} name="Pagado" />
            }
            label="Pagado"
          />
        </FormGroup>
        <Box sx={{ margin: "8px" }}>
          <Button
            variant="contained"
            sx={{ width: "100%" }}
            onClick={handleConfirmOption}
          >
            Aplicar
          </Button>
        </Box>
      </StyledMenu>
    </Box>
  );
}
