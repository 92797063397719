import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { db } from "../../Firebase/firebase";
import Dialog from "../../Components/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import { formatCurrency } from "../../utils/formatCurrency";
import policyTypeToLabel from "../../utils/policyTypeToLabel";

export default function ModalVenta({ sales = {}, isOpen, onClose }) {
  const [vehicle, setVehicle] = useState();
  const [smart, setSmart] = useState();
  const [extrasData, setExtras] = useState([]);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
  });
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
    setExtras([]);
    if (isOpen) {
      const uid = sales.vehicle?.uid || sales.smarthphone?.uid;
      db.collection("users")
        .doc(uid)
        .get()
        .then((doc) => {
          setUserData({
            firstName: doc.data().firstName,
            lastName: doc.data().lastName,
          });
        });
      if (sales?.vehicle?.chargeId) {
        db.collection(`users/${uid}/vehicles/`)
          .doc(sales?.vehicle?.vid)
          .get()
          .then((data) => {
            setVehicle(data.data());
            setIsLoaded(false);
          });
      }
      if (sales?.smarthphone?.chargeId) {
        db.collection(`users/${uid}/smartphones/`)
          .doc(sales?.smarthphone?.sid)
          .get()
          .then((data) => {
            setSmart(data.data());
            setIsLoaded(false);
          });
      }
      sales.extras.forEach((item) => {
        db.collection(`users/${uid}/vehicles/${sales?.vehicle?.vid}/extras/`)
          .doc(item.eid)
          .get()
          .then((data) => {
            setExtras((prev) => [...prev, data.data()]);
          });
      });
    }
  }, [isOpen]);

  const isRefund = sales.amount < 0;

  const title = isRefund ? "Detalles del rembolso" : "Detalles del pago";
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      content={
        <Box>
          {isLoaded ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                width: ["100%", "400px"],
                display: "flex",
                flexDirection: "column",
                gap: "0.9rem",
              }}
            >
              {sales?.vehicle?.cancellationComment && (
                <Typography>{sales.vehicle.cancellationComment}</Typography>
              )}
              <Typography sx={{ fontSize: "18px" }}>
                {userData?.firstName ? userData?.firstName : ""}{" "}
                {userData?.lastName ? userData?.lastName : ""}
              </Typography>
              {vehicle && (
                <Box>
                  <Box>
                    <Typography
                      sx={{ textTransform: "capitalize" }}
                      variant="subtitle1"
                    >
                      {}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                      }}
                    >
                      <Typography variant="subtitle1">
                        {vehicle &&
                          `${policyTypeToLabel(vehicle?.type, {
                            capitalize: true,
                          })} ${vehicle?.brand} ${vehicle?.model} ${
                            vehicle?.year ? vehicle?.year : ""
                          }`}
                      </Typography>
                      <Typography>
                        {sales &&
                          formatCurrency(sales?.vehicle?.amount, {
                            maximumFractionDigits: 0,
                          })}
                      </Typography>
                    </Box>
                  </Box>

                  <Box>
                    {extrasData.length > 0 && (
                      <Typography
                        variant="overline"
                        sx={{ marginBottom: "5px" }}
                      >
                        Coberturas extra
                      </Typography>
                    )}
                    {extrasData.map((extra, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          gap: "10px",
                          justifyContent: "space-between",
                          marginBottom: "5px",
                          fontSize: "12px",
                        }}
                      >
                        <Box>
                          {extra.name ===
                          "$100,000 adicionales para gastos médicos a ocupantes" ? (
                            <Typography variant="caption">{`${formatCurrency(
                              100000 * extra.items[0].quantity,
                              { maximumFractionDigits: "0" }
                            )} adicionales para gastos médicos a ocupantes`}</Typography>
                          ) : (
                            <Typography>{extra.name}</Typography>
                          )}
                          {extra.type === "motorcycleHelmet" && (
                            <Typography variant="caption">
                              Valor cubierto:{" "}
                              {formatCurrency(extra.items[0].value, {
                                maximumFractionDigits: "0",
                              })}
                            </Typography>
                          )}
                          {extra.type === "personalAccessories" && (
                            <Typography variant="caption">
                              Valor cubierto:{" "}
                              {formatCurrency(extra.items[0].value, {
                                maximumFractionDigits: "0",
                              })}
                            </Typography>
                          )}
                          {extra.type === "specialEquipment" && (
                            <Typography variant="caption">
                              Valor cubierto:{" "}
                              {formatCurrency(extra.items[0].value, {
                                maximumFractionDigits: "0",
                              })}
                            </Typography>
                          )}
                        </Box>
                        {!isRefund && (
                          <Typography>
                            {formatCurrency(extra.premium, {
                              maximumFractionDigits: 0,
                            })}
                          </Typography>
                        )}
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}

              {sales?.smarthphone?.chargeId && (
                <Box
                  sx={
                    !smart?.brand && {
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }
                  }
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      {policyTypeToLabel(smart?.type, {
                        capitalize: true,
                      })}{" "}
                      {smart?.brand} {smart?.model}
                    </Typography>
                    <Typography>
                      {sales &&
                        formatCurrency(sales?.smarthphone?.amount, {
                          maximumFractionDigits: 0,
                        })}
                    </Typography>
                  </Box>
                </Box>
              )}
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "space-between",
                }}
              >
                <Typography fontWeight={800}>Total:</Typography>
                <Typography fontWeight={800}>
                  {sales &&
                    formatCurrency(sales?.amount, { maximumFractionDigits: 0 })}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      }
    />
  );
}
