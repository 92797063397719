import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";
import { useEffect, useState } from "react";
import getWeekNumber from "../../utils/getWeek";
import { db } from "../../Firebase/firebase";
import Podium from "./Podium";
import RankingList from "./RankingList";
import agentYellow from "../../Assets/PNG/agentYellow.png";
import agentBlue from "../../Assets/PNG/agentBlue.png";
import agentGreen from "../../Assets/PNG/agentgreen.png";
import agentOrange from "../../Assets/PNG/agentOrange.png";
import WAgentYellow from "../../Assets/PNG/womanAgentYellow.png";
import WAgentBlue from "../../Assets/PNG/womanAgentBlue.png";
import WAgentGreen from "../../Assets/PNG/womanAgentGreen.png";
import WAgentOrange from "../../Assets/PNG/womanAgentOrange.png";
import CluppcitoYellow from "../../Assets/PNG/cluppcitoAgentYellow.png";
import CluppcitoBlue from "../../Assets/PNG/cluppcitoAgentBlue.png";
import CluppcitoGreen from "../../Assets/PNG/cluppcitoAgentGreen.png";
import CluppcitoOrange from "../../Assets/PNG/cluppcitoAgentOrange.png";
import { useAgentAuth } from "../../AuthAgentContext";
import getRankingText from "./getRankingText";
import getCurrentQuarter from "../../utils/getCurrentQuarter";
import { Redirect } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const InformationEXPSDialog = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {
          "¿Qué son los puntos de experiencia (EXPs) y cómo se calculan para un agente?"
        }
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography sx={{ mt: 2 }}>
            Los puntos de experiencia (EXPs) son una medida del progreso que un
            agente gana a través de diferentes acciones. Se ganan de distintas
            maneras, como:
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <ul>
              <li>5 EXP por cotización</li>
              <li>30 EXP por venta con efectivo</li>
              <li>30 EXP por venta con transferencia</li>
              <li>50 EXP por venta con tarjeta</li>
            </ul>
          </Typography>
        </DialogContentText>
        Al finalizar cada ranking semanal, mensual, trimestral y anual, los
        agentes que más EXPs hayan acumulado ocuparán el podio. Estos agentes
        podrán luego reclamar diversas recompensas y premios. ¡Buena suerte!
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const InformationPromotionDialog = ({
  open,
  handleClose,
  currentAgentUser,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{"¡Hey!"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography sx={{ mt: 2 }}>
            Solo un aviso amigable: esta sección de ranking es solo para
            nuestros agentes sin promotoria. Por eso, es probable que tu nombre
            no esté aquí... ¡pero sigue siendo genial! 🌟
          </Typography>
          Para participar en el ranking, por favor contacta a uno de nuestros
          agentes. Estaremos encantados de ayudarte y agregarte a la lista. Haz
          clic en "Contactar Agente" para proceder.{" "}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disableElevation
          href={`https://wa.me/5215577460177?text=Hola%2C%20me%20gustar%C3%ADa%20participar%20en%20el%20ranking%20de%20agentes.%20%C2%A1Gracias%21`}
          autoFocus
          target="_blank"
          variant="contained"
          startIcon={<WhatsAppIcon />}
          sx={{ backgroundColor: "#25D366" }}
        >
          Contactar Agente
        </Button>
        <Button onClick={handleClose} autoFocus>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function RankingAgentes() {
  const [rankingRange, setRankingRange] = useState("weekly");
  const { currentAgentUser, agentData, agentClaims } = useAgentAuth();
  const [winners, setWinners] = useState([]);
  const [ranking, setRanking] = useState([]);
  const [isLoadingRanking, setIsLoadingRanking] = useState(true);
  const [isLoadingWinners, setIsLoadingWinners] = useState(true);
  const [listRankingText, setListRankingTest] = useState("");
  const [currentAgentRanking, setCurrentAgentRanking] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(Boolean(agentData?.promotoria));
  const [isOnMobile, setIsOnMobile] = useState(false);
  useEffect(() => {
    const checkIfMobile = () => {
      setIsOnMobile(window.innerWidth <= 768); // Puedes ajustar el ancho según tus necesidades
    };

    checkIfMobile(); // Verifica al montar el componente

    window.addEventListener("resize", checkIfMobile); // Agrega el listener para detectar cambios de tamaño

    return () => {
      window.removeEventListener("resize", checkIfMobile); // Limpia el listener al desmontar
    };
  }, []);

  const notImageUsers = {
    man: {
      1: agentBlue,
      2: agentYellow,
      3: agentGreen,
      4: agentOrange,
    },
    woman: {
      1: WAgentBlue,
      2: WAgentYellow,
      3: WAgentGreen,
      4: WAgentOrange,
    },
    cluppcito: {
      1: CluppcitoBlue,
      2: CluppcitoYellow,
      3: CluppcitoGreen,
      4: CluppcitoOrange,
    },
  };

  const hashString = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash);
  };

  const getImageProfile = async (agent) => {
    if (agent?.profilePictureURL) {
      return agent.profilePictureURL;
    }

    const agentSnapshot = await db.doc(`/agentProspects/${agent.id}`).get();
    const agentData = agentSnapshot.data();
    const { gender, email } = agentData;

    const agentNameHash = hashString(agent.id);
    const index = (agentNameHash % 4) + 1;
    let profileImg = "";

    if (gender === "Masculino") {
      profileImg = notImageUsers.man[index];
    }
    if (gender === "Femenino") {
      profileImg = notImageUsers.woman[index];
    }

    profileImg = notImageUsers.cluppcito[index];
    return { profileImg, email };
  };

  const getRangeQueryPath = ({ rankingRange, isForWinners = false }) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    let collectionPath;
    if (rankingRange === "weekly") {
      const weekNumber = getWeekNumber(currentDate);
      const week = isForWinners ? weekNumber[1] - 1 : weekNumber[1];
      collectionPath = `/IT/agents/expRankings/${rankingRange}/${weekNumber[0]}_${week}`;
    }
    if (rankingRange === "monthly") {
      const month = isForWinners
        ? currentDate.getMonth().toString()
        : (currentDate.getMonth() + 1).toString();

      const monthSeted = month.length === 1 ? `0${month}` : month;
      collectionPath = `/IT/agents/expRankings/${rankingRange}/${year}_${monthSeted}`;
    }

    if (rankingRange === "quarterly") {
      const quarter = isForWinners
        ? getCurrentQuarter() - 1
        : getCurrentQuarter();
      collectionPath = `/IT/agents/expRankings/${rankingRange}/${year}_${quarter}`;
    }

    if (rankingRange === "yearly") {
      const settedYear = isForWinners ? year - 1 : year;
      collectionPath = `/IT/agents/expRankings/${rankingRange}/${settedYear}`;
    }

    return collectionPath;
  };

  const getWinnersRange = () => {
    const collectionPath = getRangeQueryPath({
      rankingRange,
      isForWinners: true,
    });

    try {
      const unsubscribe = db
        .collection(collectionPath)
        .orderBy("score", "desc")
        .limit(10)
        .onSnapshot(async (querySnapshot) => {
          const promises = querySnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const { profileImg, email } = await getImageProfile({
              ...data,
              id: doc.id,
            });
            if (data.deleted !== true) {
              return {
                id: doc.id,
                ...data,
                profilePictureURL: profileImg,
                email: email,
              };
            }
            return null;
          });

          const results = await Promise.allSettled(promises);
          const winners = results
            .filter(
              (result) => result.status === "fulfilled" && result.value !== null
            )
            .slice(0, 3)
            .map((result) => result.value);

          const currentUserIsAWinner = winners.find(
            (item) => item.id === currentAgentUser.uid
          );

          if (currentUserIsAWinner) {
          }

          setWinners(winners);
          console.log("Los ganadores son:", winners);
        });

      return unsubscribe;
    } catch (error) {
      console.error("Error obteniendo los ganadores:", error);
    } finally {
      setTimeout(() => setIsLoadingWinners(false), 1000);
    }
  };
  useEffect(() => setInfoOpen(Boolean(agentData?.promotoria)), [agentData]);
  useEffect(() => {
    const fetchData = async () => {
      const collectionPath = getRangeQueryPath({
        rankingRange,
      });
      const docRef = db
        .collection(collectionPath)
        .orderBy("score", "desc")
        .limit(25);

      const unsubscribe = docRef.onSnapshot(
        async (snapshot) => {
          const promises = snapshot.docs.map(async (item) => {
            const data = item.data();
            const { profileImg, email } = await getImageProfile({
              ...data,
              id: item.id,
            });
            return {
              ...data,
              imOnTheList: item.id === currentAgentUser.uid,
              agentID: item.id,
              profilePictureURL: profileImg,
              email,
            };
          });

          try {
            const results = await Promise.allSettled(promises);
            const exps = results
              .filter(
                (result) =>
                  result.status === "fulfilled" && !result.value.deleted
              )
              .slice(0, 10)
              .map((result, index) => ({
                ...result.value,
                position: index + 1,
              }));

            const currentAgentRanking = exps.find(
              (item) => item.agentID === currentAgentUser.uid
            );

            if (currentAgentRanking) {
              const text = getRankingText(currentAgentRanking, exps);
              setListRankingTest(text);
              setCurrentAgentRanking(currentAgentRanking);
            } else {
              const currentAgentRankingSnap = await db
                .doc(`${collectionPath}/${currentAgentUser.uid}`)
                .get();

              if (currentAgentRankingSnap.exists) {
                const data = currentAgentRankingSnap.data();
                const lastOpponent = exps[exps.length - 1];
                setListRankingTest(
                  `Aún no has llegado a los 10 primeros puestos. Necesitas ${
                    lastOpponent.score - data.score
                  } puntos de experiencia para alcanzar a ${
                    lastOpponent.username
                  }. ¡Sigue esforzándote!`
                );
                setCurrentAgentRanking({
                  ...data,
                  id: currentAgentRankingSnap.id,
                });
              } else {
                setCurrentAgentRanking({});
                setListRankingTest(
                  `Aún no has llegado a los 10 primeros puestos. Realiza cotizaciones o concreta ventas para empezar a rankear. ¡Sigue esforzándote!`
                );
              }
            }
            setRanking(exps);
          } catch (error) {
            console.error("Error processing ranking data: ", error);
          } finally {
            setTimeout(() => setIsLoadingRanking(false), 1000);
          }
        },
        (error) => {
          console.error("Error fetching ranking data: ", error);
        }
      );

      return () => unsubscribe();
    };
    if (rankingRange) getWinnersRange();
    fetchData();
  }, [rankingRange]);

  if (!currentAgentUser) return <Redirect to="/agentes/inicio-de-sesion" />;
  return (
    <Paper sx={{ minHeight: "100vh", overflowX: "hidden" }}>
      <DashboardHeader />

      <Stack
        sx={{
          marginTop: "75px",
          width: ["95%", "75%"],
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "600",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Ranking de Agentes
          <HelpIcon
            sx={{
              color: "#00BBEE",
              marginLeft: "5px",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => setIsOpen(true)}
          />
        </Typography>
        <InformationEXPSDialog
          handleClose={() => setIsOpen(false)}
          open={isOpen}
          currentAgentUser={currentAgentUser}
        />
        <InformationPromotionDialog
          handleClose={() => setInfoOpen(false)}
          open={infoOpen}
        />

        <p style={{ width: isOnMobile ? "100%" : "70%", margin: "0 auto" }}>
          Aprovecha para hacer más cotizaciones y cerrar ventas, así podrás
          alcanzar el primer lugar. <br />
          ¡Tú puedes lograrlo!
        </p>
        <Stack
          flexDirection={"row"}
          justifyContent={"center"}
          marginTop={"20px"}
          marginLeft={["15px", "0px"]}
        >
          <Button
            variant="contained"
            disableElevation
            sx={{
              backgroundColor:
                rankingRange === "weekly" ? "#00CA7D" : "#E1EAEC",
              color: rankingRange === "weekly" ? "#fff" : "#0B71C3",
              marginRight: "20px",
              "&:hover": {
                backgroundColor:
                  rankingRange === "weekly" ? "#00CA7D" : "#02AB6A",
                color: "#fff",
              },
            }}
            onClick={() => setRankingRange("weekly")}
          >
            Semanal
          </Button>
          <Button
            disableElevation
            variant="contained"
            sx={{
              backgroundColor:
                rankingRange === "monthly" ? "#00CA7D" : "#E1EAEC",
              color: rankingRange === "monthly" ? "#fff" : "#0B71C3",
              marginRight: "20px",
              "&:hover": {
                backgroundColor:
                  rankingRange === "monthly" ? "#00CA7D" : "#02AB6A",
                color: "#fff",
              },
            }}
            onClick={() => setRankingRange("monthly")}
          >
            Mensual
          </Button>
          <Button
            disableElevation
            variant="contained"
            sx={{
              backgroundColor:
                rankingRange === "quarterly" ? "#00CA7D" : "#E1EAEC",
              color: rankingRange === "quarterly" ? "#fff" : "#0B71C3",
              marginRight: "20px",
              "&:hover": {
                backgroundColor:
                  rankingRange === "quarterly" ? "#00CA7D" : "#02AB6A",
                color: "#fff",
              },
            }}
            onClick={() => setRankingRange("quarterly")}
          >
            Trimestral
          </Button>
          <Button
            disableElevation
            variant="contained"
            sx={{
              backgroundColor:
                rankingRange === "yearly" ? "#00CA7D" : "#E1EAEC",
              color: rankingRange === "yearly" ? "#fff" : "#0B71C3",
              marginRight: "20px",
              "&:hover": {
                backgroundColor:
                  rankingRange === "yearly" ? "#00CA7D" : "#02AB6A",
                color: "#fff",
              },
            }}
            onClick={() => setRankingRange("yearly")}
          >
            Anual
          </Button>
        </Stack>
      </Stack>
      {isLoadingRanking && isLoadingWinners ? (
        <Box
          sx={{
            width: "100%",
            height: "80vh",
            textAlign: "center",
            marginTop: "120px",
          }}
        >
          <Stack
            flexDirection={"row"}
            justifyContent={"space-evenly"}
            alignItems="flex-start"
            width={"80%"}
            height={"50vh"}
            margin={"80px auto 0px auto"}
          >
            <Skeleton
              variant="rectangular"
              width={"50%"}
              height={"50vh"}
              sx={{ borderRadius: "6px", marginRight: "70px" }}
            />
            <Stack width={"40%"}>
              <Skeleton animation="wave" height={"50px"} />
              <Skeleton animation="wave" height={"50px"} />
              <Skeleton animation="wave" height={"50px"} />
              <Skeleton animation="wave" height={"50px"} />
              <Skeleton animation="wave" height={"50px"} />
              <Skeleton animation="wave" height={"50px"} />
              <Skeleton animation="wave" height={"50px"} />
            </Stack>
          </Stack>
        </Box>
      ) : (
        <Grid container spacing={2} marginTop={"20px"}>
          <Grid item xs={12} md={6}>
            <Podium
              winners={winners}
              rankingRange={rankingRange}
              currentAgentUser={currentAgentUser}
              isOnMobile={isOnMobile}
              isAdmin={agentClaims.isAdmin}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RankingList
              isAdmin={agentClaims.isAdmin}
              ranking={ranking}
              listRankingText={listRankingText}
              currentAgentUser={currentAgentUser}
              rankingRange={rankingRange}
              currentAgentRanking={currentAgentRanking}
              getImageProfile={getImageProfile}
            />
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}
