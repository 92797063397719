import * as React from "react";

import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/es";

export default function BasicDatePicker({
  initialEndDate,
  initialStartDate,
  onSelectDate,
  isDisabled = false,
}) {
  return (
    <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          margin: "20px",
          display: "flex",
          justifyContent: "center",
          flexDirection: ["column", "row"],
        }}
      >
        <Box sx={{ margin: "10px" }}>
          <DatePicker
            label="Fecha inicial"
            value={initialStartDate}
            onChange={(newValue) => {
              onSelectDate(
                (a) =>
                  (a = a.map(function (item) {
                    return item === initialStartDate ? newValue.$d : item;
                  }))
              );
            }}
            inputFormat="DD/MM/YYYY"
            renderInput={(params) => <TextField {...params} />}
            disabled={isDisabled}
            maxDate={new Date()}
          />
        </Box>
        <Box sx={{ margin: "10px" }}>
          <DatePicker
            label="Fecha Final"
            value={initialEndDate}
            inputFormat="DD/MM/YYYY"
            onChange={(newValue) => {
              onSelectDate(
                (a) =>
                  (a = a.map(function (item) {
                    return item === initialEndDate ? newValue.$d : item;
                  }))
              );
            }}
            renderInput={(params) => <TextField {...params} />}
            disabled={isDisabled}
            maxDate={new Date()}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
}
