import { useState } from "react";
import { Menu, MenuItem, ListItemIcon, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import Logout from "@mui/icons-material/Logout";
import LinkIcon from "@mui/icons-material/Link";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { useAgentAuth } from "../../AuthAgentContext";
import { useAuth } from "../../AuthContext";
import Swal from "sweetalert2";
import RecuperarContraseñaModal from "../RecuperarContraseña/RecuperarContraseña";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import CircularProgress from "@mui/material/CircularProgress";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SellIcon from "@mui/icons-material/Sell";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import SchoolIcon from "@mui/icons-material/School";
import SelectOtherAgentModal from "../SelectOtherAgentModal";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { auth } from "../../Firebase/firebase";
import ReceiptIcon from "@mui/icons-material/Receipt";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";

export default function ProfileMenu({ anchorEl, open, handleClose }) {
  const [showPasswordModal, setShowPasswordModa] = useState(false);
  const {
    currentAgentUser,
    setCurrentAgentUser,
    agentDoc,
    configuracionPromotoria,
    agentClaims,
  } = useAgentAuth();
  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { logout } = useAuth();

  async function handleLogout() {
    logout();
    setCurrentAgentUser(null);
    localStorage.clear();
  }

  function handleOnClosePasswordModa() {
    setShowPasswordModa(false);
  }

  function handlerSendEmailPassword() {
    setLoading(true);
    auth
      .sendPasswordResetEmail(currentAgentUser.email)
      .then(() => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: `Se ha enviado un correo a ${currentAgentUser.email} con instrucciones para cambiar tu contraseña`,
        });
      })
      .catch((e) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: "El correo electrónico no se encuentra registrado",
        });
      });
  }

  function handleCloseModal() {
    setIsOpenModal(false);
  }

  // Las promotorías pueden elegir que secciones ocultar para sus agentes
  const promotoriaHiddenSections = configuracionPromotoria.hiddenSections || [];
  return (
    <>
      {agentClaims.isAdmin && isOpenModal && (
        <SelectOtherAgentModal isOpen onClose={handleCloseModal} />
      )}
      <RecuperarContraseñaModal
        title="Cambiar contraseña"
        option="Cambiar"
        open={showPasswordModal}
        onClose={handleOnClosePasswordModa}
      />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          style: {
            maxHeight: "80vh",
          },
          sx: {
            // maxHeight: "80vh",
            // overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem component="div">{currentAgentUser?.displayName}</MenuItem>
        <Divider />
        <MenuItem component={Link} to="/agentes/perfil">
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          <span>Perfil</span>
        </MenuItem>
        <MenuItem component={Link} to="/agentes/videos-tutoriales">
          <ListItemIcon>
            <OndemandVideoIcon fontSize="small" />
          </ListItemIcon>
          <span> Videos tutoriales</span>
        </MenuItem>
        <MenuItem component={Link} to="/agentes/historial-de-pagos">
          <ListItemIcon>
            <ReceiptIcon fontSize="small" />
          </ListItemIcon>
          <span> Historial de pagos</span>
        </MenuItem>
        <MenuItem component={Link} to="/agentes/cotizaciones">
          <ListItemIcon>
            <SummarizeOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Mis cotizaciones
        </MenuItem>

        {promotoriaHiddenSections.includes("goals") ? null : (
          <MenuItem component={Link} to="/agentes/metas-y-bonos">
            <ListItemIcon>
              <EmojiEventsIcon fontSize="small" />
            </ListItemIcon>
            Metas y bonos
          </MenuItem>
        )}
        <MenuItem component={Link} to="/agentes/ranking-agentes">
          <ListItemIcon>
            <FlagCircleIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
          </ListItemIcon>
          Ranking agentes
        </MenuItem>

        {promotoriaHiddenSections.includes("commissions") ? null : (
          <MenuItem component={Link} to="/agentes/comisiones">
            <ListItemIcon>
              <RequestQuoteIcon fontSize="small" />
            </ListItemIcon>
            Comisiones
          </MenuItem>
        )}

        <MenuItem component={Link} to="/agentes/polizas-vendidas">
          <ListItemIcon>
            <SellIcon fontSize="small" />
          </ListItemIcon>
          Pólizas Vendidas
        </MenuItem>
        {agentDoc?.promotoria && (
          <MenuItem component={Link} to="/agentes/ventas-agentes">
            <ListItemIcon>
              <SupervisorAccountIcon fontSize="small" />
            </ListItemIcon>
            Ventas de agentes
          </MenuItem>
        )}

        <MenuItem
          component="a"
          href="https://www.guros.com/?promocode=CLUPP"
          target="__blank"
        >
          <ListItemIcon>
            <LinkIcon fontSize="small" />
          </ListItemIcon>
          <span>Cotizar con otras aseguradoras</span>
        </MenuItem>
        <MenuItem
          component="a"
          href="https://drive.google.com/drive/folders/1LpjkQvCZ3lPW0IbeRqPPn0wKw_rWl7t2?usp=sharing"
          target="__blank"
        >
          <ListItemIcon>
            <PermMediaIcon fontSize="small" />
          </ListItemIcon>
          <span>Recursos audiovisuales</span>
        </MenuItem>
        <MenuItem
          component="a"
          href="https://meetings.hubspot.com/francisco-cortes2?uuid=825b1266-db8f-4f80-ac4a-bb3a613af0a5"
          target="__blank"
        >
          <ListItemIcon>
            <SchoolIcon fontSize="small" />
          </ListItemIcon>
          <span>Capacitación</span>
        </MenuItem>
        <MenuItem
          component="a"
          href="https://auto-clupp.web.app/privacy/aviso_privacidad_personal_vigente.pdf"
          target="__blank"
        >
          <ListItemIcon>
            <PrivacyTipIcon fontSize="small" />
          </ListItemIcon>
          <span>Aviso de privacidad</span>
        </MenuItem>
        <MenuItem component="a" onClick={() => handlerSendEmailPassword()}>
          <ListItemIcon>
            <VpnKeyIcon fontSize="small" />
          </ListItemIcon>
          Cambiar contraseña
          {loading && (
            <CircularProgress sx={{ marginLeft: "10px" }} size="small" />
          )}
        </MenuItem>
        {agentClaims.isAdmin && (
          <MenuItem
            component="div"
            onClick={() => {
              setIsOpenModal((prev) => !prev);
            }}
          >
            <ListItemIcon>
              <SupervisedUserCircleIcon fontSize="small" />
            </ListItemIcon>
            Cambiar de agente
          </MenuItem>
        )}
        <MenuItem component="div" onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Cerrar sesión
        </MenuItem>
      </Menu>
    </>
  );
}
