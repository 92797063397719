import { Checkbox } from "@mui/material/";
import { styled } from '@mui/material/styles';
const PREFIX = 'GreenCheckbox';

const classes = {
  root: `${PREFIX}-root`,
  checked: `${PREFIX}-checked`
};

const StyledCheckbox = styled(Checkbox)(({theme}) => ({
  [`&.${classes.root}`]: {
    color: "#00ca7d",
    "&$checked": {
      color: "#00ca7d",
    },
  },
  [`&.${classes.checked}`]: {},
}));

const GreenCheckbox = ((props) => <StyledCheckbox className={classes.root} color="default" {...props} />);

export default GreenCheckbox;
