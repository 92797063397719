import { Fragment } from 'react';
import logoclup from '../../Assets/PNG/LogoCLUP.png';
import SteeperConductor from './SteeperConductor';

const VerificarDatos = ({
  setHideVerificacion,
  setHideFinal,
  dataUser,
  dataUserCelular,
}) => {
  return (
    <Fragment>
      <div
        style={{
          backgroundColor: '#00BBEE',
          width: '100%',
          height: '81px',
          left: '1px',
        }}
      >
        <img
          src={logoclup}
          alt="clupp-logo"
          style={{
            paddingTop: '15px',
            width: '123px',
            height: '45px',
            top: '30px',
            left: '119px',
          }}
        />
      </div>
      <div
        style={{
          background: '#fff',
          height: '85vh',
          overflow: 'scroll',
          position: 'sticky',
          top: '4rem',
          width: '100%',
        }}
      >
        <h4 style={{ marginTop: '35px' }}>Estamos a unos pasos de terminar</h4>
        <SteeperConductor
          setHideVerificacion={setHideVerificacion}
          setHideFinal={setHideFinal}
          dataUser={dataUser}
          dataUserCelular={dataUserCelular}
        />
      </div>
    </Fragment>
  );
};

export default VerificarDatos;
