import { Fragment } from 'react';
import logoclup from '../../Assets/PNG/LogoCLUP.png';
import CluppFestivo from '../../Assets/PNG/Clupp_Festejo.png';

const FinalConductor = ({
  setHideBienvenido,
  setHideVerificacion,
  dataEmail,
}) => {
  const mostrarVerificar = () => {
    setHideBienvenido(false);
    setHideVerificacion(true);
  };

  return (
    <Fragment style={{ height: '100vh' }}>
      <Fragment>
        <div
          style={{
            backgroundColor: '#00BBEE',
            width: '100%',
            height: '81px',
            left: '1px',
          }}
        >
          <img
            src={logoclup}
            alt="clupp-logo"
            style={{
              paddingTop: '15px',
              width: '123px',
              height: '45px',
              top: '30px',
              left: '119px',
            }}
          />
        </div>
        <div
          style={{
            background: '#fff',
            height: '85vh',
            overflow: 'scroll',
            position: 'sticky',
            top: '4rem',
            width: '100%',
          }}
        >
          <h2
            style={{ marginTop: '60px', color: '#00BBEE', marginLeft: '10px' }}
          >
            ¡Felicidades <br /> ya estás asegurado!
          </h2>
          <div>
            <img
              src={CluppFestivo}
              alt="clupp-logo"
              style={{ width: '150px', height: '150x' }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              JustifyContent: 'center',
              flexDirection: 'columm',
            }}
          >
            <div style={{ width: '10%' }}> </div>
            <div
              style={{ width: '80%', textAlign: 'center', marginTop: '10px' }}
            >
              <p style={{ fontSize: '15px', textAlign: 'center' }}>
                Enviaremos un correo a <b>{dataEmail}</b> con instrucciones para
                que accedas a la plataforma dónde podrás consultar toda la
                información relacionada a tu seguro.
                <br />
                Esto puede tardar hasta 2 minutos. Si después de este tiempo no
                ha llegado, asegúrate de revisar la carpeta de correos no
                deseados
                <br />
                <br /> <br />
                ¡Bienvenid@ a la familia{' '}
                <span style={{ fontWeight: 'bold' }}> Clupp</span>!
              </p>
            </div>
          </div>
        </div>
      </Fragment>
    </Fragment>
  );
};

export default FinalConductor;
