import { Typography } from "@mui/material";
import Dialog from "../../Components/Dialog";

export default function DialogInfo({ rowSelected = {}, isOpen, onClose }) {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Comentario de cancelación"
      content={
        <>
          <Typography>{rowSelected?.cancellationComment}</Typography>
        </>
      }
    />
  );
}
