import { Fragment, useState, useEffect } from "react";
import Bienvenido from "./Bienvenido";
import VerificarDatos from "./VerificarDatos";
import FinalConductor from "./FinalConductor";
import whatsappWeb from "../../Assets/SVG/Group 9260.svg";

import { db } from "../../Firebase/firebase";
import useQuery from "../../hooks/useQuery";
import getWhatsAppUrl from "../../utils/getWhatsAppUrl";

const ConductorDePlataforma = () => {
  const [hideBienvenido, setHideBienvenido] = useState(true);
  const [hideVerificacion, setHideVerificacion] = useState(false);
  const [hideFinal, setHideFinal] = useState(false);

  /**Datos del usuario autentificado */
  const [docRef, setDocRef] = useState();
  const [currentVehicle, setCurrentVehicle] = useState();

  const [params, setParams] = useState();
  //console.log(quoteData)
  const query = useQuery();

  let uid = query.get("uid");
  let vid = query.get("vid");

  useEffect(() => {
    setDocRef(uid);
    setCurrentVehicle(vid);
  }, []);
  const [dataUserCelular, setDataUserCelular] = useState();
  const [dataEmail, setDataEmail] = useState();
  //console.log(dataTempPassword);
  useEffect(() => {
    if (docRef) {
      docRef &&
        db
          .collection("users")
          .doc(docRef)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setDataUserCelular(doc.data().smartphoneCovered);
              setDataEmail(doc.data().email);
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
    }
  }, [currentVehicle, docRef]);

  const whatsappUrl = getWhatsAppUrl();

  return (
    <div>
      {hideBienvenido == true && (
        <Bienvenido
          setHideBienvenido={setHideBienvenido}
          setHideVerificacion={setHideVerificacion}
        />
      )}
      {hideVerificacion == true && (
        <VerificarDatos
          setHideBienvenido={setHideBienvenido}
          setHideVerificacion={setHideVerificacion}
          setHideFinal={setHideFinal}
          dataUserCelular={dataUserCelular}
        />
      )}
      {hideFinal == true && (
        <FinalConductor
          setHideBienvenido={setHideBienvenido}
          setHideFiba={setHideVerificacion}
          dataEmail={dataEmail}
        />
      )}
      <div
        style={{
          position: "fixed",
          bottom: "0",
          height: "auto",
          width: "100%",
          marginLeft: "160px",
        }}
      >
        <a href={whatsappUrl} target="_blank" rel="noreferrer">
          <img
            src={whatsappWeb}
            alt="tengo-dudas"
            style={{ width: "50px", height: "50px" }}
          />
        </a>
      </div>
    </div>
  );
};

export default ConductorDePlataforma;
