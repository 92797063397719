import {
  Box,
  Button,
  Paper,
  Typography,
  TextField,
  Stack,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchIcon from "@mui/icons-material/Search";
import SideBarMenu from "../../Components/AgentSidebar/AgentSidebar";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";
import useQuoteHistory from "../../hooks/useQuoteHistory";
import FormDialog from "../../Components/NuevaCotizacionDialog/FormDialog";
import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import AgenteCotizacionesGrid from "../../Components/AgenteCotizacionesGrid/AgenteCotizacionesGrid";
import DottedMenuOptions from "../AgenteDashboard/DottedMenuOptios";
import { useAgentAuth } from "../../AuthAgentContext";
import { useAuth } from "../../AuthContext";
import format from "date-fns/format";
import { es } from "date-fns/locale";

async function exportCVS(data = []) {
  let columns =
    "data:text/csv;charset=utf-8,Fecha de cotización,Nombre,Correo,Teléfono,Último póliza cotizada\n";
  await data.forEach((item) => {
    columns =
      columns +
      `${format(
        new Date(item?.timestamp ? item?.timestamp : 0),
        "dd 'de' MMMM 'de' yyyy",
        {
          locale: es,
        }
      )},${item?.userName ? item.userName : ""},${
        item?.email ? item.email : ""
      },${item?.phone ? item.phone : ""},${
        item?.lastVehicle ? item.lastVehicle : ""
      }\n`;
  });
  const totalString = columns;

  var encodedUri = encodeURI(totalString);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "cotizaciones.csv");
  document.body.appendChild(link); // Required for FF

  link.click();
}

export default function TodasCotizaciones() {
  const [searchValue, setSearchValue] = useState("");
  const { loading, quotations, filteredProjects, setFilteredProjects } =
    useQuoteHistory();
  const [showQuoteDialog, setShowQuoteDialog] = useState(false);
  const isDesktop = useMediaQuery("(min-width:600px)");
  const {
    setCurrentAgentUser,
    currentAgentUser,
    otherAgent,
    agentClaims,
    handleRemoveSelectedAgent,
  } = useAgentAuth();
  const { logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOnCloseDottedMenu = () => {
    setAnchorEl(null);
  };

  async function handleLogout() {
    logout();
    localStorage.clear();
    setCurrentAgentUser(null);
  }

  useEffect(() => {
    handleSearch();
  }, [searchValue]);

  function handleSearch() {
    function normalizeInput(text) {
      return text
        ?.normalize("NFD")
        .replace(/[\u0300-\u036f]|\s/g, "")
        .toLowerCase()
        .includes(
          searchValue
            .normalize("NFD")
            .replace(/[\u0300-\u036f]|\s/g, "")
            .toLowerCase()
        );
    }
    let projectFilter = quotations.filter((item) => {
      const name = `${item?.firstName && item?.firstName} ${
        item?.lastName && item?.lastName
      } ${item?.lastName2 && item?.lastName2}`;
      const searchByName = normalizeInput(name);
      const searchByEstablishment = normalizeInput(item.email);
      const lastVehicle = normalizeInput(item.lastVehicle);
      const searchById = normalizeInput(item.id);
      const searchByPhone = item.phone?.includes(searchValue);

      return (
        searchByName ||
        searchByPhone ||
        searchByEstablishment ||
        lastVehicle ||
        searchById
      );
    });
    setFilteredProjects(projectFilter);
  }

  function handleCloseDialog() {
    setShowQuoteDialog(false);
  }

  function handleExport() {
    exportCVS(filteredProjects);
  }

  function NoInfoDataRow() {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        {quotations.length === 0 ? (
          <Box>
            <Typography>Aún no has realizado tu primera cotización</Typography>

            <Button
              sx={{
                zIndex: 10,
                backgroundColor: "#00CA7D",
                "&:hover": {
                  backgroundColor: "#009b5f",
                },
              }}
              variant="contained"
            >
              <a
                style={{
                  color: "white",
                  textDecoration: "none",
                  pointerEvents: "auto",
                  cursor: "pointer",
                }}
                href="/agentes/videos-tutoriales"
                target="_blank"
                rel="noreferrer"
              >
                Ver video tutorial
              </a>
            </Button>
          </Box>
        ) : (
          "Sin coincidencias"
        )}
      </Stack>
    );
  }

  return (
    <Paper sx={{ width: "100%", minHeight: "100vh", paddingBottom: "20px" }}>
      {!currentAgentUser && <Redirect to="/agentes/inicio-de-sesion" />}
      <DottedMenuOptions
        open={open}
        handleClose={handleOnCloseDottedMenu}
        anchorEl={anchorEl}
        logout={handleLogout}
        typeMenu={""}
      />
      <SideBarMenu viewOption="agentDashboard" />
      <DashboardHeader />
      <FormDialog open={showQuoteDialog} onClose={handleCloseDialog} />
      <main
        style={{
          width: "90%",
          maxWidth: "1140px",
          margin: "0 auto",
          paddingTop: "80px",
        }}
      >
        <Stack spacing={2} sx={{ margin: "0", marginBottom: "20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
              flexDirection: ["column", "row"],
              marginBottom: "10px",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              {otherAgent
                ? `Cotizaciones de ${otherAgent?.firstName}`
                : "Cotizaciones"}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                flexDirection: ["column", "row"],
              }}
            >
              {agentClaims.isAdmin === true && otherAgent && (
                <Button
                  disabled={!otherAgent}
                  variant="outlined"
                  onClick={() => handleRemoveSelectedAgent()}
                >
                  Mostrar mis pólizas vendidas
                </Button>
              )}
              <Button
                onClick={() => setShowQuoteDialog((prev) => !prev)}
                variant="contained"
              >
                Nueva cotización
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              variant="outlined"
              label="Ingresa tu búsqueda"
              size="small"
              sx={{ width: ["100%", "80%"] }}
            />
            {isDesktop && (
              <Button
                variant="contained"
                startIcon={<SearchIcon />}
                sx={{ width: "145px", marginLeft: "10px" }}
              >
                Buscar
              </Button>
            )}
          </Box>
        </Stack>
        <AgenteCotizacionesGrid
          agentUid={currentAgentUser?.uid}
          noRowOverlayLabel={NoInfoDataRow}
          loading={loading}
          rowData={filteredProjects}
        />
        <Button
          sx={{ margin: "20px" }}
          variant="outlined"
          onClick={handleExport}
        >
          Exporta a Excel
        </Button>
      </main>
    </Paper>
  );
}
