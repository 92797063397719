import React from "react";
import { styled } from '@mui/material/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Stepper from "../Stepper/Stepper";
import Header from "../Header/Header";
import Menu from "../Menu/Menu";
import editIcon from "../../Assets/SVG/edit.svg";
import deleteIcon from "../../Assets/SVG/delete.svg";
import vehicleIcon from "../../Assets/SVG/Group 9222.svg";
import "./MasVehiculos.css";
import BotonPrincipal from "../BotonPrincipal/BotonPrincipal";

const PREFIX = 'MasVehiculos';

const classes = {
  headerContainer: `${PREFIX}-headerContainer`,
  header: `${PREFIX}-header`,
  headerInfo: `${PREFIX}-headerInfo`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.headerContainer}`]: {
    background: "#0058f4",
    borderRadius: "0.5rem",
  },

  [`& .${classes.header}`]: {
    color: "#ffff",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "2rem",
  },

  [`& .${classes.headerInfo}`]: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    fontSize: "0.7rem",
  }
}));

export default function MasVehiculos() {

  return (
    <Root>
      <Header />
      <Menu />
      <div className="cotizar adjusted-height">
        <Stepper />
        <p>Prima mensual total:</p>
        <h1>MX$550</h1>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.headerContainer}
          >
            <div className={classes.header}>
              <div className={classes.headerInfo}>
                <img src={vehicleIcon} alt="vehicle-icon" />
                <div>
                  <Typography>Auto 1</Typography>
                  <Typography>$300 mensual</Typography>
                </div>
              </div>
              <div className="accordion-icons">
                <img src={editIcon} alt="edit-icon" />
                <img src={deleteIcon} alt="delete-icon" />
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table className="accordion-table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Cobertura</TableCell>
                    <TableCell align="center">Valor cubierto</TableCell>
                    <TableCell align="center">Lo que tu pagas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row" align="left">
                      Daños al vehículo
                      <p className="cobertura">
                        <p>¿Qué cubre?</p>
                      </p>
                    </TableCell>
                    <TableCell align="justify">$150,000</TableCell>
                    <TableCell align="center">5%</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row" align="left">
                      Robo total del vehículo
                      <p className="cobertura">
                        <p>¿Qué cubre?</p>
                      </p>
                    </TableCell>
                    <TableCell align="justify">$150,000</TableCell>
                    <TableCell align="center">10%</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row" align="left">
                      Daños que causaste
                      <p className="cobertura">
                        <p>¿Qué cubre?</p>
                      </p>
                    </TableCell>
                    <TableCell align="justify">$3,000,000</TableCell>
                    <TableCell align="center">Sin deducible</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row" align="left">
                      Asistencia vial
                      <p className="cobertura">
                        <p>¿Qué cubre?</p>
                      </p>
                    </TableCell>
                    <TableCell align="justify">Incluido</TableCell>
                    <TableCell align="center">Sin deducible</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row" align="left">
                      Asistencia legal
                      <p className="cobertura">
                        <p>¿Qué cubre?</p>
                      </p>
                    </TableCell>
                    <TableCell align="justify">$200,000</TableCell>
                    <TableCell align="center">Sin deducible</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </div>
      <BotonPrincipal tipo="Continuar" goTo="/contrata" />
    </Root>
  );
}
