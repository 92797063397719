import { useEffect, useState } from "react";
import { FormControlLabel, Checkbox, IconButton, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import ModalFinanciamiento from "./ModalFinanciamiento";
import ComoTeEnteraste from "../ComoTeEnteraste";
import "./ValorVehiculo.css";
import Swal from "sweetalert2";
import isADevice from "../../utils/isADevice";

const PREFIX = "ValorVehiculo";

const classes = {
  root: `${PREFIX}-root`,
  checked: `${PREFIX}-checked`,
  disabled: `${PREFIX}-disabled`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")({
  [`& .${classes.root}`]: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
    "&$disabled": {
      color: green[100], // Aquí defines el color del borde cuando está deshabilitado
    },
  },
  [`& .${classes.checked}`]: {},
});

const GreenCheckbox = (props) => <Checkbox color="default" {...props} />;

const FEATURE_DESCRIPTIONS = {
  "Es salvamento":
    " Vehículos recuperados de la pérdida total de una aseguradora.",
  Legalizado:
    "Vehículos fronterizos usados, originarios de otro país que ingresan a México.",
  "Cuenta con financiamiento":
    "El vehículo se adquirió a crédito y actualmente dicho crédito sigue vigente",
  "No tiene placas": "Placas en trámite o vehículo con permiso de circulación",
  "Soy conductor de plataforma":
    "El vehículo es utilizado (aunque sea de manera ocasional) para plataformas como Uber, Didi, Cabify, etc. Ya sea para reparto o transporte de personas.",
  "Está blindado": false,
};

function getVehicleFeatures(vehicleType) {
  switch (vehicleType) {
    case "auto":
    case "moto":
      return [
        "Es salvamento",
        "Legalizado",
        "Cuenta con financiamiento",
        "No tiene placas",
        "Está blindado",
        "Soy conductor de plataforma",
        "Confirmo que mi vehículo no tiene ninguna de las características anteriores",
      ];
    case "laptop":
    case "tablet":
    case "smartphone":
    case "devices":
      return ["Es restaurado", "Lo acabo de comprar"];
    case "bike":
    case "eBike":
      return [
        "Es salvamento",
        "Cuenta con financiamiento",
        "Soy conductor de plataforma",
        "Confirmo que mi vehículo no tiene ninguna de las características anteriores",
      ];
    case "scooter":
    case "otro":
      return [
        "Es salvamento",
        "Cuenta con financiamiento",
        "Confirmo que mi vehículo no tiene ninguna de las características anteriores",
      ];

    default:
      console.warn(`${vehicleType} is not a valid type`);
      break;
  }
}

const VEHICLE_PLATFORMS = [
  "Didi",
  "Uber",
  "Beat",
  "Cabify",
  "Rappi",
  "Uber Eats",
  "Otro",
];

const ValorVehiculo = ({
  vehicleData,
  setVehicleData,
  setHowFindOutClupp,
  howFindOutClupp,
  isAgentLogged,
  featuresSelectRef,
}) => {
  const [openFinanciamiento, setOpenFinanciamiento] = useState(false);
  const [disableFeatures, setDisabledFeatures] = useState([]);
  const vehicleFeatures = getVehicleFeatures(vehicleData.type);

  useEffect(() => {
    const features = getVehicleFeatures(vehicleData.type).slice(0, -1);

    if (vehicleData.features.length === 0) {
      setDisabledFeatures([]);
    } else if (
      vehicleData.features.includes(
        "Confirmo que mi vehículo no tiene ninguna de las características anteriores"
      )
    ) {
      setDisabledFeatures(features);
    } else {
      setDisabledFeatures([
        "Confirmo que mi vehículo no tiene ninguna de las características anteriores",
      ]);
    }
  }, [vehicleData.features, vehicleData.type]);

  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);
  let medium = urlParams.get("utm_medium");

  function handleCloseFinanciamiento() {
    setOpenFinanciamiento(false);
    const checked = false;
    const feature = "Cuenta con financiamiento";

    setVehicleData((prev) => {
      const updatedFeatures = checked
        ? [...(prev.features || []), feature]
        : prev.features.filter((item) => item !== feature);
      return {
        ...prev,
        features: updatedFeatures,
      };
    });
  }

  const isPlatformDriver =
    vehicleData.features &&
    vehicleData.features.includes("Soy conductor de plataforma");

  function confirmFinanciamiento(months, name) {
    if (!months || !name.trim()) {
      Swal.fire({
        icon: "warning",
        text: "Debes ingresar el nombre de la financiera y el número de meses",
      });
      return;
    }
    setOpenFinanciamiento(false);
    setVehicleData((prev) => {
      return {
        ...prev,
        financingMonths: months,
        financialName: name.trim(),
      };
    });
  }

  function handleOnFeatureChange(e) {
    const checked = e.target.checked;
    const feature = e.target.value;

    if (
      e.target.value === "Cuenta con financiamiento" &&
      e.target.checked === true
    ) {
      setOpenFinanciamiento(true);
    }

    // Disable the "platform driver" option
    if (e.target.value === "Soy conductor de plataforma") {
      Swal.fire(
        "Atención",
        "Por el momento no estamos asegurando conductores de plataforma.",
        "warning"
      );
      localStorage.removeItem("platformBoolean");
      return;
    }

    if (checked && feature === "Es salvamento") {
      Swal.fire(
        "¡Atención!",
        '<p style="text-align:left">Es necesario que cuentes al menos con una copia de la factura original y de la refactura del vehículo.<br/><br/>En las coberturas de daños materiales, adicional al deducible, tendrás que pagar el 30% de los daños y en caso de que el vehículo se declare como Pérdida total o te lo roben, el monto máximo a indemnizar será el valor del vehículo en su refactura con un descuento del 10% anual.</p>',
        "info"
      );
    }

    setVehicleData((prev) => {
      const updatedFeatures = checked
        ? [...(prev.features || []), feature]
        : prev.features.filter((item) => item !== feature);

      const isPlatformDriver = updatedFeatures.includes(
        "Soy conductor de plataforma"
      );

      if (
        e.target.value === "Cuenta con financiamiento" &&
        e.target.checked === false
      ) {
        delete prev.financingMonths;
        delete prev.financialName;
        Swal.fire({
          text: "Si tu vehículo es financiado y por lo tanto no cuentas con la factura original, el seguro no podrá cubrir los casos de pérdida total ni robo, ya que se requiere la factura para ambas.",
          confirmButtonText: "Lo entiendo y estoy de acuerdo.",
        });
      }
      return {
        ...prev,
        features: updatedFeatures,
        platforms: isPlatformDriver ? prev.platforms : [],
      };
    });
  }

  function handleOnPlatformChange(e) {
    const checked = e.target.checked;
    const platform = e.target.value;

    setVehicleData((prev) => {
      const updatedPlatforms = checked
        ? [...prev.platforms, platform]
        : prev.platforms.filter((item) => item !== platform);

      console.log(updatedPlatforms);
      return {
        ...prev,
        platforms: updatedPlatforms,
      };
    });
  }

  function openDialogFeature(description) {
    Swal.fire({
      icon: "info",
      text: description,
    });
  }

  // const [checkedSalvamento, setCheckedSalvamento] = useState(false);
  // const [isFinanced, setIsFinanced] = useState(false);
  // const [checkedBlindado, setCheckedBlindado] = useState(false);
  // const [checkedLegalizado, setCheckedLegalizado] = useState(false);
  // const [checkedPlacas, setCheckedPlacas] = useState(false);
  // const [checkedPlataforma, setCheckedPlataforma] = useState(false);

  // const [checkedDidi, setCheckedDidi] = useState(false);
  // const [checkedUber, setCheckedUber] = useState(false);
  // const [checkedCabify, setCheckedCabify] = useState(false);
  // const [checkedBeat, setCheckedBeat] = useState(false);
  // const [checkedRappi, setCheckedRappi] = useState(false);
  // const [checkedUberEats, setCheckedUberEats] = useState(false);
  // const [checkedOtro, setCheckedOtro] = useState(false);

  // function removeItemFromArr(arr, item) {
  //   var i = arr.indexOf(item);
  //   arr.splice(i, 1);
  //   setVehicleData({ ...vehicleData, features: arr });
  // }
  // function removeItemFromArr2(arr, item) {
  //   var i = arr.indexOf(item);
  //   arr.splice(i, 1);
  //   setVehicleData({ ...vehicleData, platforms: arr });
  // }

  // useEffect(() => {
  //   if (vehicleData) {
  //     if (vehicleData.features.includes("Es salvamento"))
  //       setCheckedSalvamento(true);
  //     else setCheckedSalvamento(false);

  //     if (vehicleData.features.includes("Cuenta con financiamiento"))
  //       setIsFinanced(true);
  //     else setIsFinanced(false);

  //     if (vehicleData.features.includes("Está blindado"))
  //       setCheckedBlindado(true);
  //     else setCheckedBlindado(false);

  //     if (vehicleData.features.includes("Legalizado"))
  //       setCheckedLegalizado(true);
  //     else setCheckedLegalizado(false);

  //     if (vehicleData.features.includes("No tiene placas"))
  //       setCheckedPlacas(true);
  //     else setCheckedPlacas(false);

  //     if (vehicleData.features.includes("Soy conductor de plataforma"))
  //       setCheckedPlataforma(true);
  //     else setCheckedPlataforma(false);
  //   }
  // }, [vehicleData]);

  // const handleChangeSalvamento = (e) => {
  //   const target = e.target.checked;
  //   setCheckedSalvamento(target);
  //   if (target === true)
  //     setVehicleData({
  //       ...vehicleData,
  //       features: [...vehicleData.features, e.target.value],
  //     });
  //   else {
  //     const value = e.target.value;
  //     removeItemFromArr(vehicleData.features, value);
  //   }
  // };

  // const handleIsFinancedOnChange = (e) => {
  //   const target = e.target.checked;
  //   setIsFinanced(target);
  //   if (target === true)
  //     setVehicleData({
  //       ...vehicleData,
  //       features: [...vehicleData.features, e.target.value],
  //     });
  //   else {
  //     const value = e.target.value;
  //     removeItemFromArr(vehicleData.features, value);
  //   }
  // };

  // const handleChangeBlindado = (e) => {
  //   const target = e.target.checked;
  //   setCheckedBlindado(target);
  //   if (target === true)
  //     setVehicleData({
  //       ...vehicleData,
  //       features: [...vehicleData.features, e.target.value],
  //     });
  //   else {
  //     const value = e.target.value;
  //     removeItemFromArr(vehicleData.features, value);
  //   }
  // };

  // const handleChangeLegalizado = (e) => {
  //   const target = e.target.checked;
  //   setCheckedLegalizado(target);
  //   if (target === true)
  //     setVehicleData({
  //       ...vehicleData,
  //       features: [...vehicleData.features, e.target.value],
  //     });
  //   else {
  //     const value = e.target.value;
  //     removeItemFromArr(vehicleData.features, value);
  //   }
  // };

  // const handleChangePlacas = (e) => {
  //   const target = e.target.checked;
  //   setCheckedPlacas(target);
  //   if (target === true)
  //     setVehicleData({
  //       ...vehicleData,
  //       features: [...vehicleData.features, e.target.value],
  //     });
  //   else {
  //     const value = e.target.value;
  //     removeItemFromArr(vehicleData.features, value);
  //   }
  // };

  // const handleChangePlataforma = (e) => {
  //   console.log(tipoSelected);
  //   const target = e.target.checked;
  //   setCheckedPlataforma(target);
  //   //setPlatformBoolean(target)
  //   if (target === true) {
  //     localStorage.setItem("platformBoolean", target);
  //     setVehicleData({
  //       ...vehicleData,
  //       features: [...vehicleData.features, e.target.value],
  //     });
  //   } else {
  //     const value = e.target.value;
  //     removeItemFromArr(vehicleData.features, value);
  //     if (!e.target.checked) {
  //       Swal.fire({
  //         icon: "info",
  //         text: "Si tu vehículo es de uso comercial, no aplicará el seguro.",
  //         confirmButtonText: `Ok`,
  //       });
  //     }
  //   }
  // };

  // const handleChangeUber = (e) => {
  //   const target = e.target.checked;
  //   setCheckedUber(target);
  //   console.log(vehicleData.platforms);
  //   if (target === true)
  //     setVehicleData({
  //       ...vehicleData,
  //       platforms: [...vehicleData.platforms, e.target.value],
  //     });
  //   else {
  //     const value = e.target.value;
  //     removeItemFromArr2(vehicleData.platforms, value);
  //   }
  // };
  // const handleChangeDidi = (e) => {
  //   const target = e.target.checked;
  //   setCheckedDidi(target);
  //   if (target === true)
  //     setVehicleData({
  //       ...vehicleData,
  //       platforms: [...vehicleData.platforms, e.target.value],
  //     });
  //   else {
  //     const value = e.target.value;
  //     removeItemFromArr2(vehicleData.platforms, value);
  //   }
  // };
  // const handleChangeCabify = (e) => {
  //   const target = e.target.checked;
  //   setCheckedCabify(target);
  //   if (target === true)
  //     setVehicleData({
  //       ...vehicleData,
  //       platforms: [...vehicleData.platforms, e.target.value],
  //     });
  //   else {
  //     const value = e.target.value;
  //     removeItemFromArr2(vehicleData.platforms, value);
  //   }
  // };
  // const handleChangeBeat = (e) => {
  //   const target = e.target.checked;
  //   setCheckedBeat(target);
  //   if (target === true)
  //     setVehicleData({
  //       ...vehicleData,
  //       platforms: [...vehicleData.platforms, e.target.value],
  //     });
  //   else {
  //     const value = e.target.value;
  //     removeItemFromArr2(vehicleData.platforms, value);
  //   }
  // };
  // const handleChangeUberEats = (e) => {
  //   const target = e.target.checked;
  //   setCheckedUberEats(target);
  //   if (target === true)
  //     setVehicleData({
  //       ...vehicleData,
  //       platforms: [...vehicleData.platforms, e.target.value],
  //     });
  //   else {
  //     const value = e.target.value;
  //     removeItemFromArr2(vehicleData.platforms, value);
  //   }
  // };
  // const handleChangeRappi = (e) => {
  //   const target = e.target.checked;
  //   setCheckedRappi(target);
  //   if (target === true)
  //     setVehicleData({
  //       ...vehicleData,
  //       platforms: [...vehicleData.platforms, e.target.value],
  //     });
  //   else {
  //     const value = e.target.value;
  //     removeItemFromArr2(vehicleData.platforms, value);
  //   }
  // };
  // const handleChangeOtro = (e) => {
  //   const target = e.target.checked;
  //   setCheckedOtro(target);
  //   if (target === true)
  //     setVehicleData({
  //       ...vehicleData,
  //       platforms: [...vehicleData.platforms, e.target.value],
  //     });
  //   else {
  //     const value = e.target.value;
  //     removeItemFromArr2(vehicleData.platforms, value);
  //   }
  // };
  //{(tipoSelected === 'moto' || tipoSelected === 'bikes' || tipoSelected === 'eBikes') &&
  return (
    <Root>
      <ModalFinanciamiento
        open={openFinanciamiento}
        onClose={handleCloseFinanciamiento}
        confirmFinanciamiento={confirmFinanciamiento}
      />
      <div className="container-valor">
        <p>
          {`Selecciona si tu ${
            isADevice(vehicleData.type) ? "dispositivo" : "vehículo"
          } tiene alguna de las siguientes
          características:`}
        </p>
        <div className="checkbox-grid" id="product-features">
          {vehicleFeatures &&
            vehicleFeatures.map((feature) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <FormControlLabel
                  ref={featuresSelectRef}
                  key={feature}
                  style={{
                    padding: 0,
                    marginBottom: "15px",
                    textAlign: "left",
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  labelPlacement="end"
                  id={feature}
                  checked={vehicleData?.features.includes(feature)}
                  disabled={disableFeatures.includes(feature)}
                  control={
                    <GreenCheckbox
                      onChange={handleOnFeatureChange}
                      name={feature}
                      sx={{
                        padding: 0,
                        display: "flex",
                        alignItems: "center",
                      }}
                      classes={{
                        root: disableFeatures.includes(feature)
                          ? classes.disabled
                          : classes.root,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={
                    feature.includes("Confirmo") ? <b>{feature}</b> : feature
                  }
                  value={feature}
                />
                {FEATURE_DESCRIPTIONS[feature] ? (
                  <IconButton
                    sx={{
                      width: "2px",
                      height: "0px",
                      padding: 0,
                      margin: "0px 20px 15px 0px",
                    }}
                    onClick={() =>
                      openDialogFeature(FEATURE_DESCRIPTIONS[feature])
                    }
                  >
                    <QuestionMarkIcon fontSize="small" />
                  </IconButton>
                ) : (
                  <Box sx={{ width: "24px", height: "24px" }} />
                )}
              </Box>
            ))}
        </div>
        {/* <div className="root-valor">
          <div className="root">
            <FormControlLabel
              id="Es salvamento"
              checked={checkedSalvamento}
              control={
                <GreenCheckbox
                  onChange={handleChangeSalvamento}
                  name="salvamento"
                />
              }
              label="Es salvamento"
              value="Es salvamento"
            />
          </div>
          {(tipoSelected === "auto" || tipoSelected === "moto") && (
            <div className="root">
              <FormControlLabel
                id="Legalizado"
                checked={checkedLegalizado}
                control={
                  <GreenCheckbox
                    onChange={handleChangeLegalizado}
                    name="legalizado"
                  />
                }
                label="Legalizado"
                value="Legalizado"
              />
            </div>
          )}
          <div className="root">
            <FormControlLabel
              id="Cuanta con financiamiento"
              checked={isFinanced}
              control={
                <GreenCheckbox
                  onChange={handleIsFinancedOnChange}
                  name="financiado"
                />
              }
              label="Cuenta con financiamiento"
              value="Cuenta con financiamiento"
            />
          </div>
          {(tipoSelected === "auto" || tipoSelected === "moto") && (
            <div className="root">
              <FormControlLabel
                id="No Tiene Placas"
                checked={checkedPlacas}
                control={
                  <GreenCheckbox
                    name="No tiene placas"
                    onChange={handleChangePlacas}
                  />
                }
                label="No tiene placas"
                value="No tiene placas"
              />
            </div>
          )}
          {(tipoSelected === "auto" || tipoSelected === "moto") && (
            <div className="root">
              <FormControlLabel
                id="Está blindado"
                checked={checkedBlindado}
                control={
                  <GreenCheckbox
                    name="Está blindado"
                    onChange={handleChangeBlindado}
                  />
                }
                label="Está blindado"
                value="Está blindado"
              />
            </div>
          )}
          {(vehicleData.type === "auto" ||
            vehicleData.type === "moto" ||
            vehicleData.type === "bike" ||
            vehicleData.type === "eBike") && (
            <div className="root">
              <FormControlLabel
                id="Soy conductor de plataforma"
                checked={checkedPlataforma}
                control={
                  <GreenCheckbox
                    name="Soy conductor de plataforma"
                    onChange={handleChangePlataforma}
                  />
                }
                label="Soy conductor de plataforma"
                value="Soy conductor de plataforma"
              />
            </div>
          )}
        </div> */}

        {isPlatformDriver && (
          <>
            <p>
              Selecciona las plataformas en las que trabajas con tu vehículo:
            </p>

            <div className="checkbox-grid">
              {VEHICLE_PLATFORMS.map((platform) => (
                <FormControlLabel
                  key={platform}
                  style={{
                    margin: 0,
                  }}
                  id={platform}
                  checked={
                    vehicleData.platforms &&
                    vehicleData.platforms.length &&
                    vehicleData.platforms.includes(platform)
                  }
                  control={
                    <GreenCheckbox
                      onChange={handleOnPlatformChange}
                      name={platform}
                      classes={{
                        root: classes.root,
                        checked: classes.checked,
                        disabled: classes.disabled,
                      }}
                    />
                  }
                  label={platform}
                  value={platform}
                />
              ))}
            </div>
          </>
        )}

        {/* {checkedPlataforma === true && (
          <p>Selecciona las plataformas en las que trabajas con tu vehículo:</p>
        )}
        {checkedPlataforma === true && (
          <div className="root-valor">
            <div className="root">
              <FormControlLabel
                id="Didi"
                checked={checkedDidi}
                control={
                  <GreenCheckbox onChange={handleChangeDidi} name="didi" />
                }
                label="Didi"
                value="Didi"
              />
            </div>
            <div className="root">
              <FormControlLabel
                id="Uber"
                checked={checkedUber}
                control={
                  <GreenCheckbox onChange={handleChangeUber} name="uber" />
                }
                label="Uber"
                value="Uber"
              />
            </div>

            <div className="root">
              <FormControlLabel
                id="Beat"
                checked={checkedBeat}
                control={
                  <GreenCheckbox onChange={handleChangeBeat} name="beat" />
                }
                label="Beat"
                value="Beat"
              />
            </div>
            <div className="root">
              <FormControlLabel
                id="Cabify"
                checked={checkedCabify}
                control={
                  <GreenCheckbox name="Cabify" onChange={handleChangeCabify} />
                }
                label="Cabify"
                value="Cabify"
              />
            </div>
            <div className="root">
              <FormControlLabel
                id="Rappi"
                checked={checkedRappi}
                control={
                  <GreenCheckbox
                    name="No tiene placas"
                    onChange={handleChangeRappi}
                  />
                }
                label="Rappi"
                value="Rappi"
              />
            </div>
            <div className="root">
              <FormControlLabel
                id="Uber Eats"
                checked={checkedUberEats}
                control={
                  <GreenCheckbox
                    name="Uber Eats"
                    onChange={handleChangeUberEats}
                  />
                }
                label="Uber Eats"
                value="Uber Eats"
              />
            </div>
            <div className="root">
              <FormControlLabel
                id="Otro"
                checked={checkedOtro}
                control={
                  <GreenCheckbox name="Otro" onChange={handleChangeOtro} />
                }
                label="Otro"
                value="Otro"
              />
            </div>
          </div>
        )} */}
        {!isAgentLogged && (
          <ComoTeEnteraste
            setHowFindOutClupp={setHowFindOutClupp}
            howFindOutClupp={howFindOutClupp}
          />
        )}
      </div>
    </Root>
  );
};

export default ValorVehiculo;
