import { Fragment } from "react";
import { Typography } from "@mui/material";

// import { addMonths } from "date-fns";
import formatPremium from "../utils/formatPremium";
import getTotalPremium from "../utils/getPremium";
// import formatDate from "../utils/formatDate";

function PaymentDescription({
  paymentPeriod,
  totalPremium,
  msi = false,
  separated = true,
  haveDiscount = false,
  discount,
}) {
  if (!totalPremium) return null;
  // const currentDate = new Date();
  const PAYMENT_PERIODS = {
    month: "mensualmente",
    year: "anualmente",
    "6month": "semestralmente",
    "3month": "trimestralmente",
  };
  switch (paymentPeriod) {
    // case "month":
    //   return (
    //     <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
    //       <Typography style={{ textAlign: "center" }}>
    //         Pago inicial: <b> {formatPremium(totalPremium * 2)}</b>{" "}
    //         correspondiente a los primeros 2 meses.
    //       </Typography>

    //       <Typography style={{ textAlign: "center" }}>
    //         Pagos subsecuentes: <b>{formatPremium(totalPremium)}</b>{" "}
    //         mensualmente apartir del{" "}
    //         {formatDate(addMonths(currentDate, 2), "d 'de' MMMM 'del' yyyy")}
    //       </Typography>
    //     </Box>
    //   );
    case "month":
    case "year":
    case "3month":
    case "6month":
      return (
        <Fragment>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
              width: "100%",
              gap: "1rem",
            }}
          >
            <Typography style={{ textAlign: "left" }}>
              Total a pagar {PAYMENT_PERIODS[paymentPeriod]}{" "}
              {msi ? (
                <>
                  <br />a meses sin intereses
                </>
              ) : (
                ""
              )}
              :{" "}
            </Typography>
            <Typography
              variant="h6"
              style={{ textAlign: "right", flex: separated ? "1" : "" }}
            >
              <b>
                {formatPremium(
                  getTotalPremium(totalPremium, paymentPeriod, {
                    msi,
                    haveDiscount,
                    discount,
                  })[0]
                )}
              </b>
            </Typography>
          </div>
        </Fragment>
      );
  }
}

export default PaymentDescription;
