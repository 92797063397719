import { db } from "../../Firebase/firebase";
import { Stack, Tooltip } from "@mui/material";
import { STATUS_TO_LABEL, formatTimestamp } from "./utils";
import StatusActionsMenu from "./StatusActionsMenu";

export default function StatusCell({ row }) {
  const actions = getStatusActions(row.status);
  const shouldDisplayActions = actions.length > 0;

  const lastUpdateAuthor = row.lastUpdate?.status?.updatedBy?.displayName || "";
  const lastUpdateEmail = row.lastUpdate?.status?.updatedBy?.email || "";
  const lastUpdateDate = formatTimestamp(
    row.lastUpdate?.status?.timestamp || ""
  );

  const lastUpdateText = `Cambio de estatus realizado por ${lastUpdateAuthor} ${lastUpdateEmail} el ${lastUpdateDate}`;
  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      gap={2}
      direction="row"
      width="100%"
    >
      {row.lastUpdate?.status?.updatedBy ? (
        <Tooltip placement="top" title={lastUpdateText}>
          <span>{STATUS_TO_LABEL[row.status] || ""}</span>
        </Tooltip>
      ) : (
        STATUS_TO_LABEL[row.status] || ""
      )}
      {shouldDisplayActions && (
        <StatusActionsMenu
          row={row}
          options={actions}
          value={row.status}
          handleEdit={handleStatusChange}
        />
      )}
    </Stack>
  );
}

async function handleStatusChange({
  row,
  setIsLoading,
  enqueueSnackbar,
  onClose,
  value,
  currentUser,
}) {
  setIsLoading(true);
  let message = "";
  let variant = "";
  try {
    const { id, agentId } = row;

    if (value === "upload_payment_invoice") return;

    const updatedBy = {
      uid: currentUser.uid,
      email: currentUser.email,
      displayName: currentUser.displayName,
    };

    const lastUpdate = {
      timestamp: Date.now(),
      updatedBy,
    };

    if (value === "pay") {
      await db.doc(`agentProspects/${agentId}/commissionPayouts/${id}`).update({
        status: "paid",
        timestampPaid: Date.now(),
        "lastUpdate.status": lastUpdate,
      });
    }

    if (value === "reject") {
      await db.doc(`agentProspects/${agentId}/commissionPayouts/${id}`).update({
        status: "rejected",
        timestampRejected: Date.now(),
        deleted: true,
        "lastUpdate.status": lastUpdate,
      });
    }

    if (value === "approve") {
      await db.doc(`agentProspects/${agentId}/commissionPayouts/${id}`).update({
        status: "approved",
        timestampApproved: Date.now(),
        "lastUpdate.status": lastUpdate,
      });
    }

    await db
      .collection(`agentProspects/${agentId}/commissionPayouts/${id}/updates`)
      .add({
        type: value,
        timestamp: Date.now(),
        updatedBy,

        ...(value === "pay" && {
          timestampPaid: Date.now(),
          status: "paid",
        }),

        ...(value === "reject" && {
          timestampRejected: Date.now(),
          deleted: true,
          status: "rejected",
        }),

        ...(value === "approve" && {
          timestampApproved: Date.now(),
          status: "approved",
        }),

        ...(value === "upload_payment_invoice" && {
          timestampPaymentInvoiceUploaded: Date.now(),
        }),
      });

    message = "Estatus actualizado correctamente";
    variant = "success";
  } catch (error) {
    console.log({ error });
    message = "Ocurrió un error inesperado";
    variant = "error";
  }
  enqueueSnackbar(message, {
    variant: variant,
    autoHideDuration: 2000,
  });
  setIsLoading(false);
  onClose();
}

function getStatusActions(status) {
  switch (status) {
    case "pending":
      return [
        {
          value: "approve",
          label: "Aprobar pago",
        },
        {
          value: "reject",
          label: "Eliminar",
        },
      ];
    case "rejected":
      return [];
    case "paid":
      return [
        {
          value: "upload_payment_invoice",
          label: "Subir comprobante de pago",
        },
      ];
    case "approved":
      return [
        {
          value: "pay",
          label: "Marcar como pagada",
        },
      ];
    default:
      return [];
  }
}

export function sortComparator(a, b) {
  const aLabel = STATUS_TO_LABEL[a] || "";
  const bLabel = STATUS_TO_LABEL[b] || "";
  return aLabel.localeCompare(bLabel);
}

export function valueFormatter(params) {
  return STATUS_TO_LABEL[params.value] || "";
}
