import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import TitlesHeadersRange from "./TitleHeaderRange";
import { useEffect, useState } from "react";
import useRedirect from "../../hooks/useRedirect";

const RankingList = ({
  ranking,
  listRankingText,
  currentAgentUser,
  rankingRange,
  currentAgentRanking,
  getImageProfile,
  isAdmin,
}) => {
  console.log("RANKING ->", ranking);

  const redirect = useRedirect();
  const [currentImg, setCurrentImg] = useState("");
  const headerTitles = {
    weekly: "semanal",
    monthly: "mensual",
    quarterly: "trimestral",
    yearly: "anual",
  };
  useEffect(() => {
    setCurrentImg(
      currentAgentUser?.photoURL
        ? currentAgentUser?.photoURL
        : getImageProfile(currentAgentUser.uid).profileImg
    );
  }, [currentAgentUser]);

  return (
    <Stack
      sx={{
        width: ["100%", "90%"],
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 600,
          textAlign: "left",
          marginLeft: "60px",
          justifyItems: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "82.5%",
        }}
      >
        <Stack flexDirection={"row"} alignItems={"center"}>
          <TitlesHeadersRange
            title={`Top 10 ${headerTitles[rankingRange]} actual`}
            rankingRange={rankingRange}
          />
        </Stack>
      </Typography>

      <List
        sx={{
          width: ["100%", "80%"],
          margin: "0 auto",
          bgcolor: "background.paper",
          maxHeight: "365px",
          overflowX: "hidden",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: "4px",
            borderRadius: "20px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#00BBEE",
            borderRadius: "20px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "lightgray",
            borderRadius: "20px",
          },
        }}
      >
        {ranking && Boolean(ranking.length > 0) ? (
          ranking.map((item) => (
            <>
              <ListItem
                alignItems="center"
                selected={item.imOnTheList}
                secondaryAction={
                  <Typography
                    sx={{ fontWeight: 600 }}
                  >{`${item.score.toLocaleString("es-MX")} EXP`}</Typography>
                }
              >
                <ListItemIcon>
                  <Typography sx={{ fontWeight: 600 }}>
                    {item.position}
                  </Typography>
                </ListItemIcon>
                <ListItemAvatar>
                  <Avatar
                    alt={item.username}
                    src={item.profilePictureURL}
                    sx={{ width: "45px", height: "45px" }}
                  />
                </ListItemAvatar>

                {isAdmin ? (
              <Tooltip title={<Typography variant="body2">{`Correo: ${item.email}`}</Typography>}>
                    <ListItemText primary={item.username} secondary={null} />
                  </Tooltip>
                ) : (
                  <ListItemText primary={item.username} secondary={null} />
                )}
              </ListItem>
              <Divider variant="inset" component="li" />
            </>
          ))
        ) : (
          <p>
            Parece que aún no hay agentes en la lista, ¡sé el primero en unirte!
          </p>
        )}
      </List>
      <ListItem
        alignItems="center"
        sx={{
          width: ["100%", "93%"],
          margin: "10px auto 10px 0px",
        }}
        secondaryAction={
          <Typography sx={{ fontWeight: 600 }}>{`${
            currentAgentRanking?.score
              ? currentAgentRanking.score.toLocaleString("es-MX")
              : "0"
          } EXP`}</Typography>
        }
      >
        <ListItemIcon>
          <Typography sx={{ fontWeight: 600 }}></Typography>
        </ListItemIcon>
        <ListItemAvatar
          onClick={() => redirect("/agentes/perfil")}
          sx={{ cursor: "pointer" }}
        >
          <Avatar
            alt={currentAgentUser.displayName}
            src={currentImg}
            sx={{ width: "45px", height: "45px" }}
          />
        </ListItemAvatar>
        <ListItemText
          onClick={() => redirect("/agentes/perfil")}
          sx={{ cursor: "pointer" }}
          primary={
            <Typography sx={{ fontWeight: 600 }}>
              {currentAgentUser.displayName || "Tu"}
            </Typography>
          }
          secondary={
            <Typography variant="subtitle2" sx={{ width: "90%" }}>
              {listRankingText}{" "}
            </Typography>
          }
        />
      </ListItem>
    </Stack>
  );
};

export default RankingList;
