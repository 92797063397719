import { Button, CircularProgress } from "@mui/material";
import arrow from "../../Assets/SVG/arrow-point-to-right-4.svg";
import whatsappMovil from "../../Assets/SVG/whatsapp.svg";
import whatsappWeb from "../../Assets/SVG/Group 9260.svg";
import "./BotonPrincipal.css";
import { useAuth } from "../../AuthContext";
import { useEffect, useState } from "react";
import useRedirect from "../../hooks/useRedirect";
import getWhatsAppUrl from "../../utils/getWhatsAppUrl";

const BotonPrincipal = ({ tipo, func, loading, back }) => {
  const [rastreatorUserUid, setRastreatorUserUid] = useState(false);
  useEffect(() => {
    const getRef = localStorage.getItem("rastreatorUserUid");
    if (getRef) {
      setRastreatorUserUid(true);
    } else setRastreatorUserUid(false);
  }, []);
  // console.log(rastreatorUserUid)
  const redirect = useRedirect();
  const { currentUser } = useAuth();

  const whatsappUrl = getWhatsAppUrl(
    `Hola acabo de visitar su página. Mi número de cotización es ${currentUser.uid}. Mi nombre es `
  );

  return (
    <div className="boton-principal-container">
      {tipo !== "msi" && (
        <Button
          className={
            tipo === "proceder al pago" ? "payment-btn" : "boton-principal"
          }
          onClick={() => func()}
        >
          {loading ? <CircularProgress value={100} color="inherit" /> : tipo}
        </Button>
      )}
      {tipo && (
        <div className="options">
          {(tipo !== "Cotizar" && rastreatorUserUid === false) ||
          tipo !== "Cotizar" ? (
            <p
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => redirect(back)}
            >
              <img src={arrow} alt="regresar" className="btn-icon back-btn" />
              Regresar
            </p>
          ) : (
            <div></div>
          )}
          <div className="position">
            <a href={whatsappUrl} target="_blank" rel="noreferrer">
              {/*<div className='btn-icon'></div>
                        <span className="ver">Tengo dudas</span>*/}
              <div className="movil">
                <img
                  src={whatsappMovil}
                  alt="tengo-dudas"
                  className="btn-icon"
                />{" "}
                Tengo dudas
              </div>
              <div className="web">
                <img
                  src={whatsappWeb}
                  alt="tengo-dudas"
                  className="btn-icon aling"
                />
              </div>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default BotonPrincipal;
