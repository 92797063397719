import { useState } from "react";
import { Paper, Box, Typography, Stack, Button } from "@mui/material";
import { Share, WhatsApp } from "@mui/icons-material";
import { useAgentAuth } from "../../AuthAgentContext";
import ProfileMenu from "../../Components/ProfileMenu/ProfileMenu";
import AgentSidebar from "../../Components/AgentSidebar/AgentSidebar";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";
import { Redirect } from "react-router";

export default function Refiere() {
  const { currentAgentUser } = useAgentAuth();
  const qrCode = currentAgentUser
    ? `https://api.qrserver.com/v1/create-qr-code/?data=${window.location.origin}/agentes/registro?referredBy=${currentAgentUser.uid}&size=200x200`
    : "";

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const shareData = {
    title: "Seguro de movilidad Clupp",
    text: "Te invito a vender el seguro de Clupp",
    url: currentAgentUser
      ? `${window.location.origin}/registro?referredBy=${currentAgentUser.uid}`
      : "",
  };
  const canShare = navigator.canShare && navigator.canShare(shareData);

  const handleOnCloseDottedMenu = () => {
    setAnchorEl(null);
  };

  function handleShare() {
    if (canShare) {
      // open the native share menu
      return navigator.share(shareData);
    }
    // redirect to WhatsApp
    window.open(
      `https://wa.me/?text=${shareData.text} \n\n${shareData.url}`,
      "_blank"
    );
  }

  return (
    <>
      {!currentAgentUser && <Redirect to="/agentes/inicio-de-sesion" />}
      <AgentSidebar />
      <DashboardHeader />
      <ProfileMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleOnCloseDottedMenu}
      />
      {currentAgentUser && (
        <Paper
          sx={{
            width: "100%",
            height: "100vh",
            display: "grid",
            placeItems: "center",
            marginTop: "60px"
          }}
        >
          <Box>
            <Typography
              variant="h6"
              style={{ margin: "10px", fontWeight: "600" }}
            >
              Refiere y gana con Clupp
            </Typography>
            <Stack alignItems="center" spacing={2}>
              <Typography variant="subtitle1" style={{ userSelect: "none" }}>
                {currentAgentUser.displayName}, comparte este QR personalizado,
                que es único para ti, para referir.
              </Typography>
              <Box sx={{ height: "280px" }}>
                <img
                  alt="código qr de referido"
                  style={{
                    minWidth: "200px",
                    maxWidth: "300px",
                    margin: "20px",
                  }}
                  src={qrCode}
                />
              </Box>
            </Stack>

            <Stack
              direction="row"
              alignItems="flex-end"
              justifyContent="space-around"
            >
              <Typography
                variant="subtitle1"
                style={{ userSelect: "none", width: "60%" }}
              >
                O bien, comparte tu liga única y personalizada para referir,
                dando clic aquí:
              </Typography>
              <Button
                startIcon={canShare ? <Share /> : <WhatsApp />}
                variant="outlined"
                disableElevation
                color={canShare ? "primary" : "success"}
                onClick={handleShare}
                sx={{
                  textTransform: "none",
                }}
              >
                Compartir
              </Button>
            </Stack>
          </Box>
        </Paper>
      )}
    </>
  );
}
