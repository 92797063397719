import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
//import Inicio from './Components/Inicio/Inicio';
import KmAlMes from "./Components/KmAlMes/KmAlMes";
import Cotizar from "./Views/Cotizar/Cotizar";
import ElegirPlan from "./Views/ElegirPlan/ElegirPlan";
import Contratar from "./Views/Contratar/Contratar";
import ConductorDePlataforma from "./Views/ConductorDePlataforma/ConductorDePlataforma";
import Respuestas from "./Views/Respuestas/Respuestas";
import MasVehiculos from "./Components/MasVehiculos/MasVehiculos";
import PreguntasFrecuentes from "./Components/PreguntasFrecuentes/PreguntasFrecuentes";
import AgentLogin from "./Views/AgentLogin/AgentLogin";
import AgentesRegistro from "./Views/AgentesRegistro/AgentesRegistro";
import TodasCotizaciones from "./Views/TodasCotizaciones/TodasCotizaciones";
import NotFound from "./Views/NotFound/NotFound";
import RespuestaRegistro from "./Views/AgentesRegistro/RespuestaRegistro";
import AgentDashboard from "./Views/AgenteDashboard/AgentDashboard";
import Refiere from "./Views/Refiere/Refiere";
import Comisiones from "./Views/Comisiones/Comisiones";
import VideoTutoriales from "./Views/VideoTuroriales/VideoTutoriales";
import MetasYBonos from "./Views/MetasYBonos/MetasYBonos";
import Profile from "./Views/Profile/Profile";
import AgentsSales from "./Views/AgentsSales/AgentsSales";
import PolizasVendidas from "./Views/PolizasVendidas/PolizasVendidas";
import Catalogo from "./Views/Catologo/Catalogo";
import { SnackbarProvider } from "notistack";
import { AuthProvider } from "./AuthContext";
import { AuthAgentProvider } from "./AuthAgentContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { esES as coreEsEs } from "@mui/material/locale";
import CatalogoGrid from "./Views/CatalogGrid/CatalogGrid";
import { esES } from "@mui/x-date-pickers/locales";

// import { ThemeProvider, createTheme } from "@mui/material"
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material";
import MSI from "./Views/MSI/MSI";
import PLATS from "./Views/PLATS/PLATS";
import Extras from "./Views/Extras";
import AGENTES from "./utils/agentes";
import HistorialPagosAgente from "./Views/HistorialDePagoAgentes/HistorialPagosAgente";
import RankingAgentes from "./Views/RankingAgentes/RankingAgentes";

const stripePromise = loadStripe(
  "pk_live_51H5q3WAQwCaNdJ3ZN0bCb7PsC2l3w4yOkkvCD0bC6J6blQjFzNG33MAriRSGEZXfbpmPCjtR2VythfeUV7Ebpx0N006NiETUco"
);

const theme = createTheme(
  {
    typography: {
      button: {
        textTransform: "none",
      },
    },
    palette: {
      mode: "light",
      primary: {
        main: "#0058f4",
        contrastText: "rgba(253,252,252,0.87)",
      },
      secondary: {
        main: "#66bb6a",
      },
      tertiary: {
        main: "#00bbee",
      },
      background: {
        default: "#F3F3F3",
        contrastText: "#ffffff",
      },
    },
  },
  esES,
  coreEsEs
);

function App() {
  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);
  let medium = urlParams.get("utm_campaign");
  const isWowUser = medium === AGENTES.WOW;

  return (
    <Elements stripe={stripePromise}>
      <div
        className="app"
        style={{
          paddingTop: isWowUser ? "1rem" : "",
        }}
      >
        <Router>
          <ThemeProvider theme={theme}>
            <AuthAgentProvider>
              <SnackbarProvider maxSnack={4}>
                <AuthProvider>
                  <Switch>
                    {/* <Route exact path='/' component={Inicio} /> */}
                    <Route exact path="/" component={Cotizar} />
                    <Route exact path="/elegir-plan" component={ElegirPlan} />
                    <Route exact path="/contrata" component={Contratar} />
                    <Route path="/elegir-extras" component={Extras} />
                    <Route exact path="/respuestas" component={Respuestas} />
                    <Route exact path="/conoce-planes" component={KmAlMes} />
                    <Route exact path="/vehiculos" component={MasVehiculos} />
                    <Route
                      exact
                      path="/preguntas-frecuentes"
                      component={PreguntasFrecuentes}
                    />
                    <Route exact path="/msi" component={MSI} />
                    <Route exact path="/platforms" component={PLATS} />
                    <Route
                      exact
                      path="/conductor-de-plataforma"
                      component={ConductorDePlataforma}
                    />
                    <Route
                      exact
                      path="/agentes/dashboard"
                      component={AgentDashboard}
                    />
                    <Route
                      exact
                      path="/agentes/videos-tutoriales"
                      component={VideoTutoriales}
                    />
                    <Route
                      exact
                      path="/agentes/cotizaciones"
                      component={TodasCotizaciones}
                    />
                    <Route
                      exact
                      path="/agentes/inicio-de-sesion"
                      component={AgentLogin}
                    />
                    <Route
                      exact
                      path="/agentes/registro"
                      component={AgentesRegistro}
                    />
                    <Route
                      exact
                      path="/agentes/comisiones"
                      component={Comisiones}
                    />
                    <Route
                      exact
                      path="/agentes/polizas-vendidas"
                      component={PolizasVendidas}
                    />
                    <Route
                      exact
                      path="/agentes/historial-de-pagos"
                      component={HistorialPagosAgente}
                    />
                    <Route
                      exact
                      path="/agentes/ventas-agentes"
                      component={AgentsSales}
                    />
                    <Route
                      exact
                      path="/agentes/metas-y-bonos"
                      component={MetasYBonos}
                    />
                    <Route
                      exact
                      path="/agentes/ranking-agentes"
                      component={RankingAgentes}
                    />
                    <Route
                      exact
                      path="/agentes/registro/en-espera"
                      component={RespuestaRegistro}
                    />
                    <Route exact path="/agentes/perfil" component={Profile} />
                    <Route exact path="/agentes/refiere" component={Refiere} />
                    <Route
                      exact
                      path="/catalogo/:agent/:brand"
                      component={Catalogo}
                    />
                    {/* <Route exact path="/catalogogrid/:agent/:brand" component={CatalogoGrid} /> */}
                    <Route path="/*">
                      <NotFound />
                    </Route>
                  </Switch>
                </AuthProvider>
              </SnackbarProvider>
            </AuthAgentProvider>
          </ThemeProvider>
        </Router>
      </div>
    </Elements>
  );
}

export default App;
