import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { forwardRef, useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import "./DevicesSelectStyle.css";
import whatsappMovil from "../../Assets/SVG/whatsapp.svg";
import whatsappWeb from "../../Assets/SVG/Group 9260.svg";
import getWhatsAppUrl from "../../utils/getWhatsAppUrl";
import { useAuth } from "../../AuthContext";
import Swal from "sweetalert2";
import getEstimatedValue, {
  getBrandOptions,
  getDeviceModels,
} from "../../data/devices";
import { useAgentAuth } from "../../AuthAgentContext";
import { db } from "../../Firebase/firebase";
import highlightSection from "../../utils/highlightSection";

const DevicesSelect = ({
  loading,
  utm_source,
  saveQuote,
  device,
  setDevice,
}) => {
  const MAX_VAL = 50000;
  const MIN_VAL = 5000;
  const { isAgentLogged } = useAgentAuth();
  const [devicesCatalog, setDevicesCatalog] = useState({
    types: [
      { value: "smartphone", label: "Celular" },
      { value: "laptop", label: "Laptop" },
      { value: "tablet", label: "Tableta" },
    ],
    brands: [],
    models: [],
    model: {},
  });

  const { currentUser } = useAuth();
  const inputRef = useRef(null);
  const whatsappUrl = getWhatsAppUrl(
    `Hola acabo de visitar su página. Mi número de cotización es ${currentUser.uid}. Mi nombre es `
  );

  useEffect(() => inputRef.current.focus(), [device.valueUser]);
  useEffect(() => {
    const getDeviceBrand = async () => {
      try {
        const brandSnap = await db
          .collection(`catalogNew/${device.type}/brands`)
          .get();
        const brands = brandSnap.docs.map((doc) => doc.id);
        brands.unshift("Otro");
        setDevicesCatalog((prev) => ({ ...prev, brands }));
      } catch (error) {
        console.error("Error getting brands:", error);
      }
    };

    getDeviceBrand();
  }, [device.type]);

  useEffect(() => {
    const getDeviceModels = async () => {
      try {
        const modelSnap = await db
          .collection(`catalogNew/${device.type}/brands/${device.brand}/models`)
          .get();
        const models = modelSnap.docs.map((doc) => doc.id);
        models.unshift("Otro");
        setDevicesCatalog((prev) => ({ ...prev, models }));
      } catch (error) {
        console.error("Error getting models:", error);
      }
    };

    if (device.brand && device.brand !== "Otro") getDeviceModels();
  }, [device.brand]);

  useEffect(() => {
    if (device.model === "Otro") {
      setDevice((prev) => ({ ...prev, valueUser: null }));
    }
    const getModel = async () => {
      try {
        const modelSnap = await db
          .doc(
            `catalogNew/${device.type}/brands/${device.brand}/models/${device.model}`
          )
          .get();
        if (!modelSnap.exists) return;
        const data = modelSnap.data();
        setDevicesCatalog((prev) => ({ ...prev, model: data }));

        setDevice((prev) => ({ ...prev, valueUser: data.value }));
      } catch (error) {
        console.error("Error getting models:", error);
      }
    };

    if (device.model && device.model !== "Otro") getModel();
  }, [device.model]);

  function formatNumberWithCommas(number) {
    return number.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "valueUser") value = parseInt(value);
    setDevice((prevDevice) => ({
      ...prevDevice,
      [name]: value,
    }));
  };

  const NumberFormatInput = forwardRef(function NumberFormatInput(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
  });

  const isPhoneValueValid =
    !!device.valueUser &&
    parseInt(device.valueUser) >= MIN_VAL &&
    parseInt(device.valueUser) <= MAX_VAL;

  const handleSubmit = async () => {
    // Verificar qué campos están faltantes
    if (!device.type) {
      return Swal.fire({
        icon: "warning",
        title: "Campos faltantes",
        html: `Por favor, completa el tipo de dispositivo.`,
      }).then(() => {
        highlightSection("deviceType");
      });
    }
    if (device.brand === "Otro" || device.model === "Otro") {
      if (device.brand === "Otro" && !device.userBrand) {
        return Swal.fire({
          icon: "warning",
          title: "Campos faltantes",
          html: `Por favor, completa la marca del dispositivo.`,
        }).then(() => {
          highlightSection("brand");
        });
      }
      if (device.brand === "Otro" && !device.userModel) {
        return Swal.fire({
          icon: "warning",
          title: "Campos faltantes",
          html: `Por favor, completa el modelo del dispositivo.`,
        }).then(() => {
          highlightSection("model");
        });
      }
      if (
        device.brand !== "Otro" &&
        device.model === "Otro" &&
        !device.userModel
      ) {
        return Swal.fire({
          icon: "warning",
          title: "Campos faltantes",
          html: `Por favor, completa el modelo del dispositivo.`,
        }).then(() => {
          highlightSection("model");
        });
      }
    } else {
      if (!device.brand) {
        return Swal.fire({
          icon: "warning",
          title: "Campos faltantes",
          html: `Por favor, completa la marca del dispositivo.`,
        }).then(() => {
          highlightSection("brand");
        });
      }
      if (!device.model) {
        return Swal.fire({
          icon: "warning",
          title: "Campos faltantes",
          html: `Por favor, completa el modelo del dispositivo.`,
        }).then(() => {
          highlightSection("model");
        });
      }
    }
    if (!device.valueUser) {
      return Swal.fire({
        icon: "warning",
        title: "Campos faltantes",
        html: `Por favor, completa el valor comercial del dispositivo.`,
      }).then(() => {
        highlightSection("valueUser");
      });
    }

    if (!isPhoneValueValid) {
      return Swal.fire({
        icon: "warning",
        title: "Valor Comercial Inválido",
        html: `El valor comercial debe ser mayor a $${formatNumberWithCommas(
          MIN_VAL
        )} y menor a $${formatNumberWithCommas(MAX_VAL)}`,
      }).then(() => {
        highlightSection("valueUser");
      });
    }

    saveQuote();
  };

  const isAgentQuote = utm_source === "agentes";

  return (
    <Stack id="tipoVehiculo" sx={{ marginBottom: "5px" }}>
      <p className="bold">{`Ingresa los datos de tu dispositivo`}</p>

      <FormControl
        variant="outlined"
        sx={{ m: 1, minWidth: 120 }}
        id="deviceType"
      >
        <InputLabel>Dispositivo</InputLabel>
        <Select
          value={device.type}
          onChange={(e) => {
            handleChange(e);
            if (!isAgentLogged && !isAgentQuote)
              window.dataLayer.push({
                event: "selección_de_seguro",
                type: e.target.value === "otro" ? "other" : e.target.value,
                uid: currentUser.uid,
              });
            setDevice((prevDevice) => ({
              ...prevDevice,
              brand: "",
              model: "",
            }));
          }}
          label="Dispositivo"
          name="type"
          sx={{ textAlign: "left" }}
        >
          <MenuItem value={"smartphone"}>Celular</MenuItem>
          <MenuItem value={"laptop"}>Laptop</MenuItem>
          <MenuItem value={"tablet"}>Tableta</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }} id="brand">
        <Autocomplete
          freeSolo
          name="brand"
          renderInput={(params) => (
            <TextField variant="outlined" label="Marca" {...params} />
          )}
          value={device.brand}
          options={devicesCatalog.brands}
          onBlur={(e) => {
            setDevice((prevDevice) => ({
              ...prevDevice,
              model: "",
              brand: e.target.value,
            }));
          }}
          onChange={(e, newValue) => {
            setDevice((prevDevice) => ({
              ...prevDevice,
              model: "",
              brand: newValue,
            }));
          }}
        />
      </FormControl>
      {device.brand === "Otro" && (
        <>
          <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
            <TextField
              variant="outlined"
              label="Ingresa la marca"
              value={device.userBrand}
              onChange={handleChange}
              name="userBrand"
            />
          </FormControl>
          <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
            <TextField
              variant="outlined"
              label="Ingresa el modelo"
              value={device.userModel}
              onChange={handleChange}
              name="userModel"
            />
          </FormControl>
        </>
      )}
      {device.brand !== "Otro" && (
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }} id="model">
          <Autocomplete
            freeSolo
            name="model"
            renderInput={(params) => (
              <TextField variant="outlined" label="Modelo" {...params} />
            )}
            value={device.model}
            options={devicesCatalog.models}
            onBlur={(e) => {
              setDevice((prevDevice) => ({
                ...prevDevice,
                model: e.target.value,
              }));
            }}
            onChange={(e, newValue) => {
              setDevice((prevDevice) => ({
                ...prevDevice,
                model: newValue,
              }));
            }}
          />
        </FormControl>
      )}

      {device.model === "Otro" && (
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <TextField
            variant="outlined"
            label="Ingresa el modelo"
            onChange={handleChange}
            name="userModel"
          />
        </FormControl>
      )}

      <FormControl
        variant="outlined"
        sx={{ m: 1, minWidth: 120 }}
        id="valueUser"
      >
        <TextField
          error={!isPhoneValueValid && device.valueUser !== null}
          inputRef={inputRef}
          variant="outlined"
          label="Valor comercial"
          value={device.valueUser}
          onChange={handleChange}
          name="valueUser"
          InputProps={{
            inputComponent: NumberFormatInput,
          }}
        />
        <FormHelperText>Puedes cambiar este valor</FormHelperText>
        {!isPhoneValueValid && device.valueUser !== null && (
          <FormHelperText style={{ color: "red" }}>
            {`El valor debe ser mayor a $${formatNumberWithCommas(
              MIN_VAL
            )} y menor a $${formatNumberWithCommas(MAX_VAL)}`}
          </FormHelperText>
        )}
      </FormControl>
      <Box>
        <a
          href={getWhatsAppUrl(
            "Quiero cotizar un dispositivo, pero no sé cuál es su valor."
          )}
          target="_blank"
        >
          No sé el valor comercial de mi dispositivo
        </a>
      </Box>
      <div className="boton-principal-container">
        <Button className={"boton-principal"} onClick={handleSubmit}>
          {loading ? (
            <CircularProgress value={100} color="inherit" />
          ) : (
            "Cotizar"
          )}
        </Button>
        <div className="position endPosition">
          <a href={whatsappUrl} target="_blank" rel="noreferrer">
            <div className="movil">
              <img src={whatsappMovil} alt="tengo-dudas" className="btn-icon" />{" "}
              Tengo dudas
            </div>
            <div className="web">
              <img
                src={whatsappWeb}
                alt="tengo-dudas"
                className="btn-icon aling"
              />
            </div>
          </a>
        </div>
      </div>
    </Stack>
  );
};

export default DevicesSelect;
