import { useState, useEffect, Fragment, useRef, useReducer } from "react";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material//Delete";
import LinearProgress from "@mui/material/LinearProgress";

import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CameraAltRounded } from "@mui/icons-material";
import { db, storage } from "../../Firebase/firebase";
import Swal from "sweetalert2";
import clsx from "clsx";
import useQuery from "../../hooks/useQuery";
import { format } from "date-fns";

const PREFIX = 'SteeperConductor';

const classes = {
  root: `${PREFIX}-root`,
  buttonBlue: `${PREFIX}-buttonBlue`,
  buttonRegresar: `${PREFIX}-buttonRegresar`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    width: "100%",
  },

  [`& .${classes.buttonBlue}`]: {
    background: "#00BBEE",
    color: "#fff",
    fontWeigh: "bold",
    "&, &:hover, &:active": {
      backgroundColor: "#00bbee",
      color: "#fff",
    },
  },

  [`& .${classes.buttonRegresar}`]: {
    background: "#fff",
    color: "#000",
    fontWeigh: "bold",
  }
}));

function ImageAvatars(picture) {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Avatar
        alt="Remy Sharp"
        src={picture}
        style={{ width: "150px", height: "150px", margin: "10px" }}
      />
    </Grid>
  );
}

ImageAvatars.propTypes = {
  classes: PropTypes.object.isRequired,
};
/**Funcion para ver datos de Asegurador */
const VerDatosAsegurado = () => {
  const [docRef, setDocRef] = useState();
  const [currentVehicle, setCurrentVehicle] = useState();

  let query = useQuery();
  let uid = query.get("uid");
  let vid = query.get("vid");

  useEffect(() => {
    setDocRef(uid);
    setCurrentVehicle(vid);
  }, []);

  const [dataUserName, setDataUserName] = useState();
  const [dataUserLast, setDataUserLast] = useState();
  const [dataUserCorreo, setDataUserCorreo] = useState();
  const [dataUserPhone, setDataUserPhone] = useState();
  const [dataUserDate, setDataUserDate] = useState();
  const [userData, setUserData] = useState();

  const [picture, setPicture] = useState();
  useEffect(() => {
    if (docRef) {
      docRef &&
        db
          .collection("users")
          .doc(docRef)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const data = doc.data();
              setDataUserName(data.firstName);
              setDataUserLast(data.lastName);
              setDataUserPhone(data.phone);
              setDataUserCorreo(data.email);
              setDataUserDate(
                data.birthday
                  ? format(
                      new Date(
                        data.birthday.year,
                        data.birthday.month - 1,
                        data.birthday.day
                      ),
                      "yyyy'-'MM'-'dd"
                    )
                  : ""
              );
              setUserData({
                ...doc.data(),
                uid: doc.id,
              });
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      docRef &&
        db
          .collection("prospectsNew")
          .doc(docRef)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setPicture(doc.data()?.UberJSON?.profile?.url_picture);
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
    }
  }, [docRef]);
  const onChangeName = (e) => {
    let onchangeState = db.collection("users").doc(docRef);
    onchangeState.update({ firstName: e });
  };
  const onChangeLast = (e) => {
    let onchangeState = db.collection("users").doc(docRef);
    onchangeState.update({ lastName: e });
  };
  const onChangePhone = (e) => {
    if (e.length == "10") {
      let onchangeState = db.collection("users").doc(docRef);
      onchangeState.update({ phone: e });
    }
  };
  const [errorTelefono, setErrorTelefono] = useState(false);
  const updateDate = (e) => {
    const text = dataUserDate;
    if (!text) return;
    const separar = text.split("-");
    let onchangeState = db.collection("users").doc(docRef);
    onchangeState.update({
      birthday: {
        day: Number(separar[2]),
        month: Number(separar[1]),
        year: Number(separar[0]),
      },
    });
  };

  const [progress, setProgress] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setProgress(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  ///  console.log(progress)
  return (
    <Fragment>
      {progress ? (
        <Root>
          <Card
            elevation={0}
            style={{
              minWidth: "200px",
              width: "100%",
              minHeight: "100px",
            }}
          >
            <CardContent>
              <div style={{ flexGrow: 1 }}>
                <LinearProgress variant="indeterminate" />
                <p stye={{ fontSize: "12px" }}>
                  {" "}
                  <b>Cargando tus datos</b>
                </p>
              </div>
            </CardContent>
          </Card>
        </Root>
      ) : (
        <div
          style={{
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <div>{ImageAvatars(picture)}</div>
          <div>
            <Typography
              style={{
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              Nombre
            </Typography>
            <TextField
              fullWidth
              value={dataUserName}
              id="outlined-name"
              margin="normal"
              variant="outlined"
              style={{
                width: "100%",
                marginTop: "5px",
                marginBottom: "0px",
              }}
              onChange={(e) => setDataUserName(e.target.value)}
              onBlur={(e) => onChangeName(e.target.value)}
              type="text"
              inputProps={{
                autoCapitalize: "words",
              }}
            />
          </div>
          <div>
            <Typography
              style={{
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              Apellidos
            </Typography>
            <TextField
              value={dataUserLast}
              id="outlined-name"
              margin="normal"
              variant="outlined"
              style={{
                width: "100%",
                marginTop: "5px",
                marginBottom: "0px",
              }}
              onChange={(e) => setDataUserLast(e.target.value)}
              type="text"
              inputProps={{
                autoCapitalize: "words",
              }}
              onBlur={(e) => onChangeLast(e.target.value)}
            />
          </div>
          <div>
            <Typography
              style={{
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              Teléfono
            </Typography>
            <TextField
              id="outlined-name"
              value={dataUserPhone}
              margin="normal"
              variant="outlined"
              style={{
                width: "100%",
                marginTop: "5px",
                marginBottom: "0px",
              }}
              type="number"
              onChange={(e) => {
                const { value } = e.target;
                setErrorTelefono(!(value.length >= 10 && value.length <= 10));
                setDataUserPhone(value);
              }}
              onBlur={(e) => onChangePhone(e.target.value)}
            />
            {errorTelefono && (
              <p
                style={{
                  marginTop: "-5px",
                  color: "red",
                  fontSize: "12px",
                }}
              >
                El número de teléfono debe de ser 10 dígitos
              </p>
            )}
          </div>
          <div>
            <Typography
              style={{
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              Correo
            </Typography>
            <TextField
              value={dataUserCorreo}
              id="outlined-name"
              margin="normal"
              variant="outlined"
              style={{
                width: "100%",
                marginTop: "5px",
                marginBottom: "0px",
              }}
              disabled
              onChange={(e) => setDataUserCorreo(e.target.value)}
            />
          </div>
          <div>
            <Typography
              style={{
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              Fecha de nacimiento
            </Typography>
            <TextField
              error={!dataUserDate}
              value={dataUserDate}
              id="outlined-name"
              margin="normal"
              variant="outlined"
              type="date"
              style={{
                width: "100%",
                marginTop: "5px",
                marginBottom: "0px",
              }}
              helperText={!dataUserDate && "Ingresa tu fecha de nacimiento"}
              //defaultValue="1985-06-01"
              onChange={(e) => setDataUserDate(e.target.value)}
              onBlur={(e) => updateDate(e.target.value)}
            />
          </div>
          <div>
            <Typography
              style={{
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              Código Postal
            </Typography>
            <TextField
              error={userData?.zipCode?.length !== 5}
              value={userData?.zipCode}
              id="outlined-name"
              margin="normal"
              variant="outlined"
              type="number"
              style={{
                width: "100%",
                marginTop: "5px",
                marginBottom: "0px",
              }}
              helperText={
                userData?.zipCode?.length !== 5
                  ? "Ingresa el código postal de tu domicilio"
                  : userData?.zipCode.length > 5
                  ? "El código postal debe tener 5 dígitos"
                  : ""
              }
              onChange={(e) =>
                setUserData((prev) => ({ ...prev, zipCode: e.target.value }))
              }
              onBlur={async (e) => {
                await db.collection("users").doc(docRef).update({
                  zipCode: e.target.value.trim(),
                });
              }}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};
/**Funcion para ver vehiculo */
const VerDAtosVehiculos = () => {
  const [docRef, setDocRef] = useState();
  const [currentVehicle, setCurrentVehicle] = useState();

  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);
  let uid = urlParams.get("uid");
  let vid = urlParams.get("vid");

  useEffect(() => {
    setDocRef(uid);
    setCurrentVehicle(vid);
  }, []);

  const [vehicleData, setVehicleData] = useState({
    brand: "",
    model: "",
    year: "",
    serialNumber: "",
    plates: "",
    color: "",
    version: "",
  });

  useEffect(() => {
    let unsubscribe;
    if (!docRef && !currentVehicle) return;
    unsubscribe = db
      .doc(`users/${docRef}/vehicles/${currentVehicle}`)
      .onSnapshot((querySnapshot) => {
        const datosvehicle = { ...querySnapshot.data(), id: querySnapshot.id };
        setVehicleData(datosvehicle);
      });
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [currentVehicle, docRef]);

  async function saveVehicleData(update) {
    await db.doc(`users/${docRef}/vehicles/${currentVehicle}`).update(update);
  }

  function updateVehicleData(update) {
    setVehicleData((prev) => ({
      ...prev,
      ...update,
    }));
  }

  return (
    <div
      style={{
        textAlign: "center",
        paddingLeft: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      <div>
        <Typography
          style={{ fontSize: "15px", textAlign: "left", fontWeight: "bold" }}
        >
          Marca
        </Typography>
        <div>
          <TextField
            id="outlined-name"
            value={vehicleData.brand}
            margin="normal"
            variant="outlined"
            style={{
              minWidth: "200px",
              width: "100%",
              marginTop: "5px",
              marginBottom: "0px",
            }}
            disabled
          />
        </div>
      </div>
      <div>
        <Typography
          style={{ fontSize: "15px", textAlign: "left", fontWeight: "bold" }}
        >
          Modelo
        </Typography>
        <div>
          <TextField
            id="outlined-name"
            value={vehicleData.model}
            margin="normal"
            variant="outlined"
            style={{
              minWidth: "200px",
              width: "100%",
              marginTop: "5px",
              marginBottom: "0px",
            }}
            disabled
          />
        </div>
      </div>
      <div>
        <Typography
          style={{ fontSize: "15px", textAlign: "left", fontWeight: "bold" }}
        >
          Año
        </Typography>
        <div>
          <TextField
            id="outlined-name"
            value={vehicleData.year}
            margin="normal"
            variant="outlined"
            type="number"
            style={{
              minWidth: "200px",
              width: "100%",
              marginTop: "5px",
              marginBottom: "0px",
            }}
            disabled
          />
        </div>
      </div>
      <div>
        <Typography
          style={{
            fontSize: "15px",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          Versión
        </Typography>
        <div>
          <TextField
            error={!vehicleData.version}
            value={vehicleData.version}
            id="outlined-name"
            margin="normal"
            variant="outlined"
            style={{
              minWidth: "200px",
              width: "100%",
              marginTop: "5px",
              marginBottom: "0px",
            }}
            helperText={
              !vehicleData.version && "Ingresa la versión de tu vehículo"
            }
            onChange={(e) => updateVehicleData({ version: e.target.value })}
            onBlur={(e) => saveVehicleData({ version: e.target.value.trim() })}
            inputProps={{
              autoCapitalize: "words",
            }}
          />
        </div>
      </div>
      <div>
        <Typography
          style={{
            fontSize: "15px",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          Color
        </Typography>
        <div>
          <TextField
            error={!vehicleData.color}
            value={vehicleData.color}
            id="outlined-name"
            margin="normal"
            variant="outlined"
            style={{
              minWidth: "200px",
              width: "100%",
              marginTop: "5px",
              marginBottom: "0px",
            }}
            helperText={!vehicleData.color && "Ingresa el color de tu vehículo"}
            onChange={(e) => updateVehicleData({ color: e.target.value })}
            onBlur={(e) => saveVehicleData({ color: e.target.value.trim() })}
          />
        </div>
      </div>
      <div>
        <Typography
          style={{
            fontSize: "15px",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          Número de serie
        </Typography>
        <div>
          <TextField
            error={
              !vehicleData.serialNumber ||
              !/^[A-Z0-9]+$/i.test(vehicleData.serialNumber)
            }
            value={vehicleData.serialNumber}
            id="outlined-name"
            margin="normal"
            variant="outlined"
            style={{
              minWidth: "200px",
              width: "100%",
              marginTop: "5px",
              marginBottom: "0px",
            }}
            helperText={
              !vehicleData.serialNumber &&
              "Ingresa el número de serie de vehículo"
            }
            onChange={(e) =>
              updateVehicleData({ serialNumber: e.target.value.toUpperCase() })
            }
            onBlur={(e) =>
              saveVehicleData({ serialNumber: e.target.value.trim() })
            }
            inputProps={{
              autoCapitalize: "characters",
            }}
          />
        </div>
      </div>
      <div>
        <Typography
          style={{
            fontSize: "15px",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          Placas
        </Typography>
        <div>
          <TextField
            error={!vehicleData.plates || !/\w+|\d+/i.test(vehicleData.plates)}
            helperText={
              !vehicleData.plates &&
              "Ingresa las placas de vehículo, sólo numeros y letras"
            }
            value={vehicleData.plates}
            id="outlined-name"
            margin="normal"
            variant="outlined"
            style={{
              minWidth: "200px",
              width: "100%",
              marginTop: "5px",
              marginBottom: "0px",
            }}
            onChange={(e) =>
              updateVehicleData({ plates: e.target.value.toUpperCase() })
            }
            onBlur={(e) => saveVehicleData({ plates: e.target.value.trim() })}
            inputProps={{
              autoCapitalize: "characters",
            }}
          />
        </div>
      </div>
      <div>
        <Typography
          style={{
            fontSize: "15px",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          Sube las fotos de tu vehículo
        </Typography>
        {SimpleCard()}
      </div>
    </div>
  );
};
/**Funcion para ver subir datos de celular */
const SubirCelular = () => {
  const [errorIMEI, setErrorIMEI] = useState(false);
  const [docRef, setDocRef] = useState();
  const [currentCelular, setCurrentCelular] = useState();

  const initialState = {
    isUploading: false,
    isAnalyzing: false,
    progress: 0,
    user: null,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "update-IMEI":
        return {
          ...state,
          user: {
            ...state.user,
            IMEI: action.payload,
          },
        };
      case "set-user":
        return {
          ...state,
          user: action.payload,
        };
      case "upload-image":
        return {
          ...state,
          isUploading: true,
          progress: 0,
        };

      case "analize-image":
        return {
          ...state,
          isUploading: false,
          isAnalyzing: true,
        };

      case "done":
        return {
          ...state,
          isUploading: false,
          isAnalyzing: false,
          progress: 0,
        };
      default:
        return state;
    }
  }

  const [{ isUploading, isAnalyzing, user }, dispatch] = useReducer(
    reducer,
    initialState
  );

  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);

  const IMEIInputRef = useRef();

  let uid = urlParams.get("uid");
  let vid = urlParams.get("vid");
  useEffect(() => {
    setDocRef(uid);

    const unsubscribe = db.doc(`users/${uid}`).onSnapshot((snapshot) => {
      dispatch({
        type: "set-user",
        payload: { ...snapshot.data(), uid: snapshot.id },
      });
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const unsubscribe = db
      .collection("users")
      .doc(docRef)
      .collection("smartphones")
      .onSnapshot((querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) =>
          docs.push({ ...doc.data(), id: doc.id })
        );
        const idSmart = docs.map((doc) => doc.id);
        setCurrentCelular(idSmart[0]);
      });
    return unsubscribe;
  }, [docRef]);
  const updateIMEI = (e) => {
    const imei = e;
    let onchangeState = db.collection("users").doc(docRef);
    onchangeState.update({ IMEI: imei });
    let onChangeSmart = db
      .collection("users")
      .doc(docRef)
      .collection("smartphones")
      .doc(currentCelular);
    onChangeSmart.update({ IMEI: imei });
  };

  async function handleOnIMEIInputChange(e) {
    console.log("File input changed");
    console.log(e.target.files);
    const file = e.target.files[0];
    if (!file) return;

    const regex = RegExp(".[0-9a-z]+$", "i");
    const [extension] = file.name.match(regex);

    const filePath = `users/${docRef}/IMG_${new Date().getTime()}${extension}`;

    const uploadTask = storage.ref(filePath).put(file);

    dispatch({ type: "upload-image" });

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      (error) => {
        console.error(error);
        Swal.fire(
          "Error al procesar tu imagen",
          "Verifica tu conexión a internet e inténtalo nuevamente.",
          "error"
        );
      },
      async () => {
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();

        dispatch({ type: "analize-image" });

        const response = await fetch(
          "https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/ai/smartphones/readIMEI",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              imageURL: downloadURL,
            }),
          }
        );

        if (response.ok) {
          const json = await response.json();

          if (json.IMEI) {
            await db.doc(`users/${docRef}`).update({ IMEI: json.IMEI });
            dispatch({ type: "done" });
            setErrorIMEI(false);
            Swal.fire("", "Clave IMEI capturada", "success");
            return;
          }
          Swal.fire(
            "No pudimos obtener el IMEI de tu celular",
            "Inténtalo nuevamente con una imagen diferente.\n\nSi el problema persiste, escribe el IMEI de tu celular manualmente.",
            "error"
          );
          dispatch({ type: "done" });
        }
      }
    );

    e.target.value = null;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Dialog open={isUploading || isAnalyzing} style={{ padding: "10px" }}>
        <DialogTitle> Capturar clave IMEI</DialogTitle>
        <DialogContent
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <Typography>
            {isUploading ? "Subiendo imagen" : "Analizando imagen"}
          </Typography>

          <LinearProgress variant="indeterminate" />
        </DialogContent>
        <DialogActions />
      </Dialog>
      <Typography
        style={{ fontSize: "15px", textAlign: "left", fontWeight: "bold" }}
      >
        Clave IMEI
      </Typography>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Typography
          style={{
            fontSize: "15px",
            textAlign: "justify",
          }}
        >
          Para obtener el IMEI de tu celular marca en tu teléfono:
        </Typography>
        <Typography style={{ fontSize: "20px" }}>
          <b>*#06#</b>
        </Typography>
        <TextField
          value={user?.IMEI}
          label="IMEI"
          type="number"
          variant="outlined"
          style={{
            minWidth: "200px",
            width: "100%",
            marginBottom: "0px",
          }}
          onChange={(e) => {
            const { value } = e.target;
            setErrorIMEI(!(value.length >= 15 && value.length <= 15));
            dispatch({ type: "update-IMEI", payload: value });
          }}
          onBlur={(e) => updateIMEI(e.target.value)}
        />
        {errorIMEI && (
          <Typography style={{ color: "red" }}>Deben ser 15 dígitos</Typography>
        )}
        <Typography style={{ textAlign: "left" }}>
          Si prefieres, puedes tomar una captura de pantalla de tu IMEI y
          subirla aquí:
        </Typography>
        <Button
          onClick={() => IMEIInputRef.current.click()}
          color="primary"
          disableElevation
          variant="contained"
          style={{
            textTransform: "none",
            background: "#0058f4",
            color: "#fff",
          }}
        >
          Subir imagen
        </Button>
        <input
          style={{ display: "none" }}
          onChange={handleOnIMEIInputChange}
          type="file"
          accept="image/*"
          ref={IMEIInputRef}
        />
      </div>
    </div>
  );
};

/**Subir Fotos */
const SimpleCard = () => {
  const [docRef, setDocRef] = useState();
  const [currentVehicle, setCurrentVehicle] = useState();

  const query = useQuery();

  let uid = query.get("uid");
  let vid = query.get("vid");

  useEffect(() => {
    setDocRef(uid);
    setCurrentVehicle(vid);
  }, []);

  const [dataVehicle, setDataVehicle] = useState({
    back: "",
    front: "",
    left: "",
    right: "",
  });
  const [odometro, setOdometro] = useState();
  const [idOdometro, setIdOdometro] = useState();
  useEffect(() => {
    if (docRef) {
      db.collection("users")
        .doc(docRef)
        .collection("vehicles")
        .onSnapshot((querySnapshot) => {
          const docs = [];
          querySnapshot.forEach((doc) =>
            docs.push({ ...doc.data(), id: doc.id })
          );
          const datosvehicle = docs.find((n) => n.id === currentVehicle);
          const back = datosvehicle?.pictureCarBack;
          const front = datosvehicle?.pictureCarFront;
          const left = datosvehicle?.pictureCarLeft;
          const right = datosvehicle?.pictureCarRight;
          setDataVehicle({
            ...dataVehicle,
            back: back,
            front: front,
            left: left,
            right: right,
          });
        });
      db.collection("users")
        .doc(docRef)
        .collection("vehicles")
        .doc(currentVehicle)
        .collection("odometer")
        .onSnapshot((querySnapshot) => {
          const docs = [];
          querySnapshot.forEach((doc) =>
            docs.push({ ...doc.data(), id: doc.id })
          );
          const odometer = docs.map((doc) => doc.pictureURL);
          const idOdometer = docs.map((doc) => doc.id);
          setOdometro(odometer[0]);
          setIdOdometro(idOdometer[0]);
        });
    }
  }, [currentVehicle, docRef]);
  //console.log(dataVehicle);
  const [imageURL, setImageURL] = useState("");
  const [progressFront, setProgressFront] = useState(0);
  const [progressBack, setProgressBack] = useState(0);
  const [progressLeft, setProgressLeft] = useState(0);
  const [progressRight, setProgressRight] = useState(0);
  const [progressOdometro, setProgressOdometro] = useState(0);
  const [hideProgressFront, setHideProgressFront] = useState(false);
  const [hideProgressBack, setHideProgressBack] = useState(false);
  const [hideProgressLeft, setHideProgressLeft] = useState(false);
  const [hideProgressRight, setHideProgressRight] = useState(false);
  const [hideProgressOdometro, setHideProgressodometro] = useState(false);

  const imgRef = storage.ref("users");
  const upDateFront = (e) => {
    setHideProgressFront(true);
    const valor = e.target.files[0];
    const name = valor.name;
    const extension = name.split(".").pop();
    const photo = "IMG_" + Date.now() + "." + extension;
    const token =
      "users/" +
      docRef +
      "/vehicles/" +
      currentVehicle +
      "/pictureFront/" +
      photo;
    imgRef
      .child(token)
      .put(valor)
      .then((snap) => {
        const percent = (snap.bytesTransferred / snap.totalBytes) * 100;
        setProgressFront(percent);

        return snap.ref.getDownloadURL();
      })
      .then((link) => {
        const imageUrl = link;
        localStorage.setItem("imgURL", imageUrl);
        //      setImageURL(imageUrl)
        let onchangeUrlPhoto = db
          .collection("users")
          .doc(docRef)
          .collection("vehicles")
          .doc(currentVehicle);
        onchangeUrlPhoto.update({
          pictureCarFront: imageUrl,
        });
      })
      .catch((error) => {
        console.log("Error al guardar imagen: " + error);
      });
  };
  const upDateBack = (e) => {
    setHideProgressBack(true);
    const valor = e.target.files[0];
    const name = valor.name;
    const extension = name.split(".").pop();
    const photo = "IMG_" + Date.now() + "." + extension;
    //const token = currentUser.uid+'/profilePics/'+photo;
    const token =
      "users/" +
      docRef +
      "/vehicles/" +
      currentVehicle +
      "/pictureBack/" +
      photo;
    imgRef
      .child(token)
      .put(valor)
      .then((snap) => {
        const percent = (snap.bytesTransferred / snap.totalBytes) * 100;
        setProgressBack(percent);
        return snap.ref.getDownloadURL();
      })
      .then((link) => {
        const imageUrl = link;
        localStorage.setItem("imgURL", imageUrl);
        //      setImageURL(imageUrl)
        let onchangeUrlPhoto = db
          .collection("users")
          .doc(docRef)
          .collection("vehicles")
          .doc(currentVehicle);
        onchangeUrlPhoto.update({
          pictureCarBack: imageUrl,
        });
      })
      .catch((error) => {
        console.log("Error al guardar imagen: " + error);
      });
  };

  const upDateLeft = (e) => {
    setHideProgressLeft(true);
    const valor = e.target.files[0];
    const name = valor.name;
    const extension = name.split(".").pop();
    const photo = "IMG_" + Date.now() + "." + extension;
    //const token = currentUser.uid+'/profilePics/'+photo;
    const token =
      "users/" +
      docRef +
      "/vehicles/" +
      currentVehicle +
      "/pictureLeft/" +
      photo;
    imgRef
      .child(token)
      .put(valor)
      .then((snap) => {
        const percent = (snap.bytesTransferred / snap.totalBytes) * 100;
        setProgressLeft(percent);
        return snap.ref.getDownloadURL();
      })
      .then((link) => {
        const imageUrl = link;
        localStorage.setItem("imgURL", imageUrl);
        //      setImageURL(imageUrl)
        let onchangeUrlPhoto = db
          .collection("users")
          .doc(docRef)
          .collection("vehicles")
          .doc(currentVehicle);
        onchangeUrlPhoto.update({
          pictureCarLeft: imageUrl,
        });
      })
      .catch((error) => {
        console.log("Error al guardar imagen: " + error);
      });
  };
  const upDateRight = (e) => {
    setHideProgressRight(true);
    const valor = e.target.files[0];
    const name = valor.name;
    const extension = name.split(".").pop();
    const photo = "IMG_" + Date.now() + "." + extension;
    //const token = currentUser.uid+'/profilePics/'+photo;
    const token =
      "users/" +
      docRef +
      "/vehicles/" +
      currentVehicle +
      "/pictureRight/" +
      photo;
    imgRef
      .child(token)
      .put(valor)
      .then((snap) => {
        const percent = (snap.bytesTransferred / snap.totalBytes) * 100;
        setProgressRight(percent);
        return snap.ref.getDownloadURL();
      })
      .then((link) => {
        const imageUrl = link;
        localStorage.setItem("imgURL", imageUrl);
        //      setImageURL(imageUrl)
        let onchangeUrlPhoto = db
          .collection("users")
          .doc(docRef)
          .collection("vehicles")
          .doc(currentVehicle);
        onchangeUrlPhoto.update({
          pictureCarRight: imageUrl,
        });
      })
      .catch((error) => {
        console.log("Error al guardar imagen: " + error);
      });
  };

  const upDateOdometro = (e) => {
    setHideProgressodometro(true);
    const valor = e.target.files[0];
    const name = valor.name;
    const extension = name.split(".").pop();
    const photo = "IMG_" + Date.now() + "." + extension;
    //const token = currentUser.uid+'/profilePics/'+photo;
    const token =
      "users/" + docRef + "/vehicles/" + currentVehicle + "/odometro/" + photo;
    imgRef
      .child(token)
      .put(valor)
      .then((snap) => {
        const percent = (snap.bytesTransferred / snap.totalBytes) * 100;
        setProgressOdometro(percent);
        return snap.ref.getDownloadURL();
      })
      .then((link) => {
        const imageUrl = link;
        localStorage.setItem("imgURL", imageUrl);
        //      setImageURL(imageUrl)
        let onchangeOndometro = db
          .collection("users")
          .doc(docRef)
          .collection("vehicles")
          .doc(currentVehicle)
          .collection("odometer");
        onchangeOndometro.add({
          deleted: false,
          kmTotalOriginal: Number(0),
          pictureURL: imageUrl,
          review: false,
          timestamp: Date.now(),
          kmTotal: Number(0),
        });
      })
      .catch((error) => {
        console.log("Error al guardar imagen: " + error);
      });
  };
  const [dataKilometros, setDataKilometros] = useState();
  const updateKilometros = (e) => {
    if (idOdometro != "undefined") {
      const km = e;
      let onchangeState = db
        .collection("users")
        .doc(docRef)
        .collection("vehicles")
        .doc(currentVehicle)
        .collection("odometer")
        .doc(idOdometro);
      onchangeState.update({ kmTotal: Number(km) });
    }
  };

  const DeleteFront = () => {
    let onchangeUrlPhoto = db
      .collection("users")
      .doc(docRef)
      .collection("vehicles")
      .doc(currentVehicle);
    onchangeUrlPhoto.update({
      pictureCarFront: "",
    });
    setProgressFront("0");
    setHideProgressFront(false);
  };
  const deleteBack = () => {
    let onchangeUrlPhoto = db
      .collection("users")
      .doc(docRef)
      .collection("vehicles")
      .doc(currentVehicle);
    onchangeUrlPhoto.update({
      pictureCarBack: "",
    });
    setProgressBack("0");
    setHideProgressBack(false);
  };
  const deleteLeft = () => {
    let onchangeUrlPhoto = db
      .collection("users")
      .doc(docRef)
      .collection("vehicles")
      .doc(currentVehicle);
    onchangeUrlPhoto.update({
      pictureCarLeft: "",
    });
    setProgressLeft("0");
    setHideProgressLeft(false);
  };
  const deleteRight = () => {
    let onchangeUrlPhoto = db
      .collection("users")
      .doc(docRef)
      .collection("vehicles")
      .doc(currentVehicle);
    onchangeUrlPhoto.update({
      pictureCarRight: "",
    });
    setProgressRight("0");
    setHideProgressRight(false);
  };
  const deleteOdometro = () => {
    db.collection("users")
      .doc(docRef)
      .collection("vehicles")
      .doc(currentVehicle)
      .collection("odometer")
      .doc(idOdometro)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
    setProgressOdometro("0");
    setHideProgressodometro(false);
  };
  return (
    <div
      style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <br />
      <div
        style={{
          overflow: "hidden",
          borderRadius: "5px",
          width: "270px",
          height: "270px",
          position: "relative",
        }}
      >
        {dataVehicle.front == undefined || dataVehicle.front == "" ? (
          <Card
            style={{ minminWidth: "200px", width: "100%", minHeight: "180px" }}
          >
            <CardContent>
              <p
                style={{
                  fontWeight: "bold",
                  marginLeft: "5px",
                  marginTop: "34px",
                }}
              >
                Parte delantera
              </p>
              {hideProgressFront == true && (
                <div style={{ flexGrow: 1 }}>
                  <LinearProgress
                    variant="indeterminate"
                    value={progressFront}
                  />
                  <p stye={{ fontSize: "12px" }}> Cargando</p>
                </div>
              )}
            </CardContent>
            <CardActions>
              <div style={{ marginLeft: "30px" }}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="icon-button-file"
                  type="file"
                  onChange={upDateFront}
                />
                <label htmlFor="icon-button-file">
                  <div
                    style={{
                      background: " #0058F4",
                      width: "220px",
                      color: "#fff",
                      fontWeigth: "bold",
                      height: "40px",
                      marginTop: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <CameraAltRounded
                      style={{ marginTop: "8px", marginLeft: "-130px" }}
                    />
                    <div style={{ marginLeft: "10px", marginTop: "-25px" }}>
                      TOMAR FOTO{" "}
                    </div>
                  </div>
                </label>
              </div>
            </CardActions>
          </Card>
        ) : (
          <div
            style={{ width: "270px", height: "270px", position: "relative" }}
          >
            <img
              src={dataVehicle.front}
              style={{ width: "270px", height: "270px", objectFit: "cover" }}
            />
            <div style={{ marginLeft: "200px", marginTop: "-260px" }}>
              <IconButton
                style={{ background: "#FE7676", color: "#fff" }}
                onClick={DeleteFront}
                size="large">
                <DeleteIcon />
              </IconButton>
            </div>
            <div
              style={{
                backgroundColor: "#00000085",
                position: "absolute",
                bottom: 0,
                left: 0,
                zIndex: "2",
                width: "100%",
                height: "40px",
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: "#fff",
                  fontSize: "18px",
                  fontWeight: "bold",
                  paddingTop: "6px",
                }}
              >
                Foto frontal
              </p>
            </div>
          </div>
        )}
      </div>
      <br />
      <div
        style={{
          marginTop: "20px",
          overflow: "hidden",
          borderRadius: "5px",
          width: "270px",
          height: "270px",
          position: "relative",
        }}
      >
        {dataVehicle.back == undefined || dataVehicle.back == "" ? (
          <Card
            style={{ minminWidth: "200px", width: "100%", minHeight: "180px" }}
          >
            <CardContent>
              <p
                style={{
                  fontWeight: "bold",
                  marginLeft: "5px",
                  marginTop: "34px",
                }}
              >
                Parte trasera
              </p>
              {hideProgressBack == true && (
                <div style={{ flexGrow: 1 }}>
                  <LinearProgress
                    variant="indeterminate"
                    value={progressBack}
                  />
                  <p stye={{ fontSize: "12px" }}> Cargando</p>
                </div>
              )}
            </CardContent>
            <CardActions>
              <div style={{ marginLeft: "30px" }}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="icon-button-file-back"
                  type="file"
                  onChange={upDateBack}
                />
                <label htmlFor="icon-button-file-back">
                  <div
                    style={{
                      background: " #0058F4",
                      width: "220px",
                      color: "#fff",
                      fontWeigth: "bold",
                      height: "40px",
                      marginTop: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <CameraAltRounded
                      style={{ marginTop: "8px", marginLeft: "-130px" }}
                    />
                    <div style={{ marginLeft: "10px", marginTop: "-25px" }}>
                      TOMAR FOTO{" "}
                    </div>
                  </div>
                </label>
              </div>
            </CardActions>
          </Card>
        ) : (
          <div
            style={{ width: "270px", height: "270px", position: "relative" }}
          >
            <img
              src={dataVehicle.back}
              style={{ width: "270px", height: "270px", objectFit: "cover" }}
            />
            <div style={{ marginLeft: "200px", marginTop: "-260px" }}>
              <IconButton
                style={{ background: "#FE7676", color: "#fff" }}
                onClick={deleteBack}
                size="large">
                <DeleteIcon />
              </IconButton>
            </div>
            <div
              style={{
                backgroundColor: "#00000085",
                position: "absolute",
                bottom: 0,
                left: 0,
                zIndex: "2",
                width: "100%",
                height: "40px",
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: "#fff",
                  fontSize: "18px",
                  fontWeight: "bold",
                  paddingTop: "6px",
                }}
              >
                Foto trasera
              </p>
            </div>
          </div>
        )}
      </div>
      <br />
      <div
        style={{
          marginTop: "20px",
          overflow: "hidden",
          borderRadius: "5px",
          width: "270px",
          height: "270px",
          position: "relative",
        }}
      >
        {dataVehicle.left == undefined || dataVehicle.left == "" ? (
          <Card
            style={{ minminWidth: "200px", width: "100%", minHeight: "180px" }}
          >
            <CardContent>
              <p
                style={{
                  fontWeight: "bold",
                  marginLeft: "5px",
                  marginTop: "34px",
                }}
              >
                Parte izquierda
              </p>
              {hideProgressLeft == true && (
                <div style={{ flexGrow: 1 }}>
                  <LinearProgress
                    variant="indeterminate"
                    value={progressLeft}
                  />
                  <p stye={{ fontSize: "12px" }}> Cargando</p>
                </div>
              )}
            </CardContent>
            <CardActions>
              <div style={{ marginLeft: "30px" }}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="icon-button-file-left"
                  type="file"
                  onChange={upDateLeft}
                />
                <label htmlFor="icon-button-file-left">
                  <div
                    style={{
                      background: " #0058F4",
                      width: "220px",
                      color: "#fff",
                      fontWeigth: "bold",
                      height: "40px",
                      marginTop: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <CameraAltRounded
                      style={{ marginTop: "8px", marginLeft: "-130px" }}
                    />
                    <div style={{ marginLeft: "10px", marginTop: "-25px" }}>
                      TOMAR FOTO{" "}
                    </div>
                  </div>
                </label>
              </div>
            </CardActions>
          </Card>
        ) : (
          <div
            style={{ width: "270px", height: "270px", position: "relative" }}
          >
            <img
              src={dataVehicle.left}
              style={{ width: "270px", height: "270px", objectFit: "cover" }}
            />
            <div style={{ marginLeft: "200px", marginTop: "-260px" }}>
              <IconButton
                style={{ background: "#FE7676", color: "#fff" }}
                onClick={deleteLeft}
                size="large">
                <DeleteIcon />
              </IconButton>
            </div>
            <div
              style={{
                backgroundColor: "#00000085",
                position: "absolute",
                bottom: 0,
                left: 0,
                zIndex: "2",
                width: "100%",
                height: "40px",
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: "#fff",
                  fontSize: "18px",
                  fontWeight: "bold",
                  paddingTop: "6px",
                }}
              >
                Foto izquierda
              </p>
            </div>
          </div>
        )}
      </div>
      <br />
      <div
        style={{
          marginTop: "20px",
          overflow: "hidden",
          borderRadius: "5px",
          width: "270px",
          height: "270px",
          position: "relative",
        }}
      >
        {dataVehicle.right == undefined || dataVehicle.right == "" ? (
          <Card
            style={{ minminWidth: "200px", width: "100%", minHeight: "180px" }}
          >
            <CardContent>
              <p
                style={{
                  fontWeight: "bold",
                  marginLeft: "5px",
                  marginTop: "34px",
                }}
              >
                Parte derecha
              </p>
              {hideProgressRight == true && (
                <div style={{ flexGrow: 1 }}>
                  <LinearProgress
                    variant="indeterminate"
                    value={progressRight}
                  />
                  <p stye={{ fontSize: "12px" }}> Cargando</p>
                </div>
              )}
            </CardContent>
            <CardActions>
              <div style={{ marginLeft: "30px" }}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="icon-button-file-right"
                  type="file"
                  onChange={upDateRight}
                />
                <label htmlFor="icon-button-file-right">
                  <div
                    style={{
                      background: " #0058F4",
                      width: "220px",
                      color: "#fff",
                      fontWeigth: "bold",
                      height: "40px",
                      marginTop: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <CameraAltRounded
                      style={{ marginTop: "8px", marginLeft: "-130px" }}
                    />
                    <div style={{ marginLeft: "10px", marginTop: "-25px" }}>
                      TOMAR FOTO{" "}
                    </div>
                  </div>
                </label>
              </div>
            </CardActions>
          </Card>
        ) : (
          <div
            style={{ width: "270px", height: "270px", position: "relative" }}
          >
            <img
              src={dataVehicle.right}
              style={{ width: "270px", height: "270px", objectFit: "cover" }}
            />
            <div style={{ marginLeft: "200px", marginTop: "-260px" }}>
              <IconButton
                style={{ background: "#FE7676", color: "#fff" }}
                onClick={deleteRight}
                size="large">
                <DeleteIcon />
              </IconButton>
            </div>
            <div
              style={{
                backgroundColor: "#00000085",
                position: "absolute",
                bottom: 0,
                left: 0,
                zIndex: "2",
                width: "100%",
                height: "40px",
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: "#fff",
                  fontSize: "18px",
                  fontWeight: "bold",
                  paddingTop: "6px",
                }}
              >
                Foto derecha
              </p>
            </div>
          </div>
        )}
      </div>
      <br />
      <div
        style={{
          marginTop: "20px",
          overflow: "hidden",
          borderRadius: "5px",
          width: "270px",
          height: "270px",
          position: "relative",
        }}
      >
        {odometro == undefined ? (
          <Card
            style={{ minminWidth: "200px", width: "100%", minHeight: "180px" }}
          >
            <CardContent>
              <p
                style={{
                  fontWeight: "bold",
                  marginLeft: "5px",
                  marginTop: "34px",
                }}
              >
                Foto del odómetro
              </p>
              {hideProgressOdometro == true && (
                <div style={{ flexGrow: 1 }}>
                  <LinearProgress
                    variant="indeterminate"
                    value={progressOdometro}
                  />
                  <p stye={{ fontSize: "12px" }}> Cargando</p>
                </div>
              )}
            </CardContent>
            <CardActions>
              <div style={{ marginLeft: "30px" }}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="icon-button-file-odometro"
                  type="file"
                  onChange={upDateOdometro}
                />
                <label htmlFor="icon-button-file-odometro">
                  <div
                    style={{
                      background: " #0058F4",
                      width: "220px",
                      color: "#fff",
                      fontWeigth: "bold",
                      height: "40px",
                      marginTop: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <CameraAltRounded
                      style={{ marginTop: "8px", marginLeft: "-130px" }}
                    />
                    <div style={{ marginLeft: "10px", marginTop: "-25px" }}>
                      TOMAR FOTO{" "}
                    </div>
                  </div>
                </label>
              </div>
            </CardActions>
          </Card>
        ) : (
          <div
            style={{ width: "270px", height: "270px", position: "relative" }}
          >
            <img
              src={odometro}
              style={{ width: "270px", height: "270px", objectFit: "cover" }}
            />
            <div style={{ marginLeft: "200px", marginTop: "-260px" }}>
              <IconButton
                style={{ background: "#FE7676", color: "#fff" }}
                onClick={deleteOdometro}
                size="large">
                <DeleteIcon />
              </IconButton>
            </div>
            <div
              style={{
                backgroundColor: "#00000085",
                position: "absolute",
                bottom: 0,
                left: 0,
                zIndex: "2",
                width: "100%",
                height: "40px",
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: "#fff",
                  fontSize: "18px",
                  fontWeight: "bold",
                  paddingTop: "6px",
                }}
              >
                Foto odómetro
              </p>
            </div>
          </div>
        )}
      </div>
      <br />
      <div>
        <Typography style={{ textAlign: "left", fontWeight: "bold" }}>
          Kilómetros
        </Typography>
        <div style={{ marginLeft: "0px" }}>
          <TextField
            value={dataKilometros}
            label="Kilómetros"
            margin="normal"
            type="number"
            variant="outlined"
            style={{
              minWidth: "200px",
              width: "100%",
            }}
            onChange={(e) => {
              const { value } = e.target;
              setDataKilometros(value);
            }}
            onBlur={(e) => updateKilometros(e.target.value)}
          />
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold", textAlign: "left" }}
          >
            Por favor indica los kilómetros que marca tú odómetro
          </Typography>
        </div>
      </div>
    </div>
  );
};

function ColorlibStepIcon(props) {

  const { active, completed, inactiv } = props;

  const icons = {
    1: active ? (
      <div className={classes.marginT}> 1 </div>
    ) : completed ? (
      <div className={classes.marginTI}>
        {" "}
        <DoneIcon />{" "}
      </div>
    ) : (
      <div className={classes.marginT}> 1 </div>
    ),
    2: active ? (
      <div className={classes.marginT}>2</div>
    ) : completed ? (
      <div className={classes.marginTI}>
        <DoneIcon />
      </div>
    ) : (
      <div className={classes.inactiv}>2</div>
    ),
    3: active ? (
      <div className={classes.marginTI}>3</div>
    ) : completed ? (
      <div className={classes.marginTI}>
        <DoneIcon />{" "}
      </div>
    ) : (
      <div className={classes.inactiv}>3</div>
    ),
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

function ColorlibStepIcon2(props) {
  const { active, completed, inactiv } = props;

  const icons = {
    1: active ? (
      <div className={classes.marginT}> 1 </div>
    ) : completed ? (
      <div className={classes.marginTI}>
        {" "}
        <DoneIcon />{" "}
      </div>
    ) : (
      <div className={classes.marginT}> 1 </div>
    ),
    2: active ? (
      <div className={classes.marginT}>2</div>
    ) : completed ? (
      <div className={classes.marginTI}>
        <DoneIcon />
      </div>
    ) : (
      <div className={classes.inactiv}>2</div>
    ),
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon2.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};
function getSteps() {
  return [
    "Verifica tus datos",
    "Verifica o sube los datos de tu vehículo",
    "Sube la información de tu celular",
  ];
}

function getStepsCelular() {
  return ["Verifica tus datos", "Verifica o sube los datos de tu vehículo"];
}
function getStepContent(step) {
  switch (step) {
    case 0:
      return <div> {VerDatosAsegurado()} </div>;
    case 1:
      return <div>{VerDAtosVehiculos()}</div>;
    case 2:
      return <div>{SubirCelular()}</div>;
    /*case 3:
      return <div style={{marginLeft: '-160px'}}>{SubirVehiculo()}</div>*/
    default:
      return "Unknown step";
  }
}

const SteeperConductor = ({
  setHideVerificacion,
  setHideFinal,
  dataUserCelular,
}) => {

  const steps = getSteps();
  const stepsCelular = getStepsCelular();
  const [activeStep, setActiveStep] = useState(0);
  const [docRef, setDocRef] = useState();
  const [currentVehicle, setCurrentVehicle] = useState();

  const query = useQuery();

  let uid = query.get("uid");
  let vid = query.get("vid");
  useEffect(() => {
    setDocRef(uid);
    setCurrentVehicle(vid);
  }, []);

  const [dataUserName, setDataUserName] = useState();
  const [dataUserLast, setDataUserLast] = useState();
  const [dataUserCorreo, setDataUserCorreo] = useState();
  const [dataUserPhone, setDataUserPhone] = useState();
  const [dataUserDate, setDataUserDate] = useState();
  const [dataImei, setDataImei] = useState();
  const [dataTempPassword, setDataTempPassword] = useState();

  const [userData, setUserData] = useState();
  useEffect(() => {
    let unsubscribe;
    if (docRef) {
      unsubscribe = db.doc(`users/${docRef}`).onSnapshot((snapshot) => {
        // const docs = [];
        // querySnapshot.forEach((doc) =>
        //   docs.push({ ...doc.data(), id: doc.id })
        // );
        const datosUser = { ...snapshot.data(), id: snapshot.id };
        setUserData(datosUser);
        // docs.find((n) => n.id === docRef);
        const nameUser = datosUser.firstName;
        const last = datosUser.lastName;
        const phone = datosUser.phone;
        const correo = datosUser.email;
        const nac = datosUser.birthday
          ? new Date(
              datosUser.birthday.year,
              datosUser.birthday.month - 1,
              datosUser.birthday.day
            )
          : "";
        const imei = datosUser.IMEI;
        const tempPass = datosUser.tempPassword;
        setDataUserName(nameUser);
        setDataUserLast(last);
        setDataUserPhone(phone);
        setDataUserCorreo(correo);
        setDataUserDate(nac);
        setDataImei(imei);
        setDataTempPassword(tempPass);
      });
    }

    return unsubscribe;
  }, [docRef]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const [dataVehicle, setDataVehicle] = useState({
    back: "",
    front: "",
    left: "",
    right: "",
    motor: "",
    placas: "",
  });
  const [odometro, setOdometro] = useState();
  const [kmTotal, setKmTotal] = useState();

  useEffect(() => {
    let vehicleUnsubscribe;
    let odometerUnsubscribe;
    if (!docRef && !currentVehicle) return;

    vehicleUnsubscribe = db
      .doc(`users/${docRef}/vehicles/${currentVehicle}`)
      .onSnapshot((querySnapshot) => {
        const datosvehicle = querySnapshot.data();
        const back = datosvehicle?.pictureCarBack;
        const front = datosvehicle?.pictureCarFront;
        const left = datosvehicle?.pictureCarLeft;
        const right = datosvehicle?.pictureCarRight;
        const plates = datosvehicle?.plates;
        const motor = datosvehicle?.serialNumber;

        setDataVehicle({
          ...dataVehicle,
          back: back,
          front: front,
          left: left,
          right: right,
          placas: plates,
          motor: motor,
          color: datosvehicle?.color,
          version: datosvehicle?.version,
        });
      });

    odometerUnsubscribe = db
      .collection(`users/${docRef}/vehicles/${currentVehicle}/odometer`)
      .onSnapshot((querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) =>
          docs.push({ ...doc.data(), id: doc.id })
        );
        const odometer = docs.map((doc) => doc.pictureURL);
        const dataKmTotal = docs.map((doc) => doc.kmTotal);
        setOdometro(odometer[0]);
        setKmTotal(dataKmTotal[0]);
      });

    return () => {
      if (vehicleUnsubscribe) vehicleUnsubscribe();
      if (odometerUnsubscribe) odometerUnsubscribe();
    };
  }, [currentVehicle, docRef]);
  //console.log(dataVehicle)
  const verFinalCelular = () => {
    const nacimiento = dataUserDate;
    const dateNacimiento =
      nacimiento.year + "-" + nacimiento.month + "-" + nacimiento.day;
    if (dateNacimiento == "0-0-0" || !nacimiento) {
      Swal.fire({
        icon: "warning",
        text: "Selecciona tu fecha de nacimiento.",
      });
      setActiveStep(activeStep - 2);
    } else if (dataVehicle.placas == "") {
      Swal.fire({
        icon: "warning",
        text: "Captura las placas de tu vehículo.",
      });
      setActiveStep(activeStep - 1);
    } else if (!userData?.zipCode?.length || userData?.zipCode?.length !== 5) {
      Swal.fire({
        icon: "warning",
        text: "Captura tu código postal",
      });
    } else if (dataVehicle.version === "") {
      Swal.fire({
        icon: "warning",
        text: "Captura la version de tu vehículo",
      });
    } else if (dataVehicle.color === "") {
      Swal.fire({
        icon: "warning",
        text: "Captura el color de tu vehículo",
      });
    } else if (dataVehicle.motor == "") {
      Swal.fire({
        icon: "warning",
        text: "Captura el número de serie de tu vehículo.",
      });
    } else if (dataVehicle.front == undefined || dataVehicle.front == "") {
      Swal.fire({
        icon: "warning",
        text: "Toma la foto frontal del vehículo.",
      });
      setActiveStep(activeStep - 1);
    } else if (dataVehicle.back == undefined || dataVehicle.back == "") {
      Swal.fire({
        icon: "warning",
        text: "Toma la foto trasera del vehículo.",
      });
      setActiveStep(activeStep - 1);
    } else if (dataVehicle.left == undefined || dataVehicle.left == "") {
      Swal.fire({
        icon: "warning",
        text: "Toma la foto del lado izquierdo del vehículo.",
      });
      setActiveStep(activeStep - 1);
    } else if (dataVehicle.right == undefined || dataVehicle.right == "") {
      Swal.fire({
        icon: "warning",
        text: "Toma la foto del lado derecho del vehículo.",
      });
      setActiveStep(activeStep - 1);
    } else if (odometro == undefined || odometro == "") {
      Swal.fire({
        icon: "warning",
        text: "Toma la foto del odómetro del vehículo.",
      });
      setActiveStep(activeStep - 1);
    } else if (kmTotal == undefined || kmTotal == 0) {
      Swal.fire({
        icon: "warning",
        text: "Captura los kilómetros de odómetro.",
      });
      setActiveStep(activeStep - 1);
    } else if (dataImei == undefined || dataImei == "") {
      Swal.fire({
        icon: "warning",
        text: "Captura el IMEI de celular.",
      });
    } else {
      setHideVerificacion(false);
      setHideFinal(true);
      const zapierEmail = {
        email: dataUserCorreo,
        phone: dataUserPhone,
        firstName: dataUserName,
        timestamp: Date.now(),
        tempPassword: dataTempPassword,
        uid: docRef,
      };
      db.collection("zapier")
        .doc("000emailPlatforms")
        .collection("events")
        .add(zapierEmail);
    }
  };
  const verFinal = () => {
    const nacimiento = dataUserDate;
    const dateNacimiento =
      nacimiento.year + "-" + nacimiento.month + "-" + nacimiento.day;
    if (dateNacimiento == "0-0-0" || !nacimiento) {
      Swal.fire({
        icon: "warning",
        text: "Selecciona la fecha de nacimiento.",
      });
      setActiveStep(activeStep - 1);
    } else if (dataVehicle.placas == "") {
      Swal.fire({
        icon: "warning",
        text: "Captura las placas de tu vehículo.",
      });
    } else if (!userData?.zipCode?.length || userData?.zipCode?.length !== 5) {
      Swal.fire({
        icon: "warning",
        text: "Captura tu código postal",
      });
    } else if (dataVehicle.version === "") {
      Swal.fire({
        icon: "warning",
        text: "Captura la version de tu vehículo",
      });
    } else if (dataVehicle.color === "") {
      Swal.fire({
        icon: "warning",
        text: "Captura el color de tu vehículo",
      });
    } else if (dataVehicle.motor == "") {
      Swal.fire({
        icon: "warning",
        text: "Captura el número de seria de tu vehículo.",
      });
    } else if (dataVehicle.front == undefined || dataVehicle.front == "") {
      Swal.fire({
        icon: "warning",
        text: "Toma la foto frontal del vehículo.",
      });
    } else if (dataVehicle.back == undefined || dataVehicle.back == "") {
      Swal.fire({
        icon: "warning",
        text: "Toma la foto trasera del vehículo.",
      });
    } else if (dataVehicle.left == undefined || dataVehicle.left == "") {
      Swal.fire({
        icon: "warning",
        text: "Toma la foto del lado izquierdo del vehículo.",
      });
    } else if (dataVehicle.right == undefined || dataVehicle.right == "") {
      Swal.fire({
        icon: "warning",
        text: "Toma la foto del lado derecho del vehículo.",
      });
    } else if (odometro == undefined || odometro == "") {
      Swal.fire({
        icon: "warning",
        text: "Toma la foto de odómetro del vehículo.",
      });
    } else if (kmTotal == undefined || kmTotal == 0) {
      Swal.fire({
        icon: "warning",
        text: "Captura los kilómetros de odómetro.",
      });
    } else {
      setHideVerificacion(false);
      setHideFinal(true);
      const zapierEmail = {
        email: dataUserCorreo,
        phone: dataUserPhone,
        firstName: dataUserName,
        timestamp: Date.now(),
        tempPassword: dataTempPassword,
        uid: docRef,
      };
      db.collection("zapier")
        .doc("000emailPlatforms")
        .collection("events")
        .add(zapierEmail);
    }
  };

  return (
    <div className={classes.root}>
      {dataUserCelular == true ? (
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          style={{ background: "#fff" }}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              {dataUserCelular == true ? (
                <StepLabel
                  style={{ textAlign: "left" }}
                  StepIconComponent={ColorlibStepIcon}
                >
                  <div style={{ fontWeight: "bold" }}>{label}</div>
                </StepLabel>
              ) : (
                <StepLabel
                  style={{ textAlign: "left" }}
                  StepIconComponent={ColorlibStepIcon2}
                >
                  <div style={{ fontWeight: "bold" }}>{label}</div>
                </StepLabel>
              )}
              <StepContent>
                {getStepContent(index)}

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginTop: "1rem",
                    gap: "5px",
                  }}
                >
                  {activeStep != 0 && (
                    <Button
                      onClick={() => handleBack()}
                      className={classes.buttonRegresar}
                    >
                      Regresar
                    </Button>
                  )}
                  {activeStep == 0 ? (
                    <Button
                      variant="contained"
                      onClick={() => handleNext()}
                      className={classes.buttonBlue}
                      style={{
                        "&, &:hover, &:active": {
                          backgroundColor: "#00bbee",
                          color: "#fff",
                        },
                      }}
                      disableElevation
                    >
                      SIGUIENTE
                    </Button>
                  ) : activeStep == 2 ? (
                    <Button
                      variant="contained"
                      onClick={() => verFinalCelular()}
                      className={classes.buttonBlue}
                    >
                      FINALIZAR
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => handleNext()}
                      className={classes.buttonBlue}
                      disableElevation
                    >
                      SIGUIENTE
                    </Button>
                  )}
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      ) : (
        <div>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            style={{ background: "#fff" }}
          >
            {stepsCelular.map((label, index) => (
              <Step key={label}>
                (
                <StepLabel
                  style={{ textAlign: "left" }}
                  StepIconComponent={ColorlibStepIcon}
                >
                  {label === "Verifica tus datos" ? (
                    <div style={{ fontWeight: "bold" }}>{label}</div>
                  ) : label === "Sube la informacion de tu vehículo" ? (
                    <div style={{ fontWeight: "bold" }}>{label}</div>
                  ) : (
                    <div style={{ fontWeight: "bold" }}>{label}</div>
                  )}
                </StepLabel>
                )
                <StepContent>
                  <div>{getStepContent(index)}</div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginTop: "1rem",
                      justifyContent: "space-between",
                    }}
                  >
                    {activeStep != 0 && (
                      <Button
                        onClick={() => handleBack()}
                        className={classes.buttonRegresar}
                      >
                        Regresar
                      </Button>
                    )}
                    {activeStep == 0 ? (
                      <Button
                        variant="contained"
                        onClick={() => handleNext()}
                        className={classes.buttonBlue}
                        disableElevation
                      >
                        SIGUIENTE
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => verFinal()}
                        className={classes.buttonBlue}
                        disableElevation
                      >
                        FINALIZAR
                      </Button>
                    )}
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </div>
      )}
      <br />
      <br />
    </div>
  );
};
export default SteeperConductor;
