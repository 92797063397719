import { Button, Box, Typography, Stack, Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
// import Voucher from '../../Assets/SVG/voucher.svg';
import "./PeriocidadPago.css";
import { Fragment } from "react";
// import PaymentDescription from "../PaymentDescription";
import getTotalPremium from "../../utils/getPremium";
import { LocalOffer } from "@mui/icons-material";
import discountImg from "../../Assets/PNG/etiqueta-de-descuento.png";
import isADevice from "../../utils/isADevice";

const PREFIX = "PeriocidadPago";

const classes = {
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
    backgroundColor: "#00CA7D",
    color: "#F8FFFE",
    fontSize: "small",
    fontWeight: "bold",
    width: "7rem",
    borderRadius: "2rem",
    textAlign: "center",
    "&:hover, &:active": {
      backgroundColor: "#00CA7D",
      color: "#F8FFFE",
    },
  },
}));

// const descuento = "https://clupp.typeform.com/to/ArADpUSh?utm_source=quote&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx#source=xxxxx&seller=xxxxx&email=xxxxx";

function applyDiscount(premium, disocuntPercentage) {
  return premium * (1 - disocuntPercentage / 100);
}

const PeriocidadPago = ({
  vehicleData,
  haveDiscount,
  discount,
  period: premiumPaymentPeriod,
  basePremium,
  onPaymentPeriodSelect,
  isOzonQuote = false,
  msi,
  isAgentLogged,
  mobility,
  isWowUser,
}) => {
  const PAYMENT_PERIODS =
    isADevice(vehicleData.type) && mobility === 0
      ? [
          { label: "Anual", value: "year" },
          { label: "Semestral", value: "6month" },
          { label: "Trimestral", value: "3month" },
        ]
      : isWowUser
      ? [
          { label: "Anual", value: "year" },
          { label: "Mensual", value: "month" },
        ]
      : [
          { label: "Anual", value: "year" },
          { label: "Semestral", value: "6month" },
          { label: "Trimestral", value: "3month" },
          { label: "Mensual", value: "month" },
        ];
  const isFinancedVehicle = Boolean(vehicleData?.financingMonths);
  const isYearPeriodSelected = premiumPaymentPeriod === "year";

  const isAgentQuote = vehicleData.utm_source === "agentes";

  const shouldDisplayMSI =
    isYearPeriodSelected && !isAgentLogged && !isAgentQuote;
  return (
    <Fragment>
      <h3>Elige tu periodicidad de pago:</h3>
      {basePremium && (
        <Root
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 1rem",
            gap: "10px",
          }}
        >
          {isFinancedVehicle && (
            <p>
              Debido al financiamiento de tu vehículo, la periodicidad de pago
              del seguro no puede ser menor a los meses que quedan de
              financiamiento.
            </p>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {isFinancedVehicle && !isOzonQuote ? (
              <Button
                style={{
                  backgroundColor:
                    "year" === premiumPaymentPeriod ? "#0058f4" : "",
                  textTransform: "capitalize",
                }}
                variant="contained"
                className={classes.button}
                onClick={() =>
                  onPaymentPeriodSelect(
                    "year",
                    getTotalPremium(basePremium, "year")[0]
                  )
                }
              >
                Anual
              </Button>
            ) : (
              PAYMENT_PERIODS.map((payment) => {
                if (
                  (vehicleData.type === "auto" &&
                    vehicleData.valueUser < 100000) ||
                  (vehicleData.type === "moto" && vehicleData.valueUser < 30000)
                ) {
                  if (payment.label !== "Mensual") {
                    return (
                      <Button
                        endIcon={
                          payment.value === "year" ? <LocalOffer /> : null
                        }
                        key={payment.value}
                        style={{
                          backgroundColor:
                            payment.value === premiumPaymentPeriod
                              ? "#0058f4"
                              : "",
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                        className={classes.button}
                        onClick={() =>
                          onPaymentPeriodSelect(
                            payment.value,
                            getTotalPremium(basePremium, payment.value)[0]
                          )
                        }
                      >
                        {payment.label}
                      </Button>
                    );
                  }
                } else {
                  return (
                    <Button
                      key={payment.value}
                      endIcon={payment.value === "year" ? <LocalOffer /> : null}
                      style={{
                        backgroundColor:
                          payment.value === premiumPaymentPeriod
                            ? "#0058f4"
                            : "",
                        textTransform: "capitalize",
                      }}
                      variant="contained"
                      className={classes.button}
                      onClick={() =>
                        onPaymentPeriodSelect(
                          payment.value,
                          getTotalPremium(basePremium, payment.value)[0]
                        )
                      }
                    >
                      {payment.label}
                    </Button>
                  );
                }
              })
            )}
          </div>
          <PaymentView
            haveDiscount={haveDiscount}
            discount={discount}
            msi={msi}
            paymentPeriod={premiumPaymentPeriod}
            premium={basePremium}
          />

          <Box
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {shouldDisplayMSI && (
              <Stack direction="row" gap={2} alignItems="center">
                <Typography>Pagar a Meses sin intereses</Typography>
                <Checkbox
                  defaultChecked={msi}
                  onChange={(e) => {
                    console.debug("MSI", e.target.checked);
                    onPaymentPeriodSelect(
                      e.target.checked ? "year-msi" : "year",
                      getTotalPremium(basePremium, "year", { msi: true })[0]
                    );
                  }}
                />
              </Stack>
            )}
          </Box>
        </Root>
      )}
    </Fragment>
  );
};

const MSI_DISCOUNT = 6;
const PAYMENT_DISCOUNTS = {
  month: 0,
  "3month": 3,
  "6month": 6,
  year: 10,
};

const PaymentView = ({
  premium: basePremium,
  paymentPeriod,
  msi = false,
  haveDiscount,
  discount: discountBase,
}) => {
  const TITLES = {
    month: "Pago mensual",
    "3month": "Pago trimestral",
    "6month": "Pago semestral",
    year: "Pago anual",
  };

  console.log("haveADiscount -->", haveDiscount);
  console.log("discountBase -->", discountBase);
  const discount = haveDiscount
    ? discountBase.percentage
    : msi
    ? MSI_DISCOUNT
    : PAYMENT_DISCOUNTS[paymentPeriod];

  // const [premium, premiumWithDiscount] = getPremium(basePremium, paymentPeriod, {});
  const [premiumWithDiscount, , totalPremium] = getTotalPremium(
    basePremium,
    paymentPeriod,
    {
      msi,
      haveDiscount,
      discount: discountBase,
    }
  );

  const formatter = new Intl.NumberFormat("es-MX", {
    maximumFractionDigits: 0,
  });
  const formatNumber = (number) => formatter.format(number);
  const hasDiscount = haveDiscount || paymentPeriod !== "month";
  const isYearPayment = paymentPeriod === "year";
  const isSpecialOffer = isYearPayment && !msi;

  return (
    <Stack
      sx={{
        maxWidth: "450px",
        minWidth: ["200px", "450px"],
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#f9f9f9",
        borderRadius: "10px",
        minHeight: "216px",
        width: "100%",
        border: "1px solid #D9D9D9",
        margin: "0px auto",
        position: "relative",
      }}
    >
      {hasDiscount && (
        <>
          <p
            style={{
              background: "#FF6060",
              color: "white",
              textAlign: "center",
              position: "absolute",
              top: 0,
              right: 0,
              margin: 0,
              width: "100%",
              borderRadius: "10px 10px 0px 0px",
              padding: "8px 0px",
              fontSize: "large",
            }}
          >
            <b>{discount}%</b> de descuento
          </p>
          {isSpecialOffer && (
            <img
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                width: "70px",
                marginRight: "-20px",
                transformOrigin: "30px 0px",
                animation:
                  "rotate 10s cubic-bezier(0.73, 0, 0.21, 0.97) infinite",
                zIndex: 1000,
              }}
              src={discountImg}
            />
          )}
        </>
      )}

      <div
        style={{
          display: "flex",
          gap: ".3rem",
          padding: "3rem 2rem 2rem 2rem",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {hasDiscount && (
          <p
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              textAlign: "center",
              color: "gray",
              textDecoration: "line-through",
              margin: "0px",
            }}
          >
            MX ${formatNumber(totalPremium)}
          </p>
        )}
        <p
          style={{
            fontWeight: "bold",
            fontSize: "30px",
            textAlign: "center",
            margin: "0px",
          }}
        >
          MX ${formatNumber(premiumWithDiscount)}
        </p>
        <p
          style={{
            fontWeight: " bold",
            fontSize: " medium",
            color: "#3f75cf",
            textAlign: " center",
            margin: "0px",
          }}
        >
          {TITLES[paymentPeriod]}
        </p>
        {isSpecialOffer && (
          <p
            style={{
              margin: 0,
              color: "#FF6060",
              padding: "2px 10px",
              fontSize: "large",
            }}
          >
            <b>¡Mejor oferta!</b>
          </p>
        )}
      </div>
    </Stack>
  );
};

export default PeriocidadPago;
