import { Fragment } from "react";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import Stepper from "../../Components/Stepper/Stepper";
import Plats from "../../Components/Plats/Plats";
import { useState, useEffect } from "react";
import BotonPrincipal from "../../Components/BotonPrincipal/BotonPrincipal";
// import { db } from '../../Firebase/firebase';
// import Swal from 'sweetalert2';
//import { useHistory } from "react-router-dom";

const PLATS = () => {
  console.log("PLATS");
  //const history = useHistory();
  const [plats, setPlats] = useState();
  const [uid, setUid] = useState();
  const [uber, setUber] = useState(false);
  const [didi, setDidi] = useState(false);
  const [nameP, setNameP] = useState();
  const [primafP, setPrimafP] = useState();
  const [emailP, setEmailP] = useState();
  const [phoneP, setPhoneP] = useState();
  const [spPremiumP, setSpPremiumP] = useState();
  const [periodP, setPeriodP] = useState();
  const [currentPlat, setCurrentPlat] = useState("Uber");

  const utm_source = localStorage.getItem("utm_source");

  useEffect(() => {
    const x = localStorage.getItem("email");
    setEmailP(x);
    console.log("email en PLATS: " + x);
  }, [emailP]);

  useEffect(() => {
    const x = localStorage.getItem("phone");
    setPhoneP(x);
    console.log("phone en PLATS: " + x);
  }, [phoneP]);

  useEffect(() => {
    const pf = localStorage.getItem("primaf");
    setPrimafP(pf);
    console.log("primaf en PLATS: " + pf);
  }, [primafP]);

  useEffect(() => {
    const x = localStorage.getItem("spPremium");
    setSpPremiumP(x);
    console.log("spPremium en PLATS: " + x);
  }, [spPremiumP]);

  useEffect(() => {
    const x = localStorage.getItem("name");
    setNameP(x);
    console.log("name en PLATS: " + x);
  }, [nameP]);

  useEffect(() => {
    const x = localStorage.getItem("spPremium");
    setSpPremiumP(x);
    console.log("spPremium en PLATS: " + x);
  }, [spPremiumP]);

  useEffect(() => {
    const x = localStorage.getItem("period");
    setPeriodP(x);
    console.log("period en PLATS: " + x);
  }, [periodP]);

  useEffect(() => {
    const p = localStorage.getItem("platforms");
    setPlats(p);
    console.log("platforms en PLATS: " + p);
    if (p.includes("Uber")) {
      setUber(true);
    }
    if (p.includes("Didi")) {
      setDidi(true);
    }
    if (p.includes("Didi") && !p.includes("Uber")) {
      setCurrentPlat("Didi");
    }
  }, [plats]);

  useEffect(() => {
    const u = localStorage.getItem("uid");
    setUid(u);
    console.log("uid en PLATS: " + u);
  }, [uid]);

  return (
    <Fragment>
      <Menu />
      <Header />
      <div className="cotizar adjusted-height">
        <Stepper step={4} />
        <Plats
          plats={plats}
          uid={uid}
          uber={uber}
          didi={didi}
          nameP={nameP}
          periodP={periodP}
          phoneP={phoneP}
          emailP={emailP}
          spPremiumP={spPremiumP}
          primafP={primafP}
          currentPlat={currentPlat}
          setCurrentPlat={setCurrentPlat}
          utm_source={utm_source}
        />
      </div>
      <BotonPrincipal tipo={"msi"} back="/contrata" />
    </Fragment>
  );
};

export default PLATS;
