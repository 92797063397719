import "./ElegirMovilidad.css";
import FormatAmount from "../../FormatAmout/FormatAmout";
import getTotalPremium from "../../utils/getPremium";
import { getTypeName } from "../../utils/isADevice";

const ElegirMovilidadDevices = ({
  amounts,
  selectedMobility,
  setSelectedMobility,
  vehicleData,
  onSelectMobility,
}) => {
  const handleMobility = (mobility, premium) => {
    onSelectMobility(mobility, premium);
    setSelectedMobility({ mobility, premium });
  };

  function getPeriodicityLabel(periodicity) {
    switch (periodicity) {
      case "month":
        return "al mes";
      case "3month":
        return "cada 3 meses";
      case "6month":
        return "cada 6 meses";
      case "year":
        return "al año";
      default:
        return "";
    }
  }
  const periodicityLabel = getPeriodicityLabel("month");
  return (
    <div className="elegir-movilidad">
      <p>
        Seguro para tu {getTypeName(vehicleData.type)}{" "}
        <b>
          {vehicleData?.brand} {vehicleData?.model}{" "}
        </b>
      </p>
      <h3 style={{ marginBottom: "12px" }}>{`Elige tu plan:`}</h3>{" "}
      <div className="elegir-movilidad-content">
        <div className="cards-container">
          <div
            className={
              selectedMobility.mobility === 0 ? "selected-plan" : "plan"
            }
            onClick={() => handleMobility(0, amounts.premium0)}
          >
            <h4>Básico</h4>
            {selectedMobility.mobility !== 0 && (
              <p className="kilometers">{""}</p>
            )}
            <p className="premium">
              $
              {amounts &&
                FormatAmount(getTotalPremium(amounts.premium0, "month")[0])}
            </p>
            <p className="normal-plan month">{periodicityLabel}</p>
            {selectedMobility.mobility === 0 && (
              <>
                <p className="selected-kilometers">{""}</p>
                <p className="normal-plan">
                  41% de nuestros clientes eligen este plan
                </p>
              </>
            )}
          </div>

          <div
            className={
              selectedMobility.mobility === 1 ? "selected-plan" : "plan"
            }
            onClick={() => handleMobility(1, amounts.premium1)}
          >
            <h4>Pro</h4>
            {selectedMobility.mobility !== 1 && (
              <p className="kilometers">{""}</p>
            )}
            <p className="premium">
              $
              {amounts &&
                FormatAmount(getTotalPremium(amounts.premium1, "month")[0])}
            </p>
            <p className="normal-plan month">{periodicityLabel}</p>
            {selectedMobility.mobility === 1 && (
              <>
                <p className="selected-kilometers">{""}</p>
                <p className="normal-plan">
                  51% de nuestros clientes eligen este plan
                </p>
              </>
            )}
          </div>

          {(selectedMobility.mobility === 0 ||
            selectedMobility.mobility === 1 ||
            selectedMobility.mobility === 2) && (
            <div
              className={
                selectedMobility.mobility === 2 ? "selected-plan" : "plan"
              }
              onClick={() => handleMobility(2, amounts.premium2)}
            >
              <h4>Súper Pro</h4>
              {selectedMobility.mobility !== 2 && (
                <p className="kilometers">{""}</p>
              )}
              <p className="premium">
                $
                {amounts &&
                  FormatAmount(getTotalPremium(amounts.premium2, "month")[0])}
              </p>
              <p className="normal-plan month">{periodicityLabel}</p>
              {selectedMobility.mobility === 2 && (
                <>
                  <p className="selected-kilometers">{""}</p>
                  <p className="normal-plan">
                    8% de nuestros clientes eligen este plan
                  </p>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ElegirMovilidadDevices;
