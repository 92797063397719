import { Fragment, useEffect, useState } from "react";
import firebase from "firebase/app";

import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import Stepper from "../../Components/Stepper/Stepper";
import TusDatos from "../../Components/TusDatos/TusDatos";
import MetodoPago from "../../Components/MetodoPago/MetodoPago";
import BotonPrincipal from "../../Components/BotonPrincipal/BotonPrincipal";
import { db } from "../../Firebase/firebase";
import Swal from "sweetalert2";
import { loadStripe } from "@stripe/stripe-js";
import getTotalPremium from "../../utils/getPremium";
import formatPremium from "../../utils/formatPremium";
import useRedirect from "../../hooks/useRedirect";
import useWhatsAppNumber from "../../hooks/useWhatsAppNumber";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useAuth } from "../../AuthContext";
import { addListMemberTags, addOrUpdateMember } from "../../services/mailChimp";
import isValidEmail from "../../utils/isValidEmail";
import logError from "../../services/logError";
import { useAgentAuth } from "../../AuthAgentContext";
import useBannedUsers from "../../hooks/useBannedUsers";
import notificationBan from "../../services/notificationBan";
import getWhatsAppUrl from "../../utils/getWhatsAppUrl";
import useQuery from "../../hooks/useQuery";
import AGENTES from "../../utils/agentes";
import { getWoowPaymentLink } from "../../services/woow";
import registerInterest from "../../services/logs/registerInterest";
import isADevice, { getType, getTypeName } from "../../utils/isADevice";
import { CircularProgress, Stack } from "@mui/material";
import LoadingOverlay from "../../Components/LoadingOverlay";
import highlightSection from "../../utils/highlightSection";

function extractBirthdateFromRFC(rfc) {
  const rfcRegex = /^[A-Z]{4}(\d{2})(\d{2})(\d{2})/;
  const matches = rfc.match(rfcRegex);

  if (matches && matches.length === 4) {
    const year = parseInt(matches[1], 10);
    const month = parseInt(matches[2], 10);
    const day = parseInt(matches[3], 10);

    // Assuming year in the format 'yy' represents 1900s and '20' represents 2000s
    const century = year < 40 ? 2000 : 1900;
    const birthdate = new Date(century + year, month - 1, day);

    return birthdate;
  }

  return null; // Invalid RFC format
}

const stripePromise = loadStripe(
  "pk_live_51H5q3WAQwCaNdJ3ZN0bCb7PsC2l3w4yOkkvCD0bC6J6blQjFzNG33MAriRSGEZXfbpmPCjtR2VythfeUV7Ebpx0N006NiETUco"
);

const WHATSAPP_PAYMENT_MESSAGE =
  "Hola, envío mi comprobante de pago. Mi correo es: ";

// For MSI payments we need to an extra commission
// percentage to the total premium
const MSI_COMISSION_PERCENTAGE = 0.0;

const Contratar = () => {
  const { isAgentLogged, agentData } = useAgentAuth();
  const whatsAppNumber = useWhatsAppNumber();
  const redirect = useRedirect();
  const query = useQuery();
  const { bannedUsers } = useBannedUsers();
  const [vehicleData, setVehicleData] = useState();
  const [isVLoading, setIsVLoading] = useState(true);
  const [userData, setUserData] = useState({
    msi: false,
  });
  const [docRef, setDocRef] = useState();
  const [currentVehicle, setCurrentVehicle] = useState();
  const [loading, setLoading] = useState(false);

  const [primaf, setPrimaf] = useState(
    parseInt(localStorage.getItem("primafBUG"))
  );

  const [periodf, setPeriodf] = useState("year");
  const [quotationRecord, setQuotationRecord] = useState();
  const [rastreatorData, setRastreatorData] = useState();
  const [rastreatorUserUid, setRastreatorUserUid] = useState();
  const [agentsData, setAgentsData] = useState();
  const [tipoPago, setTipoPago] = useState("debito");
  const [saveDatos, setSaveDatos] = useState({
    firstName: "",
    lastName: "",
    lastName2: "",
    email: "",
    birthDay: 0,
    birthMonth: 0,
    birthYear: 0,
    phone: 0,
    rfc: "",
    //qStatus: 3
  });
  const urlWhatsapp = `https://wa.me/521${whatsAppNumber}?text=Hola,%20tengo%20problemas%20al%20contratar%20mi%20seguro%20al%20ingresar%20mis%20datos%2E%0D%0AMi%20correo%3A%20${
    saveDatos?.email
  }%0D%0AMi%20RFC%3A%20${saveDatos?.rfc ? saveDatos?.rfc : ""}`;
  const [tituloBtn, setTituloBtn] = useState("Continuar");
  const [vehicleUID, currentUser] = useLocalStorage([
    "currentVehicle",
    "docRef",
  ]);

  const isWowUser = query.get("utm_campaign") === AGENTES.WOW;

  useEffect(() => {
    if (vehicleUID) {
      // Add a documento to track user behaviour between screens
      db.collection(`prospectsNew/${currentUser}/qStatusUpdates`).add({
        vehicleId: vehicleUID,
        qStatus: 2,
        timestamp: Date.now(),
        timestampServer: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }

    console.log("1er useEffect de Contratar");
    const getRef = localStorage.getItem("docRef");
    setDocRef(getRef);
    const getCurrentVehicle = localStorage.getItem("currentVehicle");
    setCurrentVehicle(getCurrentVehicle);
    const getRastreatorData = localStorage.getItem("rastreator_params");
    setRastreatorData(JSON.parse(getRastreatorData));
    const rastreatorUid = localStorage.getItem("rastreatorUserUid");
    setRastreatorUserUid(rastreatorUid);
    const getAgentsData = localStorage.getItem("agents_params");
    setAgentsData(JSON.parse(getAgentsData));

    console.log(rastreatorData);
    if (rastreatorData) {
      localStorage.setItem(
        "firstName",
        rastreatorData?.name ? rastreatorData.name : ""
      );
      localStorage.setItem(
        "email",
        rastreatorData?.email ? rastreatorData.email.toLowerCase() : ""
      );
      localStorage.setItem(
        "phone",
        Boolean(Number(rastreatorData?.phone))
          ? Number(rastreatorData?.phone)
          : ""
      );
      localStorage.setItem(
        "lastName",
        rastreatorData?.lastName ? rastreatorData?.lastName : ""
      );
      localStorage.setItem(
        "lastName2",
        rastreatorData?.lastName2 ? rastreatorData?.lastName2 : ""
      );
    }
  }, [vehicleUID]);

  useEffect(() => {
    if (agentsData) {
      goToStripeCheckout();
    }
    async function goToStripeCheckout() {
      let url = encodeURI(
        `https://us-central1-auto-clupp.cloudfunctions.net/app/create-checkout-session2?amount=${agentsData.premium}&name=${agentsData.name}&email=${agentsData.email}&period=${agentsData.period}&uid=${agentsData.uid}&phone=${agentsData.phone}&pid=${vehicleUID}`
      );
      try {
        setLoading(true);
        console.log("Fetching create-checkout-session2");
        const response = await fetch(url);

        const json = await response.json();
        const stripe = await stripePromise;

        let sessionId = json.id;
        if (json.status === "Error") {
          Swal.fire({
            icon: "error",
            text: "Ha ocurrido un error, por favor intenta nuevamente.",
          });
          await db.collection("errors").add({
            error: json.mensaje,
            uid: docRef,
            date: new Date(),
          });
          await logError({
            error: json.mensaje,
            metadata: {
              description:
                "Surgió un error al redirigir a stripe desde un link de pago de agentes.",
              agentsData,
            },
          });
          setLoading(false);
          return;
        }
        stripe.redirectToCheckout({ sessionId });
        localStorage.removeItem("agents_params");
      } catch (error) {
        Swal.fire({
          icon: "error",
          text: "Ha ocurrido un error, por favor intenta nuevamente.",
        });

        await logError({
          error: error.toString(),
          metadata: {
            description:
              "Surgió un error al redirigir a stripe desde un link de pago de agentes.",
            agentsData,
          },
        });

        setLoading(false);
      }
    }
  }, [agentsData]);

  useEffect(() => {
    console.log("2do useEffect de Contratar");
    if (!rastreatorUserUid) return;

    console.log(
      "paso filtro de RastreatorUID en el 2do useEffect de Contratar"
    );
    console.log("VehicleData ->", vehicleData);

    Swal.fire({
      confirmButtonText: "LO ENTIENDO",
      iconHtml:
        '<img src="https://firebasestorage.googleapis.com/v0/b/auto-clupp.appspot.com/o/hostedImages%2FCluppyCel.png?alt=media&token=69d42c05-8edd-4f7f-b57c-220b4737ef9d">',
      html: `Antes de continuar, debes saber que Clupp es accesible gracias a que es un seguro 100% digital por lo que después de hacer tu pago ${
        isADevice(vehicleData.type)
          ? "te pediremos que ingreses a una página con el usuario que se te proporcionará para:"
          : "te pediremos que descargues nuestra app"
      } para lo siguiente: para lo siguiente:<br><br> 1. Dar de alta los datos de tu auto, como placas y número de serie.<br> 2. Subir por una única vez las fotos de tu auto para que podamos conocer el estado en el que se encuentra.<br>3. Una vez al mes tomar una foto del odómetro de tu auto.<br><br><b>Cualquier hecho ocurrido previamente a realizar estos pasos no tendrá cobertura.</b><br><br>Gracias a todo esto en Clupp podemos ofrecerte increíbles tarifas para tu seguro.`,
    });
    db.collection("prospectsNew")
      .doc(rastreatorUserUid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          // console.log(doc.data())
          setSaveDatos({
            firstName: doc
              .data()
              .firstName.toLowerCase()
              .replace(/\w\S*/g, (w) =>
                w.replace(/^\w/, (c) => c.toUpperCase())
              ),
            lastName: localStorage.getItem("lastName")
              ? localStorage.getItem("lastName")
              : doc
                  .data()
                  .lastName.toLowerCase()
                  .replace(/\w\S*/g, (w) =>
                    w.replace(/^\w/, (c) => c.toUpperCase())
                  ),
            lastName2: localStorage.getItem("lastName2")
              ? localStorage.getItem("lastName2")
              : doc
                  .data()
                  .lastName2.toLowerCase()
                  .replace(/\w\S*/g, (w) =>
                    w.replace(/^\w/, (c) => c.toUpperCase())
                  ),
            email: localStorage.getItem("email")
              ? localStorage.getItem("email")
              : doc.data().email,
            birthDay: doc.data().birthDay,
            birthMonth: doc.data().birthMonth,
            birthYear: doc.data().birthYear,
            phone: doc.data().phone,
            qStatus: Number(3),
            timestamp: Date.now(),
            premium: doc.data().premium,
            period: doc.data().period,
          });
          setPrimaf(
            formatPremium(
              getTotalPremium(
                vehicleData.amount,
                userData?.period ? userData?.period : "year",
                {
                  msi: userData?.msi,
                  haveDiscount: userData?.haveADiscountCoupon,
                  discount: userData?.discount,
                }
              )[0]
            )
          );

          setTituloBtn(
            `${formatPremium(
              getTotalPremium(
                vehicleData.amount,
                userData?.period ? userData?.period : "year",
                {
                  msi: userData?.msi,
                  haveDiscount: userData?.haveADiscountCoupon,
                  discount: userData?.discount,
                }
              )[0]
            )} Continuar`
          );
          // console.log({ primaAnual: getAnualPremium(doc.data().premium) });
          setPeriodf(doc.data()?.period ? doc.data().period : "year");
          localStorage.setItem("firstName", doc.data().firstName);
          localStorage.setItem("phone", doc.data().phone);
          doc.data().email && localStorage.setItem("email", doc.data().email);
        } else console.log("No such document!");
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
    db.collection("prospectsNew")
      .doc(rastreatorUserUid)
      .collection("vehicles")
      .onSnapshot((querySnapshot) => {
        var docs = [];
        querySnapshot.forEach((doc) => {
          docs.push(doc.data());
        });
        const vehicle = docs.filter((doc) => doc.iType);
        setVehicleData(vehicle[0]);
        setIsVLoading(false);
        // console.log(vehicle);
        //setPremium(vehicle[0].amount)
      });
  }, [rastreatorUserUid]);

  useEffect(() => {
    if (!vehicleData || !userData) {
      return;
    }

    let premium = getTotalPremium(
      vehicleData.amount,
      userData?.period ? userData?.period : "year",
      {
        msi: userData?.msi,
        haveDiscount: userData?.haveADiscountCoupon,
        discount: userData?.discount,
      }
    )[0];

    // If the customer wants to pay with installments (MSI),
    // we need to add a fee
    if (tipoPago === "msi") {
      premium = Math.ceil(premium / (1 - MSI_COMISSION_PERCENTAGE));
    }
    const formattedPremium = formatPremium(premium);

    if (rastreatorUserUid) {
      setPrimaf(vehicleData?.amount / 0.9);
    }
    setPrimaf(premium);
    setTituloBtn(`${formattedPremium} Continuar`);
    console.log({ premium });
  }, [tipoPago, vehicleData, userData, rastreatorUserUid]);

  console.log(rastreatorUserUid);
  useEffect(() => {
    console.log("3er useEffect de Contratar");
    if (agentsData) return;
    console.log("paso filtro de Agents Data en el 3er useEffect de Contratar");
    if (!docRef) return;

    fetchData();

    async function fetchData() {
      const userDoc = await db.collection("prospectsNew").doc(docRef).get();
      const userData = userDoc.data();

      setUserData({
        msi: false,
        ...userData,
        uid: userData.id,
        period: userData?.period ? userData?.period : "year",
      });
      if (userData.msi) {
        setTipoPago("msi");
      }
      setPeriodf(userData?.period ? userData.period : "year");

      userData.email && localStorage.setItem("email", userData.email);

      const quotationSnapshot = await db
        .collection("prospectsNew")
        .doc(docRef)
        .collection("quotationsRecord")
        .where("id", "==", currentVehicle)
        .orderBy("timestamp", "desc")
        .limit(1)
        .get();

      const docs = [];
      quotationSnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      // docs.sort((a, b) => b.timestamp - a.timestamp);
      setQuotationRecord(docs[0]);

      const vehicleDoc = await db
        .collection("prospectsNew")
        .doc(docRef)
        .collection("vehicles")
        .doc(currentVehicle)
        .get();

      const vehicleData = vehicleDoc.data();

      setVehicleData({ ...vehicleData, id: vehicleDoc.id });
      setIsVLoading(false);
      console.log({
        vehicleData,
        userData,
        amount: vehicleData.amount,
        totalPremium: getTotalPremium(
          vehicleData.amount,
          userData?.period ? userData?.period : "year",
          {
            msi: userData.msi,
            haveDiscount: userData?.haveADiscountCoupon,
            discount: userData?.discount,
          }
        )[0],
      });
      setTituloBtn(
        `${formatPremium(
          getTotalPremium(
            vehicleData.amount,
            userData?.period ? userData?.period : "year",
            {
              msi: userData.msi,
              haveDiscount: userData?.haveADiscountCoupon,
              discount: userData?.discount,
            }
          )[0]
        )} Continuar`
      );

      if (vehicleData.features.includes("Soy conductor de plataforma")) {
        // console.log("Tus plataformas son: ");
        // console.log(vehicleData.platforms);
        // if (vehicleData.platforms.length > 0) {
        //   if (
        //     !(
        //       vehicleData.platforms.includes("Uber") ||
        //       vehicleData.platforms.includes("Uber Eats")
        //     )
        //   ) {
        //     setTituloBtn("validar credenciales");
        //   }
        // }
      } else {
        var typeX = vehicleData.type;
        if (typeX === "auto" || typeX === "moto") {
          Swal.fire({
            //icon: 'info',
            confirmButtonText: "LO ENTIENDO",
            iconHtml:
              '<img src="https://firebasestorage.googleapis.com/v0/b/auto-clupp.appspot.com/o/hostedImages%2FCluppyCel.png?alt=media&token=69d42c05-8edd-4f7f-b57c-220b4737ef9d">',
            html:
              `Antes de continuar, debes saber que Clupp es accesible gracias a que es un seguro 100% digital por lo que después de hacer tu pago ${
                isADevice(typeX)
                  ? "te pediremos que ingreses a una página con el usuario que se te proporcionará para:"
                  : "te pediremos que descargues nuestra app"
              }<br><br> 1. Dar de alta los datos de tu ` +
              getTypeName(typeX) +
              ", como placas y número de serie.<br>2. Subir por una única vez las fotos de tu " +
              getTypeName(typeX) +
              " para que podamos conocer el estado en el que se encuentra.<br>3. Una vez al mes tomar una foto del odómetro de tu " +
              getTypeName(typeX) +
              ".<br><br><b>Cualquier hecho ocurrido previamente a realizar estos pasos no tendrá cobertura.</b><br><br>Gracias a todo esto en Clupp podemos ofrecerte increíbles tarifas para tu seguro.",
          });
        } else {
          if (typeX === "bike") {
            typeX = "bici";
          }
          if (typeX === "eBike") {
            typeX = "bici eléctrica";
          }
          if (typeX === "scooter") {
            typeX = "monopatín";
          }
          if (typeX === "otro") {
            typeX = "vehículo";
          }
          Swal.fire({
            confirmButtonText: "LO ENTIENDO",
            iconHtml:
              '<img src="https://firebasestorage.googleapis.com/v0/b/auto-clupp.appspot.com/o/hostedImages%2FCluppyCel.png?alt=media&token=69d42c05-8edd-4f7f-b57c-220b4737ef9d">',
            html:
              `Antes de continuar, debes saber que Clupp es accesible gracias a que es un seguro 100% digital por lo que después de hacer tu pago ${
                isADevice(typeX)
                  ? "te pediremos que ingreses a una página con el usuario que se te proporcionará para:"
                  : "te pediremos que descargues nuestra app"
              }<br><br>1. Dar de alta los datos de tu ` +
              getTypeName(typeX) +
              ". <br>2. Subir por una única vez las fotos de tu " +
              getTypeName(typeX) +
              " para que podamos conocer el estado en el que se encuentra.<br><br><b>Cualquier hecho ocurrido previamente a realizar estos pasos no tendrá cobertura.</b><br><br>Gracias a todo esto en Clupp podemos ofrecerte increíbles tarifas para tu seguro.",
          });
        }
      }
    }
  }, [currentVehicle, docRef]);

  const regexpEmail = new RegExp("^[^@]+@[^@]+.[a-zA-Z]{2,}$");

  function httpGetAsync(theUrl, callback) {
    setLoading(true);
    let xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState === 4) {
        if (xmlHttp.status === 200) {
          callback(xmlHttp.responseText);
        } else {
          setLoading(false);
          console.log(
            "Ocurrió un error desconocido.\n\n" + xmlHttp.responseText
          );
          Swal.fire({
            icon: "error",
            text: `Ha ocurrido un error, inténtalo de nuevo.
                ${xmlHttp.responseText}`,
          });
          db.collection("errors")
            .add({
              error: xmlHttp.responseText,
            })
            .then((docRef) => {
              console.log("Document written");
            });
        }
      }
    };
    xmlHttp.open("GET", theUrl, true); // true for asynchronous
    xmlHttp.send(null);
  }

  const handleAgentCheckoutResponse = async (response) => {
    setLoading(false);
    // console.log({ response });
    if (JSON.parse(response)) {
      // console.log({ parsedResponse: JSON.parse(response) });
      let session = JSON.parse(response);
      let paymentURL = session.url;
      if (session.status === "Error") {
        return;
      }
      try {
        Swal.fire({
          titleText: "¿Enviar correo de pago?",
          text: "Se enviará un correo de pago a " + saveDatos.email,
          icon: "question",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Enviar correo",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await handlePaymentAsAgent(paymentURL, "card");
            Swal.fire(
              "¡Correo enviado!",
              `Se ha enviado un correo con la información de pago a ${saveDatos.email}.\nEl correo puede tardar hasta 2 minutos en llegar.`,
              "success"
            );
          }
        });
      } catch (error) {
        Swal.fire(
          "!Ups!",
          `Hubo un error al enviar el correo, inténtalo de nuevo.\nSi el problema persiste, contacta al departamento de ventas.`,
          "error"
        );
        logError({
          error: "Error al obtener checkout session " + error.toString(),
        });
      }
    }
  };

  const showURLResponse = async (response) => {
    setLoading(false);
    if (JSON.parse(response)) {
      console.log({ response });
      const stripe = await stripePromise;
      let session = JSON.parse(response);
      let sessionId = session.id;
      if (session.status === "Error") {
        Swal.fire({
          icon: "error",
          text: "Ha ocurrido un error, por favor intenta nuevamente.",
        });
        await logError({
          error: "Error al generar stripe checkout session",
          metadata: {
            description: "Surgió un error al redirigir a stripe",
            uid: docRef,
            ...(userData && { userData }),
            ...(vehicleData && { vehicleData }),
            ...(session && { session }),
          },
        });
        db.collection("errors")
          .add({
            error: session.mensaje,
            uid: docRef,
            date: new Date(),
          })
          .then((docRef) => {
            console.log("Document written");
          });
      }
      stripe.redirectToCheckout({ sessionId });
    } else {
      alert("Parece que ocurrió un error. Intenta más tarde.");
      await logError({
        error: "Error al generar stripe checkout session",
        metadata: {
          description: "Surgió un error al redirigir a stripe",
          uid: docRef,
          ...(userData && { userData }),
          ...(vehicleData && { vehicleData }),
          ...(response && { response }),
        },
      });
    }
  };

  const showURLResponse2 = async (response) => {
    let cID = response.toString();
    console.log("cID en MSI: " + cID);
    localStorage.setItem("cusMsi", cID);
    redirect("/msi");
  };
  const showURLResponse3 = async (response) => {
    let cID = response.toString();
    console.log("cID: " + cID);
  };

  function handlePaymentAsAgent(checkoutURL, paymentMethod) {
    const PERIOD_TO_FORMATTED_PERIOD = {
      year: "anual",
      month: "mensual",
      "3month": "trimestral",
      "6month": "semestral",
    };
    const TYPE_TO_FORMATTED_TYPE = {
      auto: "auto",
      moto: "motocicleta",
      other: "vehículo",
      otro: "vehículo",
      scooter: "monopatín",
      bike: "bicicleta",
      eBike: "bicicleta eléctrica",
      laptop: "laptop",
      smartphone: "celular",
      tablet: "tablet",
    };

    try {
      return db.collection(`zapier/000agentPaymentEmail/docs`).add({
        agent: {
          firstName: agentData.firstName || "",
          lastName: agentData.lastName || "",
          phone: agentData.phone || "",
        },
        checkoutURL: checkoutURL || "",
        amount:
          "$" +
          primaf.toLocaleString("es-MX", {
            useGruping: true,
          }),
        paymentMethod,
        email: saveDatos.email,
        firstName: saveDatos.firstName,
        lastName: saveDatos.lastName,
        lastName2: saveDatos.lastName2,
        timestamp: Date.now(),
        vehicle: {
          brand: vehicleData.brand,
          model: vehicleData.model,
          year: vehicleData.year || "",
          type: TYPE_TO_FORMATTED_TYPE[vehicleData?.type || ""] || "vehículo",
        },
        period:
          PERIOD_TO_FORMATTED_PERIOD[
            userData?.period ? userData?.period : "year"
          ],
      });
    } catch (error) {
      console.error("Error al enviar correo de pago", error);
      Swal.fire("Error", "Ocurrió un error al enviar el correo", "error");
    }
  }

  function isBannedUser() {
    let isBanned = bannedUsers.find((user) => {
      if (user?.rfc && user?.rfc.slice(0, 10) === saveDatos?.rfc.slice(0, 10)) {
        return true;
      } else if (user.email === saveDatos.email) {
        return true;
      } else {
        return false;
      }
    });

    // if (isBanned) {
    //   notificationBan(userData?.uid, userData?.firstName, userData?.lastName, userData?.email, userData?.rfc)
    // }
    return isBanned;
  }

  const save = () => {
    if (!saveDatos.firstName) {
      Swal.fire({
        icon: "warning",
        text: "Falta ingresar Nombre.",
      }).then(() => {
        highlightSection("userName");
      });
    } else if (!saveDatos.lastName) {
      Swal.fire({
        icon: "warning",
        text: "Falta ingresar Apellido Paterno.",
      }).then(() => {
        highlightSection("userLastName");
      });
    } else if (!saveDatos.email || !isValidEmail(saveDatos.email)) {
      Swal.fire({
        icon: "warning",
        text: "Falta ingresar Correo válido.",
      }).then(() => {
        highlightSection("userEmail");
      });
    } else if (!saveDatos.phone || saveDatos.phone === null) {
      Swal.fire({
        icon: "warning",
        text: "Falta ingresar Teléfono correcto.",
      }).then(() => {
        highlightSection("userPhone");
      });
    } else if (saveDatos.phone !== null && saveDatos.phone.length !== 10) {
      Swal.fire({
        icon: "warning",
        text: "10 dígitos en teléfono.",
      }).then(() => {
        highlightSection("userPhone");
      });
    } else if (!saveDatos.rfc) {
      Swal.fire({
        icon: "warning",
        text: "Falta ingresar RFC.",
      }).then(() => {
        highlightSection("userRFC");
      });
    } else if (
      saveDatos?.rfc &&
      saveDatos?.rfc.length !== 10 &&
      saveDatos?.rfc.length !== 13 &&
      saveDatos?.rfc !== ""
    ) {
      console.log("RFC mal: " + saveDatos.rfc);
      Swal.fire({
        icon: "warning",
        text: "10 o 13 dígitos en RFC.",
      });
    } else if (isBannedUser()) {
      Swal.fire({
        icon: "warning",
        html: `Lo sentimos, pero después de revisar su información, hemos tomado la decisión de no continuar con su proceso de contratación. Si tiene alguna pregunta o inquietud, no dude en ponerse en contacto con nosotros. <br/><a style="text-decoration:none;text-transform:none;" href="${urlWhatsapp}" target="_blank"><button style="color:white; background-color: #1a9c4a; padding: 10px; font-size: 14px; font-weight: 700; border: none;border-radius: 5px;margin: 8px auto;cursor:pointer;display: flex;justify-content:center;align-items:center;gap:5px;text-transform:capitalize;"><img src="/whatsapp.svg" alt="icono whatsapp"/>WhatsApp</button></a>`,
      });
    } else {
      setLoading(true);
      localStorage.setItem("rfc", saveDatos.rfc);
      var pBool = localStorage.getItem("platformBoolean") || false;
      var spPremium = quotationRecord?.spPremium
        ? quotationRecord.spPremium
        : 0;
      if (periodf === "3month") {
        spPremium = parseInt(
          quotationRecord?.spPremium
            ? quotationRecord?.spPremium
            : 0 * (3 * 0.97)
        );
      }
      if (periodf === "6month") {
        spPremium = parseInt(
          quotationRecord?.spPremium
            ? quotationRecord?.spPremium
            : 0 * (6 * 0.94)
        );
      }
      if (periodf === "year") {
        spPremium = parseInt(
          quotationRecord?.spPremium
            ? quotationRecord?.spPremium
            : 0 * (12 * 0.9)
        );
      }

      const totalPremium = getTotalPremium(
        vehicleData?.amount,
        userData?.period ? userData?.period : "year",
        {
          msi: userData.msi,
          haveDiscount: userData?.haveADiscountCoupon,
          discount: userData?.discount,
        }
      )[0];

      const extrasTotalPremium = getExtrasTotalPremium(
        userData?.period ? userData?.period : "year",
        quotationRecord,
        { msi: userData.msi }
      );

      const vFinal = totalPremium - extrasTotalPremium;

      const birthday = extractBirthdateFromRFC(saveDatos?.rfc);
      const birthdateObj = {
        day: birthday ? birthday.getDate() : 0,
        month: birthday ? birthday.getMonth() + 1 : "",
        year: birthday ? birthday.getFullYear() : "",
      };

      console.log({ vFinal, totalPremium, extrasTotalPremium });

      db.collection("prospectsNew")
        .doc(rastreatorUserUid ? rastreatorUserUid : docRef)
        .update({
          firstName: saveDatos.firstName
            .toLowerCase()
            .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase())),
          lastName: saveDatos.lastName
            .toLowerCase()
            .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase())),
          lastName2: saveDatos?.lastName2
            ? saveDatos.lastName2
                .toLowerCase()
                .replace(/\w\S*/g, (w) =>
                  w.replace(/^\w/, (c) => c.toUpperCase())
                )
            : "",
          email: saveDatos.email,
          birthDay: birthdateObj?.day ? birthdateObj?.day : saveDatos?.birthDay,
          birthMonth: birthdateObj?.month
            ? birthdateObj?.month
            : saveDatos?.birthMonth,
          birthYear: birthdateObj?.year
            ? birthdateObj?.year
            : saveDatos?.birthYear,
          phone: saveDatos.phone,
          qStatus: Number(3),
          timestamp: Date.now(),
          premium: Number(primaf),
          platform: eval(pBool),
          rfc: saveDatos?.rfc ? saveDatos.rfc.toUpperCase() : "",
          period: periodf,
          vehiclePremiumFinal: vFinal,
          smartphonePremiumFinal: spPremium,
          paymentMethod: tipoPago,
        })
        .then(async () => {
          // await db
          //   .doc(
          //     `prospectsNew/${
          //       rastreatorUserUid || docRef
          //     }/vehicles/${currentVehicle}`
          //   )
          //   .update({
          //     qStatus: 3,
          //   });

          //Send event to Google Tag Manager
          if (!isAgentLogged) {
            const paymentMethod = paymentMethodToGTM(tipoPago);
            window.dataLayer.push({
              event: "contratar",
              type: vehicleData.type,
              brand: vehicleData.brand,
              model: vehicleData.model,
              year: vehicleData.year,
              method: paymentMethod,
            });
          }

          // first name and second name will be saved as
          // FNAME & LNAME merge fields
          await addOrUpdateMember({
            email: saveDatos.email,
            firstName: saveDatos.firstName,
            lastName: saveDatos.lastName,
            phone: saveDatos.phone,
          });

          const memberTags = [{ name: "interested4" }, { name: "autoclupp" }];
          if (vehicleData.features.includes("Soy conductor de plataforma")) {
            memberTags.push({ name: "platform" });
          }

          if (userData.utm_source === "agentes") {
            memberTags.push({ name: "source_agent" });
          }

          await addListMemberTags({
            tags: memberTags,
            email: saveDatos.email,
          });

          registerInterest({
            email: saveDatos.email || "",
            brand: vehicleData.brand || "",
            model: vehicleData.model || "",
            tag: memberTags.map((tag) => tag.name).join(", "),
            uid: docRef || "",
            year: vehicleData.year || "",
            type: vehicleData.type || "",
          });

          // Add a documento to track user behaviour between screens
          await db.collection(`prospectsNew/${docRef}/qStatusUpdates`).add({
            vehicleId: vehicleUID,
            qStatus: 3,
            timestamp: Date.now(),
            timestampServer: firebase.firestore.FieldValue.serverTimestamp(),
          });

          if (isWowUser) {
            const woowToken = query.get("token");

            let url = `https://us-central1-auto-clupp.cloudfunctions.net/app/createCustomer?name=${saveDatos.firstName}&email=${saveDatos.email}&uid=${docRef}&phone=${saveDatos.phone}&rfc=${saveDatos.rfc}`;

            fetch(url);

            const response = await getWoowPaymentLink({
              amount: totalPremium,
              description: `${vehicleData.brand} ${vehicleData.model} ${
                vehicleData.year || ""
              }`,
              period: periodf,
              token: woowToken,
              email: saveDatos.email,
              uid: docRef,
              vid: vehicleUID,
              vehicleType: vehicleData.type,
              firstName: saveDatos.firstName,
            });
            if (response.status === false) {
              Swal.fire("Error", response.message, "error");
              return;
            } else if (response.status === true) {
              window.open(response.ligaCobro, "_self");
            } else {
              Swal.fire(
                "Error",
                "Surgió un error inesperado, inténtalo nuevamente más tarde.",
                "error"
              );
            }
            return;
          }

          // await db.collection("zapier").add({
          //   email: saveDatos.email,
          //   firstName: saveDatos.firstName,
          //   interestLevel: Number(3),
          //   tag: vehicleData.type,
          //   timestamp: Date.now(),
          //   uid: docRef,
          //   zapierTaskType: "interested",
          // });
          // if (
          //   vehicleData &&
          //   vehicleData.features.includes("Soy conductor de plataforma") &&
          //   !(
          //     vehicleData.platforms.includes("Uber") ||
          //     vehicleData.platforms.includes("Uber Eats")
          //   )
          // ) {
          //   // console.log("platforms Validate: ");
          //   // console.log(vehicleData.platforms);
          //   localStorage.setItem("platforms", vehicleData.platforms);
          //   localStorage.setItem("uid", docRef);
          //   localStorage.setItem("primaf", primaf);
          //   localStorage.setItem("email", saveDatos.email);
          //   localStorage.setItem("phone", saveDatos.phone);
          //   localStorage.setItem("name", saveDatos.firstName);
          //   localStorage.setItem("period", periodf);
          //   localStorage.setItem("spPremium", quotationRecord.spPremium);
          //   localStorage.setItem("utm_source", vehicleData.utm_source);
          //   //window.location='https://us-central1-auto-clupp.cloudfunctions.net/app/confirm-credentials?uid='+docRef+'&vid='+vehicleData.id
          //   //window.location=`https://clupp.typeform.com/to/mn9juTyF?utm_source=nuevo_cotizador&correo=${saveDatos.email}&valor=${vehicleData.valueUser}&vehicle=${vehicleData.type}&movilidad=${vehicleData.mobility}`

          //   redirect("/platforms");
          // } else
          if (tipoPago === "msi") {
            const createCustomerURL = new URL(
              "https://us-central1-auto-clupp.cloudfunctions.net/app/createCustomer"
            );
            createCustomerURL.searchParams.append("email", saveDatos.email);
            createCustomerURL.searchParams.append("name", saveDatos.firstName);
            createCustomerURL.searchParams.append("phone", saveDatos.phone);
            createCustomerURL.searchParams.append(
              "uid",
              rastreatorUserUid ? rastreatorUserUid : docRef
            );

            await fetch(createCustomerURL);

            const paymentLinkGenerationURL = `https://clupp-api.web.app/v1/clupp/prospects/${docRef}/payment-link?msi=true`;
            const response = await fetch(paymentLinkGenerationURL, {
              method: "POST",
            });
            const json = await response.json();

            if (json?.paymentLink) {
              window.location.replace(json.paymentLink);
            } else {
              Swal.fire(
                "Ups!",
                "Algo salió mal, por favor contacta al equipo de ventas",
                "error"
              ).then((result) => {
                if (result.isConfirmed) {
                  window.location.replace(
                    getWhatsAppUrl(
                      "No puedo realizar mi pago a meses sin intereses"
                    )
                  );
                }
              });
            }
          } else if (tipoPago === "transferencia") {
            if (!isAgentLogged) {
              Swal.fire({
                iconHtml:
                  '<img src="https://firebasestorage.googleapis.com/v0/b/auto-clupp.appspot.com/o/hostedImages%2FCluppyCel.png?alt=media&token=69d42c05-8edd-4f7f-b57c-220b4737ef9d">',
                html:
                  'Los datos para pago por transferencia son:<br><br>Banco: STP <br>Clabe: 646090258693453549<br>Beneficiario: CLUPP MUTUALIDAD SC DE RL DE CV<br><br>En "referencia" (que solo acepta números) puedes poner 000345.<br>En "concepto" (que acepta letras) debes poner tu correo electrónico sin signos.<br><br>Una vez que hayas realizado el pago, envíanos tu comprobante por WhatsApp al ' +
                  whatsAppNumber +
                  //o da clic aquí para iniciar la conversación por Whatsapp.
                  "<a style='cursor:pointer' href='https://wa.me/521" +
                  whatsAppNumber +
                  "?text=" +
                  encodeURIComponent(
                    WHATSAPP_PAYMENT_MESSAGE + (saveDatos.email || "")
                  ) +
                  "' target='_blank' rel='noreferrer'><button style='cursor:pointer;padding: 10px;color:#fff;background-color:#00ca7d;font-size:15px; border:none;border-radius:4px; width:100%; margin-top: 10px;'>Enviar comprobante de pago</button></a></p>",
              });
            }

            let url = `https://us-central1-auto-clupp.cloudfunctions.net/app/createCustomer?name=${
              saveDatos.firstName
            }&email=${saveDatos.email}&uid=${
              rastreatorUserUid ? rastreatorUserUid : docRef
            }&phone=${saveDatos.phone}&rfc=${saveDatos.rfc}&transfer=true`;

            // create the customer
            httpGetAsync2(url, async (response) => {
              await showURLResponse3(response);

              if (isAgentLogged) {
                // trigger the email
                Swal.fire({
                  titleText: "¿Enviar correo de pago?",
                  text: "Se enviará un correo de pago a " + saveDatos.email,
                  icon: "question",
                  showCancelButton: true,
                  cancelButtonText: "Cancelar",
                  confirmButtonText: "Enviar correo",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    try {
                      await handlePaymentAsAgent("", "transfer");
                      Swal.fire(
                        "¡Correo enviado!",
                        `Se ha enviado un correo con la información de pago a ${saveDatos.email}.\nEl correo puede tardar hasta 2 minutos en llegar.`,
                        "success"
                      );
                    } catch (error) {
                      Swal.fire(
                        "!Ups!",
                        `Hubo un error al enviar el correo, inténtalo de nuevo.\nSi el problema persiste, contacta al departamento de ventas.`,
                        "error"
                      );
                      logError({
                        error:
                          "Error al enviar correo para pagar desde plataforma de agentes " +
                          error.toString(),
                        metadata: {
                          agent: {
                            // Only agent case
                            uid: agentData?.uid ? agentData?.uid : "undefined",
                            ...(agentData && { ...agentData }),
                          },
                        },
                      });
                    }
                  }
                });
              }
            });
          } else if (tipoPago === "efectivo") {
            let url = `https://us-central1-auto-clupp.cloudfunctions.net/app/createCustomer?name=${
              saveDatos.firstName
            }&email=${saveDatos.email}&uid=${
              rastreatorUserUid ? rastreatorUserUid : docRef
            }&phone=${saveDatos.phone}&rfc=${saveDatos.rfc}&cash=true`;
            httpGetAsync2(url, async (response) => {
              await showURLResponse3(response);

              if (isAgentLogged) {
                // trigger the email
                Swal.fire({
                  titleText: "¿Enviar correo de pago?",
                  text: "Se enviará un correo de pago a " + saveDatos.email,
                  icon: "question",
                  showCancelButton: true,
                  cancelButtonText: "Cancelar",
                  confirmButtonText: "Enviar correo",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    try {
                      await handlePaymentAsAgent("", "cash");
                      Swal.fire(
                        "¡Correo enviado!",
                        `Se ha enviado un correo con la información de pago a ${saveDatos.email}.\nEl correo puede tardar hasta 2 minutos en llegar.`,
                        "success"
                      );
                    } catch (error) {
                      Swal.fire(
                        "!Ups!",
                        `Hubo un error al enviar el correo, inténtalo de nuevo.\nSi el problema persiste, contacta al departamento de ventas.`,
                        "error"
                      );
                      logError({
                        error:
                          "Error al enviar correo para pagar desde plataforma de agentes " +
                          error.toString(),
                        metadata: {
                          agent: {
                            uid: agentData?.uid ? agentData?.uid : "undefined",
                            ...(agentData && { ...agentData }),
                          },
                        },
                      });
                    }
                  }
                });
              }
            });

            if (!isAgentLogged) {
              Swal.fire({
                iconHtml:
                  '<img src="https://firebasestorage.googleapis.com/v0/b/auto-clupp.appspot.com/o/hostedImages%2FCluppyCel.png?alt=media&token=69d42c05-8edd-4f7f-b57c-220b4737ef9d">',
                html:
                  "<p>Acude a realizar el pago a cualquier cajero o ventanilla de BBVA con los siguientes datos:<br><br>Número de cuenta: 0116536727<br>Beneficiario: Clupp Mutualidad SC de RL de CV<br><br>Una vez que hayas realizado el pago, envíanos tu comprobante por WhatsApp al " +
                  whatsAppNumber +
                  "<br/><br/><a style='cursor:pointer' href='https://www.google.com.mx/maps/search/cajero+bbva/' target='_blank' rel='noreferrer'><button style='cursor:pointer;padding: 10px;color:#fff;background-color:#00bbee;font-size:15px; border:none;border-radius:4px; width: 100%;'>Ver ubicaciones para pago 24/7</button></a><br/>" +
                  //o da clic aquí para iniciar la conversación por Whatsapp.
                  "<a style='cursor:pointer' href='https://wa.me/521" +
                  whatsAppNumber +
                  "?text=" +
                  encodeURIComponent(
                    WHATSAPP_PAYMENT_MESSAGE + (saveDatos.email || "")
                  ) +
                  "' target='_blank' rel='noreferrer'><button style='cursor:pointer;padding: 10px;color:#fff;background-color:#00ca7d;font-size:15px; border:none;border-radius:4px; width:100%; margin-top: 10px;'>Enviar comprobante de pago</button></a></p>",
              });
            }
          } else {
            console.log("Info para stripe checkout ", { periodf, primaf });

            let url = `https://us-central1-auto-clupp.cloudfunctions.net/app/create-checkout-session-delay?amount=${primaf}&name=${
              saveDatos.firstName
            }&email=${saveDatos.email}&period=${periodf}&uid=${
              rastreatorUserUid ? rastreatorUserUid : docRef
            }&phone=${saveDatos.phone}&spPremium=${
              rastreatorUserUid ? 0 : quotationRecord.spPremium
            }&rfc=${saveDatos.rfc}&vid=${vehicleData.id}&utm_source=${
              vehicleData.utm_source
            }&pid=${vehicleUID}`;

            // create a checkout session
            const checkoutCallback = isAgentLogged
              ? handleAgentCheckoutResponse
              : showURLResponse;
            httpGetAsync(url, checkoutCallback);
          }
        })
        .then(() => {
          setLoading(false);
          if (
            tipoPago !== "transferencia" &&
            tipoPago !== "efectivo" &&
            !vehicleData.features.includes("Soy conductor de plataforma")
          ) {
            Swal.fire({
              icon: "success",
              text: "Datos guardados.",
            });
          }
        })
        .catch(async (error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            text: `Ha ocurrido un error, inténtalo de nuevo.
              ${error}`,
          });
          function parseObject(object) {
            return Object.keys(object)
              .map((key) => {
                return [key, object[key]];
              })
              .reduce((prev, current) => {
                const [key, value] = current;
                prev[key] = value ?? String(value);
                return prev;
              }, {});
          }

          await logError({
            error: String(error),
            metadata: {
              description: "Error al continuar al pago",
              uid: userData?.id ? userData.id : "",
              quotationRecord: parseObject(quotationRecord),
              vehicleData: parseObject(vehicleData),
            },
          });
          db.collection("errors")
            .add({
              error: error,
            })
            .then(() => {
              console.log("Document written");
            });
        });
    }
  };

  function httpGetAsync2(theUrl, callback) {
    //setLoading(true);
    let xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState === 4) {
        if (xmlHttp.status === 200) {
          callback(xmlHttp.responseText);
        } else {
          //setLoading(false);
          console.log(
            "Ocurrió un error desconocido.\n\n" + xmlHttp.responseText
          );
          Swal.fire({
            icon: "error",
            text: `Ha ocurrido un error creando el customer, inténtalo de nuevo.
                ${xmlHttp.responseText}`,
          });
        }
      }
    };
    xmlHttp.open("GET", theUrl, true); // true for asynchronous
    xmlHttp.send(null);
  }

  // console.log({ basePremium });
  return isVLoading ? (
    <LoadingOverlay isOpen={isVLoading} />
  ) : (
    <Fragment>
      {!isWowUser && (
        <>
          <Menu />
          <Header />
        </>
      )}
      <div className="cotizar adjusted-height">
        {!rastreatorData ? (
          <Stepper step={4} type={getType(vehicleData.type)} />
        ) : (
          <p></p>
        )}
        <TusDatos
          saveDatos={saveDatos}
          setSaveDatos={setSaveDatos}
          docRef={docRef}
          rastreatorUserUid={rastreatorUserUid}
          vehicleData={vehicleData}
          agentsData={userData}
          userData={userData}
        />

        {!isWowUser && (
          <MetodoPago
            msi={userData.msi}
            periodf={periodf}
            tipoPago={tipoPago}
            setTipoPago={setTipoPago}
            primaf={primaf}
            tituloBtn={tituloBtn}
          />
        )}
      </div>
      <BotonPrincipal
        tipo={tituloBtn}
        back={
          getType(vehicleData.type) === "devices"
            ? "/elegir-plan"
            : "/elegir-extras"
        }
        func={save}
        loading={loading}
      />
    </Fragment>
  );
};

function getExtrasTotalPremium(
  paymentPeriod,
  quotationRecord,
  { msi = false }
) {
  const extras = [
    "premiumAccesories",
    "premiumAgencyRepair",
    "premiumEquipment",
    "premiumFlexibleCancellation",
    "premiumGlassBreak",
    "premiumHelmet",
    "premiumInvoiceValue",
    "premiumMedicalExpenses",
    "spPremium",
  ];

  const extrasTotalPremium = extras.reduce((previousValue, current) => {
    if (quotationRecord) {
      return previousValue + quotationRecord[current] || 0;
    } else {
      return 0;
    }
  }, 0);

  let totalPremium;

  if (msi && paymentPeriod === "year") {
    return Math.ceil(extrasTotalPremium * 12 * 0.94);
  }

  switch (paymentPeriod) {
    case "month":
      totalPremium = extrasTotalPremium;
      break;
    case "3month":
      totalPremium = extrasTotalPremium * 3 * 0.97;
      break;
    case "6month":
      totalPremium = extrasTotalPremium * 6 * 0.94;
      break;
    case "year":
      totalPremium = extrasTotalPremium * 12 * 0.9;
      break;
    default:
      console.error(
        `Uknown payment period. The payment period ${paymentPeriod} does not exist`
      );
      break;
  }

  return Math.ceil(totalPremium);
}
/**Given a payment method return the corresponding
 * key to be send to Google Tag Manager */
function paymentMethodToGTM(paymentMethod) {
  switch (paymentMethod) {
    case "efectivo":
      return "cash";
    case "transferencia":
      return "transfer";
    case "msi":
      return "msi";
    default:
      return "card";
  }
}

export default Contratar;
