export default function registerInterest({
  email,
  brand,
  model,
  year,
  tag,
  uid,
  type,
}) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const date = new Intl.DateTimeFormat("es-MX", {
    dateStyle: "short",
    timeStyle: "short",
    timeZone: "America/Mexico_City",
  })
    .format(new Date())
    .replace(",", "");
  var raw = JSON.stringify({
    spreadsheetId: "1jQ3yjYNUGe6E2IAN72tEdzMGDOgQdMv7yzOEJE7xnFU",
    tabName: "Hoja 1",
    range: "A:E",
    rows: [[date, uid, email, tag, brand, model, year, type]],
  });

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    "https://clupp-api.web.app/v1/clupp/integrations/google-sheets/appendRows",
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
}
