const URI =
  "https://us-central1-auto-clupp.cloudfunctions.net/cluppAdminAPI/mailChimp/updateListMemberTags";

async function addListMemberTags({ tags, email }) {
  // add the property status to every tag in the array
  const processedTags = tags.map((tag) => ({ ...tag, status: "active" }));
  try {
    const response = await fetch(URI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tags: processedTags,
        email,
      }),
    });

    // const json = await response.json();
    // console.log({ json });
  } catch (error) {
    console.log(error);
  }
}

export default addListMemberTags;
