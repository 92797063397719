import { useContext, createContext, useState, useEffect } from "react";
import { auth } from "./Firebase/firebase";
import { db } from "./Firebase/firebase";

const AuthAgentContext = createContext();

export const useAgentAuth = () => useContext(AuthAgentContext);

export const AuthAgentProvider = ({ children }) => {
  const [currentAgentUser, setCurrentAgentUser] = useState(null);
  const [otherAgent, setOtherAgent] = useState(null);
  const [isAgentLogged, setIsAgentLogged] = useState(null);
  const [agentClaims, setAgentClaims] = useState({
    isAdmin: false,
  });
  const [agentData, setAgentData] = useState({});
  const [agentDoc, setAgentDoc] = useState(null);

  useEffect(() => {
    const storageAgent = window.localStorage.getItem("selectedAgent");
    if (Boolean(storageAgent)) {
      try {
        setOtherAgent(JSON.parse(storageAgent));
      } catch (error) {}
    }
  }, []);

  function handleRemoveSelectedAgent() {
    setOtherAgent(null);
    window.localStorage.removeItem("selectedAgent");
  }

  const [configuracionPromotoria, setConfiguracionPromotoria] = useState({
    hiddenSections: [],
    hideAgentBankDetails: false,
    isLoading: true,
  });

  useEffect(() => {
    if (currentAgentUser) {
      const unsubscribe = db
        .doc(`agentProspects/${currentAgentUser?.uid}`)
        .onSnapshot(async (doc) => {
          const agentGroup = doc.data()?.agentGroup;
          if (agentGroup) {
            const parentAgent = await db
              .collection("agentProspects")
              .where("promotoria", "==", agentGroup)
              .limit(1)
              .get();
            if (parentAgent.docs.length > 0) {
              const parentAgentId = parentAgent.docs[0].id;
              const configSnap = await db
                .collection(
                  `agentProspects/${parentAgentId}/agentsConfiguration`
                )
                .orderBy("timestamp", "desc")
                .limit(1)
                .get();

              // Si se encuentra una configuración de la promotoria,
              // se actualiza el estado para utilizarla en el resto
              // de la aplicación
              if (configSnap.size === 1) {
                setConfiguracionPromotoria({
                  ...configSnap.docs[0].data(),
                  isLoading: false,
                });
                setAgentDoc(doc.data());
                return;
              }
            }
          }

          // Si no se encuentra una configuración,
          // se actualiza el estado para indicar que ya se cargó
          // la configuración y se preserva la configuración por defecto
          setConfiguracionPromotoria((prev) => ({
            ...prev,
            isLoading: false,
          }));
          setAgentDoc(doc.data());
        });
      return unsubscribe;
    }
  }, [currentAgentUser]);

  const loginAgent = (email, password) =>
    auth.signInWithEmailAndPassword(email, password);

  const isUserAgentAdmin = () =>
    auth.currentUser
      .getIdTokenResult()
      .then((idTokerresult) => {
        if (idTokerresult.claims?.admin === true) {
          setAgentClaims((prev) => ({ ...prev, isAdmin: true }));
        }
        if (idTokerresult.claims?.salesAgent === true) {
          setIsAgentLogged(true);
          return true;
        } else {
          setIsAgentLogged(false);
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
        return false;
      });

  const value = {
    loginAgent,
    isUserAgentAdmin,
    setCurrentAgentUser,
    currentAgentUser,
    isAgentLogged,
    agentData,
    setAgentData,
    agentDoc,
    configuracionPromotoria,
    otherAgent,
    setOtherAgent,
    agentClaims,
    handleRemoveSelectedAgent,
  };
  return (
    <AuthAgentContext.Provider value={value}>
      {children}
    </AuthAgentContext.Provider>
  );
};
