import { useState } from "react";
import {
  DialogTitle,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { auth } from "../../Firebase/firebase";
import Swal from "sweetalert2";

export default function RecuperarContraseñaModal({ onClose, open, option="Restaurar", title = "Restaurar contraseña" }) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false)

  function handlerSendEmailPassword() {
    setLoading(true)
    auth.sendPasswordResetEmail(email).then(() => {
      setLoading(false)
      onClose();
      Swal.fire({
        icon: "success",
        text: `Se ha enviado un correo a ${email} con instrucciones para ${option.toLowerCase()} tu contraseña`,
      })
    }).catch((e) => {
      setLoading(false)
      onClose();
      Swal.fire({
        icon: "error",
        text: "El correo electrónico no se encuentra registrado",
      });
    });
  }

  function handleOnChange(e) {
    setEmail(e.target.value);
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" color="black">
          Ingresa tu correo electrónico y se te enviarán instrucciones para {option.toLowerCase()} tu contraseña.
        </DialogContentText>
        <TextField
          margin="dense"
          type="email"
          name="email"
          fullWidth
          value={email}
          onChange={handleOnChange}
          label="Ingresa tu correo electrónico registrado"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={handlerSendEmailPassword}
          autoFocus
        >
          {option} contraseña
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
