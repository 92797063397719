import { Fragment} from "react";
import Header from "../../Components/Header/Header";
import logoclupp from '../../Assets/SVG/logo-cluppBCO2.svg';

import './Respuestas.css'; 
const Cotizar = () => {
    return ( 
        <Fragment>
                <Fragment>
                    <Header />
                    <div className="block">
                    <a href="https://clupp.com.mx" target="_blank">
                    <img src={logoclupp} alt="Clupp" className="imagen" />
                    </a>
                    </div>
                    <br/><br/><br/>
                    <div className='respuestas'>
                        <h3 >
                            Muchas gracias por tu respuesta. En breve un asesor de pondrá en contacto contigo para darte tu cotización personalizada.
                        </h3>
                    </div>
                    </Fragment>
            
        </Fragment>
     );
}
 
export default Cotizar;