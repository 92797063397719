import "./Header.css";
import { Typography } from "@mui/material";
import { useAgentAuth } from "../../AuthAgentContext";
import logoclupp from "../../Assets/SVG/logo-cluppBCO2.svg";
import { Link } from "react-router-dom";
import styles from "./DashboardHeader.module.css"

const Header = () => {
  const { isAgentLogged } = useAgentAuth();

  return (
    <div className={`header`}>
      <div className={isAgentLogged && styles.bandage}>
        {isAgentLogged ? (
          <>
            <Link to="/agentes/dashboard">
              <img src={logoclupp} alt="clupp-logo" />{" "}
              <Typography variant="body1">Agentes</Typography>
            </Link>
          </>
        ) : (
          <a href="https://clupp.com.mx/" target="_blank" rel="noreferrer">
            <img src={logoclupp} alt="clupp-logo" />{" "}
          </a>
        )}
      </div>
    </div>
  );
};

export default Header;
