import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { keyframes } from '@emotion/react';

const moveAnimation = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
`;

const SectionBanner = () => {
  return (
    <Box 
      sx={{ 
        backgroundColor: '#21D38F', 
        color: '#fff', 
        padding: '5px', 
        textAlign: 'center', 
        borderRadius: '5px',
        fontFamily: 'Montserrat, sans-serif',
        overflow: 'hidden',
      }}
    >
      <Typography 
        component="span" 
        sx={{ 
          fontWeight: '300', 
          fontSize: '16px',
          display: 'inline-block',
          animation: `${moveAnimation} 3s infinite`
        }}
      >
 ¡Gana premios en el 
      </Typography>
      <Link 
        href="/agentes/ranking-agentes?utm_medium=banner" 
        color="inherit"
        sx={{ 
          fontWeight: 'bold', 
          fontSize: '16px', 
          marginLeft: '5px',
          fontFamily: 'inherit',
          '&:hover': { 
            color: '#FFDE43' 
          },
          animation: `${moveAnimation} 3s infinite`
        }}
      >
        {`Ranking de Agentes!🎁💰>`}
      </Link>
    </Box>
  );
};

export default SectionBanner;