import { useState, useEffect } from "react";
import { db } from "../Firebase/firebase";
import logError from "../services/logError";

export default function useUser(userUid, collectionDB) {
  const [user, setUser] = useState();
  //agentProspects
  useEffect(() => {
    userUid &&
      db
        .collection(collectionDB)
        .doc(userUid)
        .get()
        .then((doc) => {
          const getUser = doc.data();
          setUser(getUser);
        })
        .catch((error) => {
          logError({
            error: error.toString(),
            metadata: {
              description:
                "Error al obtener información del usuario en base de datos",
                userId: userUid ? userUid : "undefined",
                dbCollection: collectionDB ? collectionDB : ""
            },
          });
        });
  }, [userUid]);

  return { user };
}
