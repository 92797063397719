export default function persistUserData(user) {
  const { email, firstName, lastName, rfc, phone, employee } = user;
  email && localStorage.setItem("email", email);
  firstName && localStorage.setItem("firstName", firstName);
  rfc && localStorage.setItem("rfc", rfc);
  phone && localStorage.setItem("phone", phone);

  if (lastName) {
    let words = lastName.split(" ");
    if (words.length === 2) {
      localStorage.setItem("lastName", words[0]);
      localStorage.setItem("lastName2", words[1]);
    } else {
      localStorage.setItem("lastName", lastName);
    }
  }
}
