import React from "react";
import {
  Box,
  LinearProgress,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import copperMedal from "../../Assets/PNG/copperMedal.png";
import silverMedal from "../../Assets/PNG/silverMedal.png";
import goldMedal from "../../Assets/PNG/goldMedal.png";
import trofeo from "../../Assets/PNG/goldenCup.png";
import { styled } from "@mui/system";
import useRedirect from "../../hooks/useRedirect";

// Define styled components
const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "relative",
  width: "100%",
  margin: "30px 0",
}));

const Progress = styled(LinearProgress)(({ theme }) => ({
  width: "100%",
  height: 10,
  borderRadius: 5,
  backgroundColor: theme.palette.grey[300],
  "& .MuiLinearProgress-bar": {
    backgroundColor: "#00AEEF",
  },
}));

const Medal = styled("img")(({ theme }) => ({
  width: 50,
  height: 50,
  position: "absolute",
  bottom: -25,
  transform: "translateX(-50%)",
}));

const Trophy = styled("img")(({ theme }) => ({
  width: 50,
  height: 50,
}));

const calculateProgress = (amount) => {
  if (amount === 0) return 0;
  if (amount < 200000) return 10;
  if (amount >= 200000 && amount < 300000)
    return ((amount - 200000) / 100000) * 25;
  if (amount >= 300000 && amount < 400000)
    return 25 + ((amount - 300000) / 100000) * 25;
  if (amount >= 400000 && amount < 800000)
    return 50 + ((amount - 400000) / 400000) * 50;
  if (amount >= 800000) return 100;
  return 0;
};

const AchievementTimeline = ({ amount, tooltipText }) => {
  const redirect = useRedirect();
  const theme = useTheme();
  const progress = calculateProgress(amount);
  const medals = [
    {
      src: copperMedal,
      position: "25%",
      description:
        "Bono del 2% de tus ventas si tu producción del trimestre es de $200,000 a $300,000",
    },
    {
      src: silverMedal,
      position: "50%",
      description:
        "Bono del 4% de tus ventas si tu producción del trimestre es de $300,001 a $400,000",
    },
    {
      src: goldMedal,
      position: "75%",
      description:
        "Bono del 6% de tus ventas si tu producción del trimestre es de $400,001 a $800,000",
    },
  ];

  return (
    <Container
      onClick={() => redirect("/agentes/metas-y-bonos")}
      sx={{ cursor: "pointer" }}
    >
      <Progress variant="determinate" value={progress} />
      {medals.map((medal, index) => (
        <Tooltip
          title={
            <Typography variant="subtitle2" textAlign={"center"}>
              {medal.description}
            </Typography>
          }
          arrow
        >
          <Medal
            key={index}
            src={medal.src}
            style={{ left: medal.position }}
            alt={`Medal ${index + 1}`}
            sx={{ cursor: "pointer" }}
          />
        </Tooltip>
      ))}
      <Box
        sx={{
          width: "70px",
          height: "70px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          borderRadius: "50%",
          position: "absolute",
          right: 0,
          backgroundColor: progress === 100 ? "#00AEEF" : "#E0E0E0",
        }}
      >
        <Tooltip
          title={
            <Typography variant="subtitle2" textAlign={"center"}>
              Bono del 10% de tus ventas si tu producción del trimestre es de
              $800,001 o más
            </Typography>
          }
          arrow
        >
          <Trophy src={trofeo} alt="Trophy" sx={{ cursor: "pointer" }} />
        </Tooltip>
      </Box>
    </Container>
  );
};

export default AchievementTimeline;
