import { Fragment } from 'react';
import logoclup from '../../Assets/PNG/LogoCLUP.png';
import CluppFestivo from '../../Assets/PNG/Clupp_Festejo.png';
import { Button } from '@mui/material';

const Bienvenido = ({ setHideBienvenido, setHideVerificacion }) => {
  const mostrarVerificar = () => {
    setHideBienvenido(false);
    setHideVerificacion(true);
  };

  return (
    <Fragment>
      <div
        style={{
          backgroundColor: '#00BBEE',
          width: '100%',
          height: '81px',
          left: '1px',
        }}
      >
        <img
          src={logoclup}
          alt="clupp-logo"
          style={{
            paddingTop: '15px',
            width: '123px',
            height: '45px',
            top: '30px',
            left: '119px',
          }}
        />
      </div>
      <div
        style={{
          background: '#fff',
          height: '85vh',
          overflow: 'scroll',
          position: 'sticky',
          top: '4rem',
          width: '100%',
        }}
      >
        <h2 style={{ marginTop: '60px', color: '#00BBEE', marginLeft: '10px' }}>
          ¡Gracias por tu pago!
        </h2>
        <div>
          <img
            src={CluppFestivo}
            alt="clupp-logo"
            style={{ width: '150px', height: '150x' }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            JustifyContent: 'center',
            flexDirection: 'columm',
          }}
        >
          <div style={{ width: '10%' }}> </div>
          <div style={{ width: '80%', textAlign: 'center', marginTop: '10px' }}>
            <p style={{ fontSize: '16px', textAlign: 'center' }}>
              ¡Ya eres parte de{' '}
              <span style={{ fontWeight: 'bold' }}> Clupp</span>!
              <br />
              Solo necesitamos que verifiques tu información para terminar el
              proceso.
            </p>
          </div>
        </div>
        <div style={{ marginTop: '50px' }}>
          <Button
            variant="contained"
            disableElevation
            style={{
              backgroundColor: '#00BBEE',
              color: '#fff',
              '&, &:hover, &:active': {
                backgroundColor: '#00bbee',
                color: '#fff',
              },
            }}
            onClick={mostrarVerificar}
          >
            Siguiente
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default Bienvenido;
