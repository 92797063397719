import React, { Fragment } from "react";
import { Card, CardContent, Typography } from "@mui/material/";
import whatsapp from "../../Assets/SVG/whatsapp-2.svg";
import "./KmAlMes.css";
import Menu from "../Menu/Menu";
import Header from "../Header/Header";
import info from "../../Assets/SVG/info.svg";
import { useAuth } from "../../AuthContext";
import CustomLink from "../CustomLink";
import getWhatsAppUrl from "../../utils/getWhatsAppUrl";

export default function KmAlMes() {
  const { currentUser } = useAuth();
  const whatsappUrl = getWhatsAppUrl(
    `Hola acabo de visitar su página. Mi número de cotización es ${currentUser.uid}. Mi nombre es`
  );
  return (
    <Fragment>
      <Menu />
      <Header />
      <div className="kmalmes">
        <h3>No se cuántos kilómetros recorro al mes</h3>
        <p className="kmalmes-subtitle">
          No te preocupes, te ayudamos a elegir el plan de movilidad más apto
          para ti:
        </p>
        <div className="mobility-cards-container">
          <Card>
            <CardContent>
              <Typography variant="h5" component="h2" className="card-title">
                Movilidad reducida
              </Typography>
              <Typography className="card-subtitle" color="textSecondary">
                Menos de 500 km al mes
              </Typography>
              <Typography variant="body2" component="p">
                Si usas tu vehículo solo los fines de semana para ir al super y
                salir ocasionalmente entre semana a lugares cercanos este plan
                es para ti.
              </Typography>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <Typography variant="h5" component="h2" className="card-title">
                Movilidad normal
              </Typography>
              <Typography className="card-subtitle" color="textSecondary">
                De 500 a 1,200 km al mes
              </Typography>
              <Typography variant="body2" component="p">
                Si usas tu vehículo a diario para ir al trabajo o cualquier otro
                lugar que esté de 20 a 30 minutos de tu casa, te recomendamos
                este plan.
              </Typography>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <Typography variant="h5" component="h2" className="card-title">
                Movilidad intensiva
              </Typography>
              <Typography className="card-subtitle" color="textSecondary">
                De 1,200 a 1,600 km al mes
              </Typography>
              <Typography variant="body2" component="p">
                Si usas tu vehículo diariamente para hacer recorridos largos o
                viajes en carretera este plan es para ti.
              </Typography>
            </CardContent>
          </Card>
        </div>
        <div className="kmalmes-info">
          <img src={info} alt="info" className="info-icon" />
          <h5>
            Por el momento no cubrimos vehículos que recorran mas kilómetros.
          </h5>
        </div>
        <div className="kmalmes-buttons">
          <a href={whatsappUrl} target="_blank" rel="noreferrer">
            <button className="white-btn">
              <img src={whatsapp} alt="whatsapp" /> Todavia tengo dudas
            </button>
          </a>
          <CustomLink to="/elegir-plan">
            <button className="entendido-btn">Entendido</button>
          </CustomLink>
        </div>
      </div>
    </Fragment>
  );
}
