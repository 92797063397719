import { format } from "date-fns";
import { es } from "date-fns/locale";
import { formatCurrency } from "../../utils/formatCurrency";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import BadgeIcon from "@mui/icons-material/Badge";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SosIcon from "@mui/icons-material/Sos";

const COLUMNS = (type) => {
  if (type === "polizas") {
    const columns = [
      {
        field: "timestampPayed",
        valueGetter: (params) => {
          if (!params.value) {
            return 0;
          }
          return new Date(params.value).getTime();
        },
        valueFormatter: (params) => {
          if (!params.value) {
            return "";
          }
          return format(
            new Date(Number(`${params.value}`)),
            "dd 'de' MMMM 'de' yyyy hh:mm aaa",
            {
              locale: es,
            }
          );
        },
        width: 250,
        headerName: "Fecha de pago",
      },
      {
        field: "status",
        width: 150,
        headerName: "Estatus",
        valueFormatter: (params) => {
          if (params.value === "succeeded") {
            return "Exitoso";
          }
          if (params.value === "refunded") {
            return "Reembolsado";
          }
          return params.value;
        },
      },
      { field: "periodicity", width: 200, headerName: "Periodicidad" },
      {
        field: "amount",
        width: 200,
        headerName: "Pago del periodo",
        valueFormatter: (params) => {
          if (!params.value) {
            return "";
          }
          return formatCurrency(params.value, { maximumFractionDigits: 0 });
        },
      },
      {
        field: "commission",
        width: 200,
        headerName: "Comisión de venta",
        valueFormatter: (params) => {
          if (!params.value) {
            return "";
          }
          return formatCurrency(params.value, { maximumFractionDigits: 2 });
        },
      },
      {
        field: "commissionStatus",
        width: 200,
        headerName: "Estatus de comisión",
      },
      { field: "idPayment", width: 250, headerName: "Identificador" },
    ];
    return columns;
  } else if (type === "agentsPolizas") {
    const columns = [
      {
        field: "timestampPayed",
        valueGetter: (params) => {
          if (!params.value) {
            return 0;
          }
          return new Date(params.value).getTime();
        },
        valueFormatter: (params) => {
          if (!params.value) {
            return "";
          }
          const timestampDataCorrected =
            params.value.toString().length === 13
              ? Number(params.value)
              : Number(`${params.value}000`);
          return format(
            new Date(Number(timestampDataCorrected)),
            "dd 'de' MMMM 'de' yyyy hh:mm aaa",
            {
              locale: es,
            }
          );
        },
        width: 250,
        headerName: "Fecha de pago",
      },
      { field: "agentName", width: 200, headerName: "Nombre del agente" },
      {
        field: "amount",
        width: 180,
        headerName: "Producción total",
        valueFormatter: (params) => {
          if (!params.value) {
            return "";
          }
          return formatCurrency(params.value, { maximumFractionDigits: 0 });
        },
      },
      {
        field: "agentComission",
        width: 180,
        headerName: "Comisión del agente",
        valueFormatter: (params) => {
          if (!params.value) {
            return "";
          }
          return formatCurrency(params.value, { maximumFractionDigits: 0 });
        },
      },
      {
        field: "promotorComission",
        width: 180,
        headerName: "Comisión del promotor",
        valueFormatter: (params) => {
          if (!params.value) {
            return "";
          }
          return formatCurrency(params.value, { maximumFractionDigits: 0 });
        },
      },
      {
        field: "promotorComissionStatus",
        width: 220,
        headerName: "Estatus de comisión del promotor",
      },
    ];
    return columns;
  } else if (type === "polizasDeUsuarios") {
    const columns = [
      {
        field: "startVigency",
        valueGetter: (params) => {
          const startVigency = new Date(
            params.row.insuranceStartYear,
            params.row.insuranceStartMonth - 1,
            params.row.insuranceStartDay
          );

          return startVigency.getTime();
        },
        valueFormatter: (params) => {
          return format(params.value, "dd 'de' MMMM 'de' yyyy", {
            locale: es,
          });
        },
        width: 200,
        headerName: "Fecha de contratación",
      },
      {
        field: "endVigency",
        width: 200,
        headerName: "Vigencia",
        valueGetter: (params) => {
          const endVigency = new Date(
            params.row.insuranceEndYear,
            params.row.insuranceEndMonth - 1,
            params.row.insuranceEndDay
          );

          return endVigency.getTime();
        },
        valueFormatter: (params) => {
          return format(params.value, "dd 'de' MMMM 'de' yyyy", {
            locale: es,
          });
        },
      },
      { field: "userName", width: 250, headerName: "Nombre" },
      { field: "email", width: 250, headerName: "Correo" },
      { field: "phone", headerName: "Teléfono", width: 150 },
      {
        field: "vehicle",
        width: 300,
        headerName: "Producto",
      },
      {
        field: "statusDescription",
        width: 130,
        headerName: "Estatus de póliza",
      },
      {
        field: "policyURL",
        width: 165,
        headerName: "Carátula de coberturas",
        renderCell: (params) => {
          if (!params.value) {
            return "Carátula por generar";
          } else {
            return (
              <a
                href={params.value}
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="contained">Ver carátula</Button>
              </a>
            );
          }
        },
      },
      {
        field: "actions",
        width: 200,
        headerName: "Acciones",
        renderCell: (params) => {
          return (
            <>
              {params.value.addPhotoUrl && (
                <a
                  href={params.value.addPhotoUrl}
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Tooltip
                    title={
                      <Typography>
                        Desde este botón podrás subir las fotos faltantes del
                        vehículo del usuario.
                      </Typography>
                    }
                  >
                    <IconButton aria-label="subir fotos">
                      <AddAPhotoIcon />
                    </IconButton>
                  </Tooltip>
                </a>
              )}

              {params.value.addDataUser && (
                <a
                  href={params.value.addDataUser}
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Tooltip
                    title={
                      <Typography>
                        Desde este botón podrás agregar los datos faltantes del
                        usuario.
                      </Typography>
                    }
                  >
                    <IconButton aria-label="agregar datos">
                      <BadgeIcon />
                    </IconButton>
                  </Tooltip>
                </a>
              )}

              {params.value.invoice && (
                <a
                  href={params.value.invoice}
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Tooltip
                    title={
                      <Typography>
                        Desde este botón podrás ingresar los datos para generar
                        la factura del usuario.
                      </Typography>
                    }
                  >
                    <IconButton aria-label="agregar datos">
                      <ReceiptIcon />
                    </IconButton>
                  </Tooltip>
                </a>
              )}

              {params.value.siniestro && (
                <a
                  href={params.value.siniestro}
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Tooltip
                    title={
                      <Typography>
                        Desde este botón podrás ingresar a la página de
                        siniestros del usuario.
                      </Typography>
                    }
                  >
                    <IconButton aria-label="agregar datos">
                      <SosIcon />
                    </IconButton>
                  </Tooltip>
                </a>
              )}
            </>
          );
        },
      },
    ];
    return columns;
  } else {
    const columns = [
      {
        field: "timestamp",
        valueGetter: (params) => {
          if (!params.value) {
            return 0;
          }
          return new Date(params.value).getTime();
        },
        valueFormatter: (params) => {
          if (!params.value) {
            return "";
          }
          return format(new Date(params.value), "dd 'de' MMMM 'de' yyyy", {
            locale: es,
          });
        },
        width: 200,
        headerName:
          type === "polizas" ? "Fecha de contratación" : "Fecha de cotización",
      },
      { field: "userName", width: 250, headerName: "Nombre" },
      { field: "email", width: 250, headerName: "Correo" },
      { field: "phone", headerName: "Teléfono", width: 270 },
      {
        field: "lastVehicle",
        width: 250,
        headerName: type === "polizas" ? "Vehículo" : "Última póliza cotizada",
      },
    ];
    return columns;
  }
};
export default COLUMNS;
