import { Box } from "@mui/system";
import React, { useEffect } from "react";
import AgenteCotizacionesGrid from "../../Components/AgenteCotizacionesGrid/AgenteCotizacionesGrid";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";
import {
  Paper,
  Typography,
  TextField,
  Button,
  capitalize,
} from "@mui/material";
import { db } from "../../Firebase/firebase";
import { useState } from "react";
import { Redirect } from "react-router-dom";
import { useAgentAuth } from "../../AuthAgentContext";
import AgentSidebar from "../../Components/AgentSidebar/AgentSidebar";
import format from "date-fns/format";
import { es } from "date-fns/locale";
import exportCVS from "./ExportCVS";
import NoInfoDataRow from "../../Components/NoInfoDataRow/NoInfoDataRow";
import DialogInfo from "./DialogInfo";
import policyTypeToLabel from "../../utils/policyTypeToLabel";

// const ESTATUS_DESCRIPTION = {
//   1: "Vigente",
//   2: "Vigente",
//   3: "Cancelado",
//   4: "Expulsado",
//   5: "Usuario de prueba",
// };

async function getUserData(uid) {
  return await db
    .collection("users")
    .doc(uid)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return {
          ...doc.data(),
          uid: doc.id,
        };
      } else {
        return null;
      }
    });
}

async function getVehicle(uid, vid) {
  return await db
    .collection(`users/${uid}/vehicles`)
    .doc(vid)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return {
          ...doc.data(),
          vid: doc.id,
        };
      } else {
        return null;
      }
    });
}

function getStatusPoliza(data) {
  const insuranceEndDate = new Date(
    data.insuranceEndYear,
    data.insuranceEndMonth,
    data.insuranceEndDay
  );
  const insuranceEndDate30 = new Date(
    insuranceEndDate.setDate(insuranceEndDate.getDate() + 30)
  ).getTime();
  // const insuranceEndDate30 = insuranceEndDate
  const today = new Date().getTime();
  if (data?.deleted && data?.isInsured === false) {
    return "Cancelado";
  } else if (today < insuranceEndDate) {
    return "Vigente";
  } else if (insuranceEndDate30 < today) {
    return "Pendiente de pago";
  } else {
    return "Vigente";
  }
}

async function getPolizasVendidas(sales = [], agentUid) {
  const allSales = sales.map(async (sale) => {
    const customer = await getUserData(sale.uid);
    if (sale.type === "vehicle") {
      const vehicle = await getVehicle(sale.uid, sale.itemId);

      if (vehicle) {
        const typeLabel = policyTypeToLabel(vehicle?.type || "", {
          capitalize: true,
        });
        const doc = {
          ...sale,
          ...customer,
          ...vehicle,
          actions: {
            addPhotoUrl: `https://cluppseguro.web.app/agregar-fotos?userUID=${customer.uid}&vehicleId=${vehicle.vid}&agentUID=${agentUid}`,
            addDataUser: `https://cluppseguro.web.app/datos-usuario?userUID=${customer.uid}&agentUID=${agentUid}`,
            invoice: `https://cluppseguro.web.app/facturacion?uid=${customer.uid}`,
            siniestro: `https://cluppseguro.web.app/siniestros?uid=${customer.uid}`,
          },
          statusDescription: getStatusPoliza(vehicle),
          userName: `${customer?.firstName || ""} ${customer?.lastName || ""} ${
            customer?.lastName2 || ""
          }`,
          vehicle: `${typeLabel} ${vehicle.brand || ""} ${
            vehicle.model || ""
          } ${vehicle.year || ""}`,
        };

        return doc;
      }
    } else if (sale.type === "smartphone" || sale.type === "gadget") {
      const gadgetSnaphot = await db
        .collection(`users/${sale.uid}/smartphones`)
        .doc(sale.itemId)
        .get();

      if (gadgetSnaphot.exists) {
        const gadget = gadgetSnaphot.data();
        const typeLabel = policyTypeToLabel(gadget?.type || "", {
          capitalize: true,
        });

        const doc = {
          ...sale,
          ...customer,
          ...gadget,
          actions: {
            invoice: `https://cluppseguro.web.app/facturacion?uid=${customer.uid}`,
            siniestro: `https://cluppseguro.web.app/siniestros?uid=${customer.uid}`,
          },
          statusDescription: getStatusPoliza(gadget),
          userName: `${customer?.firstName || ""} ${customer?.lastName || ""} ${
            customer?.lastName2 || ""
          }`,
          vehicle: `${typeLabel} ${gadget.brand || ""} ${gadget.model || ""}`,
        };
        return doc;
      }
    }
  });
  const allSalesResolved = await Promise.all(allSales);
  const returnedArrayWithoutUndefined = [];

  allSalesResolved.forEach((item) => {
    if (item) returnedArrayWithoutUndefined.push(item);
  });
  return returnedArrayWithoutUndefined;
}

export default function PolizasVendias() {
  const [isLoading, setIsLoading] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [salesData, setSalesData] = useState([]);
  const [filteredSales, setFilteredSales] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [rowSelected, setRowSelected] = useState(false);

  const {
    currentAgentUser,
    otherAgent,
    handleRemoveSelectedAgent,
    agentClaims,
  } = useAgentAuth();

  function onChange(e) {
    setSearchValue(e.target.value);
  }

  const agentUid = otherAgent ? otherAgent?.id : currentAgentUser?.uid;
  useEffect(() => {
    setIsLoading(true);
    let unsubscribe;
    if (agentUid) {
      unsubscribe = db
        .collection(`agentProspects/${agentUid}/sales`)
        .onSnapshot(async (snapshot) => {
          const sales = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const allPolizas = await getPolizasVendidas(sales, agentUid);
          setSalesData(allPolizas);
          setFilteredSales(allPolizas);
          setIsLoading(false);

          if (sales.length <= 0) {
            setIsLoading(false);
            setSalesData([]);
            setFilteredSales([]);
          }
        });
    }
    return unsubscribe;
  }, [currentAgentUser, agentUid]);

  useEffect(() => {
    handleSearch();
  }, [searchValue]);

  function handleExport() {
    exportCVS(filteredSales);
  }

  function handleOpenDialog(row) {
    setOpenDialog(true);
    setRowSelected(row.row);
  }

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  function handleSearch() {
    const normilizeSearch = (data) => {
      return data
        .normalize("NFD")
        .replace(/[\u0300-\u036f]|\s/g, "")
        .toLowerCase()
        .includes(
          searchValue
            .normalize("NFD")
            .replace(/[\u0300-\u036f]|\s/g, "")
            .toLowerCase()
        );
    };
    let projectFilter = salesData.filter((item) => {
      const searchByUserName = normilizeSearch(item?.userName);
      const searchByEmail = normilizeSearch(item?.email);
      const searchByPhone = normilizeSearch(item?.phone);
      const searchByVehicle = normilizeSearch(item?.vehicle);
      const searchByDate = normilizeSearch(
        format(
          new Date(Number(`${item.timestamp}`)),
          "dd 'de' MMMM 'de' yyyy hh:mm aaa",
          {
            locale: es,
          }
        )
      );
      const searchById = item.uid?.includes(searchValue);

      return (
        searchById ||
        searchByUserName ||
        searchByDate ||
        searchByEmail ||
        searchByPhone ||
        searchByVehicle
      );
    });
    setFilteredSales(projectFilter);
  }

  if (!currentAgentUser) return <Redirect to="/agentes/inicio-de-sesion" />;

  return (
    <Paper elevation={0} sx={{ width: "100%", minHeight: "100vh" }}>
      <AgentSidebar />
      <DashboardHeader haveABanner={true}/>
      <DialogInfo
        isOpen={openDialog}
        onClose={handleCloseDialog}
        rowSelected={rowSelected}
      />
      <main
        style={{
          width: "90%",
          maxWidth: "1140px",
          margin: "30px auto",
          paddingTop: "70px",
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Typography style={{ margin: "10px" }} variant="h5">
            {otherAgent
              ? `Pólizas vendidas de ${otherAgent?.firstName}`
              : "Pólizas vendidas"}
          </Typography>
          {agentClaims.isAdmin === true && otherAgent && (
            <Button
              disabled={!otherAgent}
              sx={{
                position: ["initial", "initial", "absolute"],
                top: [0, "5px"],
                right: 0,
                width: ["100%", "auto"],
              }}
              variant="outlined"
              size="small"
              onClick={() => handleRemoveSelectedAgent()}
            >
              Mostrar mis pólizas vendidas
            </Button>
          )}
        </Box>
        <Box sx={{ display: "grid", gap: 3, margin: "20px 0" }}>
          <TextField
            sx={{ backgroundColor: "white" }}
            onChange={onChange}
            onKeyUp={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter")
                handleSearch();
            }}
            size="small"
            fullWidth
            placeholder="Escribe fecha, identificador, nombre, correo, teléfono o nombre del vehículo"
            variant="outlined"
            value={searchValue}
          />
          <AgenteCotizacionesGrid
            rowData={filteredSales}
            type="polizasDeUsuarios"
            onRowClickProp={(e) => {
              if (e?.row?.cancellationComment) {
                handleOpenDialog(e);
              }
            }}
            noRowOverlayLabel={
              salesData.length === 0
                ? NoInfoDataRow
                : () => {
                    return null;
                  }
            }
            agentUid={agentUid}
            idRow="id"
            loading={isLoading}
          />
        </Box>
        <Button
          sx={{ marginBottom: "20px" }}
          variant="outlined"
          onClick={handleExport}
        >
          Exportar datos
        </Button>
      </main>
    </Paper>
  );
}
