import moment from 'moment-timezone';

function getQuarterTimestamps(quarter) {
  const currentYear = new Date().getFullYear();
  let startDate, endDate;

  switch (quarter) {
    case 1:
      startDate = moment.tz([currentYear, 0, 1, 0, 0, 0], 'America/Mexico_City');
      endDate = moment.tz([currentYear, 2, 31, 23, 59, 59], 'America/Mexico_City');
      break;
    case 2:
      startDate = moment.tz([currentYear, 3, 1, 0, 0, 0], 'America/Mexico_City');
      endDate = moment.tz([currentYear, 5, 30, 23, 59, 59], 'America/Mexico_City'); // Cambié el mes para incluir todo junio
      break;
    case 3:
      startDate = moment.tz([currentYear, 6, 1, 0, 0, 0], 'America/Mexico_City');
      endDate = moment.tz([currentYear, 8, 30, 23, 59, 59], 'America/Mexico_City');
      break;
    case 4:
      startDate = moment.tz([currentYear, 9, 1, 0, 0, 0], 'America/Mexico_City');
      endDate = moment.tz([currentYear, 11, 31, 23, 59, 59], 'America/Mexico_City');
      break;
    default:
      throw new Error("Invalid quarter number. Must be between 1 and 4.");
  }

  return {
    start: startDate.valueOf(),
    end: endDate.valueOf(),
  };
}

export default getQuarterTimestamps;