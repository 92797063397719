import { useState } from "react";

/**
 * This hook is used to get the WhatsApp number from the database
 * @deprecated - The WhatsApp number is now a static value
 */
export default function useWhatsAppNumber() {
  const [whatsAppNumber] = useState("5577460177");

  return whatsAppNumber;
}
