import { useState, useEffect } from "react";
import { db } from "../Firebase/firebase";

export default function useBannedUsers() {
  const [bannedUsers, setBannedUsers] = useState([]);
  useEffect(() => {
    db.collection("bannedUsers").onSnapshot((snapshot) => {
      const bannedUsers = snapshot.docs.map((doc) => ({...doc.data(), uid: doc.id}));
      setBannedUsers(bannedUsers);
    });
  }, []);
  return { bannedUsers };
}
