import { Link, useLocation } from "react-router-dom";

// automatically preserves the query search params in present in the url
const CustomLink = ({ children, to, ...props }) => {
  const { search } = useLocation();
  return (
    <Link to={to + search} {...props}>
      {children}
    </Link>
  );
};

export default CustomLink;
