import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import Route from "../../Assets/PNG/Route.png";
import MenCharacter from "../../Assets/PNG/CharaterProgress.png";
import FemeninCharacter from "../../Assets/PNG/Girl.png";
import CluppCharacter from "../../Assets/PNG/CluppcitoHappy.png";
import copperMedal from "../../Assets/PNG/copperMedal.png";
import silverMedal from "../../Assets/PNG/silverMedal.png";
import goldMedal from "../../Assets/PNG/goldMedal.png";
import trofeo from "../../Assets/PNG/goldenCup.png";
import { useAgentAuth } from "../../AuthAgentContext";
import { useEffect, useState } from "react";
import getCurrentQuarter from "../../utils/getCurrentQuarter";
import getAgentProgress from "../../services/agentsBonusProgress/getProgress";
import { formatCurrency } from "../../utils/formatCurrency";

import DoneIcon from "@mui/icons-material/Done";
import getQuarterTimestamps from "../../utils/getQuarterTimestamp";
import { formatDateRange } from "../../Views/HistorialDePagoAgentes/utils";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import ModalComissionCheckout from "../../Views/Comisiones/ModalComissionCheckout";

const Progress = () => {
  const [progress, setProgress] = useState({});
  const { currentAgentUser, otherAgent, agentData } = useAgentAuth();
  const [gender, setGender] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isOnTheLastQuarter, setIsOnTheLastQuarter] = useState(false);
  const [quarter, setQuarter] = useState(getCurrentQuarter());
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timestampModal, setTimestampModal] = useState([]);
  const [loadigMessage, setLoadingMessage] = useState(
    "Estamos buscando tus ventas..."
  );

  const getProgress = async ({
    uid,
    quarterTimestamps,
    isForLastBonusCollect = false,
  }) => {
    setIsLoading(true);
    const progress = await getAgentProgress({
      uid,
      quarterTimestamps,
      isForLastBonusCollect,
    });

    const medalArray = Object.entries(progress.medals);
    const completedMedals = medalArray.filter((item) => item[1].isCompleted);
    if (completedMedals.length > 0) {
      const lastMedal = completedMedals.reduce((acc, currentMedal) => {
        if (currentMedal.min >= acc) {
          return currentMedal;
        }
        return acc;
      }, completedMedals[0]);
      const percentage = lastMedal[1].percentage;

      progress.commition = progress.totalAmount * (percentage / 100);
    }

    setProgress(progress);
    setIsLoading(false);
  };

  useEffect(() => {
    if (agentData?.gender)
      setGender(otherAgent ? undefined : agentData?.gender);
  }, [agentData]);

  const getLoadingMessages = (progressNumber) => {
    if (progressNumber > 0 && progressNumber <= 20)
      return "Estamos buscando tus ventas...";
    if (progressNumber > 20 && progressNumber <= 40)
      return "Estamos sumando tus comisiones...";
    if (progressNumber > 40 && progressNumber <= 85)
      return "Estamos puliendo tus medallas...";
    if (progressNumber > 85 && progressNumber <= 100)
      return "¡Ya casi estamos!";
  };

  useEffect(() => {
    setTimestampModal(handleTimestampsModal(isOnTheLastQuarter));
  }, [isOnTheLastQuarter]);

  useEffect(() => {
    handleSetCurrenQuarter();
  }, [currentAgentUser, otherAgent]);

  const handleSetLastQuarter = async () => {
    const interval = setInterval(() => {
      setLoadingProgress((oldProgress) => {
        if (oldProgress >= 98) {
          clearInterval(interval);
          return oldProgress;
        }
        setLoadingMessage(getLoadingMessages(oldProgress + 1));
        return oldProgress + 1;
      });
    }, 200);
    const currentQuarter = getCurrentQuarter();
    const quarterTimestamps = getQuarterTimestamps(currentQuarter - 1);
    const agentUid = otherAgent ? otherAgent.id : currentAgentUser?.uid;
    setQuarter(currentQuarter - 1);

    await getProgress({
      uid: agentUid,
      quarterTimestamps,
      isForLastBonusCollect: true,
    });
    setIsOnTheLastQuarter(true);
    clearInterval(interval);
    setLoadingProgress(0);
  };

  const handleSetCurrenQuarter = async () => {
    const interval = setInterval(() => {
      setLoadingProgress((oldProgress) => {
        if (oldProgress >= 98) {
          clearInterval(interval);
          return oldProgress;
        }
        setLoadingMessage(getLoadingMessages(oldProgress + 1));
        return oldProgress + 1;
      });
    }, 200);

    const currentQuarter = getCurrentQuarter();
    const quarterTimestamps = getQuarterTimestamps(currentQuarter);
    const agentUid = otherAgent ? otherAgent.id : currentAgentUser?.uid;
    setQuarter(currentQuarter);
    await getProgress({ uid: agentUid, quarterTimestamps });
    setIsOnTheLastQuarter(false);
    clearInterval(interval);
    setLoadingProgress(0);
  };

  const quarterName = {
    1: "primer",
    2: "segundo",
    3: "tercer",
    4: "cuarto",
  };

  const TitlesHeader = ({ otherAgent, quarter }) => {
    const { start, end } = getQuarterTimestamps(quarter);
    const range = formatDateRange(start, end);
    const title = otherAgent
      ? `Bono del ${quarterName[quarter]} trimestre de ${otherAgent?.firstName} `
      : `Bono del ${quarterName[quarter]} trimestre `;

    return (
      <>
        {title}
        <Chip
          icon={<AccessTimeIcon sx={{ fontSize: "20px" }} />}
          label={range}
          sx={{ maxHeight: "20px" }}
        />
      </>
    );
  };

  const handleTimestampsModal = (isOnTheLastQuarter) => {
    const quarter = getCurrentQuarter();
    const { start: startDate, end: endDate } = getQuarterTimestamps(
      isOnTheLastQuarter ? quarter - 1 : quarter
    );
    return [startDate, endDate];
  };

  return isLoading ? (
    <Box
      sx={{
        width: "100%",
        height: "80vh",
        textAlign: "center",
        marginTop: "120px",
      }}
    >
      <p>{loadigMessage}</p>
      <BorderLinearProgress variant="determinate" value={loadingProgress} />
      <Stack
        flexDirection={"row"}
        justifyContent={"space-evenly"}
        alignItems="center"
        width={"80%"}
        height={"50vh"}
        margin={"80px auto 0px auto"}
      >
        <Skeleton
          variant="rectangular"
          width={"50%"}
          height={"50vh"}
          sx={{ borderRadius: "6px", marginRight: "70px" }}
        />
        <Skeleton variant="circular" width={"250px"} height={"250px"}>
          <Avatar />
        </Skeleton>
      </Stack>
    </Box>
  ) : (
    <>
      <Stack
        sx={{
          display: ["block", "block", "flex"],
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          width: "80%",
          margin: "90px auto -90px auto",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "600",
            textAlign: "left",
          }}
        >
          <TitlesHeader otherAgent={otherAgent} quarter={quarter} />
          <Typography variant="subtitle2">{`${
            isOnTheLastQuarter ? "Lograste" : "Llevas"
          } ${formatCurrency(progress.totalAmount)} ${
            isOnTheLastQuarter ? "el trimestre anterior" : "este trimestre"
          } ${
            isOnTheLastQuarter ? "¡Solo te faltaron" : "¡Solo te faltan"
          } ${formatCurrency(
            progress?.medals[progress?.currentMedal || "trophy"]
              ?.missingAmount || 0
          )} para llevarte el bono trimestral del ${
            progress.medals[progress?.currentMedal || "trophy"].percentage
          }% de tus ventas!`}</Typography>
        </Typography>
        <Stack
          display={"flex"}
          alignItems={"center"}
          flexDirection={"row"}
          sx={{ marginTop: ["15px", "15px", "0px"] }}
        >
          {isOnTheLastQuarter && progress.medals.copper.isCompleted && (
            <Button
              sx={{
                marginRight: "13px",
                maxHeight: "36px",
              }}
              disableElevation
              onClick={() => setIsModalOpen(true)}
              target="_blank"
              variant="contained"
            >
              Cobrar mi bono
            </Button>
          )}
          {isOnTheLastQuarter && (
            <ModalComissionCheckout
              open={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              timestamps={timestampModal}
              comission={{ commition: progress.commition }}
              salesIdDocs={progress.salesIds}
              isQuarterlyCollect={true}
            />
          )}
          <Button
            variant="outlined"
            sx={{ maxHeight: "36px" }}
            onClick={() =>
              isOnTheLastQuarter
                ? handleSetCurrenQuarter()
                : handleSetLastQuarter()
            }
          >
            {isOnTheLastQuarter
              ? "Ver trimestre actual"
              : "Ver trimestre anterior"}
          </Button>
        </Stack>
      </Stack>

      <Stack
        sx={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between",
          flexDirection: "row",
          marginTop: "40px",
        }}
      >
        <Stack
          sx={{
            backgroundImage: `url(${Route})`,
            position: "relative",
            width: "55%",
            height: "78vh",
            objectFit: "cover",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <MedalProgess
            medal={copperMedal}
            medalName={"copper"}
            position={{
              bottom: ["15%", "15%", "15%", "10%"],
              left: ["30%", "30%", "20%", "20%"],
            }}
            progress={
              progress.medals.copper.isCompleted
                ? 100
                : progress.currentMedal === "copper"
                ? progress.medals.copper.progress
                : 0
            }
            description={progress.medals.copper.description}
            isCurrentMedal={
              isModalOpen ? false : progress.currentMedal === "copper"
            }
            percentage={progress.medals.copper.percentage}
            missingAmount={progress.medals.copper.missingAmount}
            isCompleted={progress.medals.copper.isCompleted}
            isOnTheLastQuarter={isOnTheLastQuarter}
          />
          <MedalProgess
            medal={silverMedal}
            medalName={"silver"}
            position={{
              bottom: ["50%", "50%", "50%", "45%"],
              left: ["30%", "35%", "35%", "30%"],
            }}
            progress={
              progress.medals.silver.isCompleted
                ? 100
                : progress.currentMedal === "silver"
                ? progress.medals.silver.progress
                : 0
            }
            description={progress.medals.silver.description}
            isCurrentMedal={
              isModalOpen ? false : progress.currentMedal === "silver"
            }
            percentage={progress.medals.silver.percentage}
            missingAmount={progress.medals.silver.missingAmount}
            isCompleted={progress.medals.silver.isCompleted}
            isOnTheLastQuarter={isOnTheLastQuarter}
          />
          <MedalProgess
            medal={goldMedal}
            medalName={"gold"}
            position={{
              bottom: ["25%", "25%", "25%", "15%"],
              left: ["55%", "70%", "60%", "50%"],
            }}
            progress={
              progress.medals.gold.isCompleted
                ? 100
                : progress.currentMedal === "gold"
                ? progress.medals.gold.progress
                : 0
            }
            description={progress.medals.gold.description}
            percentage={progress.medals.gold.percentage}
            isCurrentMedal={
              isModalOpen ? false : progress.currentMedal === "gold"
            }
            missingAmount={progress.medals.gold.missingAmount}
            isCompleted={progress.medals.gold.isCompleted}
            isOnTheLastQuarter={isOnTheLastQuarter}
          />
          <MedalProgess
            medal={trofeo}
            medalName={"trophy"}
            position={{ bottom: "59%", left: "95%" }}
            percentage={progress.medals.trophy.percentage}
            progress={
              progress.medals.trophy.isCompleted
                ? 100
                : progress.currentMedal === "trophy"
                ? progress.medals.trophy.progress
                : 0
            }
            description={progress.medals.trophy.description}
            isCurrentMedal={
              isModalOpen ? false : progress.currentMedal === "trophy"
            }
            missingAmount={progress.medals.trophy.missingAmount}
            isCompleted={progress.medals.trophy.isCompleted}
            isOnTheLastQuarter={isOnTheLastQuarter}
          />
        </Stack>
        <Stack
          sx={{
            width: "45%",
            display: ["none", "block"],
          }}
        >
          <img
            src={
              gender === "Femenino"
                ? FemeninCharacter
                : gender === "Masculino"
                ? MenCharacter
                : CluppCharacter
            }
            alt="progressCharacters"
            style={{ maxWidth: "400px", alignSelf: "center" }}
          />
        </Stack>
      </Stack>
    </>
  );
};

const MedalProgess = ({
  progress,
  medal,
  medalName,
  position,
  description,
  isCurrentMedal,
  currentMedal,
  missingAmount,
  isCompleted,
  percentage,
  isOnTheLastQuarter,
}) => {
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 200,
      textAlign: "center",
      fontSize: 14,
    },
  });

  return (
    <CustomWidthTooltip
      title={
        <>
          <h4 style={{ fontWeight: "400" }}>{description}.</h4>
          <h4>
            {isCompleted
              ? `¡Excelente, lo lograste!`
              : `¡Te ${isOnTheLastQuarter ? "faltaron" : "faltan"} ${
                  isCurrentMedal ? "" : "solo"
                } ${formatCurrency(missingAmount)} para lograrlo!`}
          </h4>
        </>
      }
      arrow
      open={isCurrentMedal ? true : undefined}
      placement="top"
    >
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...position,
        }}
      >
        {isCompleted && (
          <Box
            sx={{
              top: "-45px",
              position: "absolute",
              right: "-15px",
            }}
          >
            <Chip
              label={`${percentage}%`}
              avatar={<DoneIcon sx={{ fill: "#fff", color: "#fff" }} />}
              sx={{
                backgroundColor: "#00CA7D",
                color: "#fff",
                fontWeight: 800,
              }}
            />
          </Box>
        )}

        <CircularProgress
          variant="determinate"
          sx={{
            position: "absolute",
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          }}
          size={115}
          thickness={3}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          value={progress}
          size={115}
          thickness={3}
          sx={{
            position: "absolute",
            padding: 0,
            margin: 0,
            color: isCompleted ? "#00CA7D" : "#00BBEE",
          }}
        />

        <Box
          sx={{
            width: "80px",
            height: "80px",
            padding: "10px",
            borderRadius: "50%",
            backgroundColor: "#fff",
            backgroundImage: `url(${medal})`,
            backgroundSize: "70px",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",

            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
          }}
        ></Box>
      </Box>
    </CustomWidthTooltip>
  );
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: "70%",
  margin: "0 auto",
  borderRadius: "20px",
  height: "12px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#00BBEE" : "#00BBEE",
  },
}));

export default Progress;
