import { Typography, Box, Button, Stack } from "@mui/material";
import { useAgentAuth } from "../../AuthAgentContext";

export default function NoInfoDataRow() {
  return (
    <Stack
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box>
        <Typography>Aún no has realizado tu primera venta</Typography>
        <Button
          sx={{
            zIndex: 10,
            backgroundColor: "#00CA7D",
            "&:hover": {
              backgroundColor: "#009b5f",
            },
          }}
          variant="contained"
        >
          <a
            style={{
              color: "white",
              textDecoration: "none",
              pointerEvents: "auto",
              cursor: "pointer",
            }}
            href="/agentes/videos-tutoriales/"
            target="_blank"
            rel="noreferrer"
          >
            Ver video tutorial
          </a>
        </Button>
      </Box>
    </Stack>
  );
}
