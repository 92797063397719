import { Fragment, useEffect, useReducer, useState } from "react";
import firebase from "firebase/app";
import { useAgentAuth } from "../AuthAgentContext";
import {
  Typography,
  Box,
  Divider,
  TextField,
  InputAdornment,
  Button,
  Stack,
  Alert,
} from "@mui/material";

import { mobileModel, mobileVendor } from "react-device-detect";
import logError from "../services/logError";

import Stepper from "../Components/Stepper/Stepper";
import Header from "../Components/Header/Header";
import Menu from "../Components/Menu/Menu";
import BotonPrincipal from "../Components/BotonPrincipal/BotonPrincipal";
import ExtraItemField from "../Components/ExtraItemField";
import CounterField from "../Components/CounterField";
import LoadingOverlay from "../Components/LoadingOverlay";

import useLocalStorage from "../hooks/useLocalStorage";
import { db, storage } from "../Firebase/firebase";
import Swal from "sweetalert2";

import getTotalPremium from "../utils/getPremium";
import getPremiumPropertyName from "../utils/getPremiumPropertyName";
import formatPremium from "../utils/formatPremium";
import PaymentDescription from "../Components/PaymentDescription";
import generateQuotation from "../utils/generateQuotation";
import useRedirect from "../hooks/useRedirect";
import addInterestTag from "../utils/addInterestTag";
import EnviarCotizacion from "../Components/EnviarCotizacion/EnviarCotizacion";
import Dialog from "../Components/Dialog";
import useQuery from "../hooks/useQuery";
import AGENTES from "../utils/agentes";
import usePreventClosingWindow from "../hooks/usePreventClosingWindow";
import registerInterest from "../services/logs/registerInterest";

const initialValue = {
  userData: undefined,
  vehicleData: undefined,
  quotationData: undefined,
  isLoading: true,
  isGeneratingQuotation: true,
};

const PREMIUM_DISCOUNTS = {
  "3month": 3,
  "6month": 6,
  year: 10,
};

const ACTIONS = {
  SET_DATA: "set-data",
  GENERATE_QUOTATION: "generate-quotation",
  TOGGLE_GENERATE_QUOTATION: "toggle-generate-quotation",
  SET_QUOTATION: "set-quotation",
  UPDATE_EXTRAS: "update-extras",
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_DATA:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
        isLoading: false,
      };
    case ACTIONS.SET_QUOTATION:
      return {
        ...state,
        quotationData: action.payload,
        isGeneratingQuotation: false,
      };
    case ACTIONS.TOGGLE_GENERATE_QUOTATION:
      return {
        ...state,
        isGeneratingQuotation: !state.isGeneratingQuotation,
      };
    default:
      return state;
  }
}

function getExtraItemPriceDescription(paymentPeriod, montlyPremium) {
  if (!paymentPeriod || !montlyPremium) return null;
  // the premium does not have any discount applied
  const PAYMENT_PERIODS = {
    month: {
      premium: montlyPremium,
      label: "/mensual",
    },
    "3month": {
      premium: montlyPremium * 3,
      label: "/trimestral",
    },
    "6month": {
      premium: montlyPremium * 6,
      label: "/semestral",
    },
    year: {
      premium: montlyPremium * 12,
      label: "/anual",
    },
  };

  const { premium, label } = PAYMENT_PERIODS[paymentPeriod];

  return (
    <Fragment>
      {formatPremium(premium)} <br />{" "}
      <span style={{ fontSize: "12px" }}>{label}</span>
    </Fragment>
  );
}

const Extras = (props) => {
  usePreventClosingWindow();
  const redirect = useRedirect();
  const query = useQuery();
  const { isAgentLogged, agentData } = useAgentAuth();
  const [extrasState, setExtrasState] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [{ invoicePDF, isInvoiceModalOpen }, setInvoice] = useState({
    invoicePDF: null,
    isInvoiceModalOpen: false,
  });

  const [openQuotationModal, setOpenQuotationModal] = useState(false);
  const [{ quotationData, vehicleData, userData, ...state }, dispatch] =
    useReducer(reducer, initialValue);

  const [userUID, vehicleUID] = useLocalStorage(["docRef", "currentVehicle"]);
  const isWowUser = query.get("utm_campaign") === AGENTES.WOW;
  useEffect(() => {
    if (!userUID || !vehicleUID) return;

    // Add a documento to track user behaviour between screens
    db.collection(`prospectsNew/${userUID}/qStatusUpdates`).add({
      vehicleId: vehicleUID,
      qStatus: 1.5,
      timestamp: Date.now(),
      timestampServer: firebase.firestore.FieldValue.serverTimestamp(),
    });

    let quotationUnsubscribe = db
      .collection(`prospectsNew/${userUID}/quotationsRecord`)
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) => {
        let quotations = [];

        snapshot.forEach((doc) => {
          quotations.push({
            ...doc.data(),
            uid: doc.id,
          });
        });
        // console.log("Quotation Data", quotations[0]);

        dispatch({
          type: ACTIONS.SET_QUOTATION,
          payload: quotations[0],
        });
      });
    let vehicleUnsubscribe = db
      .doc(`prospectsNew/${userUID}/vehicles/${vehicleUID}`)
      .onSnapshot(async (snapshot) => {
        dispatch({
          type: ACTIONS.SET_DATA,
          payload: {
            key: "vehicleData",
            value: { ...snapshot.data(), uid: snapshot.id },
          },
        });
        // console.log("Vehicle data", { ...snapshot.data(), uid: snapshot.id });

        dispatch({
          type: ACTIONS.SET_DATA,
          payload: {
            key: "isLoading",
            value: false,
          },
        });
      });
    let userUnsubscribe = db
      .doc(`prospectsNew/${userUID}`)
      .onSnapshot((snapshot) => {
        const data = { msi: false, ...snapshot.data(), uid: snapshot.id };
        if (!data["period"]) {
          data["period"] = "year";
        }
        dispatch({
          type: ACTIONS.SET_DATA,
          payload: {
            key: "userData",
            value: data,
          },
        });
      });

    return () => {
      quotationUnsubscribe();
      vehicleUnsubscribe();
      userUnsubscribe();
    };
  }, [userUID, vehicleUID]);

  async function updateUser(update) {
    try {
      return await db.doc(`prospectsNew/${userUID}`).update(update);
    } catch (error) {
      console.error(error);
    }
  }

  const addExtraValueHandler = (e, extraType, agentData) => {
    const value = parseInt(e.target.value);
    const isAgent =
      agentData.promotoria === "2407SEP" || agentData.agentGroup === "2407SEP";
    const extras = {
      motorcycleHelmet: {
        name: "motorcycleHelmet",
        message: `Sólo podemos asegurar tu casco por hasta $${
          isAgent ? "10,000" : "6,000"
        }`,
        topValue: isAgent ? 10000 : 6000,
      },
      personalAccessories: {
        name: "personalAccessories",
        message: `Sólo podemos asegurar tus accesorios por hasta $${
          isAgent ? "20,000" : "10,000"
        }`,
        topValue: isAgent ? 20000 : 10000,
      },
    };

    if (value > extras[extraType].topValue) {
      return Swal.fire("", extras[extraType].message, "warning");
    }

    handleExtraItemChange({
      action: Boolean(value) ? "addItem" : "removeItem",
      payload: {
        extraItemName: extraType,
        info: { value: value },
      },
    });
  };

  async function handleNextScreen() {
    try {
      await db.doc(`prospectsNew/${userUID}`).update({
        // The premium property must be the total premium
        premium: getTotalPremium(currentPremium, userData.period, {
          msi: userData.msi,
          aveDiscount: userData?.haveADiscountCoupon,
          discount: userData?.discount,
        })[0],
        qStatus: 2,
      });
      await db.doc(`prospectsNew/${userUID}/vehicles/${vehicleUID}`).update({
        amount: currentPremium,
        // qStatus: 2,
      });

      if (!isAgentLogged) {
        // Register event in Google Tag Manager
        const gtmEvent = {
          event: "extras",
          type: vehicleData.type,
          brand: vehicleData.brand,
          model: vehicleData.model,
          year: vehicleData.year,
        };

        (vehicleData.extras || []).forEach((extra) => {
          gtmEvent[extra.type] = extra;
        });
        window.dataLayer.push(gtmEvent);
      }

      await addInterestTag(userData.email, "interested3");

      registerInterest({
        email: userData.email || "",
        brand: vehicleData.brand || "",
        model: vehicleData.model || "",
        tag: "interested3",
        uid: userUID,
        year: vehicleData.year || "",
        type: vehicleData.type || "",
      });
      // console.log("Updated premium & amount");
      redirect("/contrata");
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: `Ha ocurrido un error, inténtalo de nuevo.
                  ${error}`,
      });
      function parseObject(object) {
        return Object.keys(object)
          .map((key) => {
            return [key, object[key]];
          })
          .reduce((prev, current) => {
            const [key, value] = current;
            prev[key] = value ?? String(value);
            return prev;
          }, {});
      }

      await logError({
        error: String(error),
        metadata: {
          description: "Error al continuar cotizacion desde elegir extras",
          uid: userUID,
          quoteData: parseObject(quotationData),
          vehicleData: parseObject(vehicleData),
        },
      });
    }
  }

  const currentPremium =
    quotationData &&
    quotationData[getPremiumPropertyName(vehicleData?.mobility)];

  const currentYear = new Date().getFullYear();

  // track how many years the car has been used until this year;
  const vehicleUsageInYears = currentYear - vehicleData?.year;

  //extra available only for vehicles between 3 & 5 years of usage
  const isAgencyRepairAvailable =
    vehicleUsageInYears >= 3 && vehicleUsageInYears <= 5;

  //extra available only for vehicles between 0 & 3 years of usage
  const isInvoicePaymentAvailable =
    // Compare to -1 so that it is available for vehicles with 1 year
    // ahead of the current year
    vehicleUsageInYears >= -1 && vehicleUsageInYears <= 3;

  async function onSmartphoneChange(isSmartphoneCovered) {
    try {
      // display loading bar
      dispatch({ type: ACTIONS.TOGGLE_GENERATE_QUOTATION });

      //Update vehicle info so the CF can take that info to generate a new quotation record
      var pVNew = userData?.smartphoneAmount ? userData.smartphoneAmount : 5000;
      const x = parseInt(vehicleData.valueUser * 0.03);
      if (!userData?.smartphoneAmount) {
        if (x > 5000) {
          if (x < 30000) {
            pVNew = x;
          } else {
            pVNew = 30000;
          }
        }
      }

      let update = {};
      let updateVehicleData = {};
      updateVehicleData["smartphoneCovered"] = isSmartphoneCovered;
      updateVehicleData["smartphoneAmount"] = isSmartphoneCovered
        ? userData.smartphoneAmount === 0
          ? Number(pVNew)
          : userData.smartphoneAmount
        : 0;
      update["smartphoneCovered"] = isSmartphoneCovered;
      update["smartphoneAmount"] = isSmartphoneCovered
        ? userData.smartphoneAmount === 0
          ? pVNew
          : userData.smartphoneAmount
        : userData.smartphoneAmount;

      if (!isAgentLogged) {
        // Register event in Google Tag Manager
        const gtmEvent = {
          event: isSmartphoneCovered ? "agregarCelular" : "eliminarCelular",
          active: isSmartphoneCovered,
          value: update["smartphoneAmount"],
        };
        window.dataLayer.push(gtmEvent);
      }
      await db.doc(`prospectsNew/${userUID}`).update(update);
      await db
        .doc(`prospectsNew/${userUID}/vehicles/${vehicleData.uid}`)
        .update(updateVehicleData);
      // generate quotation record
      // This will trigger the onSnapshot listener of the quotationRecord collection
      // with the updatedInformation
      await generateQuotation({ userUID, vehicleData });
    } catch (error) {
      console.error("Error updating the user info", error);
    }
  }

  function onCloseModal() {}
  async function handleExtraItemChange({ action, payload }) {
    // console.log({ action, payload });
    dispatch({ type: ACTIONS.TOGGLE_GENERATE_QUOTATION });

    if (!isAgentLogged) {
      // Register event in Google Tag Manager
      const gtmEvent = {
        event:
          action === "addItem"
            ? `agregar${
                payload.extraItemName.charAt(0).toUpperCase() +
                payload.extraItemName.slice(1)
              }`
            : `eliminar${
                payload.extraItemName.charAt(0).toUpperCase() +
                payload.extraItemName.slice(1)
              }`,
        type: payload.extraItemName,
        ...(payload?.info?.list &&
          payload?.info?.list[0].value && {
            value: payload?.info?.list[0].value,
          }),
        ...(payload?.info?.value && { value: payload?.info?.value }),
        ...(payload?.info?.amount && { units: payload?.info?.amount }),
      };
      window.dataLayer.push(gtmEvent);
    }
    try {
      let updatedExtras = vehicleData.extras ? [...vehicleData.extras] : [];
      // console.log({ extras: vehicleData.extras });
      switch (action) {
        case "addItem":
          // if the item exist add it
          // else update it
          if (
            !updatedExtras.find((extra) => extra.type === payload.extraItemName)
          ) {
            updatedExtras = [
              ...updatedExtras,
              {
                type: payload.extraItemName,
                ...payload.info,
              },
            ];
          } else {
            updatedExtras = updatedExtras.map((extra) => {
              if (extra.type === payload.extraItemName) {
                // console.log("Update");
                return {
                  ...extra,
                  ...payload.info,
                };
              }
              return extra;
            });
          }
          break;
        case "removeItem":
          updatedExtras = updatedExtras.filter(
            (extra) => extra.type !== payload.extraItemName
          );
          break;

        default:
          console.warn("Extras did not changed");
          break;
      }
      // console.log({ updatedExtras });

      //Update vehicle info so the CF can take that info to generate a new quotation record
      await db.doc(`prospectsNew/${userUID}/vehicles/${vehicleUID}`).update({
        extras: updatedExtras,
      });
      // generate quotation record
      // This will trigger the onSnapshot listener of the quotationRecord collection
      // with the updatedInformation
      await generateQuotation({ userUID, vehicleData });
    } catch (error) {
      console.error("Error updating the user info", error);
    }
  }
  const vehicleExtras = getVehicleExtras();

  function getVehicleExtras() {
    let extras = {};
    if (!vehicleData || !vehicleData?.extras) return;
    vehicleData.extras.forEach((extra) => {
      extras[extra.type] = extra;
    });
    return extras;
  }

  function getVehiclePremium(quotationData, currentPremium) {
    if (!quotationData || !currentPremium) return;
    const PREMIUMS = [
      "premiumAgencyRepair",
      "premiumEquipment",
      "premiumGlassBreak",
      "premiumHelmet",
      "premiumInvoiceValue",
      "premiumMedicalExpenses",
      "premiumAccesories",
      "spPremium",
    ];

    // the currentPremium represents the total premium amount
    let vehiclePremium = currentPremium;

    PREMIUMS.forEach((premium) => {
      // some premiums can be undefined
      // make sure the premium exists to avoid getting NaN as the result
      if (quotationData[premium]) {
        vehiclePremium -= quotationData[premium];
      }
    });

    return vehiclePremium;
  }

  function handleCloseExtraModal() {
    setIsOpenModal(false);
  }

  function handleCloseDialog() {
    setOpenQuotationModal(false);
  }

  console.log(vehicleExtras);
  // get the vehiclePremium by substracting each extra
  // and smartphone premiums from the totalPremium
  const vehiclePremium = getVehiclePremium(quotationData, currentPremium);

  // console.log({ currentPremium, vehiclePremium, vehicleExtras });
  const mobileDevice =
    mobileVendor !== "none" && mobileModel !== "none"
      ? `${mobileVendor} ${mobileModel}`
      : "celular";

  if (state.isLoading || !vehicleData || !quotationData || !userData)
    return <LoadingOverlay isOpen={state.isGeneratingQuotation} />;

  const discount = userData?.msi
    ? 6
    : userData?.haveADiscountCoupon
    ? userData.discount.percentage
    : PREMIUM_DISCOUNTS[userData.period];

  async function handleChangeVehicleValue(e) {
    const value = Number(e.target.value);
    if (value > 0)
      await db.doc(`prospectsNew/${userUID}/vehicles/${vehicleUID}`).update({
        valueUser: Number(e.target.value),
      });
  }

  function handleUploadVehicleInvoice(e) {
    e.preventDefault();
    const file = e.target.files[0];

    if (file) {
      const task = storage
        .ref(`prospectsNew/${userUID}/vehicles/${vehicleUID}`)
        .put(file);
      task.then(async (snapshot) => {
        const url = await snapshot.ref.getDownloadURL();
        await db.doc(`prospectsNew/${userUID}/vehicles/${vehicleUID}`).update({
          invoicePDF: url,
        });

        setInvoice((prev) => ({
          ...prev,
          invoicePDF: url,
        }));
      });
    }
  }

  async function handleSubmitInvoiceValueExtraForm() {
    const invoiceValue = Number(document.getElementById("invoiceValue").value);

    if (!isAgentLogged) {
      if (!invoicePDF) {
        Swal.fire("", "Por favor, carga la factura de tu vehículo.", "info");
        return;
      }
    }

    if (invoiceValue > 0) {
      await db.doc(`prospectsNew/${userUID}/vehicles/${vehicleUID}`).update({
        valueUser: invoiceValue,
      });
      handleExtraItemChange({
        action: "addItem",
        payload: {
          extraItemName: "invoiceValuePayment",
        },
      });
    } else {
      Swal.fire("", "El valor de la factura debe ser mayor a $0", "info");
    }
    setInvoice((prev) => ({
      ...prev,
      isInvoiceModalOpen: false,
    }));
  }
  return (
    <Fragment>
      <DialogOfExtras isOpen={isOpenModal} onClose={handleCloseExtraModal} />

      {!isWowUser && (
        <>
          <Menu />
          <Header />
        </>
      )}

      <LoadingOverlay isOpen={state.isGeneratingQuotation} />
      <div className="cotizar adjusted-height">
        <Stepper step={2} />
        <h3>Agrega coberturas adicionales (opcional): </h3>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem 1rem 4rem 1rem",
            color: "#7d7d7d",
            gap: "2rem",
            paddingBottom: "120px",
          }}
        >
          {vehicleData && quotationData && userData && (
            <Fragment>
              <ExtraItemField
                disabled={true}
                label={`Seguro para tu ${vehicleData.brand} ${vehicleData.model} ${vehicleData.year}`}
                checked={true}
                price={
                  currentPremium &&
                  userData?.period &&
                  getExtraItemPriceDescription(userData?.period, vehiclePremium)
                }
              />
              {!isWowUser && (
                <ExtraItemField
                  message={
                    <Fragment>
                      Cubre tu {mobileDevice} por hasta{" "}
                      <b>{formatPremium(userData.smartphoneAmount)} </b>
                      ante robo con violencia. Nuestro seguro es el único en el
                      mercado SIN DEDUCIBLE siempre y cuando tengas activado un
                      trayecto en nuestra app al momento del robo. De otro modo,
                      aplica un deducible del 40%.`
                    </Fragment>
                  }
                  disabled={state.isGeneratingQuotation}
                  label={
                    mobileVendor !== "none" && mobileModel !== "none"
                      ? `Seguro para tu ${mobileDevice}`
                      : "Seguro para tu celular"
                  }
                  checked={vehicleData.smartphoneCovered}
                  onChange={(event) => onSmartphoneChange(event.target.checked)}
                  price={
                    quotationData &&
                    userData?.period &&
                    getExtraItemPriceDescription(
                      userData?.period,
                      quotationData.spPremium
                    )
                  }
                />
              )}

              {isAgencyRepairAvailable && (
                <ExtraItemField
                  message="Garantiza reparar en la agencia automotriz correspondiente los daños materiales de tu vehículo en caso de accidente."
                  disabled={state.isGeneratingQuotation}
                  label="Reparación en agencia"
                  checked={vehicleExtras?.agencyRepair}
                  onChange={(event) => {
                    const { checked } = event.target;

                    handleExtraItemChange({
                      action: checked ? "addItem" : "removeItem",
                      payload: {
                        extraItemName: "agencyRepair",
                      },
                    });
                  }}
                  price={
                    quotationData &&
                    userData?.period &&
                    getExtraItemPriceDescription(
                      userData?.period,
                      quotationData?.premiumAgencyRepair
                    )
                  }
                />
              )}

              {isInvoicePaymentAvailable && (
                <>
                  <Dialog
                    isOpen={isInvoiceModalOpen}
                    onClose={() =>
                      setInvoice((prev) => ({
                        ...prev,
                        isInvoiceModalOpen: false,
                      }))
                    }
                    title="Pago a valor factura"
                    content={
                      <Stack gap={2}>
                        <Typography>
                          Garantiza que el valor cubierto de tu vehículo en las
                          coberturas de daños materiales y robo sea igual al
                          monto de la factura de origen (
                          <b>{formatPremium(vehicleData.valueUser)}</b>),
                          siempre que hayas contratado con dicho monto.
                        </Typography>

                        <TextField
                          size="small"
                          required
                          variant="outlined"
                          label="Valor de la factura"
                          type="number"
                          defaultValue={vehicleData.valueUser}
                          onBlur={handleChangeVehicleValue}
                          id="invoiceValue"
                        />

                        <Alert severity="info">
                          Para que la cobertura sea válida, el cliente deberá de
                          cargar la factura del vehículo.
                        </Alert>

                        <Typography>
                          Por favor, carga la factura de tu vehículo.
                        </Typography>

                        <input
                          type="file"
                          required
                          accept=".pdf"
                          onInput={handleUploadVehicleInvoice}
                        />

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmitInvoiceValueExtraForm}
                        >
                          Aceptar
                        </Button>
                      </Stack>
                    }
                  />
                  <ExtraItemField
                    controlled
                    message={
                      <Typography>
                        Garantiza que el valor cubierto de tu vehículo en las
                        coberturas de daños materiales y robo sea igual al monto
                        de la factura de origen (
                        <b>{formatPremium(vehicleData.valueUser)}</b>), siempre
                        que hayas contratado con dicho monto.
                      </Typography>
                    }
                    disabled={state.isGeneratingQuotation}
                    label="Pago a valor factura"
                    checked={Boolean(vehicleExtras?.invoiceValuePayment)}
                    onChange={(event) => {
                      const { checked } = event.target;

                      const action = checked ? "addItem" : "removeItem";

                      if (action === "addItem") {
                        setInvoice((prev) => ({
                          ...prev,
                          isInvoiceModalOpen: true,
                        }));
                      } else {
                        handleExtraItemChange({
                          action: "removeItem",
                          payload: {
                            extraItemName: "invoiceValuePayment",
                          },
                        });
                      }
                    }}
                    price={
                      quotationData &&
                      userData &&
                      getExtraItemPriceDescription(
                        userData?.period,
                        quotationData?.premiumInvoiceValue
                      )
                    }
                  />
                </>
              )}

              {["auto", "moto"].includes(vehicleData.type) && (
                <ExtraItemField
                  message="Aumenta $200,000 al valor cubierto de la cobertura de gastos médicos ocupantes. Aplica por reembolso."
                  checked={Boolean(vehicleExtras?.extraMedicalExpensesUnits)}
                  dialogTitle="$200,000 adicionales en gastos médicos"
                  label="$200,000 adicionales en gastos médicos a ocupantes"
                  price={
                    quotationData &&
                    userData &&
                    getExtraItemPriceDescription(
                      userData?.period,
                      quotationData?.premiumMedicalExpenses
                    )
                  }
                  onChange={(event) => {
                    const { checked } = event.target;
                    handleExtraItemChange({
                      action: checked ? "addItem" : "removeItem",
                      payload: {
                        extraItemName: "extraMedicalExpensesUnits",
                        info: { amount: 2 },
                      },
                    });
                  }}
                >
                  {({ checked }) =>
                    checked && (
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <Typography style={{ textAlign: "left" }}>
                            Unidades:{" "}
                          </Typography>
                          <Box style={{ display: "inline-flex" }}>
                            <CounterField
                              modifyCouter={2}
                              increaseCounter={2}
                              value={
                                vehicleExtras?.extraMedicalExpensesUnits
                                  ?.amount || 0
                              }
                              onChange={(value) => {
                                if (value > 18) {
                                  Swal.fire(
                                    "",
                                    "Puedes agregar unidades adicionales hasta los 2 millones de pesos.",
                                    "info"
                                  );
                                  return;
                                }
                                handleExtraItemChange({
                                  action:
                                    value === 0 ? "removeItem" : "addItem",
                                  payload: {
                                    extraItemName: "extraMedicalExpensesUnits",
                                    info: { amount: value },
                                  },
                                });
                              }}
                            />
                          </Box>
                        </Box>

                        <Typography style={{ textAlign: "left" }}>
                          Cobertura total:{" "}
                          {formatPremium(
                            (vehicleExtras?.extraMedicalExpensesUnits?.amount ||
                              0) *
                              100000 +
                              200000
                          )}
                        </Typography>
                      </Box>
                    )
                  }
                </ExtraItemField>
              )}

              {/* {vehicleData.type === "auto" && (
                <ExtraItemField
                  message="Cubre el cambio y/o reparación de los cristales por rotura accidental. Aplica un 20% de deducible del costo de reparación."
                  disabled={state.isGeneratingQuotation}
                  label="Rotura de cristales"
                  checked={vehicleExtras?.glassBreakage}
                  onChange={(event) => {
                    const { checked } = event.target;
                    handleExtraItemChange({
                      action: checked ? "addItem" : "removeItem",
                      payload: {
                        extraItemName: "glassBreakage",
                      },
                    });
                  }}
                  price={
                    quotationData &&
                    userData &&
                    getExtraItemPriceDescription(
                      userData?.period,
                      quotationData?.premiumGlassBreak
                    )
                  }
                />
              )} */}

              {vehicleData.type !== "auto" && (
                <Fragment>
                  <ExtraItemField
                    message={
                      <Typography>
                        Cubre la reposición de tu casco en caso de que se dañe
                        en un accidente automovilístico. <br />
                        Se requiere factura original del equipo a cubrir
                      </Typography>
                    }
                    // {Boolean(vehicleExtras?.motorcycleHelmet) && (
                    //   <>
                    //     <br />
                    //     <br />
                    //     Para contratar esta cobertura adicional es necesario
                    //     que cuentes con la factura original del equipo a
                    //     cubrir
                    //   </>
                    // )}
                    disabled={state.isGeneratingQuotation}
                    label="Cobertura para casco"
                    price={
                      quotationData &&
                      userData &&
                      getExtraItemPriceDescription(
                        userData?.period,
                        quotationData?.premiumHelmet
                      )
                    }
                    checked={Boolean(vehicleExtras?.motorcycleHelmet)}
                    onChange={(event) => {
                      const { checked } = event.target;
                      if (checked === true) {
                        setIsOpenModal(true);
                      }

                      handleExtraItemChange({
                        action: checked ? "addItem" : "removeItem",
                        payload: {
                          // when adding the item set its value by default to 2000
                          extraItemName: "motorcycleHelmet",
                          info: { value: 2000 },
                        },
                      });
                    }}
                  >
                    {({ checked }) =>
                      checked && (
                        <TextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          label="Valor"
                          size="small"
                          type="number"
                          helperText="Ingresa el valor de tu casco"
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              e.target.blur();
                            }
                          }}
                          defaultValue={vehicleExtras?.motorcycleHelmet?.value}
                          onBlur={(e) =>
                            addExtraValueHandler(
                              e,
                              "motorcycleHelmet",
                              agentData
                            )
                          }
                        />
                      )
                    }
                  </ExtraItemField>

                  <ExtraItemField
                    message={
                      <Typography>
                        Cubre la reposición de tus accesorios personales en caso
                        de que se dañen en un accidente automovilístico.
                        <br />
                        Se requiere factura original del equipo a cubrir.
                      </Typography>
                    }
                    // {Boolean(vehicleExtras?.personalAccessories) && (
                    //   <>
                    //     <br />
                    //     <br />
                    //     Para contratar esta cobertura adicional es necesario
                    //     que cuentes con la factura original del equipo a
                    //     cubrir
                    //   </>
                    // )}
                    disabled={state.isGeneratingQuotation}
                    label="Cobertura para accesorios personales"
                    price={
                      quotationData &&
                      userData &&
                      getExtraItemPriceDescription(
                        userData?.period,
                        quotationData?.premiumAccesories
                      )
                    }
                    checked={Boolean(vehicleExtras?.personalAccessories)}
                    onChange={(event) => {
                      const { checked } = event.target;
                      if (checked === true) {
                        setIsOpenModal(true);
                      }

                      handleExtraItemChange({
                        action: checked ? "addItem" : "removeItem",
                        payload: {
                          // when adding the item set its value by default to 2000
                          extraItemName: "personalAccessories",
                          info: { list: [{ value: 2000 }] },
                        },
                      });
                    }}
                  >
                    {({ checked }) =>
                      checked && (
                        <TextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          label="Valor"
                          size="small"
                          type="number"
                          helperText="Ingresa el valor de tus accesorios"
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              e.target.blur();
                            }
                          }}
                          defaultValue={
                            vehicleExtras?.personalAccessories?.list &&
                            vehicleExtras?.personalAccessories?.list["0"]?.value
                          }
                          onBlur={(e) =>
                            addExtraValueHandler(
                              e,
                              "personalAccessories",
                              agentData
                            )
                          }
                        />
                      )
                    }
                  </ExtraItemField>
                </Fragment>
              )}
              <Fragment>
                <ExtraItemField
                  message={
                    <Typography>
                      Cubre el equipo especial instalado en tu vehículo en caso
                      de que se dañe en un accidente automovilístico.
                      <br />
                      Se requiere factura original del equipo a cubrir
                    </Typography>
                  }
                  disabled={state.isGeneratingQuotation}
                  label="Cobertura para equipo especial"
                  price={
                    quotationData &&
                    userData &&
                    getExtraItemPriceDescription(
                      userData?.period,
                      quotationData?.premiumEquipment
                    )
                  }
                  checked={Boolean(vehicleExtras?.specialEquipment)}
                  onChange={(event) => {
                    const { checked } = event.target;
                    if (checked === true) {
                      setIsOpenModal(true);
                    }

                    handleExtraItemChange({
                      action: checked ? "addItem" : "removeItem",
                      payload: {
                        // when adding the item set its value by default to 2000
                        extraItemName: "specialEquipment",
                        info: { list: [{ value: 2000 }] },
                      },
                    });
                  }}
                >
                  {({ checked }) =>
                    checked && (
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        label="Valor"
                        size="small"
                        type="number"
                        helperText="Ingresa el valor del equipo especial de tu vehículo"
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            e.target.blur();
                          }
                        }}
                        defaultValue={
                          vehicleExtras?.specialEquipment?.list &&
                          vehicleExtras?.specialEquipment?.list["0"]?.value
                        }
                        value={extrasState}
                        onChange={(e) => setExtrasState(e.target.value)}
                        onBlur={(e) => {
                          let value = parseInt(e.target.value);
                          if (e.target.value > 50000) {
                            Swal.fire({
                              icon: "warning",
                              text: "Solo podemos asegurar tu equipo especial por hasta $50,000",
                            });
                            value = 50000;
                          }
                          handleExtraItemChange({
                            action: Boolean(value) ? "addItem" : "removeItem",
                            payload: {
                              extraItemName: "specialEquipment",
                              info: { list: [{ value }] },
                            },
                          });
                          setExtrasState(value);
                        }}
                      />
                    )
                  }
                </ExtraItemField>
              </Fragment>
              {(userData?.haveADiscountCoupon ||
                userData?.period !== "month") && (
                <Fragment>
                  <ExtraItemField
                    label="Subtotal"
                    hideCheckbox
                    price={
                      userData &&
                      currentPremium &&
                      getTotalPremium(currentPremium, userData?.period, {
                        msi: userData.msi,
                        haveDiscount: userData?.haveADiscountCoupon,
                        discount: userData?.discount,
                      }) &&
                      formatPremium(
                        getTotalPremium(currentPremium, userData?.period, {
                          msi: userData.msi,
                          haveDiscount: userData?.haveADiscountCoupon,
                          discount: userData?.discount,
                        })[2]
                      )
                    }
                  />
                  <ExtraItemField
                    label={`Descuento ${
                      userData?.discount?.name || ""
                    } (${discount}%)`}
                    hideCheckbox
                    price={
                      userData &&
                      currentPremium &&
                      getTotalPremium(currentPremium, userData?.period, {
                        msi: userData.msi,
                        haveDiscount: userData?.haveADiscountCoupon,
                        discount: userData?.discount,
                      }) &&
                      formatPremium(
                        getTotalPremium(currentPremium, userData?.period, {
                          msi: userData.msi,
                          haveDiscount: userData?.haveADiscountCoupon,
                          discount: userData?.discount,
                        })[1]
                      )
                    }
                  />
                </Fragment>
              )}

              <Divider />
              <Box
                style={{
                  color: "black",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {currentPremium && userData && (
                  <PaymentDescription
                    msi={userData.msi}
                    haveDiscount={userData?.haveADiscountCoupon}
                    discount={userData?.discount}
                    paymentPeriod={userData?.period}
                    totalPremium={currentPremium}
                  />
                )}
              </Box>
            </Fragment>
          )}
        </Box>
        <EnviarCotizacion
          onSumbit={() => setOpenQuotationModal((prev) => !prev)}
          isOpen={openQuotationModal}
          onClose={handleCloseDialog}
          email={userData?.email}
          phone={userData?.phone}
          userData={userData}
          vehicleData={vehicleData}
          onEmailChange={async (email) => {
            await updateUser({ email });
            if (!!email) {
              // add the email to the local storage to be able to access it in the
              // /contratar page
              // ????
              localStorage.setItem("email", email);
            }
          }}
          onPhoneChange={async (phone) => {
            localStorage.setItem("phone", phone);
            await updateUser({ phone });
          }}
        />
      </div>
      <BotonPrincipal
        tipo={`${
          currentPremium && userData?.period
            ? `${formatPremium(
                getTotalPremium(currentPremium, userData?.period, {
                  msi: userData.msi,
                  haveDiscount: userData?.haveADiscountCoupon,
                  discount: userData?.discount,
                })[0]
              )}`
            : ""
        } Continuar`}
        back="/elegir-plan"
        func={handleNextScreen}
      />
    </Fragment>
  );
};

export default Extras;

function DialogOfExtras({ isOpen, onClose }) {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <Button onClick={onClose} variant="contained">
          Aceptar
        </Button>
      }
      content={
        <Typography>
          Para contratar esta cobertura adicional es necesario que cuentes con
          la factura original del equipo a cubrir
        </Typography>
      }
    />
  );
}
