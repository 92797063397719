import "./Msi.css";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useRef, useState } from "react";
import Swal from "sweetalert2";
import { Stack } from "@mui/material";
import useWhatsAppNumber from "../../hooks/useWhatsAppNumber";

const Msi = (props) => {
  const {
    primaf,
    msi,
    setMsi,
    msi12,
    msi9,
    msi6,
    msi3,
    setMsi12,
    setMsi9,
    setMsi6,
    setMsi3,
    uidMSI,
    emailMSI,
    customerMSI,
    spPremiumMSI,
    rfcMSI,
  } = props;
  const whatsAppNumber = useWhatsAppNumber();
  const stripe = useStripe();
  const elements = useElements();

  const cardholderName = useRef();
  console.log("Render Msi", { props });
  const [loading, setLoading] = useState(false);

  let availablePlans = [];
  const [AP, setAP] = useState([]);

  const handleMsi = (event) => {
    setMsi(event.target.value);
  };

  function confirmPayment(evt) {
    console.log("confirmPayment Click");
    setLoading(true);
    console.log(availablePlans);
    console.log(AP);
    if (msi === "0") {
      setLoading(false);
      Swal.fire("", "Selecciona un plan", "warning");
      return;
    }
    var selectedPlanIdx;
    switch (msi) {
      case "12":
        selectedPlanIdx = 3;
        console.log("msi=" + msi);
        break;
      case "9":
        selectedPlanIdx = 2;
        console.log("msi=" + msi);
        break;
      case "6":
        selectedPlanIdx = 1;
        console.log("msi=" + msi);
        break;
      case "3":
        selectedPlanIdx = 0;
        console.log("msi=" + msi);
        break;
      default:
        break;
    }
    console.log("spIDX=" + selectedPlanIdx);
    console.log("AP=" + AP);
    const selectedPlan = AP[selectedPlanIdx];
    console.log("SP=" + selectedPlan);
    const intentId = document.getElementById("payment-intent-id").value;

    fetch(
      "https://us-central1-auto-clupp.cloudfunctions.net/app/confirm_payment",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          payment_intent_id: intentId,
          selected_plan: selectedPlan,
        }),
      }
    ).then(function (result) {
      result.json().then(function (json) {
        var message;
        const vPremium = primaf - spPremiumMSI;
        //setLoading(false)
        if (json.status === "succeeded" && selectedPlan !== undefined) {
          message = " Se ha realizado el cargo a tu tarjeta a " + msi + " MSI.";
          Swal.fire("¡Pago exitoso!", message, "success");
          window.location.replace(
            "https://us-central1-auto-clupp.cloudfunctions.net/app/setup-user-after-payment-new2?uid=" +
              uidMSI +
              "&premiumAmount=" +
              primaf +
              "&email=" +
              emailMSI +
              "&customerId=" +
              customerMSI +
              "&period=year&vPremium=" +
              vPremium +
              "&spPremium=" +
              spPremiumMSI +
              "&msi=true&rfc=" +
              rfcMSI
          );
        } else if (json.status === "succeeded") {
          message = "(No se cargaron MSI)";
          Swal.fire("¡Pago exitoso!", message, "success");

          window.location.replace(
            "https://us-central1-auto-clupp.cloudfunctions.net/app/setup-user-after-payment-new2?uid=" +
              uidMSI +
              "&premiumAmount=" +
              primaf +
              "&email=" +
              emailMSI +
              "&customerId=" +
              customerMSI +
              "&period=year&vPremium=" +
              vPremium +
              "&spPremium=" +
              spPremiumMSI +
              "&msi=true&rfc=" +
              rfcMSI
          );
        } else {
          message =
            "Parece que tu tarjeta fue declinada por el banco. Repórtanos este error escribiéndonos un whatsapp a nuestra área de soporte técnico y te atenderemos de inmediato. +52" +
            whatsAppNumber;
          Swal.fire("¡Ups!", message, "error");
        }
        setLoading(false);

        document.getElementById("status-message").innerText = message;
      });
    });
    // document.getElementById("subtitle-msi").hidden = true;
    // document.getElementById("plans").hidden = true;
    // document.getElementById("result").hidden = false;
  }

  const handleInstallmentPlans = async (response) => {
    //setLoading(false)
    if (response.error) {
      console.log("Error handleInstallPlans: " + response.error);
    } else {
      // Store the payment intent ID.
      document.getElementById("payment-intent-id").value = response.intent_id;
      availablePlans = response.available_plans;
      console.log("avPlans: " + availablePlans);
      setAP(availablePlans);
      if (availablePlans.length === 0) {
        document.getElementById("status-message").innerText =
          "¡Ups! Tu banco no devolvió opciones para pagar a meses sin intereses.";
        document.getElementById("result").hidden = false;
        document.getElementById("confirm-button2").hidden = true;
      } else {
        const highestPlan = availablePlans.reduce(
          (highestPlan, currentPlan) =>
            highestPlan > currentPlan.count ? highestPlan : currentPlan.count,
          0
        );
        setMsi(String(highestPlan));
        console.log("avPlansLength: " + availablePlans.length);
        console.log("avPlans: " + availablePlans);
        document.getElementById("plans").hidden = false;
        document.getElementById("subtitle-msi").hidden = false;
      }
      // Show available installment options
      //const selectPlanForm = document.getElementById('installment-plan-form')
      availablePlans.forEach((plan, idx) => {
        switch (Math.ceil(plan.count)) {
          case 12:
            setMsi12(true);
            break;
          case 9:
            setMsi9(true);
            break;
          case 6:
            setMsi6(true);
            break;
          case 3:
            setMsi3(true);
            break;
          default:
            break;
        }
        console.log(
          "plan: " + plan.count + " interval: " + plan.interval + " idx: " + idx
        );
      });
      document.getElementById("details").hidden = true;
    }
  };

  async function handleSubmitCard(event) {
    event.preventDefault();
    setLoading(true);
    if (cardholderName.current?.value?.trim() === "") {
      Swal.fire("", "Debes ingresar un nombre válido.", "warning");
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    return stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: { name: cardholderName.current.value },
      })
      .then(function (result) {
        if (result.error) {
          console.log("Error creando payment: ", result.error);
          Swal.fire(
            "Error con tu método de pago",
            result.error.message,
            "error"
          );
          setLoading(false);
        } else {
          console.log("Result", { result });
          collectPaymentDetails(result.paymentMethod.id);
          // Otherwise send paymentMethod.id to your server (see Step 2)
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("Error creando payment method: ", error);
      });
  }

  console.log("Data in Msi", { primaf, customerMSI });
  async function collectPaymentDetails(paymentMethodId) {
    console.log("Collect Details");
    console.log({ primaf, customerMSI });

    return fetch(
      "https://us-central1-auto-clupp.cloudfunctions.net/app/collect_details",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          payment_method_id: paymentMethodId,
          payment_amount: primaf,
          customer: customerMSI,
        }),
      }
    )
      .then(function (result) {
        result.json().then(function (json) {
          console.log("Installements json", { json });
          handleInstallmentPlans(json);
        });
      })
      .catch((error) => {
        Swal.fire(
          "Ups!",
          "Hubo un error al procesar tu método de pago. Inténtalo más tarde.",
          "error"
        );
        console.log("Error llamando collect_details: ", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <p className="title-msi">Pago a Meses sin Intereses (MSI)</p>
      <br />
      <div id="subtitle-msi" hidden>
        <p className="subtitle-msi">
          Las opciones que tenemos disponibles para tu banco son las siguientes:{" "}
        </p>
      </div>
      <div className="msi-container">
        <div id="details" style={{ width: "100%" }}>
          <TextField
            size="small"
            disabled={loading}
            fullWidth
            placeholder="Nombre en la tarjeta"
            type="text"
            inputRef={cardholderName}
            inputProps={{
              id: "cardholder-name",
            }}
          />

          <br />
          <br />
          <form id="payment-form" onSubmit={handleSubmitCard}>
            <Stack spacing={3}>
              <Box
                sx={{
                  border: "1px solid #c4c4c4",
                  padding: "10px",
                  borderRadius: "5px",
                }}
              >
                <CardElement />
              </Box>
              <LoadingButton
                loading={loading}
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  borderRadius: "10px",
                }}
              >
                Continuar a MSI
              </LoadingButton>
            </Stack>
          </form>
        </div>
        <br />
        <div id="plans" hidden>
          <form id="installment-plan-form" hidden>
            <label>
              <input
                id="immediate-plan"
                type="radio"
                name="installment_plan"
                value="-1"
              />
              Pago sin MSI
            </label>

            <input id="payment-intent-id" type="hidden" />
          </form>
          {msi === "12" && msi12 ? (
            <div>
              <label>
                <div className="container-msi fondo-msi">
                  <div className="radio-msi">
                    <RadioGroup value={msi} onChange={handleMsi}>
                      <div className="tipo">
                        <FormControlLabel
                          value="12"
                          control={<Radio />}
                          name="pago"
                        />
                      </div>
                    </RadioGroup>
                  </div>

                  <div className="text-msi">
                    <p>
                      12 Meses Sin Intereses ($
                      {Math.ceil(primaf / 12).toLocaleString("en-US")}/mes)
                    </p>
                  </div>
                </div>
              </label>
            </div>
          ) : msi12 ? (
            <div>
              <label>
                <div className="container-msi fondo-msi2">
                  <div className="radio-msi">
                    <RadioGroup value={msi} onChange={handleMsi}>
                      <div className="tipo">
                        <FormControlLabel
                          value="12"
                          control={<Radio />}
                          name="pago"
                        />
                      </div>
                    </RadioGroup>
                  </div>

                  <div className="text-msi2">
                    <p>
                      12 Meses Sin Intereses ($
                      {Math.ceil(primaf / 12).toLocaleString("en-US")}/mes)
                    </p>
                  </div>
                </div>
              </label>
            </div>
          ) : (
            <div> </div>
          )}

          {msi === "9" && msi9 ? (
            <div>
              <label>
                <div className="container-msi fondo-msi">
                  <div className="radio-msi">
                    <RadioGroup value={msi} onChange={handleMsi}>
                      <div className="tipo">
                        <FormControlLabel
                          value="9"
                          control={<Radio />}
                          name="pago"
                        />
                      </div>
                    </RadioGroup>
                  </div>

                  <div className="text-msi">
                    <p>
                      9 Meses Sin Intereses ($
                      {Math.ceil(primaf / 9).toLocaleString("en-US")}/mes)
                    </p>
                  </div>
                </div>
              </label>
            </div>
          ) : msi9 ? (
            <div>
              <label>
                <div className="container-msi fondo-msi2">
                  <div className="radio-msi">
                    <RadioGroup value={msi} onChange={handleMsi}>
                      <div className="tipo">
                        <FormControlLabel
                          value="9"
                          control={<Radio />}
                          name="pago"
                        />
                      </div>
                    </RadioGroup>
                  </div>

                  <div className="text-msi2">
                    <p>
                      9 Meses Sin Intereses ($
                      {Math.ceil(primaf / 9).toLocaleString("en-US")}/mes)
                    </p>
                  </div>
                </div>
              </label>
            </div>
          ) : (
            <div> </div>
          )}

          {msi === "6" && msi6 ? (
            <div>
              <label>
                <div className="container-msi fondo-msi">
                  <div className="radio-msi">
                    <RadioGroup value={msi} onChange={handleMsi}>
                      <div className="tipo">
                        <FormControlLabel
                          value="6"
                          control={<Radio />}
                          name="pago"
                        />
                      </div>
                    </RadioGroup>
                  </div>

                  <div className="text-msi">
                    <p>
                      6 Meses Sin Intereses ($
                      {Math.ceil(primaf / 6).toLocaleString("en-US")}/mes)
                    </p>
                  </div>
                </div>
              </label>
            </div>
          ) : msi6 ? (
            <div>
              <label>
                <div className="container-msi fondo-msi2">
                  <div className="radio-msi">
                    <RadioGroup value={msi} onChange={handleMsi}>
                      <div className="tipo">
                        <FormControlLabel
                          value="6"
                          control={<Radio />}
                          name="pago"
                        />
                      </div>
                    </RadioGroup>
                  </div>

                  <div className="text-msi2">
                    <p>
                      6 Meses Sin Intereses ($
                      {Math.ceil(primaf / 6).toLocaleString("en-US")}/mes)
                    </p>
                  </div>
                </div>
              </label>
            </div>
          ) : (
            <div> </div>
          )}

          {msi === "3" && msi3 ? (
            <div>
              <label>
                <div className="container-msi fondo-msi">
                  <div className="radio-msi">
                    <RadioGroup value={msi} onChange={handleMsi}>
                      <div className="tipo">
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          name="pago"
                        />
                      </div>
                    </RadioGroup>
                  </div>

                  <div className="text-msi">
                    <p>
                      3 Meses Sin Intereses ($
                      {Math.ceil(primaf / 3).toLocaleString("en-US")}/mes)
                    </p>
                  </div>
                </div>
              </label>
            </div>
          ) : msi3 ? (
            <div>
              <label>
                <div className="container-msi fondo-msi2">
                  <div className="radio-msi">
                    <RadioGroup value={msi} onChange={handleMsi}>
                      <div className="tipo">
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          name="pago"
                        />
                      </div>
                    </RadioGroup>
                  </div>

                  <div className="text-msi2">
                    <p>
                      3 Meses Sin Intereses ($
                      {Math.ceil(primaf / 3).toLocaleString("en-US")}/mes)
                    </p>
                  </div>
                </div>
              </label>
            </div>
          ) : (
            <div> </div>
          )}
          <br />
          <br />

          <LoadingButton
            id="confirm-button2"
            type="submit"
            loading={loading}
            onClick={confirmPayment}
            variant="contained"
            fullWidth
            sx={{
              borderRadius: "10px",
            }}
          >
            Confirmar pago
          </LoadingButton>
        </div>
        <br />
        <div id="result" hidden>
          <p id="status-message"></p>
        </div>
      </div>
    </>
  );
};
export default Msi;
