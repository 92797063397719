function highlightSection(id) {
  const el = document.getElementById(id);
  if (!el) return;
  if (!el.children?.[0]) return;

  setTimeout(() => {
    el.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });

    el.classList.add("highlight-section");
  }, 500);

  setTimeout(() => {
    el.classList.remove("highlight-section");
  }, 3000);
}

export default highlightSection;
