import { useState } from "react";
import { Typography, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import cluppLogo from "../../Assets/PNG/clupp_logoC.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import ProfileMenu from "../../Components/ProfileMenu/ProfileMenu";
import AgentSidebar from "../../Components/AgentSidebar/AgentSidebar";
import { useAgentAuth } from "../../AuthAgentContext";
import styles from "./DashboardHeader.module.css";
import SectionBanner from "../Banner";

export default function DashboardHeader({
  isAgentSection = false,
  redirectLogoUrl = undefined,
  haveABanner = false
}) {
  const { currentAgentUser } = useAgentAuth();
  const isDesktopView = useMediaQuery("(min-width:800px)");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenDottedMenuOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnCloseDottedMenu = () => {
    setAnchorEl(null);
  };

  return (
    <header
      style={{
        width: "100%",
        background: "#00bbee",
        position: "fixed",
        top: "0",
        zIndex: 100,
      }}
    >
      {currentAgentUser && <AgentSidebar />}
      {currentAgentUser && (
        <ProfileMenu
          anchorEl={anchorEl}
          open={open}
          handleClose={handleOnCloseDottedMenu}
        />
      )}
      <Box
        sx={{
          width: "90%",
          maxWidth: "1140px",
          margin: "0 auto",
          padding: "0 10px 0 10px",
          display: "flex",
          justifyContent: ["center", "space-between"],
          alignItems: "center",
        }}
      >
        {redirectLogoUrl ? (
          <a href={redirectLogoUrl} style={{textDecoration: "none"}}>
            <div
              className={(currentAgentUser || isAgentSection) && styles.bandage}
              style={{ display: "flex", alignItems: "center" }}
            >
              <img src={cluppLogo} alt="logo" style={{ marginRight: "3px" }} />
              <Typography variant="h6" sx={{ color: "#fff" }}>
                Agentes
              </Typography>
            </div>
          </a>
        ) : (
          <div
            className={(currentAgentUser || isAgentSection) && styles.bandage}
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={cluppLogo}
              alt="clupp logo"
              style={{ marginRight: "3px" }}
            />
            <Typography variant="h6" sx={{ color: "#fff" }}>
              Agentes
            </Typography>
          </div>
        )}

        {isDesktopView && currentAgentUser && (
          <IconButton
            sx={{ width: "25px", height: "25px", color: "#fff" }}
            onClick={handleOpenDottedMenuOptions}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Box>
      {haveABanner && (
         <SectionBanner/>
      )}
     
    </header>
  );
}
