import { Fragment, useEffect, useState } from "react";
import {
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ElegirMovilidad from "../../Components/ElegirMovilidad/ElegirMovilidad";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import Stepper from "../../Components/Stepper/Stepper";
import EnviarCotizacion from "../../Components/EnviarCotizacion/EnviarCotizacion";
import BotonPrincipal from "../../Components/BotonPrincipal/BotonPrincipal";
import firebase from "firebase/app";
import Swal from "sweetalert2";
import { db } from "../../Firebase/firebase";
import RoboCelular from "../../Components/RoboCelular/RoboCelular";
import getPremiumPropertyName from "../../utils/getPremiumPropertyName";
import PeriocidadPago from "../../Components/PeriocidadPago/PeriocidadPago";
import LoadingOverlay from "../../Components/LoadingOverlay";
import { useAgentAuth } from "../../AuthAgentContext";
import logError from "../../services/logError";
import useQuery from "../../hooks/useQuery";
import useRedirect from "../../hooks/useRedirect";
import isValidEmail from "../../utils/isValidEmail";
import { addOrUpdateMember, addListMemberTags } from "../../services/mailChimp";
import CheckIcon from "@mui/icons-material/Check";

import getWhatsAppUrl from "../../utils/getWhatsAppUrl";
import AGENTES from "../../utils/agentes";
import usePreventClosingWindow from "../../hooks/usePreventClosingWindow";
import registerInterest from "../../services/logs/registerInterest";
import TablaCoberturaVehiculos from "../../Components/TablaCoberturaVehiculos/TablaCoberturaVehiculos";
import TablaCoberturaDispositivos from "../../Components/TablaCoberturaDispositivos/TablaCoberturaDispositivos";
import isADevice from "../../utils/isADevice";
import ElegirMovilidadDevices from "../../Components/ElegirMovilidad/ElegirMovilidadDevices";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import ClearIcon from "@mui/icons-material/Clear";
import { getWoowEmployee } from "../../services/woow";

const activeDiscountCoupons = [{ name: "WOOW4U", percentage: 15 }];

const ElegirPlan = () => {
  const query = useQuery();
  const token = query.get("token");
  const utmSource = query.get("utm_source");
  const isAdminQuotation = utmSource?.toLowerCase() === "admin";
  const isAgentQuotation = utmSource === "agentes";
  const campaign = query.get("utm_campaign");
  const isWowUser = query.get("utm_campaign") === AGENTES.WOW;

  usePreventClosingWindow();

  const redirect = useRedirect();
  const { isAgentLogged } = useAgentAuth();
  const [discountCoupon, setDiscountCoupon] = useState({
    name: "",
    percentage: 1,
  });
  const [woowUser, setWoowUser] = useState({});
  const [isACorrectCoupon, setIsACorrectCoupon] = useState(true);
  const [checkedCouponCorrectly, setCheckedCouponCorrectly] = useState(false);
  const [quotationData, setQuotationData] = useState();
  const [vehicleData, setVehicleData] = useState();

  const [openQuotationModal, setOpenQuotationModal] = useState(false);
  const [selectedMobility, setSelectedMobility] = useState({
    mobility: 2,
    premium: 0,
  });
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const { currentAgentUser } = useAgentAuth();
  const docRef = localStorage.getItem("docRef");
  const currentVehicle = localStorage.getItem("currentVehicle");

  useEffect(() => {
    const setWoowEmployee = async () => {
      try {
        const json = await getWoowEmployee(token);

        if (!json) {
          throw new Error("Error: Datos del usuario no disponibles");
        }

        setWoowUser(json);
      } catch (error) {
        console.error("Error al establecer el usuario Woow:", error);
      }
    };

    if (isWowUser && token) {
      setWoowEmployee();
    }
  }, [isWowUser, token]);

  useEffect(() => {
    if (!docRef || !currentVehicle) return;
    db.collection(`prospectsNew/${docRef}/qStatusUpdates`).add({
      vehicleId: currentVehicle,
      qStatus: 1,
      timestamp: Date.now(),
      timestampServer: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }, [docRef, currentVehicle]);

  const mobilityDescription = (n) => {
    if (
      vehicleData &&
      vehicleData.features.includes("Soy conductor de plataforma")
    ) {
      if (n === 0) {
        if (vehicleData.type === "auto" || vehicleData.type === "moto")
          return "si recorres menos de 1,500 km al mes";
        else return "mínima";
      } else if (n === 1) {
        if (vehicleData.type === "auto" || vehicleData.type === "moto")
          return "si recorres de 1,500 a 2,500 km al mes";
        else return "reducida";
      } else if (n === 2) {
        if (vehicleData.type === "auto" || vehicleData.type === "moto")
          return "si recorres de 2,501 a 5,000 km al mes";
        else return "normal";
      } else if (n === 3) {
        if (vehicleData.type === "auto" || vehicleData.type === "moto")
          return "si recorres de 5,001 a 7,500 km al mes";
        else return "intensiva";
      }
    } else {
      if (n === 0) {
        if (vehicleData.type === "auto" || vehicleData.type === "moto")
          return "si recorres menos de 250 km al mes";
        else return "mínima";
      } else if (n === 1) {
        if (vehicleData.type === "auto" || vehicleData.type === "moto")
          return "si recorres de 250 a 500 km al mes";
        else return "reducida";
      } else if (n === 2) {
        if (vehicleData.type === "auto" || vehicleData.type === "moto")
          return "si recorres de 500 a 1,200 km al mes";
        else return "normal";
      } else if (n === 3) {
        if (vehicleData.type === "auto" || vehicleData.type === "moto")
          return "Más de 1,200 km al mes.";
        else return "intensiva";
      }
    }
  };
  console.log("discountCoupon--->", discountCoupon);
  useEffect(() => {
    if (userData?.haveADiscountCoupon && woowUser.empleado) {
      console.log("Discouint--->", userData.discount);
      
      setDiscountCoupon(userData.discount);
      setCheckedCouponCorrectly(true);
    }
  }, [userData, woowUser]);

  useEffect(() => {
    if (!docRef) return;

    const userUnsubscribe = db
      .doc(`prospectsNew/${docRef}`)
      .onSnapshot((snapshot) => {
        const data = snapshot.data();
        if (!!!data["period"]) {
          data["period"] = "year";
          data["msi"] = false;
        }

        setUserData(data);
      });

    // Get the latest quotation
    const quotationUnsubscribe = db
      .collection(`prospectsNew/${docRef}/quotationsRecord`)
      .orderBy("timestamp", "desc")
      .limit(1)
      .onSnapshot((querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        setQuotationData(docs[0]);
      });

    const vehicleUnsubscribe = db
      .doc(`prospectsNew/${docRef}/vehicles/${currentVehicle}`)
      .onSnapshot((snapshot) => {
        const data = snapshot.data();
        setVehicleData({
          ...data,
          id: snapshot.id,
        });
      });

    return () => {
      quotationUnsubscribe();
      userUnsubscribe();
      vehicleUnsubscribe();
    };
  }, [currentVehicle, docRef]);

  useEffect(() => {
    if (!vehicleData || !quotationData) return setIsLoading(true);
    setIsLoading(false);
    const currentPremium =
      quotationData[getPremiumPropertyName(vehicleData.mobility)];
    setSelectedMobility({
      mobility: vehicleData.mobility,
      premium: currentPremium,
    });
  }, [quotationData, vehicleData]);

  const next = async () => {
    if (!Boolean(userData.smartphoneAmount) && userData.smartphoneCovered) {
      Swal.fire({
        icon: "warning",
        text: "¡Ups! Ingresa el valor comercial de tu celular",
      });
      return;
    }
    if (userData.smartphoneAmount > 30000) {
      Swal.fire({
        icon: "warning",
        text: "¡Ups! No podemos asegurar tu celular por más de $30,000",
      });
      return;
    }
    setLoading(true);
    localStorage.setItem("primafBUG", selectedMobility.premium);

    try {
      const update = {
        period: userData.period,
        timestamp: Date.now(),
        premium: selectedMobility.premium,
      };
      if (checkedCouponCorrectly && woowUser.empleado) {
        update.haveADiscountCoupon = true;
        update.discount = discountCoupon;
      }

      if (!woowUser.empleado) {
        update.haveADiscountCoupon = false;
        update.discount = {};
      }

      await db.collection("prospectsNew").doc(docRef).update(update);

      await db.doc(`prospectsNew/${docRef}/vehicles/${currentVehicle}`).update({
        mobility: vehicleData.mobility,
        amount: selectedMobility.premium,
        extras: [],
        // qStatus: 1.5,
      });

      if (isValidEmail(userData.email)) {
        await updateInfoInMailChimp(userData.email, "interested2");
      }

      if (!!userData.phone && userData.phone.length === 10) {
        await addOrUpdateMember({
          email: userData.email,
          phone: userData.phone,
          uid: docRef,
        });
      }

      if ((!isAdminQuotation && !isAgentQuotation) || !isAgentLogged) {
        window.dataLayer.push({
          event: "elegirPlan",
          plan: vehicleData.mobility,
          type: vehicleData.type,
          brand: vehicleData.brand,
          model: vehicleData.model,
          year: vehicleData.year,
          period: userData.period,
        });

        console.log("event sent to tag manager");
      } else {
        console.log("The user is an agent or is an agent/admin quote");
      }

      await generateQuotation(userData.uid);
      setLoading(false);
      isADevice(vehicleData.type)
        ? redirect("/contrata")
        : redirect("/elegir-extras");
    } catch (error) {
      setLoading(false);
      console.log(error);
      Swal.fire({
        icon: "error",
        text: `Ha ocurrido un error, inténtalo de nuevo.
                  ${error}`,
      });
      function parseObject(object) {
        return Object.keys(object)
          .map((key) => {
            return [key, object[key]];
          })
          .reduce((prev, current) => {
            const [key, value] = current;
            prev[key] = value ?? String(value);
            return prev;
          }, {});
      }

      await logError({
        error: String(error),
        metadata: {
          description: "Error al continuar cotizacion desde Elegir plan",
          uid: docRef,
          quoteData: parseObject(quotationData),
          vehicleData: parseObject(vehicleData),
        },
      });
    }
  };

  async function generateQuotation() {
    let url = `https://us-central1-auto-clupp.cloudfunctions.net/app/quote-mobility-insurance-5?uid=${docRef}`;
    if (vehicleData.type === "auto") {
      url += `&isPickup=${Boolean(vehicleData.isPickup)}`;
    }
    const response = await fetch(url);

    const json = await response.json();
    setQuotationData(json);
  }

  async function updateUser(update) {
    try {
      return await db.doc(`prospectsNew/${docRef}`).update(update);
    } catch (error) {
      console.error(error);
    }
  }

  async function updateVehicle(update) {
    await db
      .doc(`prospectsNew/${docRef}/vehicles/${vehicleData.id}`)
      .update(update);
  }

  function handleCloseDialog() {
    setOpenQuotationModal(false);
  }
  async function onSelectMobility(mobility, premium) {
    await updateVehicle({ mobility, amount: premium });
    if (!isAgentLogged) {
      // Register event in Google Tag Manager
      const gtmEvent = {
        event: "seleccionMovilidad",
        mobility: mobility,
      };
      window.dataLayer.push(gtmEvent);
    }
  }

  async function handleOnPhoneCheckChange(event, isActive) {
    var pVNew = userData?.smartphoneAmount ? userData.smartphoneAmount : 5000;
    const x = parseInt(vehicleData.valueUser * 0.03);
    if (!userData?.smartphoneAmount) {
      if (x > 5000) {
        if (x < 30000) {
          pVNew = x;
        } else {
          pVNew = 30000;
        }
      }
    }

    let update = {};
    let updateVehicleData = {};
    updateVehicleData["smartphoneCovered"] = isActive;
    updateVehicleData["smartphoneAmount"] = isActive
      ? userData.smartphoneAmount === 0
        ? pVNew
        : userData.smartphoneAmount
      : 0;
    update["smartphoneCovered"] = isActive;
    update["smartphoneAmount"] = isActive
      ? userData.smartphoneAmount === 0
        ? pVNew
        : userData.smartphoneAmount
      : userData.smartphoneAmount;

    await updateUser(update);
    await updateVehicle(updateVehicleData);
    await generateQuotation();
  }

  async function handleOnPhoneValueChange(event, value) {
    let update = {};
    update["smartphoneAmount"] = value;

    await updateUser(update);
    await updateVehicle(update);
    await generateQuotation();
  }

  async function updateInfoInMailChimp(email, interestTag) {
    // update email and id
    // uid will be saved as a merge field
    await addOrUpdateMember({ email, uid: docRef });

    const memberTags = [
      { name: interestTag },
      { name: vehicleData.type },
      { name: "autoclupp" },
    ];
    if (vehicleData.features.includes("Soy conductor de plataforma")) {
      memberTags.push({ name: "platform" });
    }

    if (isAgentQuotation) {
      memberTags.push({ name: "source_agent" });
    }

    registerInterest({
      email: userData.email || "",
      brand: vehicleData.brand || "",
      model: vehicleData.model || "",
      tag: memberTags.map((tag) => tag.name).join(", "),
      uid: docRef || "",
      year: vehicleData.year || "",
      type: vehicleData.type || "",
    });

    // update member tags
    await addListMemberTags({
      tags: memberTags,
      email,
    });
  }
  const isOzonQuote = vehicleData?.utm_medium === AGENTES.OZON;

  const checkDiscountCoupon = async () => {
    console.log("discount <<<<->", discountCoupon);
    
    const existCoupon = activeDiscountCoupons.find(
      (i) => i.name === discountCoupon.name
    );

    if (discountCoupon?.name && existCoupon?.name && woowUser?.empleado) {
      setDiscountCoupon(existCoupon);
      if (!isACorrectCoupon) setIsACorrectCoupon(true);
      setCheckedCouponCorrectly(true);
      await db
        .collection("prospectsNew")
        .doc(docRef)
        .update({ haveADiscountCoupon: true, discount: existCoupon });
      return;
    } else {
      setIsACorrectCoupon(false);
      return;
    }
  };

  const handleDeleteCoupon = async () => {
    try {
      await db.collection("prospectsNew").doc(docRef).update({
        discount: firebase.firestore.FieldValue.delete(),
        haveADiscountCoupon: false,
      });
      setDiscountCoupon({ name: "", percentage: 1 });
      setCheckedCouponCorrectly(false);
      setIsACorrectCoupon(true);
      console.log("Campo eliminado exitosamente.");
    } catch (error) {
      console.error("Error al eliminar el campo:", error);
    }
  };

  return (
    <Fragment>
      <LoadingOverlay isOpen={isLoading} />
      {vehicleData && (
        <Fragment>
          {!isWowUser && (
            <>
              <Menu />
              <Header />
            </>
          )}
          <div className="cotizar adjusted-height">
            <Stepper
              step={1}
              type={isADevice(vehicleData.type) ? "devices" : vehicleData.type}
            />
            {!isLoading && (
              <Fragment>
                {isADevice(vehicleData.type) ? (
                  <ElegirMovilidadDevices
                    amounts={quotationData}
                    selectedMobility={selectedMobility}
                    setSelectedMobility={setSelectedMobility}
                    vehicleData={vehicleData}
                    onSelectMobility={onSelectMobility}
                  />
                ) : (
                  <ElegirMovilidad
                    paymentPeriod={userData.period}
                    docRef={docRef}
                    currentVehicle={currentVehicle}
                    amounts={quotationData}
                    selectedMobility={selectedMobility}
                    setSelectedMobility={setSelectedMobility}
                    mobilityDescription={mobilityDescription}
                    vehicleData={vehicleData}
                    vehicleType={vehicleData.type}
                    onSelectMobility={onSelectMobility}
                  />
                )}

                {vehicleData.type === "laptop" ||
                vehicleData.type === "smartphone" ||
                vehicleData.type === "tablet" ? (
                  <TablaCoberturaDispositivos
                    selectedMobility={selectedMobility.mobility}
                    deviceValue={vehicleData.valueUser}
                  />
                ) : (
                  <TablaCoberturaVehiculos
                    amounts={quotationData}
                    vehicleData={vehicleData}
                    vehicleType={vehicleData.type}
                  />
                )}

                {isWowUser && (
                  <Stack sx={{ margin: "10px auto 30px auto", width: "90%" }}>
                    <h3>¿Tienes un código de descuento?</h3>
                    <Stack
                      sx={{
                        width: ["90%", "250px"],
                        margin: "0 auto",
                      }}
                    >
                      {checkedCouponCorrectly ? (
                        <Chip
                          icon={<LoyaltyIcon sx={{ fill: "#fff" }} />}
                          sx={{
                            backgroundColor: "#00CA7D",
                            color: "#fff",
                            margin: "0 auto",
                          }}
                          label={discountCoupon.name}
                          deleteIcon={<ClearIcon sx={{ fill: "#fff" }} />}
                          onDelete={handleDeleteCoupon}
                        />
                      ) : (
                        <>
                          <FormControl variant="outlined">
                            <InputLabel
                              sx={{ backgroundColor: "#fff", padding: "1px" }}
                            >
                              Código
                            </InputLabel>
                            <OutlinedInput
                              variant="outlined"
                              error={!isACorrectCoupon}
                              value={discountCoupon.name}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              onChange={(e) =>
                                setDiscountCoupon((prev) => ({
                                  ...prev,
                                  name: e.target.value.toUpperCase().trim(),
                                }))
                              }
                            />
                            <FormHelperText sx={{ color: "red" }}>
                              {isACorrectCoupon ? "" : "Código no valido."}
                            </FormHelperText>
                          </FormControl>
                          <Button
                            disabled={checkedCouponCorrectly}
                            style={{
                              backgroundColor: checkedCouponCorrectly
                                ? "gray"
                                : "#0058f4",
                              textTransform: "capitalize",
                              marginTop: "10px",
                            }}
                            variant="contained"
                            className={"PeriocidadPago-button"}
                            onClick={checkDiscountCoupon}
                          >
                            Aplicar
                          </Button>
                        </>
                      )}
                    </Stack>
                  </Stack>
                )}

                <PeriocidadPago
                  isAgentLogged={isAgentLogged}
                  haveDiscount={checkedCouponCorrectly}
                  msi={userData.msi}
                  discount={discountCoupon}
                  vehicleData={vehicleData}
                  basePremium={selectedMobility.premium}
                  mobility={selectedMobility.mobility}
                  isWowUser={isWowUser}
                  period={userData.period}
                  isOzonQuote={isOzonQuote}
                  onPaymentPeriodSelect={async (period) => {
                    if (period === "year-msi") {
                      setUserData((prev) => ({
                        ...prev,
                        period: "year",
                        msi: true,
                      }));
                      await updateUser({ period: "year", msi: true });

                      if (!isAgentLogged) {
                        // Register event in Google Tag Manager
                        const gtmEvent = {
                          event: "seleccionPeriodicidad",
                          period: "year",
                          msi: true,
                        };
                        window.dataLayer.push(gtmEvent);
                      }
                      return;
                    }
                    setUserData((prev) => ({ ...prev, period, msi: false }));
                    await updateUser({ period, msi: false });
                    if (!isAgentLogged) {
                      // Register event in Google Tag Manager
                      const gtmEvent = {
                        event: "seleccionPeriodicidad",
                        period: period,
                      };
                      window.dataLayer.push(gtmEvent);
                    }
                  }}
                />
                {!currentAgentUser && !isOzonQuote && !isWowUser && (
                  <Typography style={{ margin: "2rem 0px" }}>
                    Contáctanos por WhatsApp y obtén un código de descuento
                    dando clic{" "}
                    <a
                      href={getWhatsAppUrl(
                        `Hola, me interesa el código de descuento`
                      )}
                      target="__blank"
                      style={{ fontWeight: 900, color: "#0058f4" }}
                    >
                      <u>
                        <em>aquí</em>
                      </u>
                    </a>
                    .
                  </Typography>
                )}

                {!isWowUser && !isADevice(vehicleData.type) && (
                  <RoboCelular
                    onActiveChange={handleOnPhoneCheckChange}
                    onValueChange={async (_, value) => {
                      setUserData((prev) => ({
                        ...prev,
                        smartphoneAmount: value,
                      }));
                    }}
                    onBlur={handleOnPhoneValueChange}
                    isActive={userData.smartphoneCovered}
                    value={userData.smartphoneAmount}
                  />
                )}
                <EnviarCotizacion
                  onSumbit={() => setOpenQuotationModal((prev) => !prev)}
                  isOpen={openQuotationModal}
                  onClose={handleCloseDialog}
                  mobility={selectedMobility.mobility}
                  email={userData?.email}
                  phone={userData?.phone}
                  haveADiscount={checkedCouponCorrectly}
                  discountCoupon={
                    checkedCouponCorrectly ? discountCoupon.name : ""
                  }
                  isWowUser={isWowUser}
                  userData={userData}
                  vehicleData={vehicleData}
                  onEmailChange={async (email) => {
                    await updateUser({ email });
                    if (!!email) {
                      localStorage.setItem("email", email);
                    }
                  }}
                  onPhoneChange={async (phone) => {
                    await updateUser({ phone });
                    localStorage.setItem("phone", phone);
                  }}
                />
              </Fragment>
            )}
          </div>

          <BotonPrincipal
            tipo="Continuar"
            back="/"
            func={next}
            loading={loading}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default ElegirPlan;
