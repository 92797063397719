function getCurrentQuarter() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();

  if (currentMonth >= 0 && currentMonth <= 2) {
    return 1; // Primer trimestre (enero - marzo)
  } else if (currentMonth >= 3 && currentMonth <= 5) {
    return 2; // Segundo trimestre (abril - junio)
  } else if (currentMonth >= 6 && currentMonth <= 8) {
    return 3; // Tercer trimestre (julio - septiembre)
  } else {
    return 4; // Cuarto trimestre (octubre - diciembre)
  }
}

export default getCurrentQuarter;
