import { Tooltip, Typography } from "@mui/material";

import { useSnackbar } from "notistack";

const ToClipboardText = ({ textToClip, text, variant, success, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();

  async function handleClick() {
    try {
      await navigator.clipboard.writeText(textToClip);
      enqueueSnackbar(success, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(
        "Este navegador no soporta esta función , copiar manualmente.",
        { variant: "error" }
      );
    }
  }
  return (
    <Tooltip title={<Typography>Copiar al portapapeles</Typography>}>
      <Typography
        sx={{ cursor: "pointer" }}
        {...rest}
        variant={variant}
        onClick={handleClick}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};
export default ToClipboardText;