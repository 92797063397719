// trigger quotation generation
// the function generates a document in the collection prospectsNew/{uid}/quotationsRecord
// it will contain premium values
async function generateQuotation({ userUID, vehicleData }) {
  let url = `https://us-central1-auto-clupp.cloudfunctions.net/app/quote-mobility-insurance-5?`;

  url += `uid=${userUID}`;

  if (vehicleData.type === "auto") {
    url += `&isPickup=${Boolean(vehicleData.isPickup)}`;
  }

  const response = await fetch(url);

  const json = await response.json();

  return json;
}

export default generateQuotation;
