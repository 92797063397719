import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";
import cluppyImg from "../../Assets/PNG/Clupp_ThumbsUp.png";
import { useAgentAuth } from "../../AuthAgentContext";
import { Redirect } from "react-router-dom";
import { Box } from "@mui/system";

export default function RespuestaRegistro() {
  const { currentAgentUser } = useAgentAuth();

  if (currentAgentUser) {
    return <Redirect to="/agentes/dashboard" />;
  }

  return (
    <>
      <DashboardHeader isAgentSection={true}/>
      <main
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          background: "#fff",
        }}
      >
        <Box>
          <img style={{width: "180px", margin: "20px"}} src={cluppyImg} alt="imagen de cluppy de 'ok'" />
        </Box>
        <p style={{ fontSize: "18px" }}>¡Gracias! Hemos registrado tu solicitud. <br/>En 2 minutos recibirás un correo con los siguiente pasos.</p>
      </main>
    </>
  );
}
