import firebase from "firebase/app";
import { db } from "../Firebase/firebase";

async function logError({ error, metadata, source = "Cotizador" }) {
  //create record in firestore
  try {
    const docRef = await db.collection("IT/errors/docs").add({
      error,
      source,
      ...(metadata && { metadata }),
      timestamp: Date.now(),
      timestampServer: firebase.firestore.FieldValue.serverTimestamp(),
    });
    //post message in slack
    return fetch(
      "https://hooks.slack.com/services/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",
      {
        method: "POST",
        body: JSON.stringify({
          text: `[${source}] Error detectado: ${error}`,
          blocks: [
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `Error en el cotizador: <https://console.firebase.google.com/u/1/project/auto-clupp/firestore/data/~2FIT~2Ferrors~2Fdocs~2F${docRef.id} | \`${error}\` >`,
              },
            },
          ],
        }),
      }
    );
  } catch (error) {
    console.error(error);
  }
}

export default logError;
