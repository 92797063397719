import { useState } from "react";
import { Stack, Typography, Autocomplete, TextField } from "@mui/material";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
} from "use-places-autocomplete";

export default function AutocompleteAddress({ setNewAgentData, newAgentData, placeholderInput }) {
  const [address, setAddress] = useState();
  const {
    suggestions: {
      data: placesData,
      status: placesQueryStatus,
      loading: isPlacesOptionsLoading,
    },
    setValue: setPlacesQuery,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ["mx"] },
      types: ["geocode", "establishment"],
    },
    debounce: 300,
  });

  async function getLangAndLng(placeId) {
    if (placeId) {
      try {
        const results = await getGeocode({ placeId });
        const { lat: latitude, lng: longitude } = await getLatLng(results[0]);
        const zipCode = await getZipCode(results[0], false);
        return { latitude, longitude, zipCode };
      } catch (error) {
        console.log(error);
      }
    }
  }

  const isOptionEqualToValue = (option, value) => {
    return option.description === value.description;
  };

  const getPlaceOptionLabel = (option) => option.description || "";

  const customerAddressInputOptions =
    placesQueryStatus === "OK"
      ? placesData.map(({ description, place_id }) => ({
          description,
          place_id,
        }))
      : [];

  async function handleOnCustomerAddressInputChange(event, newValue) {
    if (newValue) {
      const { zipCode } = await getLangAndLng(newValue.place_id);

      setAddress(newValue);
      setNewAgentData((prev) => ({
        ...prev,
        address: {
          ...newValue,
          zipCode: zipCode,
        },
      }));
    } else {
      setAddress("");
      setNewAgentData((prev) => ({
        ...prev,
        address: {},
      }));
    }
  }

  return (
      <Autocomplete
        sx={{ margin: "0", gridColumn: ["auto","span 2"] }}
        loading={isPlacesOptionsLoading}
        value={address}
        disablePortal
        onInputChange={(_, value, reason) => {
          if (reason === "input") {
            setPlacesQuery(value);
          }
        }}
        onChange={handleOnCustomerAddressInputChange}
        options={customerAddressInputOptions}
        getOptionLabel={getPlaceOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        renderInput={(params) => (
          <TextField {...params} required label={null} placeholder={placeholderInput} />
        )}
        clearText="Borrar dirección"
        openText="Abrir menú de opciones"
        closeText="Cerrar"
        loadingText="Cargando..."
        noOptionsText="Escribe tu dirección"
      />
  );
}
