import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
} from "@mui/material";
import Email from "../../Assets/SVG/email.svg";
import Whatsapp from "../../Assets/SVG/whatsapp.svg";
import "./EnviarCotizacion.css";
import Swal from "sweetalert2";
import isValidEmail from "../../utils/isValidEmail";
import { useAgentAuth } from "../../AuthAgentContext";
import OpcionesCotizacion from "./OpcionesCotizacion";

const PREFIX = "EnviarCotizacion";

const classes = {
  blue: `${PREFIX}-blue`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.blue}`]: {
    margin: theme.spacing(1),
    backgroundColor: "white",
    color: "#3f75cf",
    fontSize: "10px",
    fontWeight: "bold",
    width: "250px",
    borderRadius: "20px",
    border: "1px solid #3f75cf",
    textAlign: "center",
  },
}));

const EnviarCotizacion = ({
  onSumbit,
  phone = "",
  email = "",
  onEmailChange,
  onPhoneChange,
  isOpen = undefined,
  onClose = undefined,
  userData={},
  vehicleData={},
  mobility,
  isWowUser
}) => {
  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);
  let param_email = urlParams.get("email");
  const [formData, setFormData] = useState({ phone, email });
  const { currentAgentUser } = useAgentAuth();

  useEffect(() => {
    setFormData(() => ({ email, phone }));
  }, [email, phone]);

  // funcion para hacer update al ingresar correo
  const handleBlurEmail = async (e) => {
    const { value } = e.target;
    if (!isValidEmail(value)) {
      Swal.fire("", "Ingresa un correo válido", "warning");
      return;
    }

    await onEmailChange(value.trim().replace("hormail", "hotmail"));
  };

  function handleOnFormChange(update) {
    setFormData((prev) => ({ ...prev, ...update }));
  }

  return (
    <Root className="enviar-cotizacion" id="enviar">
      {isOpen && onClose && (
        <OpcionesCotizacion
          isOpen={isOpen}
          onClose={onClose}
          isWowUser={isWowUser}
          onCloseQuotationModal={onClose}
          email={formData.email}
          phone={formData.phone}
          userData={userData}
          vehicleData={vehicleData}
          mobility={mobility}
        />
      )}
      <div>
        <div className="color">
          <p className="title-enviar">
            {currentAgentUser ? "Enviar/Descargar cotización" : "Enviar la cotización"}
          </p>
          <div className="enviar-cotizacion-fields-container">
            <FormControl className="enviar-cotizacion-field" variant="outlined">
              <InputLabel htmlFor="correo">Correo</InputLabel>
              <OutlinedInput
                id="correo2"
                disabled={(param_email && email)}
                startAdornment={
                  <InputAdornment position="start">
                    <img src={Email} alt="email" />
                  </InputAdornment>
                }
                label="Correo"
                placeholder="Por correo"
                onChange={(event) =>
                  handleOnFormChange({
                    email: event.target.value.trim(),
                  })
                }
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    event.target.blur();
                  }
                }}
                type="email"
                onBlur={handleBlurEmail}
                value={formData.email}
              />
            </FormControl>

            <FormControl className="enviar-cotizacion-field" variant="outlined">
              <InputLabel htmlFor="whatsapp" className="label">
                Whatsapp
              </InputLabel>
              <OutlinedInput
                id="whatsapp2"
                startAdornment={
                  <InputAdornment position="start">
                    <img src={Whatsapp} alt="whatsapp" />
                  </InputAdornment>
                }
                label="Whatsapp"
                type="number"
                placeholder="Por Whatsapp"
                onChange={(event) => {
                  const { value } = event.target;
                  if (value.length > 10) return;
                  handleOnFormChange({
                    phone: value.trim(),
                  });
                }}
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    event.target.blur();
                  }
                }}
                onBlur={() => onPhoneChange(formData.phone)}
                value={formData.phone}
              />
            </FormControl>
            <Button
              variant="contained"
              className={classes.blue}
              onClick={onSumbit}
            >
              {/* {loading && <CircularProgress />} */}
              {currentAgentUser ? "Enviar/Descargar cotización" : "Enviar la cotización"}
            </Button>
          </div>
        </div>
      </div>
    </Root>
  );
};
export default EnviarCotizacion;
