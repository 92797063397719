import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage'; 

const FirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyB9vbtEAnjNgCQAM6U4mOQ8IfqsMs-53NE",
    authDomain: "auto-clupp.firebaseapp.com",
    databaseURL: "https://auto-clupp.firebaseio.com",
    projectId: "auto-clupp",
    storageBucket: "auto-clupp.appspot.com",
    messagingSenderId: "241778345447",
    appId: "1:241778345447:web:fe701be48174f4f5c9e942",
    measurementId: "G-WFMGJ2RBE7"
  });
  
  export const auth = FirebaseApp.auth();
  export const db = FirebaseApp.firestore();
  export const storage=FirebaseApp.storage(); 
  export default FirebaseApp;