import {
  Box,
  Button,
  Typography,
  TextField,
  RadioGroup,
  FormControl,
  Radio,
  FormLabel,
  FormControlLabel,
  LinearProgress,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AutocompleteAddress from "./AutocompleteAddress";
import { useLayoutEffect, useRef } from "react";

export default function AgentesRegistroFormMoral({
  handlerSubmit,
  newAgentData,
  onChange,
  errorRFC,
  setErrorRFC,
  errorNumber,
  setErrorNumber,
  setCedulaImg,
  cedulaImg,
  imgRef,
  inputImgRef,
  refferedUser,
  isLoading,
  isAllEmpty,
  setNewAgentData,
}) {
  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setErrorRFC(!(newAgentData.rfc.length === 12));
  }, [newAgentData.isPersonaMoral, newAgentData.rfc]);
  return (
    <form
      onSubmit={handlerSubmit}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "80%",
        maxWidth: "680px",
        gap: 25,
        margin: "20px",
      }}
    >
      <Typography>Datos del representante</Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: ["1fr", "1fr 1fr"],
          gap: "17px",
          width: ["100%"],
        }}
      >
        <TextField
          helperText=" "
          variant="outlined"
          value={newAgentData.firstName}
          onChange={onChange}
          name="firstName"
          type="text"
          label={"Nombre"}
          inputProps={{ autoCapitalize: "words" }}
        />
        <TextField
          helperText=" "
          variant="outlined"
          onChange={onChange}
          value={newAgentData.lastName}
          name="lastName"
          type="text"
          label="Primer Apellido"
          inputProps={{ autoCapitalize: "words" }}
        />
        <TextField
          helperText=" "
          variant="outlined"
          onChange={onChange}
          value={newAgentData.lastName2}
          name="lastName2"
          type="text"
          label="Segundo Apellido"
          inputProps={{ autoCapitalize: "words" }}
        />
        <TextField
          helperText=" "
          variant="outlined"
          onChange={onChange}
          value={newAgentData.email}
          type="email"
          name="email"
          label="Correo electrónico"
        />
        <TextField
          error={errorNumber}
          helperText={errorNumber ? "Ingresa los 10 dígitos" : " "}
          variant="outlined"
          onChange={(e) => {
            onChange(e);
            setErrorNumber(!(e.target.value.length === 10));
          }}
          value={newAgentData.phone}
          type="number"
          label="Teléfono"
          name="phone"
          placeholder="Ingrese los 10 dígitos"
        />
        <TextField
          helperText=" "
          variant="outlined"
          onChange={onChange}
          value={newAgentData.agentGroup}
          name="agentGroup"
          type="text"
          label="Clave de promotor o de promotoría (opcional)"
          sx={{
            gridColumn: ["auto", "span 2"],
          }}
        />
        <FormLabel
          sx={{
            gridColumn: ["auto", "span 2"],
          }}
        >
          ¿Cuentas con cédula para la venta de seguros de autos?
        </FormLabel>
        <Box
          sx={{
            gridColumn: ["auto", "span 2"],
            textAlign: "left",
          }}
        >
          <FormControl component="fieldset">
            <RadioGroup
              name="cedula"
              value={newAgentData.cedula}
              onChange={(e) => {
                onChange(e);
                setCedulaImg(null);
              }}
            >
              <FormControlLabel
                value="true"
                control={<Radio color="secondary" />}
                label="Sí"
              />
              <FormControlLabel
                value="false"
                control={<Radio color="secondary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
          {newAgentData.cedula === "true" && (
            <FormControl>
              <FormLabel
                sx={{
                  textAlign: "center",
                }}
              >
                Por favor, carga una copia o foto de tu cédula. Es opcional,
                ayudará a acelerar el proceso de registro.
              </FormLabel>
              <br />
              {cedulaImg &&
                (cedulaImg.name.split(".").pop() === "pdf" ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PictureAsPdfIcon />
                    <p>{cedulaImg.name}</p>
                  </div>
                ) : (
                  <img
                    ref={imgRef}
                    style={{ width: "350px", margin: "0 auto 20px" }}
                    alt="imagen cedula"
                  />
                ))}
              <input
                type="file"
                onChange={(e) => {
                  setCedulaImg(e.target.files[0]);
                }}
                accept="image/png, image/jpeg, image/jpg, application/pdf"
                ref={inputImgRef}
                style={{
                  width: "0.1px",
                  height: "0.1px",
                  opacity: "0",
                  overflow: "hidden",
                  position: "absolute",
                  zIndex: "-1",
                }}
              />
              <Button
                onClick={() => inputImgRef.current.click()}
                type="button"
                variant="outlined"
              >
                Cargar Archivo
              </Button>
            </FormControl>
          )}
        </Box>

        <Typography
          sx={{
            gridColumn: ["auto", "span 2"],
          }}
        >
          Datos de la empresa
        </Typography>

        <TextField
          error={errorRFC}
          helperText={errorRFC && "Ingresa los 12 dígitos"}
          variant="outlined"
          onChange={(e) => {
            onChange(e);
          }}
          value={newAgentData.rfc}
          type="text"
          name="rfc"
          label="RFC"
          placeholder={"Ingresa los 12 dígitos"}
          inputProps={{
            maxLength: 13,
            autoCapitalize: "characters",
          }}
        />
        <TextField
          helperText=" "
          variant="outlined"
          onChange={onChange}
          value={newAgentData.companyName}
          name="companyName"
          type="text"
          label="Nombre fiscal"
          inputProps={{ autoCapitalize: "words" }}
        />
        <AutocompleteAddress
          setNewAgentData={setNewAgentData}
          newAgentData={newAgentData}
          placeholderInput={
            Boolean(newAgentData.isPersonaMoral)
              ? "Domicilio fiscal de la empresa"
              : "Ingresa tu domicilio"
          }
        />
      </Box>

      <FormControlLabel
        control={<Checkbox checked={Boolean(newAgentData.terms)} />}
        name="terms"
        onChange={onChange}
        label={
          <p>
            Estoy de acuerdo con el{" "}
            {
              <a
                href="https://auto-clupp.web.app/privacy/aviso_privacidad_interno_vigente.pdf"
                target="_blank"
                rel="noreferrer"
              >
                aviso de privacidad
              </a>
            }
          </p>
        }
      />
      {refferedUser && (
        <Typography>
          Estas siendo referido por: {refferedUser.firstName}
        </Typography>
      )}

      <LinearProgress
        style={{
          opacity: isLoading ? 1 : 0,
        }}
      />

      <LoadingButton
        color="primary"
        variant="contained"
        type="submit"
        loading={isLoading}
      >
        Registrarme como agente
      </LoadingButton>
    </form>
  );
}
