export default function getTotalPremium(
  monthlyPremium,
  paymentPeriod,
  options = { msi: false, haveDiscount: false }
) {

  const { msi } = options;
  if (paymentPeriod === "year" && msi) {
    const discountPercentage = options.haveDiscount
      ? options.discount.percentage
      : 6;
    const premium = monthlyPremium * 12;
    const premiumDiscount = Math.floor((discountPercentage * premium) / 100);
    return [
      Math.ceil(premium - premiumDiscount),
      Math.round(premiumDiscount),
      premium,
    ];
  }

  switch (paymentPeriod) {
    case "month":
      if (options.haveDiscount) {
        const discountPercentage = options.discount.percentage;
        const premium = monthlyPremium;
        const premiumDiscount = (discountPercentage * premium) / 100;

        return [
          Math.ceil(premium - premiumDiscount),
          Math.round(premiumDiscount),
          premium,
        ];
      }
      return [monthlyPremium, 0, monthlyPremium];

    case "3month": {
      const discountPercentage = options.haveDiscount
        ? options.discount.percentage
        : 3;
      const premium = monthlyPremium * 3;
      const premiumDiscount = (discountPercentage * premium) / 100;
      return [
        Math.ceil(premium - premiumDiscount),
        Math.round(premiumDiscount),
        premium,
      ];
    }

    case "6month": {
      const discountPercentage = options.haveDiscount
        ? options.discount.percentage
        : 6;
      const premium = monthlyPremium * 6;
      const premiumDiscount = (discountPercentage * premium) / 100;
      return [
        Math.ceil(premium - premiumDiscount),
        Math.round(premiumDiscount),
        premium,
      ];
    }

    case "year": {
      const discountPercentage = options.haveDiscount
        ? options.discount.percentage
        : 10;
      const premium = monthlyPremium * 12;
      const premiumDiscount = Math.floor((discountPercentage * premium) / 100);
      return [
        Math.ceil(premium - premiumDiscount),
        Math.round(premiumDiscount),
        premium,
      ];
    }
  }
}
