import { createContext, useContext, useEffect, useState } from "react";
import { auth, db } from "./Firebase/firebase";
import { useLocation } from "react-router-dom";
import { useAgentAuth } from "./AuthAgentContext";
import logError from "./services/logError";
import { v4 as uuidv4 } from "uuid";

function generateNewUuid() {
  const newUid = uuidv4().replace(/-/g, "");
  localStorage.setItem("currentUserId", newUid);
  localStorage.setItem("docRef", newUid);

  return newUid;
}

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [docRef, setDocRef] = useState();
  const [currentVehicle, setCurrentVehicle] = useState();
  const [userDataContext, setUserData] = useState();
  const [vehicleDataContext, setVehicleData] = useState();
  const {
    currentAgentUser,
    isAgentLogged,
    isUserAgentAdmin,
    setCurrentAgentUser,
    setAgentData,
  } = useAgentAuth();
  let currentLocation = useLocation();

  const loggedUser = auth.currentUser;
  const loginAuth = () => auth.signInAnonymously();
  const logout = () => auth.signOut();
  const resetPassword = (email) => auth.sendPasswordResetEmail(email);

  useEffect(() => {
    let agentUnsubscribe;
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const getUserAgentAdmin = user && (await isUserAgentAdmin()); //
      if (
        currentLocation.pathname === "/agentes/inicio-de-sesion" &&
        user?.isAnonymous === true
      ) {
        await logout();
      } else if (getUserAgentAdmin === true) {
        agentUnsubscribe = db
          .doc(`agentProspects/${user.uid}`)
          .onSnapshot((snapshot) => {
            const data = snapshot.data();
            setAgentData({ ...data, uid: snapshot.id });
          });
        setCurrentAgentUser(user);
        setCurrentUser({
          uid: localStorage.getItem("currentUserId")
            ? localStorage.getItem("currentUserId")
            : generateNewUuid(),
        });
      } else if (user) {
        setCurrentUser(user);
      } else if (currentLocation.pathname !== "/agentes/inicio-de-sesion") {
        try {
          await loginAuth();
        } catch (e) {
          logError({
            error: e.toString(),
            metadata: {
              userAgent: navigator.userAgent,
              urlPath: currentLocation?.pathname
                ? currentLocation.pathname
                : "",
              descripcion: "Error al iniciar sesión con usuario anonimo",
              agentUser: currentAgentUser?.uid
                ? currentAgentUser?.uid
                : "undefined",
              user: currentUser?.uid ? currentUser?.uid : "undefined",
              docRef: docRef ? docRef : "undefined",
              timestamp: new Date().getTime(),
            },
          });
          console.log(e);
        }
      } else {
        setCurrentAgentUser(null);
        // console.log("no user");
      }
      setLoading(false);
    });
    return () => {
      unsubscribe();
      if (agentUnsubscribe) agentUnsubscribe();
    };
  }, [currentAgentUser]);


  useEffect(() => {
    const getRef = localStorage.getItem("docRef");
    setDocRef(getRef);
    const getCurrentVehicle = localStorage.getItem("currentVehicle");
    setCurrentVehicle(getCurrentVehicle);
  }, []);

  // useEffect(() => {
  //   if (docRef) {
  //     docRef &&
  //       db
  //         .collection("prospectsNew")
  //         .doc(docRef)
  //         .get()
  //         .then((doc) => {
  //           if (doc.exists) {
  //             setUserData(doc.data());
  //           } else {
  //             // doc.data() will be undefined in this case
  //             console.log("No such document!");
  //           }
  //         })
  //         .catch((error) => {
  //           console.log("Error getting document:", error);
  //         });
  //     docRef &&
  //       db
  //         .collection("prospectsNew")
  //         .doc(docRef)
  //         .collection("vehicles")
  //         .doc(currentVehicle)
  //         .get()
  //         .then((doc) => {
  //           if (doc.exists) {
  //             setVehicleData(doc.data());
  //           } else {
  //             // doc.data() will be undefined in this case
  //             console.log("No such document!");
  //           }
  //         })
  //         .catch((error) => {
  //           console.log("Error getting document:", error);
  //         });
  //   }
  // }, [currentVehicle, docRef]);

  const value = {
    currentUser,
    loginAuth,
    userDataContext,
    vehicleDataContext,
    logout,
    setCurrentUser,
    loading,
    loggedUser,
    resetPassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
