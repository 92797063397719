import fetch from "node-fetch";
const authToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdhbml6YXRpb24iOiJSQVNUUkVBVE9SIn0.OGp_G6Lref09-YkoRsY3vBUEndMpAbhUR-tHHu-nlm4";
const getAgentProgress = async ({
  uid,
  quarterTimestamps,
  isForLastBonusCollect,
}) => {
  try {
    let path = `https://us-central1-auto-clupp.cloudfunctions.net/cluppAPIV1/v1/agents/bonus-progress/${uid}?start=${quarterTimestamps.start}&end=${quarterTimestamps.end}`;

    if (isForLastBonusCollect) {
      path += `&isForLastBonusCollect=true`;
    }
    const response = await fetch(path, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching agent progress:", error);
    throw error;
  }
};

export default getAgentProgress;
