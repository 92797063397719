const BASE_URL = "https://servicios.woowtodobien.com/Clupp/ligaCobro";
export async function getWoowPaymentLink({
  amount,
  description,
  token,
  period,
  email,
  uid,
  vid,
  vehicleType,
  firstName,
}) {
  const PERIOD_DIC = {
    month: "Mensual",
    "3month": "Trimestral",
    "6month": "Semestral",
    year: "Anual",
  };

  if (!(period in PERIOD_DIC)) {
    throw new Error("invalid-period");
  }

  const urlSuccess = new URL("https://cluppseguro.web.app/bienvenida");
  urlSuccess.searchParams.append("email", email);
  urlSuccess.searchParams.append("firstName", firstName);
  urlSuccess.searchParams.append("v", "woow");

  const body = {
    urlSuccess: urlSuccess.toString(),
    descripcion: description,
    monto: Number(amount),
    periodicidad: PERIOD_DIC[period],
    identificador: vid,
    tipo: vehicleType === "otro" ? "other" : vehicleType,
  };

  const response = await fetch(BASE_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(body),
  });

  return response.json();
}

export async function getWoowEmployee(token) {
  const baseURL = "https://clupp-api.web.app/v1/clupp/integrations/wow/user";

  try {
    const response = await fetch(baseURL, {
      method: "POST",
      headers: {
        Origin: "clupp",
        Referer: "clupp",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    });

    if (!response.ok) {
      const errorMessage = `Error ${response.status}: ${response.statusText}`;
      throw new Error(errorMessage);
    }

    const json = await response.json();

    if (!json || !json.data) {
      throw new Error("Error: Respuesta no válida del servidor");
    }

    return json.data;
  } catch (error) {
    console.error("Error al realizar la solicitud:", error);
    return null;
  }
}
