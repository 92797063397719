import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  RadioGroup,
  FormControl,
  Radio,
  FormControlLabel,
  Button,
} from "@mui/material";
import { Redirect, Link } from "react-router-dom";
import { db, storage } from "../../Firebase/firebase";
import Swal from "sweetalert2";
import logError from "../../services/logError";
import AgentesRegistroForm from "./AgentesRegistroForm";
import { useAgentAuth } from "../../AuthAgentContext";
import useUser from "../../hooks/useUser";
import useQuery from "../../hooks/useQuery";
import useReadImage from "../../hooks/useReadImage";
import whatsappIcon from "../../Assets/SVG/Group 9260.svg";
import getWhatsAppUrl from "../../utils/getWhatsAppUrl";
import { useHistory } from "react-router-dom";
import AgentesRegistroFormMoral from "./AgentesRegistroFormMoral";
import { useAuth } from "../../AuthContext";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";
import cluppyLogo from "../../Assets/PNG/Clupp_SeñaAbajo.png";

const parseAgentData = {
  firstName: "Nombre",
  lastName: "Primer apellido",
  lastName2: "Segundo apellido",
  rfc: "RFC",
  email: "Email",
  phone: "Teléfono",
  cedula: "Cédula",
  companyName: "Nombre de la empresa",
};
const agentDataInitialState = {
  firstName: "",
  lastName: "",
  lastName2: "",
  rfc: "",
  email: "",
  phone: "",
  address: {
    zipCode: "",
  },
  cedula: "",
  agentGroup: "",
  timestamp: Date.now(),
  isApproved: false,
  terms: false,
  isTest: false,
};

export default function AgentesRegistro() {
  const [newAgentData, setNewAgentData] = useState(agentDataInitialState);
  const [isLoading, setIsLoading] = useState(false);
  const [errorRFC, setErrorRFC] = useState(false);
  const [errorNumber, setErrorNumber] = useState(false);
  const [formType, setFormType] = useState("");
  const { currentUser } = useAuth();

  const { isAgentLogged } = useAgentAuth();
  const { imgRef, inputImgRef, inputImage, setInputImage } = useReadImage();
  const history = useHistory();
  const urlWhatsapp = getWhatsAppUrl(
    "Hola, tengo problemas para registrarme como agente."
  );

  let query = useQuery();

  const referredByQuery = query.get("referredBy");
  const customerQuery = query.get("customer");

  const { user: refferedUser } = useUser(referredByQuery, "agentProspects");
  const { user: customerLogged } = useUser(customerQuery, "users");

  const saveNewAgentData = async (data) => {
    const response = await fetch("https://clupp-api.web.app/v1/clupp/agents", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const resJson = await response.json();

    if (resJson.success === false) {
      throw new Error(resJson);
    }
    return resJson;
  };

  useEffect(() => {
    if (customerLogged) {
      const lastname = customerLogged.lastName?.split(" ");
      const lastName2 =
        lastname.length >= 2 ? lastname.slice(1).join(" ") : undefined;
      setNewAgentData((prev) => ({
        ...prev,
        firstName: customerLogged?.firstName,
        lastName: lastname[0],
        lastName2: lastName2,
        email: customerLogged?.email,
        rfc: customerLogged?.rfc,
        phone: Number(customerLogged?.phone?.replace(/[^\w]/gi, "")),
      }));
    }
  }, [customerLogged]);

  function isAllEmpty(obj) {
    const { agentGroup, isApproved, terms, isTest, ...data } = obj;
    const values = [...Object.values(data)];
    const isEmptyString = values.includes("");

    if (
      !isEmptyString
      // obj?.companyName !== undefined &&
      // obj?.companyName !== ""
    ) {
      return false;
    } else if (!isEmptyString && newAgentData.terms === false) {
      return false;
    } else {
      return true;
    }
  }

  function titleCase(str) {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  function deleteExtraSpaces(obj) {
    for (let [key, value] of Object.entries(obj)) {
      obj[key] = typeof value === "string" ? value.trim() : value;
    }
  }

  function cleanData() {
    setNewAgentData(agentDataInitialState);
    setInputImage(null);
  }

  function onChange(e) {
    let newData;
    if (e.target.name === "rfc") {
      newData = {
        [e.target.name]: e.target.value.toUpperCase(),
      };
    } else if (e.target.name === "terms") {
      newData = {
        [e.target.name]: !newAgentData.terms,
      };
    } else if (e.target.name === "companyName") {
      newData = {
        [e.target.name]:
          e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
      };
    } else if (e.target.name === "agentGroup") {
      newData = {
        [e.target.name]: e.target.value.toUpperCase(),
      };
    } else if (e.target.name !== "email" && e.target.name !== "cedula") {
      newData = {
        [e.target.name]: titleCase(e.target.value),
      };
    } else if (e.target.name === "email") {
      newData = {
        [e.target.name]: e.target.value.toLowerCase(),
      };
    }else {
      newData = {
        [e.target.name]: e.target.value,
      };
    }
    setNewAgentData((prev) => ({
      ...prev,
      ...newData,
    }));
  }

  async function handlerSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    if (isAllEmpty(newAgentData)) {
      const { agentGroup, isApproved, terms, isTest, address, ...data } =
        newAgentData;
      const values = [...Object.keys(data)];

      const emptyData = [];
      values.forEach((item) => {
        if (!data[item]) {
          emptyData.push(parseAgentData[item]);
        }
      });

      Swal.fire({
        icon: "warning",
        html: `<p>Por favor, completa los siguientes campos faltantes:<ul>
        ${emptyData
          .map((item) => `<li style="text-align: left">${item}</li>`)
          .join(" ")}
        </ul></p>`,
      });
      setIsLoading(false);
      return;
    }

    if (newAgentData.terms === false) {
      Swal.fire({
        icon: "warning",
        text: "Para continuar con la solicitud de registro, por favor acepta nuestro aviso de privacidad",
      });

      setIsLoading(false);
      return;
    }

    const { cedula, terms, isPersonaMoral, ...filterDataToSave } = newAgentData;

    deleteExtraSpaces(filterDataToSave);

    if (!errorRFC && !errorNumber) {
      if (newAgentData.cedula === "true" && inputImage) {
        const refImg = storage.ref(`agentProspects/${inputImage.name}`);
        try {
          const task = refImg.put(inputImage);
          await task.on("state_change", {
            error: (error) => {},
            complete: async () => {
              const imgURL = await refImg.getDownloadURL();

              try {
                const saveData = {
                  ...filterDataToSave,
                  ...(cedula === "true" && { cedulaURL: imgURL }),
                  ...(referredByQuery && {
                    refferedBy: referredByQuery,
                  }),
                };
                const response = await saveNewAgentData(saveData);

                console.log(response);
                setIsLoading(false);
                Swal.fire({
                  icon: "success",
                  text: "Se ha guardado la información",
                }).then(() => {
                  history.push("/agentes/registro/en-espera");
                });
              } catch (e) {
                console.warn(e);
              }
            },
          });
        } catch (error) {
          console.log(error);
          await logError({
            error: error.toString(),
            metadata: {
              descripcion:
                "Error al subir imagen o pdf de cedula del agente en firestore",
              data: filterDataToSave,
            },
          });
          Swal.fire({
            icon: "error",
            text: "Ocurrió un error, contacta a un agente de ventas",
          });
        }
      } else {
        try {
          const saveData = {
            ...filterDataToSave,
            ...(referredByQuery && {
              referredBy: referredByQuery,
            }),
          };
          const response = await saveNewAgentData(saveData);
          console.log(response);
          Swal.fire({
            icon: "success",
            text: "Se ha guardado la información",
          }).then(() => {
            history.push("/agentes/registro/en-espera");
          });
        } catch (error) {
          console.log(error);
          await logError({
            error: error.toString(),
            metadata: {
              descripcion:
                "Error al registrar la información nueva de agente en firestore",
              data: filterDataToSave,
            },
          });
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Ocurrió un error",
            html: `<p>Puedes contactar a uno de nuestros agentes <a href='${urlWhatsapp}' target='__blank' >aquí</a></p>`,
          });
        } finally {
          setIsLoading(false);
        }
      }
      cleanData();
    } else if (errorNumber) {
      Swal.fire({
        icon: "warning",
        text: "Formato de número teléfonico incorrecto",
      });

      setIsLoading(false);
      return;
    } else if (errorRFC) {
      Swal.fire({
        icon: "warning",
        text: "Formato del RFC incorrecto",
      });

      setIsLoading(false);
      return;
    }
  }

  if (isAgentLogged) return <Redirect to="/agentes/dashboard" />;

  return (
    <>
      <DashboardHeader
        isAgentSection={true}
        redirectLogoUrl={"https://clupp.com.mx/"}
      />
      <Box
        style={{
          display: "grid",
          placeItems: "center",
          minHeight: "100vh",
          background: "#fff",
          marginTop: "60px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            maxWidth: "680px",
            gap: 25,
            margin: "20px",
          }}
        >
          <Typography variant="h6" component="article">
            Regístrate como agente
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <img src={cluppyLogo} alt="cluppy logo" style={{ width: "60px" }} />
            <a
              href="https://docsend.com/view/92vbzcrf4n8daqxc"
              target="__blank"
              style={{ textDecoration: "none" }}
            >
              <Button color="secondary" variant="contained"><span style={{color: "#fff"}}>Ver más información</span></Button>
            </a>
          </Box>
          <Typography variant="body1">
            ¡Hola! En esta sección podrás registrarte para solicitar acceso a
            nuestro canal de agentes. Nuestr@s agentes generan comisiones del
            10% de las ventas que llevan a cabo, y participan en bonos y premios
            de manera trimestral. Regístrate y comienza a incrementar tus
            ingresos con Clupp. Nuestro proceso de evaluación toma menos de 48
            horas.
          </Typography>
          <FormControl component="fieldset" sx={{ margin: "0 auto" }}>
            <RadioGroup
              name="formType"
              value={formType}
              onChange={(e) => {
                setFormType(e.target.value);
                if (e.target.value === "false") newAgentData.companyName = "";
                setNewAgentData((prev) => ({ ...prev }));
                if (e.target.value === "true")
                  setNewAgentData((prev) => {
                    delete prev.companyName;
                    return { ...prev };
                  });
              }}
            >
              <FormControlLabel
                value="true"
                control={<Radio color="secondary" />}
                label="Registrarme como persona física"
              />
              <FormControlLabel
                value="false"
                control={<Radio color="secondary" />}
                label="Registrarme como persona moral (aliados, promotorías, agentes, empresas)"
                sx={{ textAlign: "left" }}
              />
            </RadioGroup>
          </FormControl>
        </div>
        {formType === "true" && (
          <AgentesRegistroForm
            type={formType}
            handlerSubmit={handlerSubmit}
            onChange={onChange}
            newAgentData={newAgentData}
            errorRFC={errorRFC}
            setErrorRFC={setErrorRFC}
            errorNumber={errorNumber}
            setErrorNumber={setErrorNumber}
            setCedulaImg={setInputImage}
            cedulaImg={inputImage}
            imgRef={imgRef}
            inputImgRef={inputImgRef}
            refferedUser={refferedUser}
            isLoading={isLoading}
            setNewAgentData={setNewAgentData}
          />
        )}
        {formType === "false" && (
          <AgentesRegistroFormMoral
            type={formType}
            handlerSubmit={handlerSubmit}
            onChange={onChange}
            newAgentData={newAgentData}
            errorRFC={errorRFC}
            setErrorRFC={setErrorRFC}
            errorNumber={errorNumber}
            setErrorNumber={setErrorNumber}
            setCedulaImg={setInputImage}
            cedulaImg={inputImage}
            imgRef={imgRef}
            inputImgRef={inputImgRef}
            refferedUser={refferedUser}
            isLoading={isLoading}
            setNewAgentData={setNewAgentData}
          />
        )}
        {currentUser && currentUser?.isAnonymous === true && (
          <Typography sx={{ marginBottom: "20px" }}>
            Si ya eres agente inicia sesión a{" "}
            <Link to="/agentes/inicio-de-sesion">aquí</Link>
          </Typography>
        )}
        <a href={urlWhatsapp} target="__blank" style={{ zIndex: "" }}>
          <img
            src={whatsappIcon}
            alt="whatsapp helper icon"
            style={{
              position: "fixed",
              bottom: 10,
              right: 10,
              width: "50px",
              stroke: "green",
            }}
          />
        </a>
      </Box>
    </>
  );
}
