import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import QueCubreModal from "../Modal/QueCubreModal";
import ModalCobertura from "../ModalCobertura/ModalCobertura";
import ModalCoberturaPlats from "../ModalCobertura/ModalCoberturaPlats";
import "./TablaCobertura.css";
// import FormatAmout from '../../FormatAmout/FormatAmout';
// import coberturas from '../../Assets/coberturas';

const TablaCoberturaVehiculos = ({ amounts, vehicleType, vehicleData }) => {
  vehicleData.valueCatalog = 0;
  const type = () => {
    if (vehicleType === "bike") return "bicicleta";
    else if (vehicleType === "eBike") return "bicicleta eléctrica";
    else if (vehicleType === "scooter") return "monopatín";
    else if (vehicleType === "auto") return "auto";
    else if (vehicleType === "moto") return "moto";
    return "vehículo";
  };

  const coberturas = {
    dañosvehiculo: {
      title: "Daños al vehículo:",
      content: `Cubre daños materiales de tu ${
        vehicleType && type()
      } en caso de que tengas un accidente. Esta cobertura tiene un deducible de 5%. Esto significa que en caso de siniestro, a ti te toca cubrir el 5% del monto contratado, y a nosotros el resto.`,
    },
    dañosmateriales: {
      title: "Daños materiales:",
      content: `Cubre daños materiales de tu ${
        vehicleType && type()
      } en caso de que tengas un accidente. Esta cobertura tiene un deducible de 10%. Esto significa que en caso de siniestro, a ti te toca cubrir el 10% del monto contratado, y a nosotros el resto.`,
    },
    robototal: {
      title: "Robo total del vehículo:",
      content: `Cubre el robo total de tu ${
        vehicleType && type()
      }. Esta cobertura tiene un deducible de 10%. Esto significa que en caso de robo total, nos toca cubrir el 90%`,
    },
    robototalplataformas: {
      title: "Robo total del vehículo:",
      content: `Cubre el robo total de tu ${
        vehicleType && type()
      }. Esta cobertura tiene un deducible de 20%. Esto significa que en caso de robo total, nos toca cubrir el 80%`,
    },
    dañosterceros: {
      title: "Daños que ocasiones a terceros:",
      content: `Cubre los daños que ocasiones a otros con tu ${
        vehicleType && type()
      } a causa de un accidente. Esto incluye tanto a personas como objetos. Pero no incluye los daños que se ocasionen a los ocupantes de tu ${
        vehicleType && type()
      }. Esta cobertura no tiene deducible. Esto significa que no tienes nada de qué preocuparte, nosotros cubrimos todo.`,
    },
    responsabilidadcivil: {
      title: "Responsabilidad civil:",
      content: `Cubre los daños que ocasiones a otros con tu ${
        vehicleType && type()
      } a causa de un accidente. Esto incluye tanto a personas como objetos. Pero no incluye los daños que se ocasionen a los ocupantes de tu ${
        vehicleType && type()
      }. `,
    },
    dañostercerosotros: {
      title: "Daños que ocasiones a terceros:",
      content: `Cubre los daños que ocasiones a otros con tu ${
        vehicleType && type()
      } a causa de un accidente. Esto incluye tanto a personas como objetos. Pero no incluye los daños que se ocasionen a los ocupantes de tu ${
        vehicleType && type()
      }. `,
    },
    accidentespersonales: {
      title: "Accidentes Personales:",
      content: `Cubre los gastos médicos que se generen a causa de que tengas un accidente que te cause lesiones físicas en cualquier momento mientras no estés en tu vehículo. No importa si ocurre en el carro de un amigo, en el transporte público, en tu casa o en cualquier lugar. Esta cobertura tiene un deducible de $500 por evento.`,
    },
    choferprivado: {
      title: "Chofer privado en caso de accidente:",
      content: `En caso de accidente y que tu ${
        vehicleType && type()
      } se tenga que ir al taller, te damos saldo en Uber, ¡para que te sigas moviendo como normalmente lo haces!`,
    },
    asistenciavial: {
      title: "Asistencia vial:",
      content:
        vehicleType === "moto"
          ? `Cubre los servicios de asistencia que requiera tu ${type()}. Por ejemplo, si necesitas grúa, te quedaste sin gasolina, o si te quedaste sin batería`
          : `Cubre los servicios de asistencia que requiera tu ${
              vehicleType && type()
            }. Por ejemplo, si necesitas grúa, te quedaste sin gasolina, requieres cambio de llanta, te quedaste sin batería, o si  requieres un cerrajero.`,
    },
    asistenciajuridica: {
      title: "Asistencia legal:",
      content: `En caso de que lo necesites, si causaste daños con tu ${
        vehicleType && type()
      } o fue robado, cubrimos tus gastos de defensa jurídica a través de los abogados que nosotros designamos. En el caso de que ocasiones daños con cualquier otro vehículo, no podemos ayudarte con los gastos legales.`,
    },
    gastosmedicos: {
      title: "Gastos médicos:",
      content: `Cubre los gastos médicos a tu persona a causa de un accidente en tu ${
        vehicleType && type()
      }. Este monto es el total tanto para ti como para los demás ocupantes de tu ${
        vehicleType && type()
      }. Esta cobertura no tiene deducible. Esto significa que no tienes nada de qué preocuparte, nosotros cubrimos todo. `,
    },
    gastosmedicosotros: {
      title: "Gastos médicos:",
      content: `Cubre los gastos médicos a tu persona a causa de un accidente en tu ${
        vehicleType && type()
      }.`,
    },
    robocelular: {
      title: "Robo de celular",
      content:
        "Con esta cobertura en caso de que te roben tu celular nosotros te lo reponemos por uno igual o de características similares. Para hacer uso de esta cobertura será necesario que hagas el reporte de robo y la denuncia correspondiente. Esta cobertura opera sin deducible siempre y cuando hayas estado registrando un trayecto con la aplicación móvil de Clupp en el momento del robo, en otro caso, tiene un deducible del 40%. No cubre pérdida del equipo, daños o fallas.",
    },
    roturaCristales: {
      title: "Rotura de cristales",
      content:
        "Cubre el cambio y/o reparación de los cristales por rotura accidental. Aplica un 20% de deducible del costo de reparación.",
    },
  };
  return (
    <div className="table-container">
      <h3>Conoce tus coberturas: </h3>

      {vehicleType === "auto" ||
      vehicleType === "moto" ||
      vehicleType === "otro" ? (
        <p>
          Todos nuestros planes <strong>son de cobertura amplia</strong>, es
          decir, todos cubren:
        </p>
      ) : (
        <p>
          Este plan <strong>es de cobertura amplia</strong>, es decir, que
          cubre:
        </p>
      )}
      {vehicleData.features &&
      vehicleData.features.includes("Soy conductor de plataforma") ? (
        <TableContainer>
          <Table aria-label="customized table">
            <TableHead className="tablehead-tabla-cobertura">
              <TableRow>
                <TableCell align="center">Cobertura</TableCell>
                <TableCell align="center">Valor cubierto</TableCell>
                <TableCell align="center">Lo que tu pagas</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Daños materiales
                  <QueCubreModal
                    title={coberturas.dañosmateriales.title}
                    content={coberturas.dañosmateriales.content}
                  />
                </TableCell>
                <TableCell align="justify">
                  $
                  {amounts &&
                    new Intl.NumberFormat("es-MX").format(
                      Math.ceil(amounts.damageAmount)
                    )}
                </TableCell>
                <TableCell align="center">
                  10% SA <br />
                  <span className="desc">
                    {" "}
                    $
                    {amounts &&
                      new Intl.NumberFormat("es-MX").format(
                        Math.ceil(amounts.damageAmount * 0.1)
                      )}
                  </span>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Robo total
                  <QueCubreModal
                    title={coberturas.robototalplataformas.title}
                    content={coberturas.robototalplataformas.content}
                  />
                </TableCell>
                <TableCell align="justify">Valor comercial</TableCell>
                <TableCell align="center">20% SA</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Responsabilidad civil
                  <QueCubreModal
                    title={coberturas.responsabilidadcivil.title}
                    content={coberturas.responsabilidadcivil.content}
                  />
                </TableCell>
                <TableCell align="justify">$3,000,000</TableCell>
                <TableCell align="center">
                  {`50 `}
                  <a
                    href="https://www.inegi.org.mx/temas/uma/#Informacion_general"
                    target="_blank"
                    rel="noreferrer"
                    className="cobertura"
                  >
                    UMAs
                  </a>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Gastos médicos ocupantes
                  <QueCubreModal
                    title={coberturas.gastosmedicos.title}
                    content={coberturas.gastosmedicos.content}
                  />
                </TableCell>
                <TableCell align="justify">$200,000</TableCell>
                <TableCell align="center">Sin deducible</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Asistencia vial
                  <QueCubreModal
                    title={coberturas.asistenciavial.title}
                    content={coberturas.asistenciavial.content}
                  />
                </TableCell>
                <TableCell align="justify">2 al año</TableCell>
                <TableCell align="center">Sin deducible</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Asistencia legal
                  <QueCubreModal
                    title={coberturas.asistenciajuridica.title}
                    content={coberturas.asistenciajuridica.content}
                  />
                </TableCell>
                <TableCell align="justify">Incluido</TableCell>
                <TableCell align="center">Sin deducible</TableCell>
              </TableRow>

              {/* <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Robo de celular
                  <QueCubreModal title={coberturas.robocelular.title} content={coberturas.robocelular.content} />
                </TableCell>
                <TableCell align="justify">Valor comercial</TableCell>
                <TableCell align="center">Sin deducible</TableCell>
              </TableRow> */}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer>
          <Table aria-label="customized table">
            <TableHead className="tablehead-tabla-cobertura">
              <TableRow>
                <TableCell align="center">Cobertura</TableCell>
                <TableCell align="center">Valor cubierto</TableCell>
                <TableCell align="center">Deducible</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Daños al vehículo
                  <QueCubreModal
                    title={coberturas.dañosvehiculo.title}
                    content={coberturas.dañosvehiculo.content}
                  />
                </TableCell>
                {vehicleType === "auto" ? (
                  <TableCell align="justify">
                    $
                    {amounts &&
                      new Intl.NumberFormat("es-MX").format(
                        Math.ceil(amounts.damageAmount)
                      )}
                  </TableCell>
                ) : (
                  <TableCell align="justify">
                    $
                    {amounts &&
                      Math.ceil(amounts.damageAmount).toLocaleString("en-US")}
                  </TableCell>
                )}
                {vehicleType === "auto" ? (
                  <TableCell align="center">
                    5% <br />
                    <span className="desc">
                      {" "}
                      $
                      {amounts &&
                        new Intl.NumberFormat("es-MX").format(
                          Math.ceil(amounts.damageAmount * 0.05)
                        )}
                    </span>
                  </TableCell>
                ) : (
                  <TableCell align="center">
                    5% <br />
                    <span className="desc">
                      {" "}
                      $
                      {amounts &&
                        Math.ceil(amounts.damageAmount * 0.05).toLocaleString(
                          "en-US"
                        )}
                    </span>
                  </TableCell>
                )}
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Robo total del vehículo
                  <QueCubreModal
                    title={coberturas.robototal.title}
                    content={coberturas.robototal.content}
                  />
                </TableCell>
                <TableCell align="justify">Valor comercial</TableCell>
                <TableCell align="center">10%</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Daños que causes
                  <QueCubreModal
                    title={coberturas.dañosterceros.title}
                    content={coberturas.dañosterceros.content}
                  />
                </TableCell>
                <TableCell align="justify">
                  $
                  {amounts &&
                    new Intl.NumberFormat("es-MX").format(
                      amounts.liabilityAmount
                    )}
                </TableCell>
                <TableCell align="center"> Sin deducible</TableCell>
              </TableRow>

              {vehicleType === "auto" && (
                <TableRow>
                  <TableCell component="th" scope="row" align="center">
                    Rotura de cristales
                    <QueCubreModal
                      title={coberturas.roturaCristales.title}
                      content={coberturas.roturaCristales.content}
                    />
                  </TableCell>
                  <TableCell align="justify">Incluido</TableCell>
                  <TableCell align="center">20% de la reparación</TableCell>
                </TableRow>
              )}

              {(vehicleType === "auto" || vehicleType === "moto") && (
                <TableRow>
                  <TableCell component="th" scope="row" align="center">
                    Asistencia vial
                    <QueCubreModal
                      title={coberturas.asistenciavial.title}
                      content={coberturas.asistenciavial.content}
                    />
                  </TableCell>
                  <TableCell align="justify">Incluido</TableCell>
                  <TableCell align="center">Sin deducible</TableCell>
                </TableRow>
              )}

              {(vehicleType === "auto" || vehicleType === "moto") && (
                <TableRow>
                  <TableCell component="th" scope="row" align="center">
                    Asistencia legal
                    <QueCubreModal
                      title={coberturas.asistenciajuridica.title}
                      content={coberturas.asistenciajuridica.content}
                    />
                  </TableCell>
                  <TableCell align="justify">
                    Incluido
                    {/* $
                    {amounts &&
                      new Intl.NumberFormat("es-MX").format(
                        amounts.liabilityAmount
                      )} */}
                  </TableCell>
                  <TableCell align="center">Sin deducible</TableCell>
                </TableRow>
              )}
              <TableRow>
                {vehicleType === "auto" ? (
                  <TableCell component="th" scope="row" align="center">
                    Gastos médicos a ocupantes
                    <QueCubreModal
                      title={coberturas.gastosmedicos.title}
                      content={coberturas.gastosmedicos.content}
                    />
                  </TableCell>
                ) : vehicleType === "moto" ? (
                  <TableCell component="th" scope="row" align="center">
                    Gastos médicos a ocupantes
                    <QueCubreModal
                      title={coberturas.gastosmedicos.title}
                      content={coberturas.gastosmedicos.content}
                    />
                  </TableCell>
                ) : (
                  <TableCell component="th" scope="row" align="center">
                    Gastos médicos al contratante
                    <QueCubreModal
                      title={coberturas.gastosmedicosotros.title}
                      content={coberturas.gastosmedicosotros.content}
                    />
                  </TableCell>
                )}

                <TableCell align="justify">
                  $
                  {amounts &&
                    new Intl.NumberFormat("es-MX").format(
                      amounts.medicalExpensesAmount
                    )}
                </TableCell>
                {vehicleType === "auto" ? (
                  <TableCell align="center"> Sin deducible</TableCell>
                ) : vehicleType === "moto" ? (
                  <TableCell align="center"> Sin deducible</TableCell>
                ) : (
                  <TableCell align="center">$500</TableCell>
                )}
              </TableRow>

              {
                <TableRow>
                  <TableCell component="th" scope="row" align="center">
                    Accidentes personales
                    <QueCubreModal
                      title={coberturas.accidentespersonales.title}
                      content={coberturas.accidentespersonales.content}
                    />
                  </TableCell>
                  <TableCell align="justify">$20,000</TableCell>
                  <TableCell align="center">$500</TableCell>
                </TableRow>
              }

              {(vehicleType === "auto" || vehicleType === "moto") && (
                <TableRow>
                  <TableCell component="th" scope="row" align="center">
                    Chofer privado
                    <QueCubreModal
                      title={coberturas.choferprivado.title}
                      content={coberturas.choferprivado.content}
                    />
                  </TableCell>
                  <TableCell align="justify">Incluido</TableCell>
                  <TableCell align="center">Sin deducible</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <p>Podrás contratar coberturas extras en el siguiente paso.</p>
        </TableContainer>
      )}
      {vehicleData.features &&
      vehicleData.features.includes("Soy conductor de plataforma") ? (
        <ModalCoberturaPlats />
      ) : (
        <ModalCobertura />
      )}
    </div>
  );
};

export default TablaCoberturaVehiculos;
