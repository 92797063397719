import { useState } from "react";
import Dialog from "../Dialog";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { useAuth } from "../../AuthContext";
import { useAgentAuth } from "../../AuthAgentContext";
import Swal from "sweetalert2";
import logError from "../../services/logError";
import Captcha from "../Captcha/Captcha";
import isADevice from "../../utils/isADevice";

export default function OpcionesCotizacion({
  isOpen,
  onClose,
  email = "",
  phone = "",
  userData = {},
  vehicleData = {},
  mobility = "",
  onCloseQuotationModal,
  isWowUser,
}) {
  const docRef = localStorage.getItem("docRef");
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const { currentUser } = useAuth();
  const { currentAgentUser } = useAgentAuth();
  const [isOpenCaptchaDialog, setIsOpenCaptchaDialog] = useState(false);

  const [checkedOptionsMobility, setCheckedOptionsMobility] = useState({
    0: vehicleData?.mobility === 0 ? true : false,
    1: vehicleData?.mobility === 1 ? true : false,
    2: vehicleData?.mobility === 2 ? true : false,
    3: vehicleData?.mobility === 3 ? true : false,
  });
  const [checkedOptionsPeriodicity, setCheckedOptionsPeriodicity] = useState({
    month: userData?.period === "month" ? true : false,
    "3month": userData?.period === "3month" ? true : false,
    "6month": userData?.period === "6month" ? true : false,
    year: userData?.period === "year" ? true : false,
  });

  function handleOpenDialog() {
    if (currentAgentUser) {
      sendQuotation();
    } else {
      if (!email && !phone) {
        Swal.fire({
          icon: "warning",
          text: "Por favor, ingresa tu correo electrónico o número telefónico al que se enviará la cotización",
        });
      } else {
        setIsOpenCaptchaDialog((prev) => !prev);
      }
    }
  }

  function handleCloseDialog() {
    setIsOpenCaptchaDialog(false);
  }

  function handleOnChangeMobility(e) {
    setCheckedOptionsMobility((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  }

  function getQuoteinformation() {
    const periodsSelected = Object.keys(checkedOptionsPeriodicity).filter(
      (item) => checkedOptionsPeriodicity[item]
    );
    const mobilitySelected = Object.keys(checkedOptionsMobility)
      .filter((item) => checkedOptionsMobility[item])
      .map(Number);
      
    const quoteInformation = {
      periods: periodsSelected,
      plans: mobilitySelected,
    };
    return quoteInformation;
  }

  function handleDownloadQuotation() {
    setIsDownloading(true);
    const quoteInformation = getQuoteinformation();
    let dataUrl;

    fetch(
      `https://clupp-api.web.app/v1/clupp/prospects/${docRef}/send-quotation?sendEmail=false&sendWhatsApp=false&download=true`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(quoteInformation),
      }
    )
      .then((data) => data.json())
      .then((data) => {
        dataUrl = data.documentURL;
        Swal.fire({
          icon: "success",
          title: "Se ha generado la cotización",
          text: "Si no se ha descargado el archivo, por favor deshabilita el bloqueador de ventanas emergentes y vuelve a hacer clic en Descargar cotización.",
        });
        window.open(dataUrl);
      })
      .catch((error) => {
        logError({
          error: String(error),
          metadata: {
            description: "Error al descargar cotización",
            uid: currentUser?.uid ? currentUser?.uid : "undefined",
            agentUid: currentAgentUser?.uid
              ? currentAgentUser?.uid
              : "undefined",
            email: email,
            phone: phone,
            vehicleData: vehicleData,
          },
        });
      })
      .finally(() => setIsDownloading(false));
  }

  function handleOnChangePeriodicity(e) {
    setCheckedOptionsPeriodicity((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  }

  async function sendQuotation() {
    setIsLoading(true);
    if (email || phone) {
      let text;
      if (email && phone) {
        text = `Se ha enviado correctamente la cotización al correo ${userData?.email} y al número ${userData?.phone}. Esto puede tardar hasta 2 minutos. Si después de este tiempo no ha llegado, asegúrate de revisar la carpeta de correos no deseados.`;
      } else if (email && !phone) {
        text = `Se ha enviado correctamente la cotización al correo ${userData?.email}. Esto puede tardar hasta 2 minutos. Si después de este tiempo no ha llegado, asegúrate de revisar la carpeta de correos no deseados.`;
      } else if (!email && phone) {
        text = `Se ha enviado correctamente la cotización al número ${userData?.phone}.`;
      }
      const quoteInformation = getQuoteinformation();
      try {
        const response = await fetch(
          `https://clupp-api.web.app/v1/clupp/prospects/${docRef}/send-quotation?sendEmail=${
            email ? "true" : "false"
          }&sendWhatsApp=${phone ? "true" : "false"}&download=false`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(quoteInformation),
          }
        );
        Swal.fire({
          icon: "success",
          text: text,
          confirmButtonText: `Ok`,
        });

        console.log(await response.json());
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          text: "Algo a occurido mal",
        });
        logError({
          error: String(error),
          metadata: {
            uid: currentUser?.uid ? currentUser?.uid : "undefined",
            agentUid: currentAgentUser?.uid
              ? currentAgentUser?.uid
              : "undefined",
            email: email,
            phone: phone,
            vehicleData: vehicleData,
            description: "Error al enviar cotización",
          },
        });
      } finally {
        setIsLoading(false);
        handleCloseDialog();
        onClose();
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Por favor, ingresa tu correo electrónico o número telefónico al que se enviará la cotización",
      });
    }
    setIsLoading(false);
  }

  return (
    <div>
      <Captcha
        onClose={handleCloseDialog}
        isOpen={isOpenCaptchaDialog}
        callBack={sendQuotation}
        loading={isDownloading}
      />
      <Dialog
        sx={{ zIndex: "1050" }}
        isOpen={isOpen}
        onClose={onClose}
        title="Selecciona las opciones a cotizar"
        actions={
          <Box
            sx={{
              display: "flex",
              flexDirection: ["column", "row"],
              width: ["100%", "auto"],
            }}
          >
            {currentAgentUser && (
              <LoadingButton
                sx={{ marginBottom: ["10px", "0"] }}
                disabled={isLoading}
                loading={isDownloading}
                onClick={handleDownloadQuotation}
                variant="outlined"
              >
                Descargar cotización
              </LoadingButton>
            )}
            <LoadingButton
              disabled={isDownloading}
              loading={isLoading}
              sx={{ marginLeft: ["0", "10px"] }}
              variant="contained"
              onClick={handleOpenDialog}
            >
              Enviar cotización
            </LoadingButton>
          </Box>
        }
        content={
          <Box
            sx={{ display: "grid", gridTemplateColumns: ["1fr", "1fr 1fr"] }}
          >
            {["auto", "moto"].includes(vehicleData.type) && (
              <FormGroup
                onChange={handleOnChangeMobility}
                sx={{ marginBottom: ["25px", "0"] }}
              >
                <FormLabel component="legend">Movilidad</FormLabel>
                {/* <FormControlLabel
                  name="0"
                  control={
                    <Checkbox
                      sx={{ color: "#66bb6a" }}
                      color="secondary"
                      checked={checkedOptionsMobility[0]}
                    />
                  }
                  label="Mínima"
                /> */}
                <FormControlLabel
                  name="1"
                  control={
                    <Checkbox
                      sx={{ color: "#66bb6a" }}
                      color="secondary"
                      checked={checkedOptionsMobility[1]}
                    />
                  }
                  label="Reducida"
                />
                <FormControlLabel
                  name="2"
                  control={
                    <Checkbox
                      sx={{ color: "#66bb6a" }}
                      color="secondary"
                      checked={checkedOptionsMobility[2]}
                    />
                  }
                  label="Normal"
                />
                <FormControlLabel
                  name="3"
                  control={
                    <Checkbox
                      sx={{ color: "#66bb6a" }}
                      color="secondary"
                      checked={checkedOptionsMobility[3]}
                    />
                  }
                  label="Intensiva"
                />
              </FormGroup>
            )}
            {!Boolean(vehicleData.financingMonths) && (
              <FormGroup onChange={handleOnChangePeriodicity}>
                <FormLabel component="legend">Periodicidad de pago</FormLabel>
                {!(isADevice(vehicleData.type) && mobility === 1) &&
                  !(
                    (vehicleData.type === "auto" &&
                      vehicleData.valueUser < 100000) ||
                    (vehicleData.type === "moto" &&
                      vehicleData.valueUser < 30000)
                  ) && (
                    <FormControlLabel
                      name="month"
                      control={
                        <Checkbox
                          sx={{ color: "#66bb6a" }}
                          color="secondary"
                          checked={checkedOptionsPeriodicity.month}
                        />
                      }
                      label="Mensual"
                    />
                  )}
                {!isWowUser && (
                  <>
                    <FormControlLabel
                      name="3month"
                      control={
                        <Checkbox
                          sx={{ color: "#66bb6a" }}
                          color="secondary"
                          checked={checkedOptionsPeriodicity["3month"]}
                        />
                      }
                      label="Trimestral"
                    />
                    <FormControlLabel
                      name="6month"
                      control={
                        <Checkbox
                          sx={{ color: "#66bb6a" }}
                          color="secondary"
                          checked={checkedOptionsPeriodicity["6month"]}
                        />
                      }
                      label="Semestral "
                    />
                  </>
                )}

                <FormControlLabel
                  name="year"
                  control={
                    <Checkbox
                      sx={{ color: "#66bb6a" }}
                      color="secondary"
                      checked={checkedOptionsPeriodicity.year}
                    />
                  }
                  label="Anual"
                />
              </FormGroup>
            )}
          </Box>
        }
      />
    </div>
  );
}
