import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import whatsapp from "../../Assets/SVG/whatsapp-2.svg";
import "./PreguntasFrecuentes.css";
import Menu from "../Menu/Menu";
import Header from "../Header/Header";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import getWhatsAppUrl from "../../utils/getWhatsAppUrl";

const PREFIX = 'PreguntasFrecuentes';

const classes = {
  root: `${PREFIX}-root`,
  heading: `${PREFIX}-heading`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    width: "100%",
  },

  [`& .${classes.heading}`]: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  }
}));

const PreguntasFrecuentes = ({ back }) => {
  const { currentUser } = useAuth();
  const whatsappUrl = getWhatsAppUrl(
    `Hola acabo de visitar su página. Mi cotización es ${currentUser.uid}. Mi nombre es`
  );


  const history = useHistory();

  return (
    <Root>
      <Menu viewOption="cotizar" />
      <Header />
      <div className="preguntas">
        <h3>Preguntas frecuentes</h3>
        <div className={classes.root}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                ¿Qué aseguradora respalda el seguro de Clupp?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="description">
                  Trabajamos la cobertura de Responsabilidad Civil (daños a
                  terceros) con Mapfre y HDI.
                </p>
                <p className="description">
                  El resto de las coberturas las ofrecemos directamente
                  nosotros, Clupp, que contamos con el respaldo internacional de
                  la reaseguradora Munich Re para el pago de siniestros.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>
                ¿Cómo se compara con un seguro tradicional?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="description">
                  Nuestro seguro de auto y moto es de cobertura amplia, por lo
                  que incluye lo mismo que un seguro tradicional, pero con
                  algunos beneficios adicionales, como: viajes en Uber cuando tu
                  auto o moto se debe ir al taller por reparación y gastos
                  médicos para ti en caso de accidente, aunque no vengas en tu
                  vehículo.
                </p>
                <p className="description">
                  Le llamamos de "movilidad" porque a ti como persona te cubre
                  siempre que te muevas, no importa cómo lo hagas.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography className={classes.heading}>
                ¿Cómo medimos los kilómetros recorridos al mes?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="description">
                  La forma en la que medimos los kilómetros es a través de
                  nuestra app móvil. Pedimos a nuestros asegurados que cada mes
                  suban a la app una fotografía del odómetro de su vehículo.
                </p>
                <p className="description">
                  *La medición de kilómetros solo aplica para auto y moto.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography className={classes.heading}>
                ¿Qué sucede si te pasas de los kilómetros de tu plan contratado?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="description">
                  Si tu contratas un plan de movilidad reducida y un mes te
                  pasas de los km del plan, no pasa nada, pero si sucede por dos
                  meses seguidos, entonces te notificaremos para hacer un cambio
                  de plan al siguiente. Lo mismo pasa si contratas un plan de
                  movilidad intensiva y usas menos tu vehículo, a los dos meses
                  te notificamos para bajar tu plan y así pagues menos. Lo que
                  buscamos es que siempre pagues lo justo de acuerdo a tu
                  movilidad
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5a-content"
              id="panel5a-header"
            >
              <Typography className={classes.heading}>
                ¿Cada cuando debes tomar la foto del odómetro de tu vehículo?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                La foto del odómetro deberás tomarla desde nuestra app móvil
                durante los primeros 5 días de cada mes.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6a-content"
              id="panel6a-header"
            >
              <Typography className={classes.heading}>
                ¿Tu vehículo está asegurado no importa quien lo maneje?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="description">Si, no te preocupes.</p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7a-content"
              id="panel7a-header"
            >
              <Typography className={classes.heading}>
                ¿Cuánto tiempo tardan en llegar los ajustadores?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="description">
                  En el caso de los daños a tu vehículo, en la mayoría de las
                  ocasiones no es necesario que llegue un ajustador, ya que
                  contamos con ajuste remoto (a través de tu celular).
                </p>
                <p className="description">
                  Si no quieres o no puedes realizar el ajuste remoto, enviamos
                  a una persona a hacerlo por ti, quien no tarda más de 10
                  minutos en llegar. Si el siniestro es muy complejo y se
                  requiere a un ajustador certificado, tardamos alrededor de 30
                  minutos.
                </p>
                <p className="description">
                  Si hay terceros involucrados, tenemos un acuerdo con las
                  aseguradoras Mapfre (para autos) y HDI (para motos) quienes se
                  encargan de atender a los terceros. Sus ajustadores tardan
                  alrededor de 30 minutos en llegar.
                </p>
                <p className="description">
                  La atención a ti y tu vehículo corre a cuenta de nosotros y la
                  atención a los terceros involucrados corre a cargo de Mapfre o
                  HDI.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel8a-content"
              id="panel8a-header"
            >
              <Typography className={classes.heading}>
                ¿Podemos asegurar un auto o moto aunque la factura no esté a
                nombre de la persona contratante?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="description">
                  Sí, podemos asegurar tu vehículo, aunque la factura no esté a
                  tu nombre. Sin embargo, es importante mencionar que, en caso
                  de robo o pérdida total, la persona dueña de la factura debe
                  poder endosarla a nombre de Clupp para proceder a hacer el
                  pago del siniestro.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel0a-content"
              id="panel0a-header"
            >
              <Typography className={classes.heading}>
                ¿Hay algún costo adicional al pago de la primera mensualidad?
                ¿cobro por expedición de póliza?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="description">
                  No hay cobros sorpresa o extras de ningún tipo, solo es el
                  pago de tu mensualidad.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel11a-content"
              id="panel11a-header"
            >
              <Typography className={classes.heading}>
                ¿El cobro de cada mes se hace en automático?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="description">
                  Sí, la tarjeta con la que contratas tu seguro queda
                  domiciliada.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel12a-content"
              id="panel12a-header"
            >
              <Typography className={classes.heading}>
                ¿Hay alguna penalización al cancelar el seguro?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="description">
                  No, nuestro seguro es sin planes forzosos, por lo que no hay
                  penalización por cancelación.
                </p>
                <p className="description">
                  Cuando desees cancelar tu seguro debes hacerlo a través de
                  nuestra app movil qye te pedira que tomes una última foto del
                  ondómetro de tu vehívulo. Si los km del periodo excedieron tu
                  plan de contrato, se te hará el cobro adicional para poder
                  cancelar. Si no excedieron, la cancelación se realiza
                  inmediatamente.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel13a-content"
              id="panel13a-header"
            >
              <Typography className={classes.heading}>
                ¿Cómo es el proceso de contratación?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p className="description">
                  1. Una vez que realizas tu cotización, procedes a hacer el
                  pago en línea de tu primera mensualidad. Lo puedes realizar
                  con cualquier tarjeta de crédito o débito.
                </p>
                <p className="description">
                  2. Después de hacer tu pago, automáticamente recibes un correo
                  electrónico de bienvenida donde te pedimos que descargues
                  nuestra app para que ahí des de alta los datos de tu vehículo
                  (marca, modelo, año, placas (auto y moto), número de motor
                  (auto y moto), número de serie, etc.)
                </p>
                <p className="description">
                  3. Ya que hayas dado de alta los datos de tu vehículo
                  procedemos a emitir tu póliza la cual te enviaremos a tu
                  correo electrónico. En este documento vienen todos los
                  detalles de tu seguro, el número para reportar cualquier
                  siniestro y solicitar asistencia. etc.
                </p>
                <p className="description">Y !Listo¡</p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="preguntas-buttons">
          <a href={whatsappUrl} target="_blank" rel="noreferrer">
            <button className="white-btn">
              <img src={whatsapp} alt="whatsapp" /> Todavia tengo dudas
            </button>
          </a>
          <div className="frequently_asked_questions_primary_btn_container">
            <button onClick={() => history.goBack()} className="entendido-btn">
              Regresar a mi cotización
            </button>
          </div>
        </div>
      </div>
    </Root>
  );
};
export default PreguntasFrecuentes;
