import { format } from "date-fns";
import { es } from "date-fns/locale";

export const STATUS_TO_LABEL = {
  pending: "Pendiente",
  paid: "Pagado",
  rejected: "Rechazado",
  approved: "Aprobado",
};

export function formatMexicanPeso(valor) {
  const valorLimitado = Number(valor.toFixed(2));
  const valorFormateado = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(valorLimitado);

  return valorFormateado;
}

function formatTimestampDate(timestamp) {
  const date = new Date(timestamp);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('es-ES', options).replace(/ de /g, ' ');
}

export function formatDateRange(timestampStart, timestampEnd) {
  if (
    typeof timestampStart === "number" &&
    typeof timestampEnd === "number" &&
    !isNaN(timestampStart) &&
    !isNaN(timestampEnd)
  ) {
    const formattedStart = formatTimestampDate(timestampStart);
    const formattedEnd = formatTimestampDate(timestampEnd);
    return `Del ${formattedStart} al ${formattedEnd}`;
  }

  return "Período no disponible";
}

export function formatTimestamp(timestamp, dateFormat = "dd'/'MM'/'yyyy") {
  if (typeof timestamp === "number" && !isNaN(timestamp)) {
    return format(timestamp, dateFormat, { locale: es });
  }

  return "";
}
