import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  InputLabel,
  FormControl,
  Select,
  TextField,
  Button,
} from "@mui/material";
import { db } from "../../Firebase/firebase";
import PropTypes from "prop-types";
import "./Nolose.css";
import { useAuth } from "../../AuthContext";
import NumberFormat from "react-number-format";
import getWhatsAppUrl from "../../utils/getWhatsAppUrl";
import Swal from "sweetalert2";
import AGENTES from "../../utils/agentes";
import isValidProductValue from "../../utils/isValidProductValue";

const PREFIX = "Nolose";

const classes = {
  top: `${PREFIX}-top`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.top}`]: {
    paddingTop: "20px",
  },
}));

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      value={props.value}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
});

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Nolose = ({
  vehicleData,
  setVehicleData,
  tipoSelected,
  setTipoSelected,
  prevVehicleData,
  vehicleBrandSelectRef,
  vehicleModelSelectRef,
  vehicleYearSelectRef,
}) => {
  const { currentUser } = useAuth();

  const [brands, setBrands] = useState();
  const [brandSelected, setBrandSelected] = useState();
  const [models, setModels] = useState();
  const [modelSelected, setModelSelected] = useState();
  const [years, setYear] = useState();
  const [yearSelected, setYearSelected] = useState();
  const [errorValue, setErrorValue] = useState(false);
  const [errorValueAuto, setErrorValueAuto] = useState(false);
  const [precio, setPrecio] = useState();
  const [resultValor, setResultValor] = useState(false);
  const [showTipo, setShowTipo] = useState(true);
  const [otroBrandsAuto, setOtroBrandsAuto] = useState(false);
  const [otroBrands, setOtroBrands] = useState(false);
  const [precioOther, setPrecioOther] = useState(0);
  const [resultOther, setResultOther] = useState(false);
  const [otroModel, setOtroModel] = useState(false);

  let whatsAppUrl = getWhatsAppUrl(
    "Hola, no encuentro mi vehículo en su catálogo."
  );

  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);
  let medium = urlParams.get("utm_medium");

  useEffect(() => {
    if (vehicleData.type === "bike" || vehicleData.type === "eBike") {
      setBrandSelected(vehicleData.brand);
      setModelSelected(vehicleData.model);
      vehicleData.valueUser && setPrecio(vehicleData.valueUser);
    }
  }, [vehicleData]);

  const type = (tipo) => {
    if (tipo === "bike") return "bikes";
    else if (tipo === "eBike") return "eBikes";
    else if (tipo === "scooter") return "scooter";
    else if (tipo === "auto") return "auto";
    else if (tipo === "moto") return "moto";
    else if (tipo === "devices") return "devices";
    else if (tipo === "otro") return "otro";
  };

  async function searchOtherVehicle() {
    const allModels = [];
    await db
      .collection("catalogNew")
      .doc("auto")
      .collection("brands")
      .doc(
        vehicleData?.brand?.trim().charAt(0).toUpperCase() +
          vehicleData?.brand?.trim().toLowerCase().slice(1)
      )
      .collection("models")
      .where("name", "!=", "Otro")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) =>
          allModels.push({ ...doc.data(), id: doc.id })
        );
      });

    await db
      .collection("catalogNew")
      .doc("moto")
      .collection("brands")
      .doc(vehicleData?.brand?.toUpperCase())
      .collection("models")
      .where("name", "!=", "Otro")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) =>
          allModels.push({ ...doc.data(), id: doc.id })
        );
      });

    const data = allModels.find((model) => {
      if (model.name.toUpperCase() === vehicleData.model.toUpperCase().trim()) {
        return true;
      } else {
        return false;
      }
    });
    console.log(data);
    return data;
  }
  async function messageOtherVehicle() {
    let otherVehicle = false;
    if (vehicleData?.brand && vehicleData?.model && tipoSelected === "otro") {
      otherVehicle = await searchOtherVehicle();
      if (otherVehicle) {
        Swal.fire({
          icon: "warning",
          text: "El tipo de vehículo que has seleccionado para esta marca y modelo es incorrecta, por favor selecciona el tipo de moto o auto correcto.",
        });
      }
    }
  }

  const getBrands = () => {
    tipoSelected &&
      db
        .collection("catalogNew")
        .doc(type(tipoSelected))
        .collection("brands")
        .onSnapshot((querySnapshot) => {
          const docs = [];
          querySnapshot.forEach((doc) =>
            docs.push({ ...doc.data(), id: doc.id })
          );
          let sortName = docs.sort((prev, next) => {
            if (prev.name > next.name) {
              return 1;
            }
            if (prev.name < next.name) {
              return -1;
            }
            return 0;
          });
          return setBrands(sortName);
        });
  };

  useEffect(() => {
    if (prevVehicleData) {
      if (type(tipoSelected) === prevVehicleData.type) {
        getBrands();
        setVehicleData({
          brand: prevVehicleData.brand,
          model: prevVehicleData.model,
          year: prevVehicleData.year,
          valueCatalog: prevVehicleData.valueCatalog,
          valueUser: prevVehicleData.valueUser,
          features: prevVehicleData.features,
          userAgent: navigator.userAgent,
          type: prevVehicleData.type,
          isInsured: prevVehicleData.isInsured,
        });
        if (prevVehicleData.type === "otro") {
          setTipoSelected(type(prevVehicleData.type));
          setResultOther(true);
          setOtroModel(true);
          prevVehicleData.valueUser &&
            setPrecioOther(prevVehicleData.valueUser);
        } else {
          setTipoSelected(type(prevVehicleData.type));
          setResultValor(true);
          setBrandSelected(
            tipoSelected === "moto"
              ? prevVehicleData.brand.toUpperCase()
              : prevVehicleData.brand
          );
          setModelSelected(prevVehicleData.model);
          setYearSelected(prevVehicleData.year);
          prevVehicleData.valueUser && setPrecio(prevVehicleData.valueUser);
        }
      } else {
        const empty = [];
        setBrands(empty);
        getBrands();
        setVehicleData({
          ...vehicleData,
          brand: "",
          model: "",
          year: "",
          valueCatalog: 0,
          valueUser: 0,
          features: [],
        });
        setResultOther(false);
        setResultValor(false);
        setBrandSelected("");
        setModelSelected("");
        setYearSelected("");
        setPrecio("");
        setPrecioOther("");
        setOtroBrandsAuto(false);
        setOtroModel(false);
      }
    } else if (tipoSelected && currentUser) {
      console.log("Entro");

      const empty = [];
      setBrands(empty);
      getBrands();
      setVehicleData({
        ...vehicleData,
        brand: "",
        model: "",
        year: "",
        valueCatalog: 0,
        valueUser: 0,
        features: [],
      });
      setResultOther(false);
      setResultValor(false);
      setBrandSelected("");
      setModelSelected("");
      setYearSelected("");
      setPrecio("");
      setPrecioOther("");
      setOtroBrandsAuto(false);
      setOtroModel(false);
    }

    if (medium === AGENTES.OZON) {
      const features = new Set([
        ...vehicleData.features,
        "Soy conductor de plataforma",
      ]);

      setVehicleData({
        ...vehicleData,
        features: [...features],
      });
    }
  }, [tipoSelected, currentUser, prevVehicleData]);

  /**Consulta de modelos */
  const getModels = () => {
    brandSelected &&
      db
        .collection("catalogNew")
        .doc(type(tipoSelected))
        .collection("brands")
        .doc(brandSelected)
        .collection("models")
        .where("name", "!=", "Otro")
        .onSnapshot((querySnapshot) => {
          const docs = [];
          querySnapshot.forEach((doc) =>
            docs.push({ ...doc.data(), id: doc.id })
          );
          let sortName = docs.sort((prev, next) => {
            if (prev.name > next.name) {
              return 1;
            }
            if (prev.name < next.name) {
              return -1;
            }
            return 0;
          });
          return setModels(sortName);
        });
  };

  useEffect(() => {
    if (prevVehicleData) {
      getModels();
      if (
        prevVehicleData?.brand?.toLowerCase() !== brandSelected?.toLowerCase()
      ) {
        setVehicleData({
          ...vehicleData,
          year: "",
          model: "",
          valueCatalog: 0,
          valueUser: 0,
        });
        setModelSelected("");
        setYearSelected("");
        setPrecio("");
      }
    } else if (brandSelected) {
      getModels();
      setVehicleData({ ...vehicleData, model: "", year: "" });
      setModelSelected("");
      setYearSelected("");
      setPrecio("");
    }
  }, [brandSelected]);

  /**Consulta de años */
  const getYears = () => {
    modelSelected &&
      db
        .collection("catalogNew")
        .doc(type(tipoSelected))
        .collection("brands")
        .doc(brandSelected)
        .collection("models")
        .doc(modelSelected)
        .collection("years")
        .where("name", ">=", 1994)
        .onSnapshot((querySnapshot) => {
          const docs = [];
          querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id });
          });
          let sortName = docs.sort((prev, next) => {
            if (prev.name < next.name) {
              return 1;
            }
            if (prev.name > next.name) {
              return -1;
            }
            return 0;
          });
          return setYear(sortName);
        });
  };

  useEffect(() => {
    if (prevVehicleData) {
      getYears();
      if (
        prevVehicleData.model !== modelSelected &&
        !(
          type(tipoSelected) === "bikes" ||
          type(tipoSelected) === "eBikes" ||
          type(tipoSelected) === "scooter"
        )
      ) {
        setVehicleData({
          ...vehicleData,
          year: "",
          valueCatalog: 0,
          valueUser: 0,
        });
        setYearSelected("");
      }
    } else if (
      modelSelected &&
      !(
        type(tipoSelected) === "bikes" ||
        type(tipoSelected) === "eBikes" ||
        type(tipoSelected) === "scooter"
      )
    ) {
      getYears();
      setYear("");
      setVehicleData({
        ...vehicleData,
        year: "",
        valueCatalog: 0,
        valueUser: 0,
      });
      setYearSelected("");
      tipoSelected === "auto" && setPrecio("");
    }
  }, [modelSelected]);

  useEffect(() => {
    if (!yearSelected) return;
    setResultValor(true);
  }, [yearSelected]);

  /** Función de Cambio de precio*/
  const handleV = (e) => {
    const pr = e.target.value;
    const valor = parseInt(pr.trim());
    setVehicleData({ ...vehicleData, valueUser: valor });
    setPrecio(e.target.value);
    setErrorValue(!(valor >= 5000));
    if (tipoSelected === "auto") {
      setErrorValueAuto(!(valor >= 30000));
      setErrorValue(false);
    } else {
      setErrorValueAuto(false);
    }
  };
  /** Función de Cambio de precio*/
  const handleVOther = (e) => {
    const pr = e.target.value;
    const valor = parseInt(pr); //pr.trim()
    ///puede ser que aqui no se guardara bien por el .trim()
    setVehicleData({ ...vehicleData, valueCatalog: valor, valueUser: valor });
    setPrecioOther(e.target.value);
    setErrorValue(!(valor >= 5000));
  };
  /**Función cambio de seleccion de brands */
  const handleBrands = (e) => {
    setErrorValueAuto(false);
    const changebrand = e.target.value;
    let indexbrand = e.target.selectedIndex;
    // console.log(e.target.options[indexbrand].text)
    if (changebrand === "Otro") {
      if (tipoSelected === "auto" || tipoSelected === "moto") {
        setOtroBrandsAuto(true);
      } else {
        setOtroBrands(true);
      }
      setShowTipo(false);
      setResultValor(false);
      setVehicleData({ ...vehicleData, brand: changebrand });
      setBrandSelected(changebrand);
    } else {
      // const brand = brands.find(n=> n.id === changebrand);
      setVehicleData({
        ...vehicleData,
        brand: e.target.options[indexbrand].text,
      });
      setBrandSelected(changebrand);
      setOtroBrands(false);
      setShowTipo(true);
      setResultValor(false);
      setOtroBrandsAuto(false);
      setResultOther(false);
    }
  };
  /**Funcion de cambio de selección de  model de auto*/
  const handleModel = (e) => {
    setErrorValueAuto(false);
    const changemodel = e.target.value;
    if (changemodel === "Otro") {
      setVehicleData({
        ...vehicleData,
        model: "Otro",
        year: 0,
        valueCatalog: 0,
        valueUser: 0,
      });
      setPrecio(0);
      setModelSelected(changemodel);
      setResultValor(false);
      setResultOther(true);
      setOtroBrandsAuto(true);
    } else {
      const model = models.find((n) => n.id === changemodel);
      setVehicleData((vehicleData) => {
        const updatedVehicle = { ...vehicleData, model: model.name };

        if (updatedVehicle.type === "auto") {
          updatedVehicle["isPickup"] = Boolean(model.isPickup);
        }
        return updatedVehicle;
      });
      setModelSelected(changemodel);
      setYearSelected("");
      setResultValor(false);
      setResultOther(false);
      setOtroBrandsAuto(false);
    }
  };

  /**Funcion de cambio de selección de  model de los demas tipos*/
  const handleM = (e) => {
    const changemodel = e.target.value;
    if (changemodel === "Otro") {
      setVehicleData({
        ...vehicleData,
        model: "Otro",
        year: 0,
        valueCatalog: 0,
        valueUser: 0,
      });
      setPrecio(0);
      setModelSelected(changemodel);
      setResultValor(false);
      setResultOther(true);
      setOtroModel(true);
    } else if (tipoSelected === "moto") {
      setVehicleData({
        ...vehicleData,
        model: changemodel,
        year: 0,
        valueCatalog: 0,
        valueUser: 0,
      });
      setYearSelected("");
      setPrecio(0);
      setModelSelected(changemodel);
      setResultValor(false);
      setResultOther(true);
    } else {
      const model = models.find((n) => n.id === changemodel);
      const valoruser = parseInt(model.price);
      setVehicleData({
        ...vehicleData,
        model: model.name,
        year: 0,
        valueCatalog: model.price,
        valueUser: valoruser,
      });
      //const value = FormatAmout(model.price);
      setPrecio(model.price);
      setModelSelected(changemodel);
      setResultValor(true);
      setResultOther(false);
      setOtroModel(false);
    }
  };

  /**Funcion de cambio de selección de año*/
  const handleYear = (e) => {
    setErrorValueAuto(false);
    const changeyear = e.target.value;
    const yeard = years.find((n) => n.id === changeyear);
    const valoruser = parseInt(yeard.value);
    setVehicleData({
      ...vehicleData,
      year: yeard.name,
      valueCatalog: yeard.value,
      valueUser: Math.max(30000, valoruser),
    });
    setPrecio(Math.max(30000, valoruser));
    setYearSelected(changeyear);
  };
  /**Funcion de cambio de selección de año*/
  const handleYearMoto = (e) => {
    const changeyear = e.target.value;
    const yeard = years.find((n) => n.id === changeyear);
    const valoruser = yeard?.value ? parseInt(yeard.value) : 0;
    setVehicleData({
      ...vehicleData,
      year: yeard.name,
      valueCatalog: yeard.value ? yeard.value : 0,
      valueUser: valoruser,
    });
    setPrecio(yeard.value ? yeard.value : 0);
    setYearSelected(changeyear);
  };
  /**Funcion de cambio de selección de año*/
  const handleYearOther = (e) => {
    const changeyear = e.target.value;
    setVehicleData({ ...vehicleData, year: Number(changeyear) });
    setResultOther(true);
  };
  /**Funcion de cambio de selección de model*/
  const handleModelOther = (e) => {
    const changeyear = e.target.value;
    console.log(changeyear);
    setVehicleData({ ...vehicleData, model: changeyear, year: 0 });
    setResultOther(true);
  };
  /*Variable de año */
  const date = new Date();
  const currentYear = date.getFullYear();
  const getTitleText = (type) => {
    if (type === "auto") return "auto";
    if (type === "moto") return "motocicleta";
    if (type === "scooter") return "monopatin";
    if (type === "bike") return "bicicleta";
    if (type === "eBike") return "bicicleta eléctrica";
    if (type === "devices") return "dispositivo";
    if (type === "otro") return "vehículo";
  };

  const isNewVehicleModel = Number(yearSelected) >= new Date().getFullYear();
  const isVehicleValueOnCatalogValid = isValidProductValue({
    type: tipoSelected,
    value: vehicleData.valueCatalog,
  });

  const shouldAllowToChangeVehicleValue =
    tipoSelected === "bike" ||
    tipoSelected === "eBike" ||
    tipoSelected === "scooter"
      ? true
      : resultValor && (isNewVehicleModel || !isVehicleValueOnCatalogValid);

  return (
    <Root className="nolse" id="tipoVehiculo">
      <p className="bold">
        {`Ingresa los datos de tu ${getTitleText(tipoSelected)}`}
      </p>
      {tipoSelected === "otro" ? (
        <div className="container-nolose">
          <FormControl variant="outlined" className="select-input">
            <TextField
              label="Marca"
              variant="outlined"
              name="brand"
              className="select"
              onChange={(e) =>
                setVehicleData({ ...vehicleData, brand: e.target.value })
              }
              onBlur={async () => {
                await messageOtherVehicle();
              }}
            />
          </FormControl>
          <FormControl variant="outlined" className="select-input" id="model">
            <TextField
              label="Modelo"
              variant="outlined"
              name="model"
              className="select"
              onChange={(e) =>
                setVehicleData({ ...vehicleData, model: e.target.value })
              }
              onBlur={async () => {
                await messageOtherVehicle();
              }}
            />
          </FormControl>

          <FormControl variant="outlined" className="select-input" id="year">
            <InputLabel htmlFor="outlined-age-native-simple">Año</InputLabel>
            <Select
              id="outlined-age-native-simple"
              native
              label="Año"
              inputRef={vehicleYearSelectRef}
              className="select"
              onChange={handleYearOther}
              onBlur={async () => {
                await messageOtherVehicle();
              }}
            >
              <option aria-label="None" value={currentYear + 1} />
              <option value={currentYear + 1}>{currentYear + 1}</option>
              <option value={currentYear}>{currentYear}</option>
              <option value={currentYear - 1}>{currentYear - 1}</option>
              <option value={currentYear - 2}>{currentYear - 2}</option>
              <option value={currentYear - 3}>{currentYear - 3}</option>
              <option value={currentYear - 4}>{currentYear - 4}</option>
              <option value={currentYear - 5}>{currentYear - 5}</option>
              <option value={currentYear - 6}>{currentYear - 6}</option>
              <option value={currentYear - 7}>{currentYear - 7}</option>
              <option value={currentYear - 8}>{currentYear - 8}</option>
              <option value={currentYear - 9}>{currentYear - 9}</option>
              <option value={currentYear - 10}>{currentYear - 10}</option>
              <option value={currentYear - 11}>{currentYear - 11}</option>
              <option value={currentYear - 12}>{currentYear - 12}</option>
              <option value={currentYear - 13}>{currentYear - 13}</option>
              <option value={currentYear - 14}>{currentYear - 14}</option>
              <option value={currentYear - 15}>{currentYear - 15}</option>
              <option value={currentYear - 16}>{currentYear - 16}</option>
              <option value={currentYear - 17}>{currentYear - 17}</option>
              <option value={currentYear - 18}>{currentYear - 18}</option>
              <option value={currentYear - 19}>{currentYear - 19}</option>
              <option value={currentYear - 20}>{currentYear - 20}</option>
              <option value={currentYear - 21}>{currentYear - 21}</option>
              <option value={currentYear - 22}>{currentYear - 22}</option>
              <option value={currentYear - 23}>{currentYear - 23}</option>
              <option value={currentYear - 24}>{currentYear - 24}</option>
              <option value={currentYear - 25}>{currentYear - 25}</option>
              <option value={currentYear - 26}>{currentYear - 26}</option>
              <option value={currentYear - 27}>{currentYear - 27}</option>
              <option value={currentYear - 28}>{currentYear - 28}</option>
            </Select>
          </FormControl>
        </div>
      ) : (
        <div className="container-nolose">
          <FormControl variant="outlined" className="select-input" id="brand">
            <InputLabel htmlFor="outlined-age-native-simple">
              {prevVehicleData ? "" : "Marca"}
            </InputLabel>
            <Select
              native
              inputRef={vehicleBrandSelectRef}
              value={brandSelected}
              onChange={handleBrands}
              label="Marca"
              className="select"
            >
              <option aria-label="None" value="" />
              <option value="Otro">Otro</option>
              {brands &&
                brands.map((brand, index) => (
                  <option value={brand.id} id={brand.name} key={index}>
                    {brand.name}
                  </option>
                ))}
            </Select>
          </FormControl>
        </div>
      )}
      {showTipo && (
        <div>
          {tipoSelected === "auto" ? (
            <div className="container-nolose">
              <FormControl
                variant="outlined"
                className="select-input"
                id="model"
              >
                <InputLabel htmlFor="outlined-age-native-simple">
                  {prevVehicleData ? "" : "Modelo"}
                </InputLabel>
                <Select
                  native
                  onChange={handleModel}
                  label="Modelo"
                  inputRef={vehicleModelSelectRef}
                  value={modelSelected}
                  className="select"
                >
                  <option aria-label="None" value="" />
                  {models &&
                    models.map((model, index) => (
                      <option value={model.id} key={index}>
                        {model.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
              {!otroBrandsAuto && (
                <FormControl
                  variant="outlined"
                  className="select-input"
                  id="year"
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    {prevVehicleData ? "" : "Año"}
                  </InputLabel>
                  <Select
                    native
                    inputRef={vehicleYearSelectRef}
                    onChange={handleYear}
                    label="Año"
                    value={yearSelected}
                    className="select"
                  >
                    <option aria-label="None" value="" />
                    {years &&
                      years.map((year, index) => (
                        <option value={year.id} key={index}>
                          {year.name}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              )}
            </div>
          ) : tipoSelected === "moto" ? (
            <div className="container-nolose">
              <FormControl
                variant="outlined"
                className="select-input"
                id="model"
              >
                <InputLabel htmlFor="outlined-age-native-simple">
                  {prevVehicleData ? "" : "Modelo"}
                </InputLabel>
                <Select
                  native
                  onChange={handleModel}
                  label="Modelo"
                  inputRef={vehicleModelSelectRef}
                  value={modelSelected}
                  className="select"
                >
                  <option aria-label="None" value="" />
                  {models &&
                    models.map((model, index) => (
                      <option value={model.id} key={index}>
                        {model.name}
                      </option>
                    ))}
                  <option value="Otro">Otro</option>
                </Select>
              </FormControl>
              {!otroBrandsAuto && (
                <FormControl
                  variant="outlined"
                  className="select-input"
                  id="year"
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    {prevVehicleData ? "" : "Año"}
                  </InputLabel>
                  <Select
                    native
                    onChange={handleYearMoto}
                    inputRef={vehicleYearSelectRef}
                    label="Año"
                    value={yearSelected}
                    className="select"
                  >
                    <option aria-label="None" value="" />
                    {years &&
                      years.map((year, index) => (
                        <option value={year.id} key={index}>
                          {year.name}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              )}
            </div>
          ) : tipoSelected !== "otro" ? (
            <div className="container-nolose">
              <FormControl variant="outlined" className="select-input">
                <InputLabel htmlFor="outlined-age-native-simple">
                  {prevVehicleData ? "" : "Modelo"}
                </InputLabel>
                <Select
                  native
                  onChange={handleM}
                  label="Modelo"
                  inputRef={vehicleModelSelectRef}
                  value={modelSelected}
                  className="select"
                >
                  <option aria-label="None" value="" />
                  {models &&
                    models.map((model, index) => (
                      <option value={model.id} key={index}>
                        {model.name}
                      </option>
                    ))}
                  <option value="Otro">Otro</option>
                </Select>
              </FormControl>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      {brandSelected === "Otro" && (
        <FormControl variant="outlined" className="select-input">
          <TextField
            label="Marca"
            variant="outlined"
            name="brand"
            className="select"
            onChange={(e) =>
              setVehicleData({ ...vehicleData, brand: e.target.value })
            }
          />
        </FormControl>
      )}
      {otroBrandsAuto && (
        <div className="container-nolose">
          <FormControl variant="outlined" className="select-input" id="model">
            <TextField
              label="Modelo"
              variant="outlined"
              name="model"
              className="select"
              onChange={(e) =>
                setVehicleData({ ...vehicleData, model: e.target.value })
              }
            />
          </FormControl>
          <FormControl variant="outlined" className="select-input" id="year">
            <InputLabel htmlFor="outlined-age-native-simple">
              {prevVehicleData ? "" : "Año"}
            </InputLabel>
            <Select
              id="outlined-age-native-simple"
              native
              inputRef={vehicleYearSelectRef}
              label="Año"
              className="select"
              onChange={handleYearOther}
            >
              <option value={currentYear + 1}>{currentYear + 1}</option>
              <option value={currentYear}>{currentYear}</option>
              <option value={currentYear - 1}>{currentYear - 1}</option>
              <option value={currentYear - 2}>{currentYear - 2}</option>
              <option value={currentYear - 3}>{currentYear - 3}</option>
              <option value={currentYear - 4}>{currentYear - 4}</option>
              <option value={currentYear - 5}>{currentYear - 5}</option>
              <option value={currentYear - 6}>{currentYear - 6}</option>
              <option value={currentYear - 7}>{currentYear - 7}</option>
              <option value={currentYear - 8}>{currentYear - 8}</option>
              <option value={currentYear - 9}>{currentYear - 9}</option>
              <option value={currentYear - 10}>{currentYear - 10}</option>
              <option value={currentYear - 11}>{currentYear - 11}</option>
              <option value={currentYear - 12}>{currentYear - 12}</option>
              <option value={currentYear - 13}>{currentYear - 13}</option>
              <option value={currentYear - 14}>{currentYear - 14}</option>
              <option value={currentYear - 15}>{currentYear - 15}</option>
              <option value={currentYear - 16}>{currentYear - 16}</option>
              <option value={currentYear - 17}>{currentYear - 17}</option>
              <option value={currentYear - 18}>{currentYear - 18}</option>
              <option value={currentYear - 19}>{currentYear - 19}</option>
              <option value={currentYear - 20}>{currentYear - 20}</option>
              <option value={currentYear - 21}>{currentYear - 21}</option>
              <option value={currentYear - 22}>{currentYear - 22}</option>
              <option value={currentYear - 23}>{currentYear - 23}</option>
              <option value={currentYear - 24}>{currentYear - 24}</option>
              <option value={currentYear - 25}>{currentYear - 25}</option>
              <option value={currentYear - 26}>{currentYear - 26}</option>
              <option value={currentYear - 27}>{currentYear - 27}</option>
              <option value={currentYear - 28}>{currentYear - 28}</option>
              {/* <option value={currentYear - 29}>{currentYear - 29}</option>
              <option value={currentYear - 30}>{currentYear - 30}</option>
              <option value={currentYear - 31}>{currentYear - 31}</option> */}
            </Select>
          </FormControl>
        </div>
      )}
      {otroBrands && (
        <div className="container-nolose">
          <FormControl variant="outlined" className="select-input" id="model">
            <TextField
              label="Modelo"
              variant="outlined"
              name="model"
              className="select"
              onChange={handleModelOther}
            />
          </FormControl>
        </div>
      )}
      {otroModel && (
        <div className="container-nolose">
          <FormControl variant="outlined" className="select-input" id="model">
            <TextField
              label="Modelo"
              variant="outlined"
              name="model"
              className="select"
              onChange={handleModelOther}
            />
          </FormControl>
        </div>
      )}
      <a
        style={{ color: "#0058f4", textDecoration: "underline" }}
        href={whatsAppUrl}
        target="_blank"
        rel="noreferrer"
      >
        ¿No encuentras tu vehículo?
      </a>
      {shouldAllowToChangeVehicleValue && (
        <div className="container-nolose">
          <div className="result">
            {tipoSelected === "moto" ? (
              vehicleData.valueCatalog === 0 ? (
                <p>
                  Ingresa el valor comercial de tu moto <br />{" "}
                  <b>
                    {vehicleData.brand}, {vehicleData.model}, {vehicleData.year}{" "}
                  </b>
                  :
                </p>
              ) : (
                <p>
                  Otros clientes han asegurado su <br />
                  <b>
                    {vehicleData.brand} {vehicleData.model} {vehicleData.year}
                  </b>{" "}
                  en promedio por un valor comercial de:
                </p>
              )
            ) : (
              <p>
                Otros clientes han asegurado su <br />
                <b>
                  {vehicleData.brand} {vehicleData.model}
                  {(tipoSelected === "auto" ||
                    tipoSelected === "moto" ||
                    tipoSelected === "otro") &&
                    ` ${vehicleData.year}`}
                </b>{" "}
                en promedio por un valor comercial de:
              </p>
            )}
            <div className={classes.top} id="valueUser">
              <TextField
                variant="outlined"
                label="Valor comercial"
                value={precio}
                onChange={handleV}
                name="numberformat"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </div>
            {errorValue && (
              <p className="error-msg">El valor debe ser mayor a $5,000</p>
            )}
            {errorValueAuto && (
              <p className="error-msg">El valor debe ser mayor a $30,000</p>
            )}
            <span className="nota">
              Puedes editar este valor. Da clic en cotizar para ver la prima.
            </span>
          </div>
        </div>
      )}
      {resultOther && (
        <div className="container-nolose">
          <div className="result">
            <p>
              Asegura tu{" "}
              <b>
                {vehicleData.brand} {vehicleData.model}
                {tipoSelected === "auto" && ` ${vehicleData.year}`}
                {tipoSelected === "moto" && `, ${vehicleData.year}`}
              </b>{" "}
              por:
            </p>
            <div className={classes.top} id="valueUser">
              <TextField
                variant="outlined"
                label="Valor"
                value={precioOther}
                onChange={handleVOther}
                name="numberformat"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </div>
            {errorValue && (
              <p className="error-msg">El valor debe ser mayor a $5,000</p>
            )}
            <span className="nota">Puedes editar este valor.</span>
          </div>
        </div>
      )}
    </Root>
  );
};
export default Nolose;
