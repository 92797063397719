import { format } from "date-fns";
import { es } from "date-fns/locale";

export default async function exportCVS(data = []) {
  let columns = "data:text/csv;charset=utf-8,Fecha de contratación, Vigencia,Nombre,Correo,Teléfono,Vehículo,Estatus de póliza\n"
  await data.forEach((item) => {
    console.log(`${item.userName},${item.vehicle},${item.email},${item.phone}\n`)
    columns = columns + (`${format(
      new Date(item.insuranceStartYear, item.insuranceStartMonth -1,item.insuranceStartDay),
      "dd 'de' MMMM 'de' yyyy",
      {
        locale: es,
      }
    )}, ${format(
      new Date(item.insuranceEndYear, item.insuranceEndMonth -1,item.insuranceEndDay),
      "dd 'de' MMMM 'de' yyyy",
      {
        locale: es,
      }
    )},${item.userName},${item.email},${item.phone},${item.vehicle},${item.statusDescription}\n`)
  })
  
  const totalString = columns;
  
  var encodedUri = encodeURI(totalString);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "polizas_vendidas.csv");
  document.body.appendChild(link); // Required for FF
  
  link.click(); 
}
