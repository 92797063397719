import { Fragment, useState } from "react";
import { TextField, Box, Button, Typography, InputAdornment } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useAgentAuth } from "../../AuthAgentContext";
import { Redirect, useHistory } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import Swal from "sweetalert2";
import logoClupp from "../../Assets/PNG/logo-clupp.png";
import NotAgentDialog from "./NotAgentDialog";
import RecuperarContraseñaModal from "../../Components/RecuperarContraseña/RecuperarContraseña"
import logError from "../../services/logError";

const loginDataInitialState = {
  username: "",
  password: "",
};

export default function AgentLogin() {
  const { loginAgent, isAgentLogged, setCurrentAgentUser, currentAgentUser } =
    useAgentAuth();
  const {currentUser, logout} = useAuth()
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginData, setLoginData] = useState(loginDataInitialState);
  const [_, setOpenModal] = useState(true)
  const [showPasswordModal, setShowPasswordModa] = useState(false);
  const history = useHistory()

  function handleOnChange(e) {
    setLoginData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  function handleOnClosePasswordModa () {
    setShowPasswordModa(false)
  }

  function handleOnClose() {
    setOpenModal(false)
  }

  function handleLogout () {
    logout()
    localStorage.clear()
    setCurrentAgentUser(null)
  }

  function onSubmitLogin(e) {
    e.preventDefault();
    setIsLoading(true);
    loginAgent(loginData.username.replace(/\s/g, ''), loginData.password.trim())
      .then((data) => {
        setCurrentAgentUser(data);
        setLoginData(loginDataInitialState);
        setIsLoading(false);
      })
      .catch(async (error) => {
        // if(error.code !== "auth/invalid-email" && error.code !== "auth/wrong-password") {
          await logError({
            error: error.toString(),
            metadata: {
              descripcion: "Error al iniciar sesión",
              email: loginData?.username ? loginData.username : "",
              password: loginData?.password ? loginData.password : "",
              userAgent: navigator.userAgent,
            }
          })
        // }
        Swal.fire({
          icon: "error",
          text: `El correo electrónico o contraseña son incorrectos.`,
        })
        setIsLoading(false);
      });
  }

  function toRegister () {
    history.push(`/agentes/registro?customer=${currentUser.uid}`)
  }
  
  if (currentAgentUser && isAgentLogged === false)
    return <NotAgentDialog open={true} handleClose={handleOnClose} logout={handleLogout} toRegister={toRegister}/>

  if (currentAgentUser && isAgentLogged === true) return <Redirect to="/agentes/dashboard" />;

  return (
    <Fragment>
      <RecuperarContraseñaModal open={showPasswordModal} onClose={handleOnClosePasswordModa}/>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "grid",
          placeItems: "center",
          background: "#fff",
          "& .MuiTextField-root": {
            marginBottom: "26px",
          },
        }}
      >
        <Box sx={{ width: "80%", maxWidth: "480px", margin: "0 auto" }}>
          <Box sx={{ width: ["220px",  "340px"], margin: "0 auto"}}>
            <img src={logoClupp} alt="logo de clupp" style={{ width: "100%" }} />
          </Box>
          <Typography variant="h4">Iniciar sesión</Typography>
          <form
            onSubmit={onSubmitLogin}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: "30px",
            }}
          >
            <TextField
              color="primary"
              variant="outlined"
              value={loginData.username}
              name="username"
              onChange={handleOnChange}
              label="Correo electrónico"
            />
            <TextField
              color="primary"
              variant="outlined"
              value={loginData.passweord}
              name="password"
              onChange={handleOnChange}
              type={showPassword ? "text" : "password"}
              label="Contraseña"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </InputAdornment>
                ),
              }}
            />
            <LoadingButton
              color="primary"
              variant="contained"
              type="submit"
              loading={isLoading}
              sx={{marginBottom: "20px"}}
            >
              Ingresar
            </LoadingButton>
            <Button
              onClick={() => setShowPasswordModa(prev => !prev)}
              color="inherit"
              variant="text"
              type="button"
            >
              Olvidé mi contraseña
            </Button>
          </form>
        </Box>
      </Box>
    </Fragment>
  );
}