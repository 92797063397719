const devices = ["smartphone", "laptop", "tablet"];
const isADevice = (d) => devices.includes(d) || d === "devices";
export default isADevice;

export const getType = (type) => {
  switch (type) {
    case "devices":
    case "smartphone":
    case "laptop":
    case "tablet":
      return "devices";
    case "bike":
    case "bikes":
      return "bike";
    case "eBike":
    case "eBikes":
      return "eBike";
    case "otro":
    case "scooter":
    case "moto":
    case "auto":
      return type;
    default:
      return "auto";
  }
};

export const getTypeName = (type) => {
  switch (type) {
    case "devices":
      return "dispositivos"
    case "smartphone":
      return "celular"
    case "laptop":
      return "computadora"
    case "tablet":
      return "tableta"
    case "bike":
      return "bicicleta"
    case "bikes":
      return "bicicletas";
    case "eBike":
      return "bicicleta eléctrica"
    case "eBikes":
      return "bicicletas eléctricas";
    case "otro":
      return "otro"
    case "scooter":
      return "monopatín"
    case "moto":
      return "motocicleta"
    case "auto":
      return "auto"
    default:
      return "auto";
  }
};
