import { Fragment, useEffect, useState, useRef } from "react";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import Stepper from "../../Components/Stepper/Stepper";
import SeleccionVehiculo from "../../Components/SeleccionVehiculo/SeleccionVehiculo";
import Nolose from "../../Components/Nolose/Nolose";
import ValorVehiculo from "../../Components/ValorVehiculo/ValorVehiculo";
import BotonPrincipal from "../../Components/BotonPrincipal/BotonPrincipal";
import firebase from "firebase/app";
import Swal from "sweetalert2";
import { db } from "../../Firebase/firebase";
import { useAuth } from "../../AuthContext";
import { mobileVendor, mobileModel } from "react-device-detect";

import useRedirect from "../../hooks/useRedirect";
import Captcha from "../../Components/Captcha/Captcha";
import { useAgentAuth } from "../../AuthAgentContext";
import "./Cotizar.css";
import logError from "../../services/logError";
import getWhatsAppUrl from "../../utils/getWhatsAppUrl";
import AGENTES from "../../utils/agentes";
import persistUserData from "../../utils/persistUserData";
import useQuery from "../../hooks/useQuery";
import DevicesSelect from "../../Components/DevicesSelect/DevicesSelect";
import isADevice, { getType } from "../../utils/isADevice";
import { getWoowEmployee } from "../../services/woow";
import highlightSection from "../../utils/highlightSection";

async function getAllProspectVehicles(prospectRef) {
  const vehiclesArr = await db
    .collection(`prospectsNew/${prospectRef}/vehicles`)
    .get();

  return vehiclesArr.docs.map((item) => ({ ...item.data(), id: item.id }));
}
const OTHER_VEHICLES_MAX_VALUE = 700_000;

const Cotizar = (props) => {
  const { history } = props;
  const redirect = useRedirect();

  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);
  let source = urlParams.get("utm_source");
  let medium = urlParams.get("utm_medium");
  let campaign = urlParams.get("utm_campaign");
  let deeplink = urlParams.get("deeplink");
  let content = urlParams.get("utm_content");
  let token = urlParams.get("token");
  let qs = urlParams.get("qs");
  let puid = urlParams.get("puid");
  let vuid = urlParams.get("vuid");
  let disableDeepLinkParam = urlParams.get("disableDeepLink");
  let vehicleTypeParam = urlParams.get("vType");

  const query = useQuery();
  const isWowUser = query.get("utm_campaign") === AGENTES.WOW;
  const { currentUser, logout } = useAuth();
  const { currentAgentUser, isAgentLogged } = useAgentAuth();

  const [howFindOutClupp, setHowFindOutClupp] = useState([]);
  const [otherMatchVehicle, setOtherMatchVehicle] = useState(false);
  const [userData, setUserData] = useState({ timestampFirst: Date.now() });
  const [prevVehicleData, setPrevVehicleData] = useState();
  const [loading, setLoading] = useState(false);
  const [isOpenCaptchaDialog, setIsOpenCaptchaDialog] = useState(false);
  const [allQuotedVehicles, setAllQuotedVehicles] = useState([]);
  //const [platformBoolean, setPlatformBoolean] = useState(false)
  const [quoteData, setQuoteData] = useState({
    mobilityMeans: ["auto"],
    timestamp: Date.now(),
    timestampServer: firebase.firestore.FieldValue.serverTimestamp(),
    userAgent: navigator.userAgent,
    qStatus: Number(1),
    smartphoneCovered: currentAgentUser || isWowUser ? false : true,
    smartphoneAmount: currentAgentUser || isWowUser ? 0 : Number(5000),
  });
  const defaultType =
    vehicleTypeParam === "devices" && isWowUser
      ? "auto"
      : vehicleTypeParam || "auto";
  const [tipoSelected, setTipoSelected] = useState(defaultType);

  const docRef = localStorage.getItem("docRef");
  const currentVehicle = localStorage.getItem("currentVehicle");

  const [vehicleData, setVehicleData] = useState({
    brand: "",
    model: "",
    year: 0,
    valueCatalog: 0,
    valueUser: 0,
    features: [],
    platforms: [],
    userAgent: navigator.userAgent,
    type: getType(vehicleTypeParam),
    isInsured: true,
    smartphoneCovered: currentAgentUser || isWowUser ? false : true,
    smartphoneAmount: currentAgentUser || isWowUser ? 0 : Number(5000),
  });
  console.log("VehicleData ->", vehicleData);

  const [device, setDevice] = useState({
    type: "smartphone",
    brand: "",
    model: "",
    valueUser: null,
  });
  const [params, setParams] = useState();
  const [paramsAgents, setParamsAgents] = useState();

  const vehicleBrandSelectRef = useRef();
  const vehicleModelSelectRef = useRef();
  const featuresSelectRef = useRef();
  const vehicleYearSelectRef = useRef();
  const isAgentQuote = source === "agentes";

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    params.set("vType", getType(tipoSelected));
    history.push({ search: params.toString() });
    if (!isAgentLogged && !isAgentQuote)
      window.dataLayer.push({
        event: "selección_de_seguro",
        type: tipoSelected === "otro" ? "other" : tipoSelected,
        uid: currentUser?.uid,
      });
  }, [tipoSelected, history]);

  useEffect(() => {
    const setWoowEmployee = async () => {
      try {
        const json = await getWoowEmployee(token);

        if (!json) {
          throw new Error("Error: Datos del usuario no disponibles");
        }

        persistUserData({
          email: json.correo,
          firstName: json.nombre,
          lastName: json.apellido_paterno + " " + json.apellido_materno,
          phone: json.telefono,
          rfc: "",
        });
      } catch (error) {
        console.error("Error al obtener los datos del usuario:", error);
      }
    };

    if (campaign === AGENTES.WOW) {
      setWoowEmployee();
    }
  }, [campaign, token]);

  useEffect(() => {
    const isCluppGPTDeepLink = deeplink === "true" && puid;
    const disableDeepLink = disableDeepLinkParam === "true";
    if (isCluppGPTDeepLink && !disableDeepLink) {
      db.collection(`prospectsNew/${puid}/vehicles`)
        .limit(1)
        .orderBy("timestamp", "desc")
        .get()
        .then(async (snapshot) => {
          if (snapshot.size >= 1) {
            const vehicleId = snapshot.docs[0].id;
            localStorage.setItem("docRef", puid);
            localStorage.setItem("currentVehicle", vehicleId);
            const prospectSnap = await db.doc(`prospectsNew/${puid}`).get();
            if (prospectSnap.exists) {
              const prospect = prospectSnap.data();
              persistUserData({
                email: prospect.email,
                firstName: prospect.firstName,
                lastName: prospect.lastName + " " + prospect.lastName2,
                phone: prospect.phone,
                rfc: prospect.rfc,
              });
            }

            redirect("/elegir-plan");
          }
        });
    }
  }, [puid, disableDeepLinkParam, deeplink]);

  // useEffect(() => {
  // async function searchOtherVehicle() {
  //   const allModels = [];
  //   await db
  //     .collection("catalogNew")
  //     .doc("auto")
  //     .collection("brands")
  //     .doc(vehicleData?.brand.trim().charAt(0).toUpperCase() + vehicleData?.brand.trim().toLowerCase().slice(1))
  //     .collection("models")
  //     .where("name", "!=", "Otro")
  //     .get()
  //     .then((querySnapshot) => {
  //       querySnapshot.forEach((doc) =>
  //         allModels.push({ ...doc.data(), id: doc.id })
  //       );
  //     });

  //   await db
  //     .collection("catalogNew")
  //     .doc("moto")
  //     .collection("brands")
  //     .doc(vehicleData?.brand?.toUpperCase())
  //     .collection("models")
  //     .where("name", "!=", "Otro")
  //     .get()
  //     .then((querySnapshot) => {
  //       querySnapshot.forEach((doc) =>
  //         allModels.push({ ...doc.data(), id: doc.id })
  //       );
  //     });

  //     const data = allModels.find((model) => {
  //       if (model.name.toUpperCase() === vehicleData.model.toUpperCase().trim()) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     })
  //     console.log(data)
  //     return data
  // }

  // }, [tipoSelected, vehicleData?.brand, vehicleData?.model])

  function handleCloseDialog() {
    setIsOpenCaptchaDialog(false);
  }
  async function handleOpenCaptchaDialog() {
    if (allQuotedVehicles.length < 1 || isAgentLogged) {
      await validationVehicle(async () => await saveQuote());
    } else {
      validationVehicle(() => setIsOpenCaptchaDialog((prev) => !prev));
    }
  }

  async function validationVehicle(callback = async () => {}) {
    if (vehicleData.brand.trim() === "") {
      vehicleBrandSelectRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
      vehicleBrandSelectRef.current.focus();
      Swal.fire({
        icon: "warning",
        text: "Selecciona la marca de tu vehículo",
      }).then(() => {
        highlightSection("brand");
      });
    } else if (vehicleData.model.trim() === "") {
      vehicleModelSelectRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
      vehicleModelSelectRef.current.focus();
      Swal.fire({
        icon: "warning",
        text: " Selecciona el modelo de tu vehículo",
      }).then(() => {
        highlightSection("model");
      });
    } else if (
      (vehicleData.type === "auto" || vehicleData.type === "moto") &&
      vehicleData.year === ""
    ) {
      vehicleYearSelectRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
      vehicleYearSelectRef.current.focus();
      Swal.fire({
        icon: "warning",
        text: " Selecciona el año de tu vehículo",
      }).then(() => {
        highlightSection("year");
      });
    } else if (
      (vehicleData.valueUser < 5000 || !vehicleData.valueUser) &&
      vehicleData.type !== "otro"
    ) {
      Swal.fire({
        icon: "warning",
        text: " El valor de tu vehículo es menor a $5,000, el valor comercial mínimo es de $5,000",
      }).then(() => {
        highlightSection("valueUser");
      });
    } else if (
      !isADevice(vehicleData.type) &&
      vehicleData.features.length <= 0
    ) {
      Swal.fire({
        icon: "warning",
        text: "Por favor, selecciona las características de tu vehículo",
      }).then(() => {
        highlightSection("product-features");
      });
    } else if (
      (vehicleData.type === "auto" || vehicleData.type === "moto") &&
      vehicleData.valueUser > 1500000
    ) {
      Swal.fire({
        icon: "warning",
        text: `El valor de tu ${vehicleData.type} no puede ser mayor a $1,500,000`,
      }).then(() => {
        highlightSection("valueUser");
      });
    } else if (vehicleData.type === "auto" && vehicleData.valueUser < 30000) {
      Swal.fire({
        icon: "warning",
        text: "El valor de tu auto no puede ser menor a $30,000",
      }).then(() => {
        highlightSection("valueUser");
      });
    } else if (
      vehicleData.type === "moto" &&
      vehicleData.brand === "BMW" &&
      vehicleData.model !== "S 1000 RR" &&
      vehicleData.valueUser > 650000
    ) {
      Swal.fire({
        icon: "warning",
        text: "El valor de tu moto no puede ser mayor a $650,000",
      }).then(() => {
        highlightSection("valueUser");
      });
    } else if (
      vehicleData.type === "moto" &&
      vehicleData.valueUser > 760_000 &&
      vehicleData.brand === "Harley-Davidson" &&
      vehicleData.model !== "CVO TRI-GLIDE - DANTES RED FADE" &&
      vehicleData.model !== "PURSUIT DARK HORSE" &&
      vehicleData.model !== "FLTFTXSE CVO ROAD GLIDE" &&
      vehicleData.model !== "FLTRK ROAD GLIDE LIMITED" &&
      vehicleData.model !== "FLHXST STREET GLIDE SPECIAL ST" &&
      vehicleData.model !== "FLTRKSE CVO ROAD GLIDE LIMITED" &&
      vehicleData.model !== "FLHXS STREET GLIDE SPECIAL"
    ) {
      Swal.fire({
        icon: "warning",
        text: "El valor de tu moto no puede ser mayor a $600,000",
      }).then(() => {
        highlightSection("valueUser");
      });
    } else if (
      vehicleData.type === "moto" &&
      vehicleData.valueUser > 1300000 &&
      vehicleData.model === "CVO TRI-GLIDE - DANTES RED FADE" &&
      vehicleData.brand === "Harley-Davidson"
    ) {
    } else if (
      vehicleData.type === "moto" &&
      vehicleData.valueUser > 608000 &&
      vehicleData.model === "PURSUIT DARK HORSE" &&
      vehicleData.brand === "Indian"
    ) {
      Swal.fire({
        icon: "warning",
        text: "El valor de tu moto no puede ser mayor a $608,000",
      }).then(() => {
        highlightSection("valueUser");
      });
    } else if (
      vehicleData.type === "moto" &&
      vehicleData.valueUser > 1300000 &&
      vehicleData.model === "CVO TRI-GLIDE - DANTES RED FADE" &&
      vehicleData.brand === "Harley-Davidson"
    ) {
    } else if (
      vehicleData.type === "moto" &&
      vehicleData.valueUser > 675400 &&
      vehicleData.model === "FLTFTXSE CVO ROAD GLIDE" &&
      vehicleData.brand === "Harley-Davidson"
    ) {
      Swal.fire({
        icon: "warning",
        text: "El valor de tu moto no puede ser mayor a $675,400",
      }).then(() => {
        highlightSection("valueUser");
      });
    } else if (
      vehicleData.type === "moto" &&
      vehicleData.valueUser > 1300000 &&
      vehicleData.model === "CVO TRI-GLIDE - DANTES RED FADE" &&
      vehicleData.brand === "Harley-Davidson"
    ) {
      Swal.fire({
        icon: "warning",
        text: "El valor de tu moto no puede ser mayor a $1,300,000",
      });
    } else if (
      vehicleData.type === "moto" &&
      vehicleData.valueUser > 750000 &&
      vehicleData.model === "FLHXS STREET GLIDE SPECIAL" &&
      vehicleData.brand === "Harley-Davidson"
    ) {
      Swal.fire({
        icon: "warning",
        text: "El valor de tu moto no puede ser mayor a $750,000",
      }).then(() => {
        highlightSection("valueUser");
      });
    } else if (
      vehicleData.type === "moto" &&
      vehicleData.valueUser > 750000 &&
      vehicleData.model === "FLHXST STREET GLIDE SPECIAL ST" &&
      vehicleData.brand === "Harley-Davidson"
    ) {
      Swal.fire({
        icon: "warning",
        text: "El valor de tu moto no puede ser mayor a $750,000",
      }).then(() => {
        highlightSection("valueUser");
      });
    } else if (
      vehicleData.type === "moto" &&
      vehicleData.valueUser > 777800 &&
      vehicleData.model === "FLTRK ROAD GLIDE LIMITED" &&
      vehicleData.brand === "Harley-Davidson"
    ) {
      Swal.fire({
        icon: "warning",
        text: "El valor de tu moto no puede ser mayor a $777,800",
      }).then(() => {
        highlightSection("valueUser");
      });
    } else if (
      vehicleData.type === "moto" &&
      vehicleData.valueUser > 1356000 &&
      vehicleData.model === "FLTRKSE CVO ROAD GLIDE LIMITED" &&
      vehicleData.brand === "Harley-Davidson"
    ) {
      Swal.fire({
        icon: "warning",
        text: "El valor de tu moto no puede ser mayor a $1,356,000",
      }).then(() => {
        highlightSection("valueUser");
      });
    } else if (
      vehicleData.type === "eBike" &&
      vehicleData.valueUser > 300000 &&
      vehicleData.brand === "TURBO"
    ) {
      Swal.fire({
        icon: "warning",
        text: "El valor de tu moto no puede ser mayor a $300,000",
      }).then(() => {
        highlightSection("valueUser");
      });
    } else if (
      vehicleData.type === "eBike" &&
      vehicleData.valueUser > 247000 &&
      vehicleData.brand === "Specialized"
    ) {
      Swal.fire({
        icon: "warning",
        text: "El valor de tu moto no puede ser mayor a $247,000",
      }).then(() => {
        highlightSection("valueUser");
      });
    } else if (
      vehicleData.type === "otro" &&
      vehicleData.valueUser > 344800 &&
      vehicleData.brand === "CAN-AM" &&
      vehicleData.model === "COMMANDER MAX XT"
    ) {
      Swal.fire({
        icon: "warning",
        text: "El valor de tu moto no puede ser mayor a $344,800",
      }).then(() => {
        highlightSection("valueUser");
      });
    } else if (
      vehicleData.type === "otro" &&
      vehicleData.valueUser > 230000 &&
      vehicleData.brand === "CFMOTO" &&
      vehicleData.model.toUpperCase() !== "CFORCE 625 TOURING"
    ) {
      Swal.fire({
        icon: "warning",
        text: "El valor de tu vehículo no puede ser mayor a $230,000",
      }).then(() => {
        highlightSection("valueUser");
      });
    } else if (
      vehicleData.type === "otro" &&
      vehicleData.valueUser > 323900 &&
      vehicleData.brand === "CAN-AM" &&
      vehicleData.model === "MAVERICK SPORT M"
    ) {
      Swal.fire({
        icon: "warning",
        text: "El valor de tu moto no puede ser mayor a $323,900",
      }).then(() => {
        highlightSection("valueUser");
      });
    } else if (
      vehicleData.type === "otro" &&
      vehicleData.valueUser > 799990 &&
      vehicleData.brand === "Kawasaki" &&
      vehicleData.model === "Teryx 4 KRX 1000 EDICION ESPECIAL"
    ) {
      Swal.fire({
        icon: "warning",
        text: "El valor de tu moto no puede ser mayor a $799,990",
      }).then(() => {
        highlightSection("valueUser");
      });
    } else if (
      (vehicleData.type === "bike" || vehicleData.type === "eBike") &&
      vehicleData.valueUser > 300000
    ) {
      Swal.fire({
        icon: "warning",
        text: `El valor de tu ${
          vehicleData.type === "bike" ? "bicicleta" : "bicicleta eléctrica"
        } no puede ser mayor a $300,000`,
      }).then(() => {
        highlightSection("valueUser");
      });
    } else if (
      (vehicleData.type === "otro" || vehicleData.type === "scooter") &&
      vehicleData.valueUser > OTHER_VEHICLES_MAX_VALUE &&
      !vehicleData.brand.toUpperCase().includes("CAN-AM") &&
      !vehicleData.brand.toUpperCase().includes("CF MOTO") &&
      vehicleData.brand !== "TURBO" &&
      vehicleData.model !== "LEVO" &&
      vehicleData.brand !== "Specialized" &&
      vehicleData.model !== "Turbo Levo Expert" &&
      vehicleData.model !== "Teryx 4 KRX 1000 EDICION ESPECIAL"
    ) {
      Swal.fire({
        icon: "warning",
        text: `El valor de tu vehículo no puede ser mayor a $${OTHER_VEHICLES_MAX_VALUE.toLocaleString(
          "en-US"
        )}`,
      }).then(() => {
        highlightSection("valueUser");
      });
    } else if (
      vehicleData.features.includes("Soy conductor de plataforma") &&
      vehicleData.platforms.length === 0
    ) {
      Swal.fire({
        icon: "warning",
        text: "Selecciona al menos una plataforma",
      });
    } else {
      try {
        await callback();
      } catch (e) {
        console.error(e);
      }
    }
  }

  useEffect(() => {
    if (!currentUser) return;
    // Add a documento to track user behaviour between screens
    db.collection(`prospectsNew/${currentUser.uid}/qStatusUpdates`).add({
      qStatus: 0,
      timestamp: Date.now(),
      timestampServer: firebase.firestore.FieldValue.serverTimestamp(),
    });

    if (medium === AGENTES.OZON) {
      Swal.fire({
        imageUrl:
          "https://firebasestorage.googleapis.com/v0/b/auto-clupp.appspot.com/o/assets%2Fpartners%2Fozon%2FOzon_logo.jpg?alt=media&token=9c89716a-5853-4b03-9407-e560ad0357f0",
        imageHeight: 250,
        html: '<h2 style="margin:0">¡Te damos la bienvenida a Clupp!</h2><br/>Al ser cliente de Ozon, gozas de beneficios especiales. Solo cotiza tu moto y los beneficios Ozon se verán reflejados en automático.',
        confirmButtonText: "Entendido",
        background: "#f3f4f6",
      });

      const features = new Set([
        ...vehicleData.features,
        "Soy conductor de plataforma",
      ]);
      setVehicleData({
        ...vehicleData,
        features: [...features],
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (prevVehicleData) {
      // console.log("prevVehicleData: ", prevVehicleData);

      const features = new Set([
        ...vehicleData.features,
        ...prevVehicleData.features,
      ]);

      if (medium === AGENTES.OZON) {
        features.add("Soy conductor de plataforma");
      }

      if (isADevice(prevVehicleData.type)) {
        setDevice({
          type: prevVehicleData.type,
          brand: prevVehicleData.brand,
          model: prevVehicleData.model,
          valueUser: prevVehicleData.valueUser,
        });

        if (!isAgentLogged && !isAgentQuote)
          window.dataLayer.push({
            event: "selección_de_seguro",
            type: prevVehicleData.type,
            uid: currentUser?.uid,
          });

        setVehicleData({
          ...vehicleData,
          features: prevVehicleData.features,
        });
      } else {
        setVehicleData({
          ...vehicleData,
          ...prevVehicleData,
          features: [...features],
        });
      }
    } else {
      if (medium === AGENTES.OZON) {
        const features = new Set([
          ...vehicleData.features,
          "Soy conductor de plataforma",
        ]);

        setVehicleData({
          ...vehicleData,
          features: [...features],
        });
      }
    }
  }, [prevVehicleData]);
  console.log("vehicle Data ->", vehicleData);

  useEffect(() => {
    if (docRef && currentVehicle) {
      db.collection("prospectsNew")
        .doc(docRef)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            setUserData(userData);
            if (userData?.howFindOut) {
              setHowFindOutClupp(userData.howFindOut);
            }
          } else console.log("No such document!");
        })
        .catch((error) => console.log("Error getting document:", error));
      db.collection("prospectsNew")
        .doc(docRef)
        .collection("vehicles")
        .doc(currentVehicle)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setPrevVehicleData(doc.data());
          } else console.log("No such document!");
        })
        .catch((error) => console.log("Error getting document:", error));

      getAllProspectVehicles(docRef)
        .then((items) => setAllQuotedVehicles(items))
        .catch((error) => console.log("Error getting document:", error));
    }
  }, [currentVehicle, docRef]);

  let param_type = urlParams.get("type");
  let param_brand = urlParams.get("brand");
  let param_year = urlParams.get("year");
  let param_model = urlParams.get("model");
  let param_version = urlParams.get("version");
  let param_vStatus = urlParams.get("vStatus");
  let param_gender = urlParams.get("gender");
  let param_birthDay = urlParams.get("birthDay");
  let param_birthMonth = urlParams.get("birthMonth");
  let param_birthYear = urlParams.get("birthYear");
  let param_civilStatus = urlParams.get("civilStatus");
  let param_zipCode = urlParams.get("zipCode");
  let param_isInsured = urlParams.get("isInsured");
  let param_iDay = urlParams.get("iDay");
  let param_iMonth = urlParams.get("iMonth");
  let param_iYear = urlParams.get("iYear");
  let param_iType = urlParams.get("iType");
  let param_name = urlParams.get("name");
  let param_firstName = urlParams.get("firstName");
  let param_lastName = urlParams.get("lastName");
  let param_lastName2 = urlParams.get("lastName2");
  let param_phone = urlParams.get("phone");
  let param_premium = urlParams.get("premium");
  let param_rank = urlParams.get("rank");
  let param_cluppId = urlParams.get("cluppId");

  let param_email = urlParams.get("email");
  let param_rfc = urlParams.get("rfc");
  let param_uid = urlParams.get("uid");
  let param_period = urlParams.get("period");

  useEffect(() => {
    setParamsAgents({
      utm_source: source,
      utm_campaign: campaign,
      name: param_name,
      email: param_email,
      phone: param_phone,
      premium: param_premium,
      period: param_period,
      uid: param_uid,
    });
  }, [
    source,
    campaign,
    param_name,
    param_email,
    param_phone,
    param_premium,
    param_period,
    param_uid,
  ]);

  useEffect(() => {
    setParams({
      utm_source: source,
      utm_campaign: campaign,
      utm_content: content,
      type: param_type,
      brand: param_brand,
      year: param_year,
      model: param_model,
      version: param_version,
      vStatus: param_vStatus,
      gender: param_gender,
      birthDay: param_birthDay,
      birthMonth: param_birthMonth,
      birthYear: param_birthYear,
      civilStatus: param_civilStatus,
      zipCode: param_zipCode,
      isInsured: param_isInsured,
      iDay: param_iDay,
      iMonth: param_iMonth,
      iYear: param_iYear,
      iType: param_iType,
      name: param_name,
      lastName: param_lastName,
      lastName2: param_lastName2,
      email: param_email,
      phone: param_phone,
      premium: param_premium,
      rank: param_rank,
      cluppId: param_cluppId,
    });
  }, [
    campaign,
    content,
    medium,
    param_birthDay,
    param_birthMonth,
    param_birthYear,
    param_brand,
    param_civilStatus,
    param_gender,
    param_iDay,
    param_iMonth,
    param_iType,
    param_iYear,
    param_isInsured,
    param_model,
    param_name,
    param_phone,
    param_premium,
    param_rank,
    param_type,
    param_vStatus,
    param_version,
    param_year,
    param_zipCode,
    source,
    param_cluppId,
    param_email,
  ]);

  useEffect(() => {
    if (params && currentUser) {
      if (params.utm_source === "rastreator") {
        let url = `https://us-central1-auto-clupp.cloudfunctions.net/app/notify-clupp-from-rastreator?`;
        url += "&uid=" + currentUser.uid;
        url += "&type=" + params.type;
        url += "&brand=" + params.brand;
        url += "&year=" + params.year;
        url += "&model=" + params.model;
        url += "&version=" + params.version;
        url += "&vStatus=" + params.vStatus;
        url += "&gender=" + params.gender;
        url += "&birthDay=" + params.birthDay;
        url += "&birthMonth=" + params.birthMonth;
        url += "&birthYear=" + params.birthYear;
        url += "&civilStatus=" + params.civilStatus;
        url += "&zipCode=" + params.zipCode;
        url += "&isInsured=" + params.isInsured;
        url += "&iDay=" + params.iDay;
        url += "&iMonth=" + params.iMonth;
        url += "&iYear=" + params.iYear;
        url += "&iType=" + params.iType;
        url += "&name=" + params.name;
        url += "&email=" + params.email;
        url += "&phone=" + params.phone;
        url += "&premium=" + params.premium;
        url += "&rank=" + params.rank;
        url += "&cluppId=" + params.cluppId;
        url += "&campaign=" + params.utm_campaign;
        httpGetAsync(url, showURLResponse);
        localStorage.setItem("rastreator_params", JSON.stringify(params));
        localStorage.setItem("rastreatorUserUid", currentUser.uid);
      } else {
        param_email && localStorage.setItem("email", param_email);
        param_firstName && localStorage.setItem("firstName", param_firstName);
        param_rfc && localStorage.setItem("rfc", param_rfc);
        param_phone && localStorage.setItem("phone", param_phone);
        if (param_lastName) {
          let lastnameSetted = param_lastName.split(" ");
          if (lastnameSetted.length === 2) {
            localStorage.setItem("lastName", lastnameSetted[0]);
            localStorage.setItem("lastName2", lastnameSetted[1]);
          } else {
            localStorage.setItem("lastName", param_lastName);
          }
        }
      }
    }
  }, [params, currentUser]);

  useEffect(() => {
    if (paramsAgents && currentUser) {
      if (
        params.utm_source === "BikeOn" &&
        params.utm_campaign === "deeplinkBikeOn"
      ) {
        console.log("Viene de BikeOn");
        localStorage.setItem("agents_params", JSON.stringify(paramsAgents));
        redirect("/contrata");
      }
    }
  }, [paramsAgents, currentUser]);

  function httpGetAsync(theUrl, callback) {
    setLoading(true);
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState === 4) {
        if (xmlHttp.status === 200) {
          callback(xmlHttp.responseText);
        } else {
          setLoading(false);
          console.log(
            "Ocurrió un error desconocido.\n\n" + xmlHttp.responseText
          );
          Swal.fire({
            icon: "error",
            text: `Ha ocurrido un error, inténtalo de nuevo.
                        ${xmlHttp.responseText}`,
          });
          db.collection("errors")
            .add({
              error: xmlHttp.responseText,
              date: new Date(),
            })
            .then((docRef) => {
              console.log("Document written");
            });
        }
      }
    };
    xmlHttp.open("GET", theUrl, true); // true for asynchronous
    xmlHttp.send(null);
  }

  function showURLResponse(response) {
    setLoading(false);
    // console.log(response);
    if (response) {
      if (params.utm_source === "rastreator") {
        history.push("/contrata");
      } else {
        localStorage.setItem("amountsData", response);
        setLoading(false);
        // history.push("/elegir-plan");
        redirect("/elegir-plan");
      }
    } else {
      alert("Parece que ocurrió un error. Intenta más tarde.");
    }
  }

  const saveQuote = async () => {
    var pVNew = userData?.smartphoneAmount ? userData.smartphoneAmount : 5000;
    const x = parseInt(vehicleData.valueUser * 0.03);
    if (!userData?.smartphoneAmount) {
      if (x > 5000) {
        if (x < 30000) {
          pVNew = x;
        } else {
          pVNew = 30000;
        }
      }
    }

    try {
      setLoading(true);
      // We have to update the last vehicle quoted in case it exists
      // We need to make sure the vehicle that it's in LocalStorage actually exists
      if (currentVehicle) {
        const prevVehicleRef = db.doc(
          `prospectsNew/${currentUser.uid}/vehicles/${currentVehicle}`
        );

        const prevVehicle = await prevVehicleRef.get();

        if (prevVehicle.exists) {
          // update the last vehicle quoted
          await prevVehicleRef.update({
            isInsured: false,
            extras: [],
          });
        }
      }

      const quoteDataCheck = isADevice(vehicleData.type)
        ? { ...quoteData, smartphoneCovered: false, smartphoneAmount: 0 }
        : quoteData;

      const updateData = {
        ...userData,
        ...quoteDataCheck,
        ...(content === "link" &&
          !currentAgentUser && {
            agentUID: medium,
          }),
        ...(param_email && { email: param_email }),
        howFindOut: howFindOutClupp,
        discount: { name: "", percentage: 1 },
        haveADiscountCoupon: false,
        period: "year",
        quotationSoftwareVersion: qs || "2.5",
        timestamp: Date.now(),
        timestampServer: firebase.firestore.FieldValue.serverTimestamp(),
        spDetected: `${mobileVendor || ""} ${mobileModel || ""}`,
        smartphoneAmount: isWowUser
          ? 0
          : currentAgentUser
          ? userData?.smartphoneAmount
            ? userData?.smartphoneAmount
            : Number(pVNew)
          : Number(pVNew),
        status: "Pendiente",
      };

      const prospectRef = db.doc(`prospectsNew/${currentUser.uid}`);
      // Make sure the utms are set only on the first quotation (when the user document does not exist on the database)
      const doc = await prospectRef.get();

      if ((content === "link" && !currentAgentUser) || !doc.exists) {
        updateData["utm_campaign"] = currentAgentUser
          ? currentAgentUser?.uid
          : campaign || "noCampaign";
        updateData["utm_content"] = content || "noContent";
        updateData["utm_medium"] = currentAgentUser
          ? currentAgentUser?.uid
          : medium || "noMedium";
        updateData["utm_source"] = currentAgentUser
          ? "agentes"
          : source || "noSource";
      }
      await prospectRef.set(updateData, { merge: true });
      let updateDataVehicle = {};
      if (isADevice(vehicleData.type) || vehicleData.type === "devices") {
        const { userBrand, userModel, ...settedDevice } = device;
        if (settedDevice.brand === "Otro") {
          settedDevice.brand = userBrand;
          settedDevice.model = userModel;
        }
        if (settedDevice.model === "Otro") {
          settedDevice.model = userModel;
        }
        updateDataVehicle = {
          ...vehicleData,
          ...settedDevice,
        };
      } else {
        updateDataVehicle = vehicleData;
      }

      const data = {
        ...updateDataVehicle,
        ...(currentAgentUser && { agentUID: currentAgentUser.uid }),
        valueCatalog: vehicleData.valueCatalog || 0,
        utm_campaign: currentAgentUser
          ? currentAgentUser?.uid
          : campaign || "noCampaign",
        utm_content: content || "noContent",
        utm_medium: currentAgentUser
          ? currentAgentUser?.uid
          : medium || "noMedium",
        utm_source: currentAgentUser ? "agentes" : source || "noSource",
        mobility: 2,
        timestamp: Date.now(),
        smartphoneCovered:
          currentAgentUser || isWowUser || isADevice(vehicleData.type)
            ? false
            : true,
        smartphoneAmount:
          currentAgentUser || isWowUser || isADevice(vehicleData.type)
            ? 0
            : userData?.smartphoneAmount
            ? userData?.smartphoneAmount
            : Number(pVNew),
        extras: [],
        isInsured: true,
      };

      const vehicleId = db
        .collection(`prospectsNew/${currentUser.uid}/vehicles`)
        .doc().id;

      await db
        .doc(`prospectsNew/${currentUser.uid}/vehicles/${vehicleId}`)
        .set({ ...data, id: vehicleId }, { merge: true });

      localStorage.setItem("currentVehicle", vehicleId);
      let url = `https://us-central1-auto-clupp.cloudfunctions.net/app/quote-mobility-insurance-5?uid=${currentUser.uid}`;
      if (vehicleData.type === "auto") {
        url += `&isPickup=${Boolean(vehicleData.isPickup)}`;
      }
      // generate the quotation data
      const response = await fetch(url);
      const json = await response.json();
      localStorage.removeItem("rastreatorUserUid");
      localStorage.removeItem("rastreator_params");
      localStorage.setItem("docRef", currentUser.uid);
      const firstName = localStorage.getItem("firstName");
      const lastName = localStorage.getItem("lastName");
      const prospectName =
        firstName && lastName ? `${firstName} ${lastName}` : "";

      if (isAgentQuote) {
        await db.collection(`/agentProspects/${campaign}/expTransactions`).add({
          amount: 5,
          deleted: false,
          timestamp: Date.now(),
          metadata: {
            costumerName: prospectName || "",
            vehicleType: vehicleData.type,
            transactionType: "quote",
            description: `${vehicleData.brand} ${vehicleData.model} ${
              vehicleData?.year || ""
            }`,
          },
        });
      }

      if (params.utm_source === "rastreator") {
        setLoading(false);
        redirect("/contrata");
      } else {
        localStorage.setItem("amountsData", JSON.stringify(json));
        if (!isAgentLogged) {
          window.dataLayer.push({
            event: "cotizar",
            type: vehicleData.type,
            brand: vehicleData.brand,
            model: vehicleData.model,
            year: vehicleData.year,
          });
        }

        setLoading(false);
        redirect("/elegir-plan");
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      Swal.fire({
        icon: "error",
        text: `Ha ocurrido un error, solicita ayuda con uno de nuestros agentes.`,
        confirmButtonText: "Contactar a un agente",

        didClose: () => {
          window.open(
            getWhatsAppUrl(
              "Hola, tengo problemas para cotizar un segundo vehículo desde la página."
            )
          );
        },
      });
      try {
        // Transform every value of the object thats falsy into a string
        function parseObject(object) {
          return Object.keys(object)
            .map((key) => {
              return [key, object[key]];
            })
            .reduce((prev, current) => {
              const [key, value] = current;
              prev[key] = value ?? String(value);
              return prev;
            }, {});
        }

        await logError({
          error: String(error),
          metadata: {
            description: "Error al continuar cotizacion desde Cotizar",
            uid: currentUser.uid,
            quoteData: parseObject(quoteData),
            vehicleData: parseObject(vehicleData),
          },
        });
      } catch (error) {
        console.error(error);
        await logError({
          error: "Error al enviar error: " + String(error),
        });
      }
    }
  };

  return (
    <Fragment>
      {!currentAgentUser && (
        <Captcha
          onClose={handleCloseDialog}
          isOpen={isOpenCaptchaDialog}
          callBack={saveQuote}
          loading={loading}
        />
      )}
      {currentUser && (
        <Fragment>
          {!isWowUser && (
            <>
              <Menu viewOption="cotizar" />
              <Header />
            </>
          )}

          <div
            className="cotizar adjusted-height"
            style={{
              paddingTop: isWowUser ? "10px" : "",
            }}
          >
            <Stepper type={tipoSelected} />
            <SeleccionVehiculo
              quoteData={quoteData}
              setQuoteData={setQuoteData}
              vehicleData={vehicleData}
              setVehicleData={setVehicleData}
              setTipoSelected={setTipoSelected}
              prevVehicleData={prevVehicleData}
              isWowUser={isWowUser}
            />
            {tipoSelected === "devices" ? (
              <>
                <DevicesSelect
                  utm_source={source}
                  vehicleBrandSelectRef={vehicleBrandSelectRef}
                  vehicleModelSelectRef={vehicleModelSelectRef}
                  loading={loading}
                  saveQuote={saveQuote}
                  setDevice={setDevice}
                  device={device}
                  setTipoSelected={setTipoSelected}
                />
                <ValorVehiculo
                  setVehicleData={setVehicleData}
                  vehicleData={vehicleData}
                  prevVehicleData={prevVehicleData}
                  tipoSelected={tipoSelected}
                  setHowFindOutClupp={setHowFindOutClupp}
                  howFindOutClupp={howFindOutClupp}
                  isAgentLogged={isAgentLogged}
                  featuresSelectRef={featuresSelectRef}
                />
              </>
            ) : (
              <>
                <Nolose
                  vehicleData={vehicleData}
                  setVehicleData={setVehicleData}
                  tipoSelected={tipoSelected}
                  prevVehicleData={prevVehicleData}
                  setTipoSelected={setTipoSelected}
                  vehicleBrandSelectRef={vehicleBrandSelectRef}
                  vehicleModelSelectRef={vehicleModelSelectRef}
                  vehicleYearSelectRef={vehicleYearSelectRef}
                  otherMatchVehicle={otherMatchVehicle}
                  setOtherMatchVehicle={setOtherMatchVehicle}
                />
                <ValorVehiculo
                  setVehicleData={setVehicleData}
                  vehicleData={vehicleData}
                  prevVehicleData={prevVehicleData}
                  tipoSelected={tipoSelected}
                  setHowFindOutClupp={setHowFindOutClupp}
                  howFindOutClupp={howFindOutClupp}
                  isAgentLogged={isAgentLogged}
                  featuresSelectRef={featuresSelectRef}
                />
                <BotonPrincipal
                  tipo={"Cotizar"}
                  goTo="/elegir-plan"
                  func={handleOpenCaptchaDialog}
                  loading={loading}
                />
              </>
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Cotizar;
