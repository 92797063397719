import "./Plats.css";
import { styled } from '@mui/material/styles';
import { Fragment, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { FormControlLabel, TextField, Checkbox, Button, CircularProgress } from "@mui/material";
import { green } from "@mui/material/colors";
import Swal from "sweetalert2";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { TramRounded } from "@mui/icons-material";

const PREFIX = 'Plats';

const classes = {
  root: `${PREFIX}-root`,
  checked: `${PREFIX}-checked`,
  blue: `${PREFIX}-blue`,
  blue2: `${PREFIX}-blue2`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.blue}`]: {
    //margin: theme.spacing(1),
    backgroundColor: "white",
    color: "#3f75cf",
    fontSize: "10px",
    fontWeight: "bold",
    width: "250px",
    borderRadius: "20px",
    border: "1px solid #3f75cf",
    textAlign: "center",
    alignSelf: "center",
  },

  [`& .${classes.blue2}`]: {
    backgroundColor: "#0058f4",
    borderRadius: "1.5rem",
    color: "#fff",
    fontWeight: "bold",
    height: "2.7rem",
    marginTop: "0.6rem",
    width: "80%",
  }
}));

const GreenCheckbox = ((props) => <Checkbox color="default" {...props} />);

const Plats = ({
  plats,
  uid,
  uber,
  didi,
  nameP,
  periodP,
  phoneP,
  emailP,
  spPremiumP,
  primafP,
  currentPlat,
  setCurrentPlat,
  utm_source,
}) => {
  const stripePromise = loadStripe(
    "pk_live_51H5q3WAQwCaNdJ3ZN0bCb7PsC2l3w4yOkkvCD0bC6J6blQjFzNG33MAriRSGEZXfbpmPCjtR2VythfeUV7Ebpx0N006NiETUco"
  );
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [dataPlat, setDataPlat] = useState({
    email: "",
    psw: "",
    code: "",
  });
  const [checkedPP, setCheckedPP] = useState(false);

  const [errorTelefono, setErrorTelefono] = useState(false);

  function confirmCredentials(evt) {
    if (!checkedPP) {
      Swal.fire({
        icon: "warning",
        text: "Para enviar tus credenciales debes aceptar nuestro aviso de privacidad",
      });
      return;
    }

    if (currentPlat === "Uber") {
      if (!dataPlat.email) {
        Swal.fire({
          icon: "warning",
          text: "Falta ingresar correo.",
        });
        return;
      } else if (!dataPlat.psw) {
        Swal.fire({
          icon: "warning",
          text: "Falta ingresar contraseña.",
        });
        return;
      } else {
        fetch(
          "https://us-central1-auto-clupp.cloudfunctions.net/app/confirm_credentials",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              country: "mx",
              user_id: uid,
              email: dataPlat.email,
              password: dataPlat.psw,
            }),
          }
        ).then(function (result) {
          result.json().then(function (json) {
            var message;
            console.log(json);

            if (json.message === "CONFIRMATION_CODE_SENT") {
              document.getElementById("TF1").hidden = true; //email
              document.getElementById("TF2").hidden = true; //psw
              document.getElementById("title1").hidden = true;
              document.getElementById("title2").hidden = true;
              document.getElementById("sub1").hidden = true;
              document.getElementById("sub2").hidden = true;
              document.getElementById("button1").hidden = true;
              document.getElementById("button2").hidden = false;
              document.getElementById("TF3").hidden = false; //code
              document.getElementById("title3").hidden = false;
              document.getElementById("sub4").hidden = false;
              document.getElementById("divPP").hidden = true;

              //document.getElementById('sub3').hidden = true
            } else {
              Swal.fire({
                icon: "warning",
                text: "¡Ups! Parece que algo salió mal, revisa tus credenciales y vuelve a intentarlo.",
              });
              //document.getElementById('sub3').hidden = false
            }
            //document.getElementById('sub3').innerText = message
          });
        });
      }
    } else if (currentPlat === "Didi") {
      if (!dataPlat.phone) {
        Swal.fire({
          icon: "warning",
          text: "Falta ingresar teléfono.",
        });
        return;
      } else {
        console.log("El telefono es: ");
        console.log(dataPlat.phone);
        fetch(
          "https://us-central1-auto-clupp.cloudfunctions.net/app/confirm_credentials_didi",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              country: "mx",
              user_id: uid,
              phone: dataPlat.phone,
            }),
          }
        ).then(function (result) {
          result.json().then(function (json) {
            var message;
            console.log(json);

            if (json.message === "CONFIRMATION_CODE_SENT") {
              document.getElementById("sub1").hidden = true;
              document.getElementById("sub2").hidden = true;
              document.getElementById("button1").hidden = true;
              document.getElementById("button2").hidden = false;
              document.getElementById("TF3").hidden = false;
              document.getElementById("title3").hidden = false;
              document.getElementById("sub4").hidden = false;
              document.getElementById("divPP").hidden = true;
              document.getElementById("titlePhone").hidden = true;
              document.getElementById("TFPhone").hidden = true;
              //document.getElementById('sub3').hidden = true
            } else {
              Swal.fire({
                icon: "warning",
                text: "¡Ups! Parece que algo salió mal, revisa tus credenciales y vuelve a intentarlo.",
              });
              //document.getElementById('sub3').hidden = false
            }
            //document.getElementById('sub3').innerText = message
          });
        });
      }
    }
  }

  function goToCheckout(evt) {
    let url = `https://us-central1-auto-clupp.cloudfunctions.net/app/create-checkout-session-delay?amount=${primafP}&name=${nameP}&email=${emailP}&period=${periodP}&uid=${uid}&phone=${phoneP}&spPremium=${spPremiumP}&platforms=true&utm_source=${utm_source}`;
    httpGetAsync(url, showURLResponse);
  }

  function validateCode(evt) {
    if (!dataPlat.code) {
      Swal.fire({
        icon: "warning",
        text: "Ingresa el código que recibiste vía SMS",
      });
      return;
    }
    if (currentPlat === "Uber") {
      fetch(
        "https://us-central1-auto-clupp.cloudfunctions.net/app/validate_code",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            user_id: uid,
            code: dataPlat.code,
          }),
        }
      ).then(function (result) {
        console.log(result);
        result.json().then(function (json) {
          var message;
          console.log(json);

          if (json.message === "SUCCESS" || json.message === "success") {
            if (didi) {
              setCurrentPlat("Didi");
              document.getElementById("titlePhone").hidden = false;
              document.getElementById("TFPhone").hidden = false;
              document.getElementById("sub1").hidden = false;
              document.getElementById("sub2").hidden = false;
              document.getElementById("button1").hidden = false;
            } else {
              message =
                "¡Exito! Has validado tus credenciales para la plataforma: UBER";
              document.getElementById("sub4").hidden = false;
              document.getElementById("sub4").innerText = message;
              uber = false;
              let url = `https://us-central1-auto-clupp.cloudfunctions.net/app/create-checkout-session-delay?amount=${primafP}&name=${nameP}&email=${emailP}&period=${periodP}&uid=${uid}&phone=${phoneP}&spPremium=${spPremiumP}&platforms=true&utm_source=${utm_source}`;
              httpGetAsync(url, showURLResponse);
            }
            document.getElementById("button2").hidden = true;
            document.getElementById("TF3").hidden = true;
            document.getElementById("title3").hidden = true;
            document.getElementById("sub3").hidden = true;
            document.getElementById("sub4").hidden = true;
          } else {
            Swal.fire({
              icon: "warning",
              text: "¡Ups! No pudimos verificar tu código, intenta nuevamente.",
            });
            document.getElementById("sub4").hidden = true;
          }
          //document.getElementById('sub3').innerText = message
        });
      });
    } else if (currentPlat === "Didi") {
      fetch(
        "https://us-central1-auto-clupp.cloudfunctions.net/app/validate_code_didi",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            user_id: uid,
            phone: dataPlat.phone,
            code: dataPlat.code,
          }),
        }
      ).then(function (result) {
        console.log(result);
        result.json().then(function (json) {
          var message;
          console.log(json);

          if (json.message === "SUCCESS" || json.message === "success") {
            document.getElementById("titlePhone").hidden = true;
            document.getElementById("TFPhone").hidden = true;
            document.getElementById("sub1").hidden = true;
            document.getElementById("sub2").hidden = true;
            document.getElementById("button1").hidden = true;
            message =
              "¡Exito! Has validado tus credenciales para la plataforma: DIDI";
            document.getElementById("sub4").hidden = false;
            document.getElementById("sub4").innerText = message;
            document.getElementById("button2").hidden = true;
            document.getElementById("TF3").hidden = true;
            document.getElementById("title3").hidden = true;
            document.getElementById("sub3").hidden = true;
            //uber=false
            let url = `https://us-central1-auto-clupp.cloudfunctions.net/app/create-checkout-session-delay?amount=${primafP}&name=${nameP}&email=${emailP}&period=${periodP}&uid=${uid}&phone=${phoneP}&spPremium=${spPremiumP}&platforms=true&utm_source=${utm_source}`;
            httpGetAsync(url, showURLResponse);
          } else {
            Swal.fire({
              icon: "warning",
              text: "¡Ups! No pudimos verificar tu código, intenta nuevamente.",
            });
            document.getElementById("sub4").hidden = true;
          }
          //document.getElementById('sub3').innerText = message
        });
      });
    }
  }

  const handleChangePP = (e) => {
    const target = e.target.checked;
    setCheckedPP(target);
  };

  const handlePhone = (e) => {
    const value = e.target.value;
    const telefono = value.toString();
    setDataPlat({ ...dataPlat, phone: telefono });
    localStorage.setItem("phonePlat", e.target.value);
    setErrorTelefono(!(value.length === 10));
  };

  function togglePasswordVisibility() {
    if (isPasswordVisible === true) {
      setIsPasswordVisible(false);
    } else {
      setIsPasswordVisible(true);
    }
  }

  const handleClick = (e) => {
    window.open(
      "https://auto-clupp.web.app/privacy/aviso_privacidad_vigente.pdf"
    );
    //const validateEmail = new RegExp("^[^@]+@[^@]+\.[a-zA-Z]{2,}$");
    //'https://auto-clupp.web.app/privacy/aviso_privacidad_vigente.pdf'
    //<a onClick={this.handleClick} style={{cursor: 'pointer'}}>click me!</a>
    //validateEmail.test(e.target.value) &&
    //db.collection("prospectsNew").doc(rastreatorUserUid ? rastreatorUserUid : docRef).update({"email": e.target.value,})
  };

  function httpGetAsync(theUrl, callback) {
    setLoading(true);
    let xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState === 4) {
        if (xmlHttp.status === 200) {
          callback(xmlHttp.responseText);
        } else {
          setLoading(false);
          console.log(
            "Ocurrió un error desconocido.\n\n" + xmlHttp.responseText
          );
          Swal.fire({
            icon: "error",
            text: `Ha ocurrido un error, inténtalo de nuevo.
                ${xmlHttp.responseText}`,
          });
        }
      }
    };
    xmlHttp.open("GET", theUrl, true); // true for asynchronous
    xmlHttp.send(null);
  }

  const showURLResponse = async (response) => {
    setLoading(false);
    if (JSON.parse(response)) {
      const stripe = await stripePromise;
      let session = JSON.parse(response);
      let sessionId = session.id;
      if (session.status === "Error") {
        Swal.fire({
          icon: "error",
          text: "Ha ocurrido un error, por favor intenta nuevamente.",
        });
      }
      stripe.redirectToCheckout({ sessionId });
    } else {
      alert("Parece que ocurrió un error. Intenta más tarde.");
    }
  };

  return (
    <Root>
      <p className="title-msi">Validar credenciales</p>
      {!uber && !didi && (
        <div id="subtitle-msi">
          <p className="subtitle-msi" id="pending" hidden>
            Por el momento solo cubrimos vehículos trabajando sobre la
            plataforma UBER o DIDI
          </p>
          <Button
            type="submit"
            onClick={goToCheckout}
            variant="contained"
            className={classes.blue2}
          >
            {loading && <CircularProgress />}
            Proceder al pago
          </Button>
        </div>
      )}
      {(uber || didi) && (
        <div className="container-uber">
          <p className="subtitle-msi" id="sub1">
            Ingresa tus credenciales de la plataforma {currentPlat}.
          </p>
          <p className="subtitle-msi" id="sub2">
            Se te enviará un código de confirmación para validar tu identidad.
          </p>
          <p className="subtitle-msi2" id="sub3" hidden>
            ¡Ups! Parece que algo salió mal, revisa tus credenciales y vuelve a
            intentarlo.
          </p>
          <p className="subtitle-msi" id="sub4" hidden>
            ¡Éxito! Se te ha enviado un código vía SMS, ingrésalo para proceder
            a validar tu usuario.
          </p>
          <br />
          {uber && (
            <div className="subtitle" id="title1">
              <p>Correo</p>
            </div>
          )}
          {uber && (
            <div id="TF1">
              <TextField
                type="email"
                variant="outlined"
                className="input"
                placeholder="Correo de registro en la plataforma"
                onChange={(e) => {
                  setDataPlat({
                    ...dataPlat,
                    email: e.target.value.trim().toLowerCase(),
                  });
                }}
              />
            </div>
          )}
          {uber && (
            <div className="subtitle" id="title2">
              <p>Contraseña</p>
            </div>
          )}
          {uber && (
            <div id="TF2">
              <TextField
                //inputRef={passwordInputRef}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} size="large">
                        {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                //disabled={isLoading}
                //value={password}
                //onChange={(e) => updateState({ password: e.target.value })}
                type={isPasswordVisible ? "text" : "password"}
                variant="outlined"
                className="input"
                //color="primary"
                placeholder="Contraseña de registro en la plataforma"
                onChange={(e) => {
                  setDataPlat({ ...dataPlat, psw: e.target.value });
                }}
                //required
              />
            </div>
          )}
          {currentPlat === "Didi" && (
            <div className="subtitle" id="titlePhone">
              <p>Teléfono</p>
            </div>
          )}
          {currentPlat === "Didi" && (
            <div id="TFPhone">
              <TextField
                type="number"
                variant="outlined"
                className="input"
                placeholder="Teléfono registrado en Didi"
                //value={localStorage.getItem('phone')}
                onChange={handlePhone}
                //onBlur={handleBlurPhone}
              />
              {errorTelefono && (
                <p className="error-msg" style={{ color: "red" }}>
                  Deben ser 10 dígitos
                </p>
              )}
            </div>
          )}
          <div className="subtitle" id="title3" hidden>
            <p>Código de confirmación</p>
          </div>
          <div id="TF3" hidden>
            <TextField
              type="number"
              variant="outlined"
              className="input"
              placeholder="Código que recibiste vía SMS"
              onChange={(e) => {
                setDataPlat({ ...dataPlat, code: e.target.value });
              }}
            />
          </div>
          <br />
          <div className="root2" id="divPP">
            <a href="#" onClick={handleClick}>
              Consulta nuestro aviso de privacidad
            </a>
            <br />
            <FormControlLabel
              id="PP"
              checked={checkedPP}
              control={<GreenCheckbox
                onChange={handleChangePP}
                name="PP"
                classes={{
                  root: classes.root,
                  checked: classes.checked
                }} />}
              label="Aceptar aviso de privacidad"
              value="PP"
            />
          </div>
          <br />
          <div id="button1">
            <Button
              type="submit"
              onClick={confirmCredentials}
              variant="contained"
              className={classes.blue2}
            >
              {loading && <CircularProgress />}
              Continuar
            </Button>
          </div>
          <br />
          <div id="result" hidden>
            <p id="status-message"></p>
          </div>
          <div id="button2" hidden>
            <Button
              type="submit"
              onClick={validateCode}
              variant="contained"
              className={classes.blue2}
            >
              {loading && <CircularProgress />}
              Enviar código
            </Button>
          </div>
        </div>
      )}
    </Root>
  );
};
export default Plats;
