function getWeekStartAndEndTimestamp(year, weekNumber) {
    // Get the first day of the year
    const firstDayOfYear = new Date(year, 0, 1);
    const dayOfWeek = firstDayOfYear.getDay(); // Day of the week for January 1st (0 for Sunday, 1 for Monday, etc.)
  
    // Calculate the date of the first Monday of the year
    const firstMondayOfYear = new Date(firstDayOfYear);
    firstMondayOfYear.setDate(
      firstDayOfYear.getDate() + ((dayOfWeek <= 4 ? 1 : 8) - dayOfWeek)
    );
  
    // Calculate the start date of the week
    const startOfWeek = new Date(firstMondayOfYear);
    startOfWeek.setDate(startOfWeek.getDate() + (weekNumber - 1) * 7);
  
    // Calculate the end date of the week
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);
  
    return {
      startOfWeek,
      endOfWeek,
    };
  }

  export default getWeekStartAndEndTimestamp;