function FormatAmount(amount){
    var formattedAmount = "";
    if(amount<1000){
      formattedAmount = amount;
    } else if(amount<1000000){
      var firstPart = Math.floor(amount/1000);
      var secondPart = amount-firstPart*1000;
      if(secondPart<10){
        secondPart = "00"+secondPart;
      } else if(secondPart<100){
        secondPart = "0"+secondPart;
      } else {
        secondPart = secondPart.toString();
      }
      formattedAmount = firstPart.toString()+','+secondPart;
    }
    return ''+formattedAmount;
  }

  export default FormatAmount;