import { Box, ButtonBase, Typography } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

const SquaredButton = ({ orientation, children, ...props }) => (
  <ButtonBase
    {...props}
    style={{
      width: "30px",
      height: "30px",
      padding: "5px",
      borderRadius: orientation === "left" ? "5px 0px 0px 5px" : "",
      borderRight: orientation === "left" ? "lightgray solid 1px" : "",
      borderLeft: orientation === "left" ? "" : "lightgray solid 1px",
    }}
  >
    {children}
  </ButtonBase>
);

const CounterField = ({ value, modifyCouter = 1, increaseCounter = 1, onChange }) => {
  function increment(value) {
    if (value <= 0) {
      onChange(0);
      return;
    }
    onChange(value);
  }
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        width: "auto",
        border: "lightgray solid 1px",
        borderRadius: "5px",
      }}
    >
      <SquaredButton
        orientation="left"
        onClick={() => increment(value - increaseCounter)}
        size="small"
      >
        <Remove />
      </SquaredButton>

      <Typography
        style={{
          width: "32px",
        }}
      >
        {value / modifyCouter}
      </Typography>
      <SquaredButton
        orientation="right"
        onClick={() => increment(value + increaseCounter)}
        size="small"
      >
        <Add />
      </SquaredButton>
    </Box>
  );
};

export default CounterField;
