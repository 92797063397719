import addListMemberTags from "../services/mailChimp/addListMemberTags";

async function addInterestTag(email, interestLabel) {
  return await addListMemberTags({
    tags: [{ name: interestLabel }],
    email,
  });
}

export default addInterestTag;
