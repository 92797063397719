import { useHistory } from "react-router-dom";

// returns a function that redirects to another route
// preserving the  URLSearchParams
function useRedirect() {
  const { push, location } = useHistory();

  function redirect(route) {
    const routeWithSearchParams = route + location.search;
    push(routeWithSearchParams);
  }

  return redirect;
}

export default useRedirect;
