import { Box } from "@mui/system";
import React, { useEffect } from "react";
import AgenteCotizacionesGrid from "../../Components/AgenteCotizacionesGrid/AgenteCotizacionesGrid";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";
import { Paper, Typography, Button, TextField } from "@mui/material";
import { db } from "../../Firebase/firebase";
import { useState } from "react";
import { Redirect } from "react-router-dom";
import { useAgentAuth } from "../../AuthAgentContext";
import AgentSidebar from "../../Components/AgentSidebar/AgentSidebar";
import BasicDatePicker from "../../Components/DatePickerRange/DatePickerRange";
import ModalVenta from "./ModalVenta";
import { formatCurrency } from "../../utils/formatCurrency";
import Swal from "sweetalert2";
// import Estadisticas from "./Estadisticas";
import FormDialog from "../../Components/NuevaCotizacionDialog/FormDialog";
import useQuery from "../../hooks/useQuery";
import ModalComissionCheckout from "./ModalComissionCheckout";
import format from "date-fns/format";
import { es } from "date-fns/locale";
import { startOfDay, endOfDay, isAfter } from "date-fns";
import NoInfoDataRow from "../../Components/NoInfoDataRow/NoInfoDataRow";
import LoadingOverlay from "../../Components/LoadingOverlay";
import ComissionFilterOption from "./ComissionFilterOption";
import { GridFooterContainer, GridFooter } from "@mui/x-data-grid";
import moment from "moment-timezone";

function getUniqueArrayByFields(array, fields) {
  return array.filter(
    (item, index) =>
      array.findIndex((v2) => fields.every((k) => v2[k] === item[k])) === index
  );
}

async function exportCVS(
  data = [],
  dataColumns = [],
  formatDate = "dd 'de' MMMM 'de' yyyy"
) {
  let columns = `data:text/csv;charset=utf-8,${dataColumns.join(",")}\n`;
  await data.forEach((item) => {
    columns =
      columns +
      `${format(new Date(item.timestampPayed), formatDate, {
        locale: es,
      })},${item.status},${item.periodicity},${item.amount},${
        item.commission
      },${item.commissionStatus},${item.idPayment}\n`;
  });

  const totalString = columns;

  var encodedUri = encodeURI(totalString);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute(
    "download",
    `${format(new Date(), `dd_MM_yyyy`)}_comisiones.csv`
  );
  document.body.appendChild(link); // Required for FF

  link.click();
}

const PERIODICITY = {
  month: "Mensual",
  "3month": "Trimestral",
  "6month": "Semestral",
  year: "Anual",
};

const SALES_INITIAL_STATE = {
  vehicle: [],
  extras: [],
  smartphone: [],
};

const COMMISSION_STATUS = {
  pending: "Por pagar",
  paid: "Pagado",
  unpaid: "Sin pagar",
};

async function getVehicleAndExtrasSubpayments(sale, startAt, endAt) {
  const subpaymentArr = await db
    .collectionGroup(`subPayments`)
    .where("timestampPayed", ">=", startAt)
    .where("timestampPayed", "<=", endAt)
    .where("vid", "==", sale.itemId)
    .where("status", "in", ["succeeded", "refunded"])
    .get();

  let vehicleOrExtraToReturn = {
    extras: [],
    vehicle: [],
  };

  subpaymentArr.docs.forEach((item) => {
    // Do not include uncollectible commissions
    // This is a temporary fix to avoid including uncollectible commissions
    // In the future, we should display uncollectible commissions
    if (item.commissionStatus === "uncollectible") return;
    const paths = item.ref.path.split("/");
    const isExtra = paths.includes("extras");
    const isActive = sale?.isActive !== undefined ? sale?.isActive : true;

    const newItem = {
      isActive,
      cancellationComment: sale.cancellationComment,
      path: item.ref.path,
      idPayment: item.id,
      type: isExtra ? "extra" : "vehicle",
      ...item.data(),
    };

    if (paths.includes(sale.uid)) {
      if (newItem.type === "vehicle") {
        vehicleOrExtraToReturn.vehicle.push(newItem);
      } else if (newItem.type === "extra") {
        vehicleOrExtraToReturn.extras.push(newItem);
      }
    }
  });

  return vehicleOrExtraToReturn;
}

async function getSmartphoneSubpayments(sale, startAt, endAt) {
  const subpaymentArr = await db
    .collectionGroup(`subPayments`)
    .where("timestampPayed", ">=", startAt)
    .where("timestampPayed", "<=", endAt)
    .where("sid", "==", sale.itemId)
    .where("status", "in", ["succeeded", "refunded"])
    .get();

  const getSmartphone = subpaymentArr.docs.map((item) => {
    return {
      cancellationComment: sale.cancellationComment,
      isActive: sale?.isActive !== undefined ? sale?.isActive : true,
      path: item.ref.path,
      idPayment: item.id,
      ...item.data(),
    };
  });

  let smartphoneArrToReturn = [];
  if (getSmartphone.length > 0) {
    smartphoneArrToReturn = getSmartphone.map((item) => {
      return { smartphone: item };
    });
  }
  return smartphoneArrToReturn;
}

async function getCommissiones(sales, startAt, endAt) {
  const arrSnap = await sales.map(async (sale) => {
    const vehicleOrExtra = await getVehicleAndExtrasSubpayments(
      sale,
      startAt,
      endAt
    );

    const smartphone = await getSmartphoneSubpayments(sale, startAt, endAt);

    return {
      vehicle: vehicleOrExtra.vehicle,
      extras: vehicleOrExtra.extras,
      smartphone: smartphone,
    };
  });

  const resolvedArrSnap = await Promise.all(arrSnap);

  let objectToSet = {
    vehicle: [],
    extras: [],
    smartphone: [],
  };

  resolvedArrSnap.forEach((item) => {
    if (item.vehicle && item.vehicle.length > 0) {
      item.vehicle.forEach((vehicleObj) => {
        objectToSet.vehicle.push(vehicleObj);
      });
    }
    if (item.extras && item.extras.length > 0) {
      item.extras.forEach((extraObj) => {
        objectToSet.extras.push(extraObj);
      });
    }
    if (item.smartphone && item.smartphone.length > 0) {
      item.smartphone.forEach((smartObj) => {
        objectToSet.smartphone.push(smartObj.smartphone);
      });
    }
  });

  return objectToSet;
}

export default function Comisiones() {
  const query = useQuery();
  const isGoal = query.get("goal");
  const timestampStart = query.get("timestampStart");
  const timestampEnd = query.get("timestampEnd");

  const date = new Date();
  const firstDay = timestampStart
    ? startOfDay(new Date(Number(timestampStart)))
    : startOfDay(new Date(date.getFullYear(), date.getMonth(), 1));
  const lastDay = timestampEnd
    ? endOfDay(new Date(Number(timestampEnd)))
    : endOfDay(new Date());

  const comissionPercent = 0.1;

  const [isLoading, setIsLoading] = useState(false);

  const [filterByComissionStatus, setFilterByComissionStatus] = useState({
    Pagado: true,
    "Sin pagar": true,
    "Por pagar": true,
  });
  const [selectedRowData, setSelectedRowData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openModalComission, setOpenModalComission] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openNewQuote, setOpenNewQuote] = useState(false);
  const [userDateRange, setUserDateRange] = useState([firstDay, lastDay]);
  const [salesIdDocs, setSalesIdDocs] = useState([]);
  const [salesData, setSalesData] = useState(SALES_INITIAL_STATE);
  const [salesMerged, setSalesMerged] = useState([]);
  const [filteredSalesMerged, setFilteredSalesMerged] = useState([]);
  const [filteredSalesByPaidStatus, setFilteredSalesByPaidStatus] = useState(
    []
  );
  const totalAmounts = getAllPayments();

  const {
    currentAgentUser,
    configuracionPromotoria,
    otherAgent,
    handleRemoveSelectedAgent,
    agentClaims,
  } = useAgentAuth();

  function filterByDate() {
    const [startDate, endDate] = userDateRange;

    // Convertir las fechas de rango a la zona horaria de México
    const initDate = moment
      .tz(startDate, "America/Mexico_City")
      .startOf("day")
      .valueOf();
    const finalDate = moment
      .tz(endDate, "America/Mexico_City")
      .endOf("day")
      .valueOf();

    let filteredProjectByDate = salesMerged.filter((obj) => {
      let date = moment
        .tz(Number(obj.timestampPayed), "America/Mexico_City")
        .valueOf();
      return date >= initDate && date <= finalDate;
    });
    return filteredProjectByDate;
  }

  const agentUid = otherAgent ? otherAgent.id : currentAgentUser?.uid;

  function onChange(e) {
    setSearchValue(e.target.value);
  }

  useEffect(() => {
    setIsLoading(true);
    let unsubscribe;
    if (agentUid) {
      if (
        userDateRange[0] &&
        userDateRange[1] &&
        userDateRange[0] !== userDateRange[1]
      ) {
        const startAt = moment
          .tz(userDateRange[0], "America/Mexico_City")
          .startOf("day")
          .valueOf();
        const endAt = moment
          .tz(userDateRange[1], "America/Mexico_City")
          .endOf("day")
          .valueOf();

        unsubscribe = db
          .collection(`agentProspects/${agentUid}/sales`)
          .onSnapshot(async (snapshot) => {
            const sales = [];
            snapshot.forEach((doc) => {
              const data = doc.data();
              if (
                data?.itemId &&
                !sales.some((item) => item.itemId === data?.itemId)
              ) {
                sales.push({
                  id: doc.id,
                  path: doc.ref.path,
                  ...doc.data(),
                });
              }
            });
            setSalesIdDocs(sales);
            if (sales.length <= 0) {
              setSalesData(SALES_INITIAL_STATE);
              setIsLoading(false);
            }
            const commissions = await getCommissiones(sales, startAt, endAt);
            setSalesData(commissions);

            setIsLoading(false);
          });
      }
    }
    return unsubscribe;
  }, [currentAgentUser, agentUid, userDateRange]);

  function getAllPayments() {
    /**
     * This amount is the sum of all the payments made in the selected date range
     * It does not include taxes (IVA) 16%
     */
    let commition = filteredSalesMerged.reduce((acc, item) => {
      if (
        item.commissionStatus === "Sin pagar" &&
        (item.vehicle?.isActive ||
          item.vehicle?.status === "refunded" ||
          item.smarthphone?.isActive ||
          item.smarthphone?.status === "refunded")
      ) {
        return acc + Number(item.commission);
      }
      return acc;
    }, 0);

    let total = filteredSalesMerged.reduce((acc, item) => {
      if (
        item.vehicle?.isActive ||
        item.vehicle?.status === "refunded" ||
        item.smarthphone?.isActive ||
        item.smarthphone?.status === "refunded"
      ) {
        return acc + Number(item.amount);
      } else {
        return acc;
      }
    }, 0);

    let commitionPayed = filteredSalesMerged.reduce((acc, item) => {
      if (
        item.commissionStatus === "Pagado" &&
        (item.vehicle?.isActive ||
          item.vehicle?.status === "refunded" ||
          item.smarthphone?.isActive ||
          item.smarthphone?.status === "refunded")
      ) {
        return acc + Number(item.commission);
      }
      return acc;
    }, 0);

    return {
      commition,
      total,
      commitionPayed,
    };
  }

  function mergeData() {
    // Takes object with vehicle, smartphone and extras and merges them into one object
    // with the same chargeId and adds the total amount of the three objects

    // We need to treat smartphones separately if they do not belong to a vehicle
    /// We correlate vehicles with smartphones and extras by chargeId
    const STATUS = {
      succeeded: "Exitoso",
      refunded: "Reembolsado",
    };

    const mergedData = salesData.vehicle.map((item) => {
      let filteredSmatphone = salesData.smartphone.filter((filter) => {
        return item.chargeId === filter.chargeId;
      })[0];

      let filteredExtras = salesData.extras.filter((filter) => {
        return item.chargeId === filter.chargeId;
      });

      const totalExtras = filteredExtras.reduce((acc, itemE) => {
        return acc + Number(itemE.amount);
      }, 0);

      const totalSumComission =
        item.status === "refunded"
          ? item.amount
          : item?.amount +
            (filteredSmatphone?.amount ? filteredSmatphone?.amount : 0) +
            totalExtras;

      const comissionTotal = (totalSumComission * comissionPercent).toFixed(2);

      const newComissionTotal = (
        Math.floor((totalSumComission / 1.16) * comissionPercent * 100) / 100
      ).toFixed(2);

      const timestampPayed =
        item?.timestampPayed.toString().length === 13
          ? Number(item?.timestampPayed)
          : Number(`${item?.timestampPayed}000`);

      return {
        vehicle: item,
        idPayment: item?.idPayment,
        timestampPayed: timestampPayed,
        smarthphone: filteredSmatphone,
        extras: filteredExtras,
        periodicity: PERIODICITY[item?.period],
        amount:
          item?.isActive === true
            ? totalSumComission
            : -Math.abs(totalSumComission),
        commission:
          item?.isActive === true
            ? new Date(2023, 0, 25, 11).getTime() >
              new Date(item.timestampPayed).getTime()
              ? comissionTotal
              : newComissionTotal
            : -Math.abs(newComissionTotal),
        status: STATUS[item?.status] || item?.status,
        commissionStatus:
          item?.isActive === true
            ? item?.commissionStatus
              ? COMMISSION_STATUS[item?.commissionStatus]
              : "Sin pagar"
            : "Cancelada",
        sid: filteredSmatphone?.sid ? filteredSmatphone?.sid : "",
        vid: item?.vid ? item?.vid : "",
      };
    });

    const mergedSmartphonesPayments = salesData.smartphone.map((item) => {
      const isCrossSale = Boolean(
        salesData.vehicle.some((vehicle) => vehicle.chargeId === item.chargeId)
      );

      if (isCrossSale) return undefined;

      // At this point we know that the smartphone is not related to a vehicle
      // and we can treat it as a standalone sale

      const totalSumComission = item?.amount;
      const comissionTotal = (totalSumComission * comissionPercent).toFixed(2);
      const newComissionTotal = (
        Math.floor((totalSumComission / 1.16) * comissionPercent * 100) / 100
      ).toFixed(2);

      const timestampPayed =
        item?.timestampPayed.toString().length === 13
          ? Number(item?.timestampPayed)
          : Number(`${item?.timestampPayed}000`);

      return {
        vehicle: undefined,
        idPayment: item?.idPayment,
        timestampPayed: timestampPayed,
        smarthphone: item,
        extras: [],
        periodicity: PERIODICITY[item?.period],
        amount:
          item?.isActive === true
            ? totalSumComission
            : -Math.abs(totalSumComission),
        commission:
          item?.isActive === true
            ? new Date(2023, 0, 25, 11).getTime() >
              new Date(item.timestampPayed).getTime()
              ? comissionTotal
              : newComissionTotal
            : -newComissionTotal,
        status: STATUS[item?.status] || item?.status,
        commissionStatus:
          item?.isActive === true
            ? item?.commissionStatus
              ? COMMISSION_STATUS[item?.commissionStatus]
              : "Sin pagar"
            : "Cancelada",
        sid: item?.sid || "",
        vid: "",
      };
    });

    let sales = [...mergedData, ...mergedSmartphonesPayments].filter(Boolean);

    setSalesMerged(sales);
  }

  useEffect(() => {
    mergeData();
  }, [salesData]);

  useEffect(() => {
    handleSearch();
  }, [searchValue]);

  useEffect(() => {
    if (!!userDateRange.length && userDateRange) {
      setFilteredSalesMerged(filterByDate());
    }
  }, [userDateRange, salesMerged]);

  useEffect(() => {
    showRowsByPaidStatus(filterByComissionStatus, filterByDate());
  }, [userDateRange]);

  useEffect(() => {
    showRowsByPaidStatus(filterByComissionStatus);
  }, [filteredSalesMerged]);

  function handleExport() {
    exportCVS(
      filteredSalesMerged,
      [
        "Fecha de pago",
        "Estatus,Periodicidad",
        "Pago del periodo",
        "Comisión de venta",
        "Estatus de comisión",
        "Identificador",
      ],
      "dd 'de' MMMM 'de' yyyy"
    );
  }
  function handleSearch() {
    const filteredDate = filterByDate();
    const normilizeSearch = (data) => {
      return data
        .normalize("NFD")
        .replace(/[\u0300-\u036f]|\s/g, "")
        .toLowerCase()
        .includes(
          searchValue
            .normalize("NFD")
            .replace(/[\u0300-\u036f]|\s/g, "")
            .toLowerCase()
        );
    };
    let projectFilter = filteredDate.filter((item) => {
      const searchByStatus = normilizeSearch(item?.status);
      const searchByPeriodicity = normilizeSearch(item?.periodicity);
      const searchByAmount = item.amount?.toString().includes(searchValue);
      const searchByDate = normilizeSearch(
        format(
          new Date(Number(`${item.timestampPayed}`)),
          "dd 'de' MMMM 'de' yyyy hh:mm aaa",
          {
            locale: es,
          }
        )
      );
      const searchByComission = item?.commission
        ?.toString()
        .includes(searchValue);
      const searchById = item.idPayment?.includes(searchValue);

      return (
        searchById ||
        searchByStatus ||
        searchByPeriodicity ||
        searchByComission ||
        searchByAmount ||
        searchByDate
      );
    });
    showRowsByPaidStatus(filterByComissionStatus, projectFilter);
    setFilteredSalesMerged(projectFilter);
  }

  function onCloseModalComission() {
    setOpenModalComission(false);
    localStorage.removeItem("paymentRequestId");
  }

  function onOpenModal(selected) {
    setOpenModal(true);
    setSelectedRowData(selected.row);
  }

  function onCloseModal() {
    setOpenModal(false);
  }

  function handleCloseQuoteModal() {
    setOpenNewQuote(false);
  }

  function showRowsByPaidStatus(objectComissionStatus, salesToFilter = []) {
    function getKeysWithValueTrue(obj) {
      return Object.keys(obj).filter((key) => obj[key] === true);
    }
    const paidsLables = getKeysWithValueTrue(objectComissionStatus);
    let newFilterdSales;
    if (salesToFilter.length > 0) {
      newFilterdSales = salesToFilter.filter((item) => {
        if (paidsLables.includes(item.commissionStatus)) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      newFilterdSales = filteredSalesMerged.filter((item) => {
        if (paidsLables.includes(item.commissionStatus)) {
          return true;
        } else {
          return false;
        }
      });
    }
    setFilteredSalesByPaidStatus(newFilterdSales);
  }

  const isForbiddenSection =
    configuracionPromotoria.hiddenSections.includes("commissions");

  function CustomToolBar() {
    return (
      <Box>
        <GridFooterContainer
          sx={{
            display: "flex",
            alignItem: "center",
            padding: "0px 20px",
            gap: "10px",
            justifyContent: "flex-end",
          }}
        >
          <Box sx={{ display: "flex", gap: "12px" }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
              Totales:
            </Typography>
            <Typography variant="subtitle2">
              Pago del periodo:{" "}
              {formatCurrency(totalAmounts?.total, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
            </Typography>
            <Typography variant="subtitle2">
              Comisión de venta:{" "}
              {formatCurrency(
                filteredSalesByPaidStatus.reduce((acc, item) => {
                  return acc + Number(item.commission);
                }, 0),
                { minimumFractionDigits: 0, maximumFractionDigits: 2 }
              )}
            </Typography>
          </Box>
        </GridFooterContainer>
        <GridFooterContainer
          sx={{
            display: "flex",
            alignItem: "center",
            gap: "10px",
            justifyContent: "flex-end",
          }}
        >
          <GridFooter sx={{ border: "none" }} />
        </GridFooterContainer>
      </Box>
    );
  }

  if (configuracionPromotoria.isLoading) return <LoadingOverlay isOpen />;
  if (!currentAgentUser) return <Redirect to="/agentes/inicio-de-sesion" />;
  if (isForbiddenSection) return <Redirect to="/agentes/dashboard" />;

  return (
    <Paper elevation={0} sx={{ width: "100%", minHeight: "100vh" }}>
      <ModalComissionCheckout
        open={openModalComission}
        onClose={onCloseModalComission}
        timestamps={userDateRange}
        comission={totalAmounts}
        selectedSales={salesIdDocs}
        salesIdDocs={filteredSalesMerged}
      />
      <ModalVenta
        isOpen={openModal}
        sales={selectedRowData}
        onClose={onCloseModal}
      />
      <AgentSidebar />
      <DashboardHeader haveABanner={true} />
      <main
        style={{
          width: "90%",
          maxWidth: "1140px",
          margin: "30px auto",
          paddingTop: "70px",
        }}
      >
        <FormDialog open={openNewQuote} onClose={handleCloseQuoteModal} />
        <Box sx={{ position: "relative" }}>
          <Typography style={{ margin: "10px" }} variant="h5">
            {otherAgent
              ? `Comisiones de ${otherAgent.firstName}`
              : "Comisiones"}
          </Typography>
          {agentClaims.isAdmin === true && otherAgent && (
            <Button
              disabled={!otherAgent}
              sx={{
                position: ["initial", "initial", "absolute"],
                top: [0, "5px"],
                right: 0,
                width: ["100%", "auto"],
              }}
              variant="outlined"
              size="small"
              onClick={() => handleRemoveSelectedAgent()}
            >
              Mostrar mis comisiones
            </Button>
          )}
        </Box>
        <BasicDatePicker
          label="polizas"
          initialEndDate={userDateRange[1]}
          initialStartDate={userDateRange[0]}
          onSelectDate={setUserDateRange}
          isDatePicked={true}
          isDisabled={isGoal}
        />
        <Box sx={{ display: "grid", gap: "18px" }}>
          <Box>
            <Typography>Producción (prima pagada) del periodo:</Typography>
            <Box>
              {formatCurrency(totalAmounts?.total, {
                maximumFractionDigits: 0,
              })}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "30px",
            }}
          >
            <Box>
              <Typography>
                Comisión del periodo: <br />
                (Sin pagar):
              </Typography>
              <Box>
                {formatCurrency(totalAmounts?.commition, {
                  maximumFractionDigits: 2,
                })}
              </Box>
            </Box>
            <Box>
              <Typography>
                Comisión del periodo <br />
                (Pagada):
              </Typography>
              <Box>
                {formatCurrency(totalAmounts?.commitionPayed, {
                  maximumFractionDigits: 2,
                })}
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: 700, fontSize: "17px" }}>
              Comisión total del periodo (no pagadas + pagadas):
            </Typography>
            <Box>
              <Typography sx={{ fontWeight: 700 }}>
                {formatCurrency(
                  totalAmounts?.commition + totalAmounts?.commitionPayed,
                  {
                    maximumFractionDigits: 2,
                  }
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* <Estadisticas
          allCustomers={sales}
          vehicles={filteredSales}
          agentData={currentAgentUser}
          dateRange={userDateRange}
          customers={filteredSales}
        /> */}
        <Box sx={{ display: "grid", gap: 3, margin: "20px 0" }}>
          <TextField
            sx={{ backgroundColor: "white" }}
            onChange={onChange}
            onKeyUp={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter")
                handleSearch();
            }}
            size="small"
            fullWidth
            placeholder="Escribe identificador, estatus, periodicidad, o pago del periodo"
            variant="outlined"
            value={searchValue}
          />
          <ComissionFilterOption
            showRowsByPaidStatus={showRowsByPaidStatus}
            filterByComissionStatus={filterByComissionStatus}
            setFilterByComissionStatus={setFilterByComissionStatus}
          />
          <AgenteCotizacionesGrid
            CustomToolBar={CustomToolBar}
            onRowClickProp={(e) => {
              onOpenModal(e);
            }}
            rowData={filteredSalesByPaidStatus}
            type="polizas"
            noRowOverlayLabel={
              salesMerged.length === 0
                ? NoInfoDataRow
                : () => {
                    return null;
                  }
            }
            agentUid={agentUid}
            idRow="idPayment"
            loading={isLoading}
          />
          <Box
            sx={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Typography>
              {!isGoal && (
                <Button
                  onClick={() => {
                    if (totalAmounts.commition <= 0) {
                      Swal.fire({
                        icon: "warning",
                        text: "No tienes comisiones pendientes por cobrar, por favor verifica la fecha de inicio y fin de periodo",
                      });

                      return;
                    }
                    if (isAfter(userDateRange[1], new Date())) {
                      Swal.fire({
                        icon: "warning",
                        text: "Solo puedes cobrar comisiones de periodos anteriores a la fecha de hoy",
                      });

                      return;
                    }

                    setOpenModalComission((prev) => !prev);
                  }}
                  variant="outlined"
                >
                  Cobrar comisiones del periodo{" "}
                </Button>
              )}
            </Typography>
            <Box>
              <Button onClick={handleExport} variant="outlined">
                Exportar datos
              </Button>
            </Box>
          </Box>
        </Box>
      </main>
    </Paper>
  );
}
