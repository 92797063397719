import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import QueCubreModal from "../Modal/QueCubreModal";
import "./TablaCobertura.css";
import { useEffect, useState } from "react";
import ModalDispositivosCobertura from "../ModalDispositivosCobertura/ModalDispositivosCobertura";

const TablaCoberturaDispositivos = ({
  selectedMobility,
  deviceValue: deviceValueInput = 0,
}) => {
  const [coverages, setCoverages] = useState([]);
  useEffect(() => {
    setCoverages(dinamicCoverages(coberturas, selectedMobility));
  }, [selectedMobility]);

  const deviceValue = deviceValueInput.toLocaleString("es-MX");
  const coberturas = [
    {
      cobertura: "Robo con violencia",
      queCubre:
        "Cubre el reembolso o reposición de tu dispositivo en caso de robo con violencia.",
      valorCubierto: (
        <>
          {" "}
          ${deviceValue} <br /> sin exceder el valor comercial.
        </>
      ),
      loQuePagas: "40% del valor",
    },
    {
      cobertura: "Robo sin violencia",
      queCubre:
        "Cubre el reembolso o reposición de tu dispositivo en caso de robo sin violencia.",
      valorCubierto: (
        <>
          {" "}
          ${deviceValue} <br /> sin exceder el valor comercial.
        </>
      ),
      loQuePagas: "40% del valor",
    },
    {
      cobertura: "Rotura de pantalla",
      queCubre:
        "Ampara el reembolso del 60% del gasto de reposición de la pantalla de tu dispositivo en caso de rotura.",
      valorCubierto: (
        <>
          {" "}
          ${deviceValue} <br /> sin exceder el valor comercial.
        </>
      ),
      loQuePagas: "40% de los gastos",
    },
    {
      cobertura: "Daños al equipo",
      queCubre:
        "Ampara el reembolso del 50% del gasto de reparación o reposición de tu dispositivo derivado de daños por variación de voltaje, mojadura, incendio y/o explosión.",
      valorCubierto: (
        <>
          {" "}
          ${deviceValue} <br /> sin exceder el valor comercial.
        </>
      ),
      loQuePagas: "50% de los gastos",
    },
    {
      cobertura: "Descompostura",
      queCubre:
        "Ampara el reembolso del 50% del gasto de reparación o reposición del dispositivo derivado de falla o descompostura.",
      valorCubierto: (
        <>
          {" "}
          ${deviceValue} <br /> sin exceder el valor comercial.
        </>
      ),
      loQuePagas: "50% de los gastos",
    },
  ];

  const dinamicCoverages = (coverages, mobilitySelected) => {
    const coveragesWithEmojis = coverages.map((cover) => {
      let emoji = "❌ (No incluye)";
      if (mobilitySelected === 2) {
        emoji = "✅";
      } else if (
        mobilitySelected === 1 &&
        cover.cobertura !== "Daños al equipo" &&
        cover.cobertura !== "Descompostura"
      ) {
        emoji = "✅";
      } else if (
        mobilitySelected === 0 &&
        cover.cobertura === "Robo con violencia"
      ) {
        emoji = "✅";
      }
      return {
        ...cover,
        emoji: emoji,
      };
    });
    return coveragesWithEmojis;
  };
  return (
    <div className="table-container">
      <h3>Conoce tus coberturas: </h3>
      <p>Este plan cubre:</p>
      <TableContainer>
        <Table aria-label="customized table">
          <TableHead className="tablehead-tabla-cobertura">
            <TableRow>
              <TableCell align="center">Cobertura</TableCell>
              <TableCell align="center">Valor cubierto</TableCell>
              <TableCell align="center">Lo que tu pagas</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coverages.map((item) => (
              <>
                <TableRow>
                  <TableCell component="th" scope="row" align="center">
                    {`${item.emoji} ${item.cobertura}`}
                    <QueCubreModal
                      title={item.cobertura}
                      content={item.queCubre}
                    />
                  </TableCell>
                  <TableCell align="center">{item.valorCubierto}</TableCell>
                  <TableCell align="center">{item.loQuePagas}</TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ModalDispositivosCobertura selectedMobility={selectedMobility} />
    </div>
  );
};

export default TablaCoberturaDispositivos;
