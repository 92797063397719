//import { useState } from "react";
import { useEffect } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Card from "../../Assets/SVG/credit-card.svg";
import CardInactive from "../../Assets/SVG/credit-card 1.svg";
import Trans from "../../Assets/SVG/Group 9214.svg";
import TransInactive from "../../Assets/SVG/Group 9214 1.svg";
import "./MetodoPago.css";
import { Fragment } from "react";
import { red } from "@mui/material/colors";
import { useAgentAuth } from "../../AuthAgentContext";

const primary = red[500]; // #f44336
// color removed from Radio component for error

const MetodoPago = ({
  periodf,
  msi,
  tipoPago,
  setTipoPago,
  primaf,
  tituloBtn,
}) => {
  //const [tipoPago, setTipoPago] = useState('debito');

  const { currentAgentUser } = useAgentAuth();
  const handleTipo = (event) => {
    setTipoPago(event.target.value);
  };

  useEffect(() => {
    console.log("Metodo pago 2: " + tipoPago);
  }, [tipoPago]);

  useEffect(() => {
    console.log("Period f: " + periodf);
    if (periodf !== "year") {
      setTipoPago("debito");
    }
  }, [periodf]);

  return (
    <Fragment>
      <p className="title-metodo">Método pago</p>
      <div className="metodo-pago-container">
        {msi ? null : (
          <>
            {tipoPago === "debito" ? (
              <div>
                <label>
                  <div className="container-metodo fondo-metodo">
                    <div className="radio-metodo">
                      <RadioGroup value={tipoPago} onChange={handleTipo}>
                        <div className="tipo">
                          <FormControlLabel
                            value="debito"
                            control={<Radio />}
                            name="pago"
                          />
                        </div>
                      </RadioGroup>
                    </div>
                    <div className="radio-metodo">
                      <img src={Card} alt="Clup" />
                    </div>
                    <div className="text-metodo">
                      <p>Tarjeta de crédito/débito</p>
                    </div>
                  </div>
                </label>
              </div>
            ) : (
              <div>
                <label>
                  <div className="container-metodo fondo-metodo2">
                    <div className="radio-metodo">
                      <RadioGroup value={tipoPago} onChange={handleTipo}>
                        <div className="tipo">
                          <FormControlLabel
                            value="debito"
                            control={<Radio />}
                            name="pago"
                          />
                        </div>
                      </RadioGroup>
                    </div>
                    <div className="radio-metodo">
                      <img src={CardInactive} alt="Clup" />
                    </div>
                    <div className="text-metodo">
                      <p>Tarjeta de crédito/débito</p>
                    </div>
                  </div>
                </label>
              </div>
            )}
            {tipoPago === "transferencia" &&
            tituloBtn !== "validar credenciales" ? (
              <div>
                <label>
                  <div className="container-metodo fondo-metodo">
                    <div className="radio-metodo">
                      <RadioGroup value={tipoPago} onChange={handleTipo}>
                        <div className="tipo">
                          <FormControlLabel
                            value="transferencia"
                            control={<Radio />}
                            name="pago"
                          />
                        </div>
                      </RadioGroup>
                    </div>
                    <div className="radio-metodo-cel">
                      <img src={TransInactive} alt="Clup" />
                    </div>
                    <div className="text-metodo2">
                      <p>
                        Transferencia <br />
                        electrónica (SPEI)
                      </p>
                    </div>
                  </div>
                </label>
              </div>
            ) : tituloBtn !== "validar credenciales" ? (
              <div>
                <label>
                  <div className="container-metodo fondo-metodo2">
                    <div className="radio-metodo">
                      <RadioGroup value={tipoPago} onChange={handleTipo}>
                        <div className="tipo">
                          <FormControlLabel
                            value="transferencia"
                            control={<Radio />}
                            name="pago"
                          />
                        </div>
                      </RadioGroup>
                    </div>
                    <div className="radio-metodo-cel">
                      <img src={Trans} alt="Clup" />
                    </div>
                    <div className="text-metodo2">
                      <p>
                        Transferencia <br />
                        electronica (SPEI)
                      </p>
                    </div>
                  </div>
                </label>
              </div>
            ) : (
              <div></div>
            )}
            {tipoPago === "efectivo" && tituloBtn !== "validar credenciales" ? (
              <div>
                <label>
                  <div className="container-metodo fondo-metodo">
                    <div className="radio-metodo">
                      <RadioGroup value={tipoPago} onChange={handleTipo}>
                        <div className="tipo">
                          <FormControlLabel
                            value="efectivo"
                            control={<Radio />}
                            name="pago"
                          />
                        </div>
                      </RadioGroup>
                    </div>
                    <div className="text-metodo2">
                      <p>Efectivo</p>
                    </div>
                  </div>
                </label>
              </div>
            ) : tituloBtn !== "validar credenciales" ? (
              <div>
                <label>
                  <div className="container-metodo fondo-metodo2">
                    <div className="radio-metodo">
                      <RadioGroup value={tipoPago} onChange={handleTipo}>
                        <div className="tipo">
                          <FormControlLabel
                            value="efectivo"
                            control={<Radio />}
                            name="pago"
                          />
                        </div>
                      </RadioGroup>
                    </div>
                    <div className="text-metodo2">
                      <p>Efectivo</p>
                    </div>
                  </div>
                </label>
              </div>
            ) : (
              <div></div>
            )}
          </>
        )}

        {tipoPago === "msi" && periodf === "year" && primaf >= 300 ? (
          <div>
            <label>
              <div className="container-metodo fondo-metodo">
                <div className="radio-metodo">
                  <RadioGroup value={tipoPago} onChange={handleTipo}>
                    <div className="tipo">
                      <FormControlLabel
                        value="msi"
                        control={<Radio />}
                        name="pago"
                      />
                    </div>
                  </RadioGroup>
                </div>
                <div className="radio-metodo">
                  <img src={Card} alt="Clup" />
                </div>
                <div className="text-metodo">
                  <p>Meses sin intereses (MSI)</p>
                </div>
              </div>
            </label>
          </div>
        ) : periodf === "year" && primaf >= 300 && !currentAgentUser ? null : (
          // <div>
          //   <label>
          //     <div className="container-metodo fondo-metodo2">
          //       <div className="radio-metodo">
          //         <RadioGroup value={tipoPago} onChange={handleTipo}>
          //           <div className="tipo">
          //             <FormControlLabel
          //               value="msi"
          //               control={<Radio />}
          //               name="pago"
          //             />
          //           </div>
          //         </RadioGroup>
          //       </div>
          //       <div className="radio-metodo">
          //         <img src={CardInactive} alt="Clup" />
          //       </div>
          //       <div className="text-metodo">
          //         <p>Meses sin intereses (MSI)</p>
          //       </div>
          //     </div>
          //   </label>
          // </div>
          <div> </div>
        )}
      </div>
    </Fragment>
  );
};
export default MetodoPago;
