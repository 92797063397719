import { FormGroup, Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const checkboxMuiStyles = {
  color: "#66bb6a",
  "&.Mui-checked": {
    color: "#66bb6a",
  },
};

export default function ComoTeEnteraste({
  howFindOutClupp = [],
  setHowFindOutClupp,
}) {
  function controlPreCheckedOptions(name) {
    return howFindOutClupp.includes(name);
  }

  function handleSelectCheckbox(e) {
    const selectedOption = e.target.name;
    if (e.target.checked === true) {
      setHowFindOutClupp((prev) => prev.concat(selectedOption));
    } else if (e.target.checked === false) {
      const filteredActiveData = howFindOutClupp.filter(
        (item) => item !== selectedOption
      );

      setHowFindOutClupp(filteredActiveData);
    }
  }

  return (
    <FormControl>
      <FormLabel
        sx={{
          color: "#000",
          "&.Mui-focused": { color: "#000" },
        }}
        id="demo-row-radio-buttons-group-label"
      >
        <p className="bold">¿Cómo te enteraste de Clupp?</p>
      </FormLabel>
      <FormGroup
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onClick={handleSelectCheckbox}
        row
        sx={{
          display: ["grid", "grid", "flex"],
          gridTemplateColumns: ["1fr 1fr", "1fr 1fr 1fr"],
          "& .MuiFormControlLabel-root": {
            display: "flex",
            alignItems: ["baseline", "center"]
          }
        }}
        className="checkbox-grid"
      >
        <FormControlLabel
          value="insuranceAgent"
          name="insuranceAgent"
          control={
            <Checkbox
              checked={controlPreCheckedOptions("insuranceAgent")}
              sx={checkboxMuiStyles}
            />
          }
          sx={{ textAlign: "left" }}
          label="Agente de seguros"
        />
        <FormControlLabel
          value="facebook"
          name="facebook"
          control={
            <Checkbox
              checked={controlPreCheckedOptions("facebook")}
              sx={checkboxMuiStyles}
            />
          }
          label="Facebook"
        />
        <FormControlLabel
          value="relative"
          name="relative"
          control={
            <Checkbox
              checked={controlPreCheckedOptions("relative")}
              sx={checkboxMuiStyles}
            />
          }
          sx={{ textAlign: "left" }}
          label="Familiar, conocido u amigo"
        />
        <FormControlLabel
          value="google"
          name="google"
          control={
            <Checkbox
              checked={controlPreCheckedOptions("google")}
              sx={checkboxMuiStyles}
            />
          }
          label="Google"
        />
        <FormControlLabel
          value="instagram"
          name="instagram"
          control={
            <Checkbox
              checked={controlPreCheckedOptions("instagram")}
              sx={checkboxMuiStyles}
            />
          }
          label="Instagram"
        />
        <FormControlLabel
          value="radio"
          name="radio"
          control={
            <Checkbox
              checked={controlPreCheckedOptions("radio")}
              sx={checkboxMuiStyles}
            />
          }
          label="Radio"
        />
        <FormControlLabel
          value="rastreator"
          name="rastreator"
          control={
            <Checkbox
              checked={controlPreCheckedOptions("rastreator")}
              sx={checkboxMuiStyles}
            />
          }
          label="Rastreator"
        />
        <FormControlLabel
          value="spotify"
          name="spotify"
          control={
            <Checkbox
              checked={controlPreCheckedOptions("spotify")}
              sx={checkboxMuiStyles}
            />
          }
          label="Spotify"
        />
        <FormControlLabel
          value="television"
          name="television"
          control={
            <Checkbox
              checked={controlPreCheckedOptions("television")}
              sx={checkboxMuiStyles}
            />
          }
          label="Televisión"
        />
        <FormControlLabel
          value="tiktok"
          name="tiktok"
          control={
            <Checkbox
              checked={controlPreCheckedOptions("tiktok")}
              sx={checkboxMuiStyles}
            />
          }
          label="Tiktok"
        />
        <FormControlLabel
          value="youtube"
          name="youtube"
          control={
            <Checkbox
              checked={controlPreCheckedOptions("youtube")}
              sx={checkboxMuiStyles}
            />
          }
          label="Youtube"
        />
        <FormControlLabel
          value="other"
          name="other"
          control={
            <Checkbox
              checked={controlPreCheckedOptions("other")}
              sx={checkboxMuiStyles}
            />
          }
          label="Otro"
        />
      </FormGroup>
    </FormControl>
  );
}
