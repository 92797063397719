import { IconButton, Stack, Tooltip } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

export default function InvoiceCell({ row }) {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      gap={1}
      direction="row"
      width="100%"
    >
      <Tooltip placement="top" title={"Factura PDF"}>
        <IconButton
          size="medium"
          target="_blank"
          rel="noreferrer"
          href={row.invoicePDF || ""}
          LinkComponent="a"
        >
          <InsertDriveFileIcon />
        </IconButton>
      </Tooltip>
      <Tooltip placement="top" title={"Factura XML"}>
        <IconButton
          size="medium"
          href={row.invoiceXML || ""}
          target="_blank"
          rel="noreferrer"
          LinkComponent="a"
        >
          <DescriptionIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}
