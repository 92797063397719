import { useEffect, useState } from "react";
import { Box, Typography, FormControl } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { IconButton, Button } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useAgentAuth } from "../../AuthAgentContext";
import { db } from "../../Firebase/firebase";
import format from "date-fns/format";
import { es } from "date-fns/locale";
import Swal from "sweetalert2";
import Dialog from "../../Components/Dialog";
import { formatCurrency } from "../../utils/formatCurrency";
import useReadImage from "../../hooks/useReadImage";
import xmlIcon from "../../Assets/PNG/xml-file-icon.png";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import logError from "../../services/logError";
// import comissionLogError from "../../services/commissionLogError";
import { storage } from "../../Firebase/firebase";
import isNumberWithinRange from "../../utils/isNumberWithinRange";

const INVOICE_DATA = {
  razonSocial: "CLUPP MUTUALIDAD",
  rfc: "CMU201120EEA",
  cp: "04230",
  regimenFiscal: "601",
  usoCfdi: ["G01", "G03"],
  claveProducto: "80141600",
  claveUnidad: "E48",
  descripcion: "Comisiones por venta",
};

export default function ModalComissionCheckout({
  open,
  onClose,
  timestamps,
  comission,
  selectedSales,
  salesIdDocs,
  isQuarterlyCollect = false,
}) {
  const { currentAgentUser, otherAgent } = useAgentAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, endDate] = timestamps;
  const [userBankData, setUserBankData] = useState(null);
  const [paymentRequestId, setPaymentRequestId] = useState(null);
  const [paymentRequest, setPaymentRequest] = useState(null);

  const agentUid = otherAgent ? otherAgent?.id : currentAgentUser?.uid;

  useEffect(() => {
    db.collection("agentProspects")
      .doc(agentUid)
      .collection("bankDetails")
      .orderBy("timestamp", "desc")
      .limit(1)
      .get()
      .then((doc) => {
        const docs = doc.docs.map((doc) => doc.data());
        setUserBankData(docs[0]);
      });
  }, [agentUid]);

  const handlerOnSubmit = () => {
    setIsLoading(true);

    const salesIds = isQuarterlyCollect
      ? salesIdDocs
      : selectedSales
          .filter((item) =>
            salesIdDocs.find(
              (doc) => doc.sid === item.itemId || doc.vid === item.itemId
            )
          )
          .map((item) => item.id);

    const bodyData = {
      timestampStart: new Date(startDate).getTime(),
      timestampEnd: new Date(endDate).getTime(),
      salesIds: salesIds,
      type: isQuarterlyCollect ? "bonus" : "commission",
    };
    fetch(
      `https://clupp-api.web.app/v1/clupp/agents/${agentUid}/commission-payouts`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      }
    )
      .then((res) => res.json())
      .then(async (data) => {
        if (data?.payoutId) {
          setPaymentRequestId(data.payoutId);
          setPaymentRequest({
            amountWithTaxes: data.amountWithTaxes,
            amount: data.amount,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            text: data?.message
              ? data?.message
              : "Algo salió mal al confirmar tu solicitud, intentalo más tarde",
          });
          await logError({
            error: data.error,
            metadata: {
              description:
                "Error al generar código de solicitud de pago de comision",
              bodyData: { ...bodyData },
              agentId: currentAgentUser.uid,
              userAgent: navigator.userAgent,
              message: data?.message ? data?.message : "",
            },
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const amountToPay = paymentRequest?.amount || 0;

  return (
    <Dialog
      isOpen={open}
      onClose={onClose}
      sx={{ zIndex: 1000 }}
      title={paymentRequestId && "Genera y carga tu factura"}
      content={
        userBankData?.bankName &&
        userBankData?.CLABE &&
        userBankData?.holderName ? (
          !paymentRequestId ? (
            <>
              <p>
                {`¿Confirmas que deseas comenzar el proceso de cobro de comisiones del
            periodo de ${format(startDate, "d 'de' MMMM 'del' yyyy", {
              locale: es,
            })} al ${format(endDate, "d 'de' MMMM 'del' yyyy", {
                  locale: es,
                })} por un total de ${formatCurrency(amountToPay, {
                  maximumFractionDigits: 2,
                })} a la cuenta de ${userBankData?.bankName} con CLABE
            terminación ${userBankData?.CLABE?.substr(
              userBankData?.CLABE?.length - 4
            )}?`}
              </p>
              <Typography
                variant="body2"
                sx={{
                  "& a": {
                    textDecoration: "none",
                    color: "primary.main",
                  },
                }}
              >
                Si tus datos bancarios no son correctos, por favor corrígelos en{" "}
                <Link to="/agentes/perfil">tu perfil.</Link>
              </Typography>
            </>
          ) : (
            <InvoiceInstruccion
              currentAgentUser={currentAgentUser}
              comission={amountToPay}
              paymentRequestId={paymentRequestId}
              onClose={onClose}
              agentUid={agentUid}
              isBonusPayment={isQuarterlyCollect}
            />
          )
        ) : (
          <Typography>
            Aún no has registrado tus datos bancarios, por favor registra tu
            información desde <Link to="/agentes/perfil">tu perfil</Link> para
            continuar.
          </Typography>
        )
      }
      actions={
        // !paymentRequestId &&
        userBankData?.bankName &&
        userBankData?.CLABE &&
        userBankData?.holderName && (
          <>
            <Button variant="outlined" onClick={onClose}>
              Cancelar
            </Button>
            <LoadingButton
              variant="contained"
              loading={isLoading}
              onClick={handlerOnSubmit}
            >
              Confirmar
            </LoadingButton>
          </>
        )
      }
    />
  );
}

function InvoiceInstruccion({
  /**
   *  Final amount to pay to the agent
   */
  comission,
  currentAgentUser,
  paymentRequestId,
  onClose,
  agentUid,
}) {
  const [loading, setIsLoading] = useState(false);
  const [agentData, setAgentData] = useState(null);
  const [docUrls, setDocUrls] = useState({
    pdf: null,
    xml: null,
  });
  const {
    inputImgRef: inputPdfRef,
    setInputImage: setInputPdf,
    inputImage: inputPdf,
  } = useReadImage();

  const {
    inputImgRef: inputXmlRef,
    setInputImage: setInputXml,
    inputImage: inputXml,
  } = useReadImage();

  const esPersonaMoral = Boolean(agentData?.companyName);

  const comissionRounded =
    Math.round((Math.round(comission * 0.95333333) / 0.953333) * 100) / 100;

  const totalComission = Math.round(
    Math.round(comission * 0.95333333 * 100) / 100
  );

  const ivaComission = Math.round(comission * 0.16 * 100) / 100;

  const retenedIva = Math.round(comission * 0.106667 * 100) / 100;

  const isrRetend = Math.round(comission * 0.1 * 100) / 100;

  // Persona Moral data
  // subtotal
  const montoPersonaModal = Math.round((comission / 1.16) * 100) / 100;
  const ivaPersonaMoral = Math.round(montoPersonaModal * 0.16 * 100) / 100;
  const totalPersonaMoral = Math.round(comission * 100) / 100;

  useEffect(() => {
    const unsubscribe = db
      .collection("agentProspects")
      .doc(agentUid)
      .onSnapshot((doc) => {
        setAgentData({
          ...doc.data(),
          uid: doc.id,
        });
      });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (inputXml) {
      uploadXml(inputXml);
    }
  }, [inputXml]);
  useEffect(() => {
    if (inputPdf) {
      uploadPdf(inputPdf);
    }
  }, [inputPdf]);
  function comissionLogError() {}

  function openInfo() {
    Swal.fire({
      icon: "info",
      html: `<p>
      El monto se redondea para que el total a pagar salga en pesos, sin centavos. De esta manera todas las plataformas de faturación pueden generar la factura correctamente.
      <br/><br/><b>Monto sin redondear:</b> ${formatCurrency(comission, {
        maximumFractionDigits: 2,
      })}
      </p>`,
      padding: "0.5em 0.1em",
    });
  }

  function isXmlValid(xmlFile) {
    const errorData = {
      agentUid: currentAgentUser?.uid,
      xmlUrl: docUrls.xml,
      pdfUrl: docUrls.pdf,
      metadata: {
        message:
          "Usuario intentó subir un archivo xml no válido para cobrar comisión",
        user: currentAgentUser?.uid,
        commisionPayout: paymentRequestId,
        userAgent: navigator.userAgent,
        amount: totalComission,
        xmlUrl: docUrls.xml,
        pdfUrl: docUrls.pdf,
      },
    };
    let reader = new FileReader();
    reader.readAsText(xmlFile);
    return new Promise((resolve, reject) => {
      reader.onload = function () {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(reader.result, "text/xml");

        if (
          xmlDoc.getElementsByTagName("cfdi:Comprobante").length === 0 ||
          xmlDoc.getElementsByTagName("cfdi:Receptor").length === 0 ||
          xmlDoc.getElementsByTagName("cfdi:Concepto").length === 0
        ) {
          comissionLogError({
            ...errorData,
            error:
              "El archivo xml no contiene los tags 'cfdi:Comprobante' o 'cfdi:Receptor' para ser válido",
          });
          Swal.fire({
            icon: "error",
            text: "La factura registrada no es válida, por favor verifica que sea la factura correcta",
          });
          resolve(false);
        } else if (
          xmlDoc
            .getElementsByTagName("cfdi:Receptor")[0]
            ?.getAttribute("Nombre") !== INVOICE_DATA.razonSocial
        ) {
          comissionLogError({
            ...errorData,
            error: `El archivo xml no contiene la razón social (${xmlDoc
              .getElementsByTagName("cfdi:Receptor")[0]
              ?.getAttribute("Nombre")}) correcta: ${INVOICE_DATA.razonSocial}`,
          });
          Swal.fire({
            icon: "error",
            text: "La razón social registrada en la factura es distinta, por favor verifica que sea la factura correcta",
          });
          resolve(false);
        } else if (
          xmlDoc
            .getElementsByTagName("cfdi:Receptor")[0]
            ?.getAttribute("Rfc") !== INVOICE_DATA.rfc
        ) {
          comissionLogError({
            ...errorData,
            error: `El archivo xml no contiene el RFC (${xmlDoc
              .getElementsByTagName("cfdi:Receptor")[0]
              ?.getAttribute("Rfc")}) correcto: ${INVOICE_DATA.rfc}`,
          });
          Swal.fire({
            icon: "error",
            text: "El RFC de la factura registrada no es válido, por favor verifica que sea la factura correcta",
          });
          resolve(false);
        } else if (
          xmlDoc
            .getElementsByTagName("cfdi:Receptor")[0]
            ?.getAttribute("UsoCFDI") !== INVOICE_DATA.usoCfdi[0] &&
          xmlDoc
            .getElementsByTagName("cfdi:Receptor")[0]
            ?.getAttribute("UsoCFDI") !== INVOICE_DATA.usoCfdi[1]
        ) {
          comissionLogError({
            ...errorData,
            error: `El archivo xml no contiene el uso de CFDI (${xmlDoc
              .getElementsByTagName("cfdi:Receptor")[0]
              ?.getAttribute("UsoCFDI")}) correcto: ${
              INVOICE_DATA.usoCfdi[0]
            } o ${INVOICE_DATA.usoCfdi[1]}`,
          });
          Swal.fire({
            icon: "error",
            text: "El uso del CFDI de la factura registrada no es válida, por favor verifica que sea la factura correcta",
          });
          resolve(false);
        } else if (
          xmlDoc
            .getElementsByTagName("cfdi:Concepto")[0]
            ?.getAttribute("ClaveProdServ") !== INVOICE_DATA.claveProducto
        ) {
          comissionLogError({
            ...errorData,
            error: `El archivo xml no contiene la clave de producto (${xmlDoc
              .getElementsByTagName("cfdi:Concepto")[0]
              ?.getAttribute("ClaveProdServ")}) correcta: ${
              INVOICE_DATA.claveProducto
            }`,
          });
          Swal.fire({
            icon: "error",
            text: "La clave del producto de la factura registrada no es válida, por favor verifica que sea la factura correcta",
          });
          resolve(false);
        } else if (
          xmlDoc
            .getElementsByTagName("cfdi:Concepto")[0]
            ?.getAttribute("ClaveUnidad") !== INVOICE_DATA.claveUnidad
        ) {
          comissionLogError({
            ...errorData,
            error: `El archivo xml no contiene la clave de unidad (${xmlDoc
              .getElementsByTagName("cfdi:Concepto")[0]
              ?.getAttribute("ClaveUnidad")}) correcta: ${
              INVOICE_DATA.claveUnidad
            }`,
          });
          Swal.fire({
            icon: "error",
            text: "La clave de unidad de la factura registrada no es válida, por favor verifica que sea la factura correcta",
          });
          resolve(false);
        }
        // else if (
        //   xmlDoc
        //     .getElementsByTagName("cfdi:Concepto")[0]
        //     ?.getAttribute("Descripcion") !== INVOICE_DATA.descripcion
        // ) {
        //   comissionLogError({
        //     ...errorData,
        //     error: `El archivo xml no contiene la descripción(${xmlDoc
        //       .getElementsByTagName("cfdi:Concepto")[0]
        //       ?.getAttribute("Descripcion")}) correcta: ${
        //       INVOICE_DATA.descripcion
        //     }`,
        //   });
        //   Swal.fire({
        //     icon: "error",
        //     text: "La descripción de la factura registrada no es válida, por favor verifica que sea la factura correcta",
        //   });
        //   resolve(false);
        // }
        else if (
          !isNumberWithinRange(
            Number(
              xmlDoc
                .getElementsByTagName("cfdi:Comprobante")[0]
                ?.getAttribute("Total")
            ),
            Number(esPersonaMoral ? totalPersonaMoral : totalComission),
            -1,
            1
          )
        ) {
          comissionLogError({
            ...errorData,
            error: `El archivo xml no contiene el monto (${Number(
              xmlDoc
                .getElementsByTagName("cfdi:Comprobante")[0]
                ?.getAttribute("Total")
            )}) correcto: ${Number(
              esPersonaMoral ? totalPersonaMoral : totalComission
            )}`,
          });
          Swal.fire({
            icon: "error",
            text: `El monto total en la factura (${Number(
              xmlDoc
                .getElementsByTagName("cfdi:Comprobante")[0]
                .getAttribute("Total")
            )}) no coincide con el monto total de tu comisión (${Number(
              esPersonaMoral ? totalPersonaMoral : totalComission
            )}). Por favor verifica que hayas cargado la factura correcta.`,
          });
          resolve(false);
        }
        resolve(true);
      };
    });
  }

  function uploadXml(inputXml) {
    setIsLoading(true);
    new Promise((resolve, reject) => {
      const refXml = storage.ref(
        `agents/${agentUid}/comission-payouts/${paymentRequestId}/factura_${format(
          new Date().getTime(),
          "d-MM-yy"
        )}.xml`
      );
      const taskXML = refXml.put(inputXml);

      taskXML.on("state_changed", {
        error: (error) => {
          reject(error);
        },
        complete: async () => {
          const url = {
            type: "xml",
            url: await refXml.getDownloadURL(),
          };
          resolve(url);
        },
      });
    })
      .then((value) => {
        setDocUrls((prevState) => ({
          ...prevState,
          xml: value.url,
        }));
        setIsLoading(false);
      })
      .catch((err) => {
        logError({
          error: err.message,
          message: "Error al guardar factura en firestore",
          timestamp: new Date().getTime(),
          context: {
            user: currentAgentUser,
            commisionPayout: paymentRequestId,
            userAgent: navigator.userAgent,
          },
        });
        Swal.fire({
          icon: "error",
          text: "Error al subir el archivo xml",
        });
        setIsLoading(false);
      });
  }

  function uploadPdf(inputPdf) {
    setIsLoading(true);
    new Promise((resolve, reject) => {
      const refPdf = storage.ref(
        `agents/${agentUid}/comission-payouts/${paymentRequestId}/factura_${format(
          new Date().getTime(),
          "d-MM-yy"
        )}.pdf`
      );
      const taskPDF = refPdf.put(inputPdf);

      taskPDF.on("state_changed", {
        error: (error) => {
          reject(error);
        },
        complete: async () => {
          const url = {
            type: "pdf",
            url: await refPdf.getDownloadURL(),
          };
          resolve(url);
        },
      });
    })
      .then((value) => {
        setDocUrls((prevState) => ({
          ...prevState,
          pdf: value.url,
        }));
        setIsLoading(false);
      })
      .catch((err) => {
        logError({
          error: err.message,
          message: "Error al guardar factura en firestore",
          timestamp: new Date().getTime(),
          context: {
            user: currentAgentUser,
            commisionPayout: paymentRequestId,
            userAgent: navigator.userAgent,
          },
        });
        Swal.fire({
          icon: "error",
          text: "Error al subir el archivo xml",
        });
        setIsLoading(false);
      });
  }

  async function sendInovice() {
    setIsLoading(true);
    if (!(await isXmlValid(inputXml))) {
      setIsLoading(false);
      console.log("invalid xml");
      return;
    }

    if (!docUrls.pdf || !docUrls.xml) {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        text: "Debes subir tu factura",
      });
      return;
    }

  
    const response = await fetch(
      `https://clupp-api.web.app/v1/clupp/agents/${agentUid}/commission-payouts/${paymentRequestId}/submit-request`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          invoicePDF: docUrls.pdf,
          invoiceXML: docUrls.xml,
        }),
      }
    );

    const parsedReponse = await response.json();
    if (response.ok) {
      Swal.fire({
        icon: "success",
        text: "Se cargó tu factura correctamente. En un lapso de 3 días hábiles recibirás tu pago",
      }).then(() => {
        onClose();
      });
    } else {
      Swal.fire({
        icon: "error",
        text: "Ocurrió un error al cargar tu factura. Inténtalo más tarde",
      });
      comissionLogError({
        error: parsedReponse.error,
        timestamp: new Date().getTime(),
        xmlUrl: docUrls.xml,
        pdfUrl: docUrls.pdf,
        agentUid: currentAgentUser.uid,
        metadata: {
          description: "Error al enviar factura",
          user: currentAgentUser.uid,
          commisionPayout: paymentRequestId,
          pdfUrl: docUrls.pdf ? docUrls.pdf : "No se pudo subir el pdf",
          xmlUrl: docUrls.xml ? docUrls.xml : "No se pudo subir el xml",
          userAgent: navigator.userAgent,
        },
      });
      console.log(parsedReponse.error);
    }
    setIsLoading(false);
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
      <Typography variant="body1">
        Por favor sigue estas indicaciones para elaborar tu factura. Una vez
        elaborada, carga aquí la factura en formato PDF y XML.
      </Typography>
      {esPersonaMoral ? (
        <Box>
          <Typography>
            <b>Razón Social:</b> CLUPP MUTUALIDAD
            <br />
            <b>RFC:</b> CMU201120EEA
            <br />
            <b>CP:</b> 04230
            <br />
            <b>Régimen fiscal:</b> 601 - General de Ley Personas Morales
            <br />
            <b>Uso CFDI:</b> G01 - Gastos en general
            <br />
            <b>Clave del producto o servicio:</b> 80141600
            <br />
            <b>Unidad de servicio:</b> E48
            <br />
            <b>Descripción:</b> Comisiones por venta
            <br />
            <b>Forma de pago:</b> Transferencia electrónica de fondos
            <br />
            <b>Método de pago:</b> Pago en una sola exhibición
            <br />
          </Typography>
          <Typography variant="body1">
            <b>Monto:</b>{" "}
            {formatCurrency(montoPersonaModal, {
              maximumFractionDigits: 2,
            })}
            <br />
            <b>IVA:</b>{" "}
            {formatCurrency(ivaPersonaMoral, {
              maximumFractionDigits: 2,
            })}{" "}
            {"(16%)"}
            <br />
            <b>Total:</b>{" "}
            {formatCurrency(totalPersonaMoral, { maximumFractionDigits: 2 })}
          </Typography>
        </Box>
      ) : (
        <Box>
          <Typography>
            <b>Razón Social:</b> CLUPP MUTUALIDAD
            <br />
            <b>RFC:</b> CMU201120EEA
            <br />
            <b>CP:</b> 04230
            <br />
            <b>Régimen fiscal:</b> 601 - General de Ley Personas Morales
            <br />
            <b>Uso CFDI:</b> G01 - Gastos en general
            <br />
            <b>Clave del producto o servicio:</b> 80141600
            <br />
            <b>Unidad de servicio:</b> E48
            <br />
            <b>Descripción:</b> Comisiones por venta
            <br />
            <b>Forma de pago:</b> Transferencia electrónica de fondos
            <br />
            <b>Método de pago:</b> Pago en una sola exhibición
            <br />
          </Typography>
          <Typography variant="body1">
            {/* <b>Monto:</b>{" "}
            {formatCurrency(comission, { maximumFractionDigits: 2 })}
            <br /> */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <b>Monto redondeado:&nbsp;</b>
              {formatCurrency(comissionRounded, {
                maximumFractionDigits: 2,
              })}
              <IconButton
                sx={{ marginLeft: "12px" }}
                variant="outlined"
                size="small"
                onClick={openInfo}
              >
                <QuestionMarkIcon fontSize="small" />
              </IconButton>
            </div>
            <b>IVA trasladado:</b>{" "}
            {formatCurrency(ivaComission, { maximumFractionDigits: 2 })}{" "}
            {"(16%)"}
            <br />
            <b>IVA retenido:</b>{" "}
            {formatCurrency(retenedIva, { maximumFractionDigits: 2 })}{" "}
            {"(10.6667%)"}
            <br />
            <b>ISR retenido:</b>{" "}
            {formatCurrency(isrRetend, { maximumFractionDigits: 2 })} {"(10%)"}
            <br />
            <b>Total:</b>{" "}
            {formatCurrency(totalComission, { maximumFractionDigits: 2 })}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: ["1fr", "1fr 1fr"],
          gap: "15px",
        }}
      >
        <Box sx={{ maxWidth: "100%" }}>
          <FormControl sx={{ width: ["100%", "auto"] }}>
            <div style={{ height: "70px" }}>
              {inputPdf && inputPdf.name.split(".").pop() === "pdf" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PictureAsPdfIcon sx={{ marginRight: "5px" }} />
                  <Typography
                    sx={{
                      width: ["220px", "230px"],
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {inputPdf.name}
                  </Typography>
                </div>
              )}
            </div>
            <input
              type="file"
              onChange={(e) => {
                setInputPdf(e.target.files[0]);
              }}
              accept="application/pdf"
              ref={inputPdfRef}
              style={{
                width: "0.1px",
                height: "0.1px",
                opacity: "0",
                overflow: "hidden",
                position: "absolute",
                zIndex: "-1",
              }}
            />
            <Button
              onClick={() => inputPdfRef.current.click()}
              type="button"
              variant="outlined"
              sx={{ width: ["100%", "140px"] }}
            >
              Cargar PDF
            </Button>
          </FormControl>
        </Box>
        <Box sx={{ maxWidth: "100%" }}>
          <FormControl sx={{ width: ["100%", "auto"] }}>
            <div style={{ height: "70px" }}>
              {inputXml && inputXml.name.split(".").pop() === "xml" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={xmlIcon}
                    alt="xml icon charged"
                    style={{ width: "25px", marginRight: "5px" }}
                  />
                  <Typography
                    sx={{
                      width: ["220px", "230px"],
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {inputXml.name}
                  </Typography>
                </div>
              )}
            </div>
            <input
              type="file"
              onChange={(e) => {
                setInputXml(e.target.files[0]);
              }}
              accept="application/xml"
              ref={inputXmlRef}
              style={{
                width: "0.1px",
                height: "0.1px",
                opacity: "0",
                overflow: "hidden",
                position: "absolute",
                zIndex: "-1",
              }}
            />
            <Button
              onClick={() => inputXmlRef.current.click()}
              type="button"
              variant="outlined"
              sx={{ width: ["100%", "140px"] }}
            >
              Cargar XML
            </Button>
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "right", marginTop: "20px" }}>
        <LoadingButton
          disabled={!docUrls.pdf || !docUrls.xml}
          loading={loading}
          variant="contained"
          onClick={sendInovice}
        >
          Enviar factura
        </LoadingButton>
      </Box>
    </Box>
  );
}
