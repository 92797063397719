function getWeekNumber(date) {
    // Create a copy of the input date object to avoid modifying the original date
const d = new Date(date.getFullYear(), date.getMonth(), date.getDate());

// Adjust the date to the nearest Thursday
d.setDate(d.getDate() + 4 - (d.getDay() || 7));

// Get the start of the year for the adjusted date
const yearStart = new Date(d.getFullYear(), 0, 1);

// Calculate the week number
const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);

// Return the year and the week number as an array
return [d.getFullYear(), weekNo];
}

export default getWeekNumber