// given the mobility propety value from the database
// return the corresponding property from the quotation document
function getPremiumPropertyName(mobility) {
  const NAMES = {
    0: "premium0",
    1: "premium1",
    2: "premium2",
    3: "premium3",
  };
  return NAMES[mobility] || "";
}

export default getPremiumPropertyName;
