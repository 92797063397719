import { useEffect, useState } from "react";
import { Chip, TextField } from "@mui/material";
import "./TusDatos.css";
import { db } from "../../Firebase/firebase";
import { Fragment } from "react";
import isValidEmail from "../../utils/isValidEmail";
import getTotalPremium from "../../utils/getPremium";
import formatPremium from "../../utils/formatPremium";
import Swal from "sweetalert2";
import isADevice from "../../utils/isADevice";
import LoyaltyIcon from "@mui/icons-material/Loyalty";

const PERIODICITY_DATA = {
  year: "anual",
  "6month": "semestral",
  "3month": "trimestral",
  month: "mensual",
};
function getAllDataFromLocalStorage() {
  const localStorageData = {};

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    const value = localStorage.getItem(key);
    localStorageData[key] = value;
  }

  return localStorageData;
}

const TusDatos = ({
  saveDatos,
  setSaveDatos,
  docRef,
  rastreatorUserUid,
  vehicleData,
  agentsData,
  userData,
}) => {
  const [errorTelefono, setErrorTelefono] = useState(false);
  const [errorRFC, setErrorRFC] = useState(false);
  const [input, setInput] = useState("");
  const localStorageObj = getAllDataFromLocalStorage();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const param_email = urlParams.get("email");

  useEffect(() => {
    if (localStorageObj)
      if (localStorageObj?.rfc) {
        setInput(localStorageObj?.rfc);
      }
    return setSaveDatos({
      ...saveDatos,
      ...localStorageObj,
    });
  }, [localStorage, userData]);

  const handleBlurEmail = (e) => {
    if (isValidEmail(e.target.value)) {
      const email = e.target.value.trim().replace("hormail", "hotmail");
      // update email in localStorage ???
      localStorage.setItem("email", email);
      setSaveDatos({ ...saveDatos, email });
      return db
        .collection("prospectsNew")
        .doc(rastreatorUserUid ? rastreatorUserUid : docRef)
        .update({
          email,
        });
    }

    Swal.fire({ icon: "warning", text: "Ingresa un correo válido" });
  };

  // funcion para hacer update al ingresar teléfono
  const handleBlurPhone = (e) => {
    e.target.value < 10000000000 &&
      e.target.value > 1000000000 &&
      db
        .collection("prospectsNew")
        .doc(rastreatorUserUid ? rastreatorUserUid : docRef)
        .update({
          phone: e.target.value,
        });
  };

  const handleTelefono = (e) => {
    const value = e.target.value;
    const telefono = value.toString();
    setSaveDatos({ ...saveDatos, phone: telefono });
    localStorage.setItem("phone", e.target.value);
    setErrorTelefono(!(value.length === 10));
  };

  const handleRFC = (e) => {
    const value = e.target.value;
    const rfc = value.toString();
    setSaveDatos({ ...saveDatos, rfc: rfc });
    localStorage.setItem("rfc", e.target.value.toUpperCase());
    setInput(e.target.value.toUpperCase());
    setErrorRFC(value.length !== 10 && value.length !== 13);
  };

  return (
    <Fragment>
      <h5 className="tus-datos-title">
        {vehicleData &&
          `Estás por contratar un seguro de cobertura ${
            isADevice(vehicleData.type) ? "" : "amplia"
          } para tu ${
            isADevice(vehicleData.type)
              ? `${
                  vehicleData.type === "smartphone"
                    ? "celular"
                    : vehicleData.type === "laptop"
                    ? "computadora"
                    : "tableta"
                }`
              : ""
          } ${vehicleData.brand}, ${vehicleData.model}${
            vehicleData.year ? `, ${vehicleData.year}` : ""
          } con valor de $${vehicleData.valueUser.toLocaleString(
            "en-US"
          )} por una prima ${PERIODICITY_DATA[userData?.period]} de ${
            agentsData?.period
              ? formatPremium(
                  getTotalPremium(vehicleData.amount, agentsData?.period, {
                    msi: userData.msi || false,
                    haveDiscount: userData?.haveADiscountCoupon,
                    discount: userData?.discount,
                  })[0]
                )
              : ""
          }.`}
      </h5>
      {userData?.haveADiscountCoupon && (
        <>
          <p
            style={{ marginTop: "15px" }}
          >{`Tienes un ${userData.discount.percentage}% de descuento con el cupón:`}</p>
          <Chip
            icon={<LoyaltyIcon sx={{ fill: "#fff" }} />}
            sx={{
              backgroundColor: "#00CA7D",
              color: "#fff",
              margin: "-5px auto 20px auto",
            }}
            label={userData?.discount.name}
          />
        </>
      )}

      <p>Para finalizar por favor introduce los siguientes datos:</p>
      <div className="tus-datos" style={{ marginBottom: "150px" }}>
        <div className="container-datos" id="userName">
          <div className="title">
            <p>Nombre(s)</p>
          </div>
          <TextField
            fullWidth
            type="text"
            variant="outlined"
            className="input"
            placeholder="Nombre(s)"
            value={saveDatos?.firstName}
            onChange={(e) => {
              setSaveDatos({
                ...saveDatos,
                firstName: e.target.value
                  .toLowerCase()
                  .replace(/\w\S*/g, (w) =>
                    w.replace(/^\w/, (c) => c.toUpperCase())
                  ),
              });
              localStorage.setItem(
                "firstName",
                e.target.value
                  .toLowerCase()
                  .replace(/\w\S*/g, (w) =>
                    w.replace(/^\w/, (c) => c.toUpperCase())
                  )
              );
            }}
          />
        </div>

        <div className="container-datos" id="userLastName">
          <div className="title">
            <p>Primer apellido</p>
          </div>
          <TextField
            fullWidth
            type="text"
            variant="outlined"
            className="input"
            placeholder="Escribe tu Primer apellido"
            value={saveDatos?.lastName}
            onChange={(e) => {
              setSaveDatos({
                ...saveDatos,
                lastName: e.target.value
                  .toLowerCase()
                  .replace(/\w\S*/g, (w) =>
                    w.replace(/^\w/, (c) => c.toUpperCase())
                  ),
              });
              localStorage.setItem(
                "lastName",
                e.target.value
                  .toLocaleLowerCase()
                  .replace(/\w\S*/g, (w) =>
                    w.replace(/^\w/, (c) => c.toUpperCase())
                  )
              );
            }}
          />
        </div>

        <div className="container-datos">
          <div className="title">
            <p>Segundo apellido</p>
          </div>
          <TextField
            fullWidth
            type="text"
            variant="outlined"
            className="input"
            placeholder="Escribe tu Segundo apellido"
            value={saveDatos?.lastName2}
            onChange={(e) => {
              setSaveDatos({
                ...saveDatos,
                lastName2: e.target.value
                  .toLowerCase()
                  .replace(/\w\S*/g, (w) =>
                    w.replace(/^\w/, (c) => c.toUpperCase())
                  ),
              });
              localStorage.setItem(
                "lastName2",
                e.target.value
                  .toLowerCase()
                  .replace(/\w\S*/g, (w) =>
                    w.replace(/^\w/, (c) => c.toUpperCase())
                  )
              );
            }}
          />
        </div>

        <div className="container-datos" id="userEmail">
          <div className="title">
            <p>Correo</p>
          </div>
          <TextField
            fullWidth
            type="email"
            variant="outlined"
            className="input"
            placeholder="Escribe tu correo"
            value={saveDatos?.email}
            disabled={agentsData?.email && param_email}
            onChange={(e) => {
              setSaveDatos({
                ...saveDatos,
                email: e.target.value.trim().toLowerCase(),
              });
              localStorage.setItem("email", e.target.value.toLowerCase());
            }}
            onBlur={handleBlurEmail}
          />
        </div>

        <div className="container-datos" id="userPhone">
          <div className="title">
            <p>Teléfono</p>
          </div>
          <TextField
            fullWidth
            type="number"
            variant="outlined"
            className="input"
            placeholder="Escribe tu teléfono"
            value={localStorageObj?.phone}
            onChange={handleTelefono}
            onBlur={handleBlurPhone}
          />
          {errorTelefono && (
            <p className="error-msg" style={{ color: "red" }}>
              Deben ser 10 dígitos
            </p>
          )}
        </div>
        <div className="container-datos" id="userRFC">
          <div className="title">
            <p>RFC (si requieres factura, debe contener homoclave)</p>
          </div>
          <TextField
            fullWidth
            type="text"
            variant="outlined"
            className="input"
            autocapitalize="characters"
            placeholder="10 o 13 dígitos"
            value={input}
            //value={localStorage.getItem('rfc')}
            onChange={handleRFC}
          />
          {errorRFC && (
            <p className="error-msg" style={{ color: "red" }}>
              Deben ser 10 o 13 dígitos
            </p>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default TusDatos;
