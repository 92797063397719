import React from 'react';
import Modal from '@mui/material/Modal';
import carIcon from '../../Assets/SVG/Group 9231.svg';
import './ModalPasa.css';

export default function ModalPasa() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const body = (
    <div className='paper'>
      <img src={carIcon} alt='car-icon' />
      <p className='modal-cobertura-title'>¿Qué sucede si te pasas de los km de tu plan contratado?</p>
      <p className='modal-cobertura-subtitle'>
      Si un mes te pasas de los km del plan, no pasa nada (no se te cobra nada adicional), pero si sucede por dos meses seguidos, entonces te notificaremos para hacer el cambio de plan al que corresponda. Esto mismo sucede tanto para arriba, como para abajo, es decir, también si usas menos km de los que corresponden a tu plan durante dos meses seguidos, se puede corregir tu tarifa a la baja.
      <br/>
      Lo que buscamos es que siempre pagues lo justo de acuerdo a tu movilidad.
      </p>
      
      <button className='entendido-btn' onClick={handleClose}>Entendido</button>
    </div>
  );

  return (
    <div>
      <p onClick={handleOpen} className='link-modal'>¿Qué pasa si me paso?</p>
      <Modal
        open={open}
        onClose={handleClose}
      >
        {body}
      </Modal>
    </div>
  );
}