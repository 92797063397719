import { useEffect, useState, useRef, useCallback } from "react";
import firebase from "firebase/app";
import { db } from "../../Firebase/firebase";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Dialog from "../Dialog";
import ReplayIcon from "@mui/icons-material/Replay";
import { LoadingButton } from "@mui/lab";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";

function randomValidationCaptcha() {
  return Boolean(Math.round(Math.random()));
}

async function getOdometerDocs() {
  const start = Math.round(Math.random() * 10000);
  const captchaRef = await db
    .collection("/captchas/MEXICO/unchecked")
    .where("available", "==", true)
    .orderBy("timestamp")
    .startAt(start)
    .limit(20)
    .get();

  return captchaRef.docs.map((item) => ({ ...item.data(), id: item.id }));
}

function selectAleatoryImage(odometerDocs) {
  const randomNumber = Math.round(Math.random() * 20);
  const selectedDoc = odometerDocs[randomNumber];

  return selectedDoc;
}

export default function Captcha({
  loading,
  onClose,
  isOpen,
  callBack = () => {},
}) {
  const [odometerDocs, setOdometerDocs] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState({});
  const [isIncorrectCaptcha, setIsIncorrectCaptcha] = useState(false);
  const [kmInput, setKmInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(true);
  const [isRequiredValidation, setIsRequiredValidation] = useState(
    randomValidationCaptcha()
  );
  const isMobile = useMediaQuery("(max-width: 600px)");
  const imgRef = useRef();
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });

      img.style.setProperty("transform", value);
    }
  }, []);

  useEffect(() => {
    getOdometerDocs().then((docs) => {
      setOdometerDocs(docs);
    });
  }, []);
  useEffect(() => {
    odometerDocs.length > 0 &&
      setSelectedDoc(selectAleatoryImage(odometerDocs));
  }, [odometerDocs]);

  function handleOnChangeInputKm(e) {
    setKmInput(e.target.value);
  }

  async function changeRandomDoc() {
    setIsLoadingImg(true);
    setSelectedDoc(selectAleatoryImage(odometerDocs));
    setKmInput("");
    setIsIncorrectCaptcha(false);
    try {
      await db
        .collection(`/captchas/MEXICO/unchecked/`)
        .doc(selectedDoc?.id)
        .update({
          skippedCount: firebase.firestore.FieldValue.increment(1),
        });
    } catch (e) {
      console.error(e);
    }
  }

  async function handleUserSure() {
    setIsLoading(true);
    await db
      .collection(`/captchas/MEXICO/unchecked/${selectedDoc?.id}/answers`)
      .add({
        km: Number(kmInput),
        isUserSure: true,
        available: true,
        timestamp: new Date().getTime(),
      });
    await callBack();
    onClose();
    setIsLoadingImg(false);
  }

  async function validateOpenCaptcha(e) {
    e.preventDefault();
    setIsLoading(true);
    await db
      .collection(`/captchas/MEXICO/unchecked/${selectedDoc?.id}/answers`)
      .add({
        km: Number(kmInput),
        available: true,
        timestamp: new Date().getTime(),
      });
    if (Number(kmInput) !== selectedDoc?.km && isRequiredValidation) {
      setIsIncorrectCaptcha(true);
    } else {
      await callBack();
      onClose();
    }
    setIsLoading(false);
  }

  const handleImageLoad = () => {
    setIsLoadingImg(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        isOpen={isOpen}
        title="¿Qué es lo que ves en la imagen?"
        sx={{
          "& .MuiDialogTitle-root": {
            paddingLeft: ["12px", "24px"],
            alignText: "left",
          },
          "& .MuiPaper-root": {
            maxHeight: ["calc(100% - 27px)", "calc(100% - 64px)"],
            width: ["calc(100% - 8px)"],
            margin: 0,
          },
          "& .MuiDialogContent-root": {
            padding: ["8px", "24px"],
          },
        }}
        content={
          <Box>
            <Stack>
              <Typography>
                Para continuar necesitamos verificar que no eres un robot.
                Captura los kilómetros que indica el odómetro.
              </Typography>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                marginTop: "6px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                {isLoadingImg && (
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={isMobile ? "304px" : "414px"}
                  />
                )}
                {isMobile && !isLoadingImg ? (
                  <QuickPinchZoom onUpdate={onUpdate}>
                    <img
                      onLoad={handleImageLoad}
                      ref={imgRef}
                      src={selectedDoc?.url}
                      alt="Imagen ódometro"
                      style={{
                        width: "100%",
                        height: "300px",
                        objectFit: "cover",
                      }}
                    />
                  </QuickPinchZoom>
                ) : (
                  <Box
                    sx={{
                      position: "relative",
                      height: !isLoadingImg && "414px",
                    }}
                  >
                    <img
                      onLoad={handleImageLoad}
                      ref={imgRef}
                      src={selectedDoc?.url}
                      alt="Imagen ódometro"
                      style={{
                        width: "100%",
                        maxHeight: "",
                        height: "414px",
                        objectFit: "cover",
                        position: "absolute",
                        zIndex: 2500,
                        top: "0",
                        left: "0",
                        display: isLoadingImg ? "none" : "block",
                      }}
                    />
                  </Box>
                )}
                <Typography
                  sx={{ color: "#000", padding: "6px 0 0 0" }}
                  variant="body2"
                >
                  Puedes ampliar la imagen si lo requieres
                </Typography>
              </Box>
              <form onSubmit={validateOpenCaptcha}>
                <TextField
                  placeholder="1000"
                  name="km"
                  value={kmInput}
                  onChange={handleOnChangeInputKm}
                  variant="outlined"
                  size="small"
                  type="number"
                  error={isIncorrectCaptcha}
                  helperText={
                    isIncorrectCaptcha
                      ? "Los kilómetros son incorrectos, por favor inténtalo de nuevo"
                      : " "
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">km</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
                {isIncorrectCaptcha && isMobile && (
                  <LoadingButton
                    variant="outlined"
                    loading={isLoading}
                    onClick={handleUserSure}
                    sx={{ margin: "6px 0" }}
                    fullWidth
                  >
                    No tengo duda que es correcto
                  </LoadingButton>
                )}
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    marginTop: "4px",
                  }}
                >
                  <Tooltip title={<Typography>Cambiar imagen</Typography>}>
                    <Button
                      onClick={changeRandomDoc}
                      sx={{ display: "flex", placeItems: "center" }}
                      endIcon={<ReplayIcon />}
                    >
                      <Typography>Cambiar imagen</Typography>
                    </Button>
                  </Tooltip>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: ["column", "row"],
                    }}
                  >
                    {!isMobile && isIncorrectCaptcha && (
                      <LoadingButton
                        variant="outlined"
                        loading={isLoading}
                        onClick={handleUserSure}
                      >
                        No tengo duda que es correcto
                      </LoadingButton>
                    )}
                    <LoadingButton
                      loading={isLoading}
                      variant="contained"
                      onClick={validateOpenCaptcha}
                    >
                      Verificar
                    </LoadingButton>
                  </Box>
                </Box>
              </form>
            </Stack>
          </Box>
        }
      />
    </>
  );
}
