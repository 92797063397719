import { useState } from "react";
import { Box, Button, FormControl, InputLabel, Select, MenuItem, Typography, TextField } from "@mui/material";
import Dialog from "../Dialog";

export default function ModalFinanciamiento({ open, onClose, confirmFinanciamiento }) {
  const [financialMonth, setFinancialMonth] = useState("");
  const [financialName, setFinancialName] = useState("");

  const handleChange = (event) => {
    setFinancialMonth(event.target.value);
  };

  const handleNameChange = (event) => {
    setFinancialName(toTitleCase(event.target.value));
  };

  function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  return (
    <Dialog
      isOpen={open}
      onClose={onClose}
      sx={{zIndex: 1000}}
      title="Meses de financiamiento"
      content={
        <Box sx={{display: "grid", gap: "20px"}}>
          <Typography variant="body1">Nombre de la financiera (beneficiario preferente)</Typography>
          <FormControl>
            <TextField type="text" onChange={handleNameChange} value={financialName} label="Nombre de la financiera (beneficiario preferente)"/>
          </FormControl>
          <Box>
            <Typography variant="body1">¿En cuántos meses terminas de pagar tu financiamiento?</Typography>
          </Box>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Meses</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={financialMonth}
              label="Meses de financiamiento"
              onChange={handleChange}
            >
              {
                Array.from(Array(60), (el, index) => (
                  <MenuItem key={index} value={index + 1}>{index+1} {index + 1 === 1 ? "mes" : "meses"}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Box>
      }
      actions={
        <>
          <Button onClick={onClose} variant="outlined">
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={() => confirmFinanciamiento(financialMonth, financialName)}
          >Confirmar</Button>
        </>
      }
    />
  );
}
