import AccessTimeIcon from "@mui/icons-material/AccessTime";
import getWeekStartAndEndTimestamp from "../../utils/getWeekTimestamps";
import getWeekNumber from "../../utils/getWeek";
import { Chip } from "@mui/material";
import getQuarterTimestamps from "../../utils/getQuarterTimestamp";
import getCurrentQuarter from "../../utils/getCurrentQuarter";

const TitlesHeadersRange = ({ title, rankingRange, last = false }) => {
  function formatTimestampDate(timestamp) {
    const date = new Date(timestamp);
    const options = { day: "2-digit", month: "short" };
    return date.toLocaleDateString("es-ES", options).replace(/ de /g, " ");
  }

  function formatMonth(timestamp) {
    const date = new Date(timestamp);
    const options = { month: "long" };
    return date.toLocaleDateString("es-ES", options).replace(/ de /g, "");
  }

  function formatQuarter(timestamp) {
    const date = new Date(timestamp);
    const month = date.getMonth();
    if (month <= 2) return "Ene a Mar";
    if (month <= 5) return "Abr a Jun";
    if (month <= 8) return "Jul a Sep";
    return "Oct a Dic";
  }

  function formatYear(timestamp) {
    const date = new Date(timestamp);
    const options = { year: "numeric" };
    return date.toLocaleDateString("es-ES", options);
  }

  function formatDateRange(timestampStart, timestampEnd, rankingRange) {
    if (
      typeof timestampStart === "number" &&
      typeof timestampEnd === "number" &&
      !isNaN(timestampStart) &&
      !isNaN(timestampEnd)
    ) {
      switch (rankingRange) {
        case "weekly": {
          const formattedStart = formatTimestampDate(timestampStart);
          const formattedEnd = formatTimestampDate(timestampEnd);
          return `Del ${formattedStart} al ${formattedEnd}`;
        }
        case "monthly": {
          const formattedMonth = formatMonth(timestampStart);
          return `Mes de ${formattedMonth}`;
        }
        case "quarterly": {
          const formattedQuarter = formatQuarter(timestampStart);
          return `De ${formattedQuarter}`;
        }
        case "yearly": {
          const formattedYear = formatYear(timestampStart);
          return `Año ${formattedYear}`;
        }
        default:
          return "Período no disponible";
      }
    }

    return "Período no disponible";
  }

  const currentDate = new Date();
  let range = "";
  const year = currentDate.getFullYear();
  if (rankingRange === "weekly") {
    const weekNumber = getWeekNumber(currentDate);
    const { startOfWeek, endOfWeek } = getWeekStartAndEndTimestamp(
      weekNumber[0],
      last ? weekNumber[1] - 1 : weekNumber[1]
    );
    range = formatDateRange(startOfWeek.getTime(), endOfWeek.getTime(), rankingRange);
  }
  if (rankingRange === "monthly") {
    const month = last ? currentDate.getMonth() - 1 : currentDate.getMonth();

    const startOfMonth = new Date(year, month, 1);
    startOfMonth.setHours(0, 0, 0, 0);

    const endOfMonth = new Date(year, month + 1, 0);
    endOfMonth.setHours(23, 59, 59, 999);
    range = formatDateRange(startOfMonth.getTime(), endOfMonth.getTime(), rankingRange);
  }

  if (rankingRange === "quarterly") {
    const quarter = getCurrentQuarter();
    const { start, end } = getQuarterTimestamps(last ? quarter - 1 : quarter);
    range = formatDateRange(start, end, rankingRange);
  }

  if (rankingRange === "yearly") {
    range = `Período ${last ? year - 1 : year}`;
  }

  return (
    <>
      {title}
      <Chip
        icon={<AccessTimeIcon sx={{ fontSize: "20px", fill: "#00BBEE" }} />}
        label={range}
        sx={{ maxHeight: "20px", backgroundColor: "#fff" }}
      />
    </>
  );
};

export default TitlesHeadersRange;
